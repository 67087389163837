import { SchedulingInfo } from "@deep-consulting-solutions/bmh-constants";

import { Formik, FormikConfig } from "formik";
import React, { useCallback } from "react";
import { getDurationFromPurpose } from "../SchedulingTool.helpers";
import { PurposeOfConsultationForm } from "./PurposeOfConsultationForm";
import { getInitialValues, validationSchema } from "./helpers";
import { FormValues } from "./types";

interface Props {
  handleUpdateSchedulingDetails: (
    details: Pick<SchedulingInfo, "duration" | "purpose">,
    comment: string
  ) => void;
  handleUpdateInfo: (
    details: Pick<SchedulingInfo, "duration" | "purpose">
  ) => void;
  info: SchedulingInfo;
  comment: string;
  host: "crm" | "client";
}

export const PurposeOfConsultation = ({
  handleUpdateSchedulingDetails,
  info,
  comment,
  host,
  handleUpdateInfo,
}: Props) => {
  const onSubmit: FormikConfig<FormValues>["onSubmit"] = useCallback(
    (values) => {
      const duration = getDurationFromPurpose(values.purpose);
      handleUpdateSchedulingDetails(
        { purpose: values.purpose, duration },
        values.comment
      );
    },
    [handleUpdateSchedulingDetails]
  );

  return (
    <Formik<FormValues>
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      initialValues={getInitialValues(info.purpose, comment)}
    >
      <PurposeOfConsultationForm
        host={host}
        handleUpdateInfo={handleUpdateInfo}
      />
    </Formik>
  );
};
