import React, { useCallback } from "react";
import { Container, Typography, Button, makeStyles } from "@material-ui/core";

import { ZohoServices } from "services";

const useStyles = makeStyles(({ spacing: s }) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "90vh",
  },
  btn: {
    marginTop: s(5),
  },
}));

interface Props {
  message: string;
}

export const InfoMessage: React.FC<Props> = ({ message }) => {
  const handleClose = useCallback(async () => {
    try {
      await ZohoServices.closePopup();
    } catch {
      //
    }
  }, []);

  const classes = useStyles();

  return (
    <Container className={classes.root}>
      <Typography variant="h5" color="primary">
        {message}
      </Typography>
      <Button onClick={handleClose} className={classes.btn} color="primary">
        Close
      </Button>
    </Container>
  );
};
