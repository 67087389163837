import React, { memo, useCallback } from "react";
import { TableCell, Box, Typography, makeStyles } from "@material-ui/core";
import { ExpandLess } from "@material-ui/icons";

import { TABLE_META, FetchResultsParamsFieldEnum } from "./types";

const useStyle = makeStyles(({ palette }) => ({
  cellHeader: {
    whiteSpace: "nowrap",
  },
  wrapper: {
    display: "flex",
    alignItems: "center",
    "&:hover": {
      cursor: "pointer",
    },
  },
  text: {
    color: palette.primary.light,
    fontSize: "14px",
    fontWeight: "bold",
    lineHeight: "20px",
  },
  icon: {
    color: palette.primary.light,
    transition: "0.2s",
  },
}));

interface TableCellHeaderProps {
  column: FetchResultsParamsFieldEnum;
  isDesc?: boolean;
  onClick: (column: FetchResultsParamsFieldEnum) => any;
}

const getRotate = (isDesc?: boolean) => {
  if (isDesc === undefined || isDesc) {
    return "180deg";
  }
  return "0deg";
};

const TableCellHeader: React.FC<TableCellHeaderProps> = ({
  column,
  isDesc,
  onClick,
}) => {
  const { label, noSort } = TABLE_META[column];

  const onColumnClick = useCallback(() => {
    onClick(column);
  }, [onClick, column]);

  const classes = useStyle();

  return (
    <TableCell
      className={classes.cellHeader}
      onClick={noSort ? undefined : onColumnClick}
    >
      <Box className={classes.wrapper}>
        <Typography className={classes.text}>{label}</Typography>
        {!noSort && (
          <ExpandLess
            className={classes.icon}
            style={{
              opacity: isDesc === undefined ? 0 : 100,
              transform: `rotate(${getRotate(isDesc)})`,
            }}
          />
        )}
      </Box>
    </TableCell>
  );
};

export default memo(TableCellHeader);
