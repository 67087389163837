import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  Grid,
  Typography,
  LinearProgress,
} from "@material-ui/core";
import { useHistory, useLocation } from "react-router-dom";
import { useUpdateStaticLastLogin } from "@deep-consulting-solutions/portals-web";
import { Formik, FormikProps, Form, FormikConfig } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { DATE_REQUEST_FORMAT, ROUTES } from "configs";
import { format } from "date-fns";
import { GenderEnum } from "@deep-consulting-solutions/bmh-constants";

import { userActions, userSelectors } from "redux/user";
import { AppDispatch } from "redux/store";
import {
  WrapperBox,
  AddressForm,
  PersonalForm,
  AddressFormValues,
  PersonalFormValues,
  addressValidationSchema,
  personalValidationSchema,
} from "components/UserProfileForm";
import Loader from "components/Loader";

import { FormHeader } from "./FormHeader";

interface FormValues extends PersonalFormValues, AddressFormValues {}

const validationSchema = Yup.object({
  ...personalValidationSchema,
  ...addressValidationSchema,
});

export const ActivationProfileInfoPage = () => {
  const formRef = useRef<FormikProps<FormValues>>(null);
  const dispatch = useDispatch<AppDispatch>();
  const redirectPathname = useSelector(userSelectors.getRedirectPathname);
  const location = useLocation<{ isActivating: boolean }>();
  const history = useHistory();
  const contact = useSelector(userSelectors.getContact);

  const [loading, setLoading] = useState(false);
  const [loadingAddress, setLoadingAddress] = useState(false);
  const [googleReady, setGoogleReady] = useState(false);

  const onContinueClick = useCallback(() => {
    formRef.current?.submitForm();
  }, []);

  const onFormSubmit: FormikConfig<FormValues>["onSubmit"] = useCallback(
    async ({
      gender,
      dob,
      street,
      city,
      region,
      other,
      zip,
      country,
      ...values
    }) => {
      setLoading(true);
      const res = await dispatch(
        userActions.updateUserProfile({
          ...values,
          gender: gender as GenderEnum,
          dob: dob ? format(dob, DATE_REQUEST_FORMAT) : undefined,
          shippingAddress: {
            street,
            city,
            region,
            street2: other,
            zip,
            country,
          },
        })
      );

      if (userActions.updateUserProfile.fulfilled.match(res)) {
        if (redirectPathname) {
          dispatch(userActions.setRedirectPathname(null));
          history.push(redirectPathname);
          return;
        }
        history.replace(ROUTES.dashboard.path);
      } else {
        setLoading(false);
      }
    },
    [history, dispatch, redirectPathname]
  );

  useEffect(() => {
    if (!location.state || !location.state.isActivating) {
      history.replace(ROUTES.profile.path);
    }
  }, [history, location.state]);

  useUpdateStaticLastLogin("Customer");

  return (
    <Formik<FormValues>
      innerRef={formRef}
      initialValues={{
        mobilePhone: contact?.mobilePhone || "",
        phone: contact?.phone || "",
        gender: contact?.gender || "",
        dob: contact?.dob ? new Date(contact?.dob.replace(/-/g, "/")) : null,
        street: contact?.shippingAddress.street || "",
        city: contact?.shippingAddress.city || "",
        region: contact?.shippingAddress.region || "",
        other: contact?.shippingAddress.street2 || "",
        zip: contact?.shippingAddress.zip || "",
        country: contact?.shippingAddress.country || "",
      }}
      onSubmit={onFormSubmit}
      validationSchema={validationSchema}
      enableReinitialize
      validateOnMount
    >
      {(props) => {
        return (
          <Box display="flex" flex="1" py={4}>
            <Loader open={loading || !googleReady} />
            <Grid container spacing={4}>
              <Grid item xs={12} md={3}>
                <Typography
                  color="primary"
                  variant="h4"
                  component="h1"
                  paragraph
                >
                  Profile Information
                </Typography>
                <Typography gutterBottom>
                  Check and edit your profile information.
                </Typography>
                <Typography paragraph>
                  Please, fill in all required fields to continue.
                </Typography>
                <Button color="primary" onClick={onContinueClick}>
                  Continue
                </Button>
              </Grid>
              <Grid item xs={12} md={9}>
                <Form noValidate autoComplete="off">
                  <WrapperBox>
                    <FormHeader title="Personal Information" />
                    <PersonalForm
                      formikProps={props}
                      isActivation
                      contact={contact}
                      editing
                    />
                  </WrapperBox>

                  <WrapperBox>
                    {loadingAddress && (
                      <LinearProgress
                        style={{
                          position: "absolute",
                          top: "0",
                          left: "0",
                          width: "100%",
                        }}
                      />
                    )}
                    <FormHeader
                      title="Your Shipping Address"
                      subtitle="Used for sending medications and blood testing kits if applicable"
                    />
                    <AddressForm
                      formikProps={props}
                      isActivation
                      loadingAddress={loadingAddress}
                      setLoadingAddress={setLoadingAddress}
                      setGoogleReady={setGoogleReady}
                      editing
                    />
                  </WrapperBox>
                </Form>
              </Grid>
            </Grid>
          </Box>
        );
      }}
    </Formik>
  );
};
