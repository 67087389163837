import React from "react";
import {
  Typography,
  makeStyles,
  Button,
  Paper,
  Theme,
} from "@material-ui/core";
import {
  Phone as PhoneIcon,
  Today as CalendarIcon,
  AccessTime as ClockIcon,
} from "@material-ui/icons";
import { MCMCall } from "@deep-consulting-solutions/bmh-constants";

import { getDifferenceInMinutes } from "helpers";
import { getClientFacingFromPurpose } from "components/SchedulingTool/SchedulingTool.helpers";

const useStyle = makeStyles<Theme, { hasTZ?: boolean }>(
  ({ palette: p, spacing: s }) => ({
    paper: {
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      paddingLeft: s(3),
      paddingRight: s(3),
    },
    items: {
      display: "flex",
      alignItems: ({ hasTZ }) => (hasTZ ? "flex-start" : "center"),
    },
    item: {
      textAlign: "center",
      padding: s(3),
    },
    label: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      color: p.text.secondary,
      marginBottom: s(1),
    },
    labelIcon: {
      color: p.text.secondary,
      marginRight: s(0.5),
    },
    labelTitle: {
      color: p.text.secondary,
    },
    actions: {
      display: "flex",
      flexDirection: "column",
      height: "100%",
      alignItems: "flex-end",
      paddingTop: ({ hasTZ }) => (hasTZ ? s(2) : undefined),
    },
    mcmTitle: {
      flex: 1,
    },
  })
);

interface UpcomingMCMCallDesktopProps {
  mcmCall: MCMCall;
  handleOpenCancelMcmCall: () => void;
  openReschedulingDialog: () => any;
  startDate: string;
  startTime: string;
  tz?: string | null;
}

export const UpcomingMCMCallDesktop: React.FC<UpcomingMCMCallDesktopProps> = ({
  mcmCall,
  handleOpenCancelMcmCall,
  openReschedulingDialog,
  startDate,
  startTime,
  tz,
}) => {
  const classes = useStyle({ hasTZ: !!tz });

  return (
    <Paper
      className={classes.paper}
      variant="outlined"
      data-testid="upcoming-mcm-desktop"
    >
      <div>
        <Typography variant="subtitle1" className={classes.mcmTitle}>
          {getDifferenceInMinutes(mcmCall.start, mcmCall.end).string} call with{" "}
          <b>{mcmCall.mcm.name}</b>
        </Typography>

        {!!mcmCall.purpose && (
          <Typography variant="caption" className={classes.mcmTitle}>
            {getClientFacingFromPurpose(mcmCall.purpose, "client")}
          </Typography>
        )}
      </div>
      <div className={classes.items}>
        <div className={classes.item}>
          <div className={classes.label}>
            <PhoneIcon className={classes.labelIcon} />
            <Typography variant="body2" className={classes.labelTitle}>
              Specified phone number
            </Typography>
          </div>
          <Typography variant="body1">{mcmCall.phone || "--"}</Typography>
        </div>

        <div className={classes.item}>
          <div className={classes.label}>
            <CalendarIcon className={classes.labelIcon} />
            <Typography variant="body2" className={classes.labelTitle}>
              Date
            </Typography>
          </div>
          <Typography variant="body1">{startDate}</Typography>
        </div>

        <div className={classes.item}>
          <div className={classes.label}>
            <ClockIcon className={classes.labelIcon} />
            <Typography variant="body2" className={classes.labelTitle}>
              Time
            </Typography>
          </div>
          <Typography variant="body1">{startTime}</Typography>
          {!!tz && <Typography variant="body2">({tz})</Typography>}
        </div>

        <div className={classes.actions}>
          <Button
            variant="text"
            color="primary"
            onClick={openReschedulingDialog}
          >
            reschedule
          </Button>
          <Button
            variant="text"
            color="primary"
            onClick={handleOpenCancelMcmCall}
          >
            cancel
          </Button>
        </div>
      </div>
    </Paper>
  );
};
