import React from "react";
import * as Sentry from "@sentry/react";

import { ErrorDisplay } from "./ErrorDisplay";

export const ErrorCatchPage: React.FC = ({ children }) => {
  return (
    <Sentry.ErrorBoundary fallback={<ErrorDisplay />}>
      {children}
    </Sentry.ErrorBoundary>
  );
};
