import { Button, IconButton } from "@material-ui/core";
import FileDownloadIcon from "components/FileDownloadIcon";
import React, { useCallback } from "react";

interface Props {
  isMinimal?: boolean;
  handleDownloadFile: () => Promise<void>;
}

export const PdfDownloadButton = ({ isMinimal, handleDownloadFile }: Props) => {
  const handleClick = useCallback(() => {
    handleDownloadFile();
  }, [handleDownloadFile]);

  if (isMinimal) {
    return (
      <IconButton onClick={handleClick} color="primary">
        <FileDownloadIcon fontSize="large" />
      </IconButton>
    );
  }
  return (
    <Button
      color="primary"
      variant="outlined"
      endIcon={<FileDownloadIcon />}
      onClick={handleClick}
    >
      Download PDF
    </Button>
  );
};
