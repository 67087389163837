import React, { memo, useEffect, useState, useCallback } from "react";
import { makeStyles, Box, Typography } from "@material-ui/core";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";

import { useResponsive } from "hooks";
import { AppDispatch } from "redux/store";
import { schedulingActions } from "redux/scheduling";
import Loader from "components/Loader";
import bmhLogo from "images/bmh-logo.png";

import CancelMcmCall from "./CancelMcmCall";
import { TOP_BAR_HEIGHT } from "../SchedulingTool.styles";
import { SchedulingTokenStatus } from "../SchedulingTokenStatus";
import { OtherReasonEnum } from "../SchedulingTool.helpers";

const useStyles = makeStyles(({ palette: p, spacing: s, breakpoints: b }) => ({
  wrapper: {
    height: "100vh",
    display: "flex",
    flexDirection: "column",
  },
  header: {
    display: "flex",
    alignItems: "center",
    background: p.common.white,
    borderBottom: `1px solid ${p.primary.main}`,
    height: TOP_BAR_HEIGHT.normal,
    padding: s(0, 1),
    position: "fixed",
    zIndex: 1000,
    width: "100%",
    [b.down("sm")]: {
      height: TOP_BAR_HEIGHT.mobile,
      top: 0,
    },
  },
  headerLogo: {
    width: "56px",
    height: "56px",
    marginRight: s(2),
    [b.down("sm")]: {
      width: "42px",
      height: "42px",
    },
  },
  headerTitle: {
    [b.down("sm")]: {
      marginLeft: "auto",
    },
  },
  content: {
    flex: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: TOP_BAR_HEIGHT.normal,
    [b.down("sm")]: {
      marginTop: TOP_BAR_HEIGHT.mobile,
      display: "block",
    },
  },
  contentInner: {
    minWidth: "90vw",
    minHeight: "90%",
    backgroundColor: p.common.white,
    padding: s(3, 0, 1),
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    [b.down("sm")]: {
      minHeight: `calc(100vh - ${TOP_BAR_HEIGHT.mobile})`,
    },
  },
  status: {
    minHeight: "unset",
  },
}));

export const EmailCancelMcmCall: React.FC = () => {
  const { isSMDown } = useResponsive();
  const classes = useStyles();
  const dispatch = useDispatch<AppDispatch>();

  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const token = params.get("token") || "";

  const [loading, setLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  const fetchUpcomingMCMMeeting = useCallback(async () => {
    try {
      const res = await dispatch(
        schedulingActions.getCancelPageAccessibilityByCancelToken({ token })
      );

      const hasError = schedulingActions.getCancelPageAccessibilityByCancelToken.rejected.match(
        res
      );
      setIsError(hasError);

      setLoading(false);
    } catch (err) {
      setIsError(true);
    }
  }, [dispatch, token]);

  useEffect(() => {
    fetchUpcomingMCMMeeting();
  }, [fetchUpcomingMCMMeeting]);

  return (
    <Box className={classes.wrapper}>
      <Box className={classes.header}>
        <img src={bmhLogo} alt="BMH Logo" className={classes.headerLogo} />
        <Typography
          variant={isSMDown ? "subtitle2" : "h4"}
          color="primary"
          className={classes.headerTitle}
        >
          Cancel PCM consultation
        </Typography>
      </Box>
      <Box className={classes.content}>
        <Box className={classes.contentInner}>
          {(() => {
            if (loading) {
              return <Loader open={loading} />;
            }

            if (isError) {
              return (
                <SchedulingTokenStatus
                  reason={OtherReasonEnum.others}
                  className={classes.status}
                  isCancel
                />
              );
            }

            if (!token) {
              return (
                <SchedulingTokenStatus
                  reason={OtherReasonEnum.noToken}
                  className={classes.status}
                  isCancel
                />
              );
            }

            return <CancelMcmCall isEmail />;
          })()}
        </Box>
      </Box>
    </Box>
  );
};

export const MemoEmailCancelMcmCall = memo(EmailCancelMcmCall);
