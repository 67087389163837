import { SvgIcon, SvgIconProps } from "@material-ui/core";
import React from "react";

export default function BloodIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M14 2l6 6v12a2 2 0 01-2 2H6a2 2 0 01-2-2V4a2 2 0 012-2h8m4 18V9h-5V4H6v16h12m-6-1l-4-4h2.5v-3h3v3H16l-4 4z" />
    </SvgIcon>
  );
}
