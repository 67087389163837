import { ContactRes, MCMCall } from "@deep-consulting-solutions/bmh-constants";
import { createAsyncThunk } from "@reduxjs/toolkit";

import { AppState } from "redux/store";
import { SchedulingTokens } from "components/SchedulingTool/SchedulingToolTokenContext";

import { updateTimeZoneRequest } from "./requests";

export const ENTITY = "user";

export const updateTimeZone = createAsyncThunk<
  ContactRes,
  {
    tz: string;
    tokens?: SchedulingTokens;
    meeting?: MCMCall;
    contactZohoID?: string;
  },
  { state: AppState }
>(
  `${ENTITY}/updateTimeZone`,
  async ({ tz, tokens, meeting, contactZohoID }, { getState }) => {
    const ids = getState().zoho.ids;
    const contact = await updateTimeZoneRequest(tz, {
      tokens,
      zohoID: ids[0] || "",
      contactZohoID,
      meeting,
    });
    return contact;
  }
);
