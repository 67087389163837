import React, { useCallback, useState } from "react";
import { Tooltip, makeStyles, Typography } from "@material-ui/core";

const useStyle = makeStyles(({ spacing: s, typography: typo }) => ({
  info: {
    display: "flex",
    alignItems: "center",
    width: "fit-content",
    marginTop: s(2),
    "&:first-child": {
      marginTop: s(1),
    },
  },
  infoIcon: {
    fontSize: "30px",
    marginRight: s(2),
  },
  infoText: {
    ...typo.body1,
  },
}));

interface ResultItemProps {
  title: string;
  Icon: React.ElementType<any>;
  iconClassName?: string;
  text: string;
}

export const ResultItem: React.FC<ResultItemProps> = ({
  title,
  Icon,
  iconClassName,
  text,
}) => {
  const [position, setPosition] = useState<{ x: number; y: number }>({
    x: 0,
    y: 0,
  });

  const onPositionUpdate = useCallback((e: React.MouseEvent<HTMLElement>) => {
    setPosition({ x: e.pageX, y: e.pageY });
  }, []);

  const classes = useStyle();

  return (
    <Tooltip
      title={title}
      onMouseMove={onPositionUpdate}
      PopperProps={{
        anchorEl: {
          clientHeight: 0,
          clientWidth: 0,
          getBoundingClientRect: () =>
            ({
              top: position.y,
              left: position.x,
              right: position.x,
              bottom: position.y,
              width: 0,
              height: 0,
            } as any),
        },
      }}
    >
      <div className={classes.info}>
        <Icon className={`${classes.infoIcon} ${iconClassName || ""}`} />
        <Typography className={classes.infoText}>{text}</Typography>
      </div>
    </Tooltip>
  );
};
