import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { Box, Typography, makeStyles, Button, Theme } from "@material-ui/core";
import { EventAvailable } from "@material-ui/icons";
import {
  MCMInfo,
  SchedulingInfo,
  MCMCall,
  ContactRes,
} from "@deep-consulting-solutions/bmh-constants";
import { useSelector } from "react-redux";

import { ZohoServices } from "services";
import { zohoSelectors } from "redux/zoho";

import { fetchUpcomingMCMReq } from "redux/scheduling/requests";
import Loader from "components/Loader";
import { convertTimeFromUTCToAnotherTimezone } from "helpers";
import CalendarAlertOutline from "./icons/calendar-alert.svg";

import {
  getCRMMeetingUrl,
  TimeSlot,
  updateSlotDatesWithTZ,
} from "../SchedulingTool.helpers";
import { TOP_BAR_HEIGHT } from "../SchedulingTool.styles";
import { useSchedulingTokens } from "../SchedulingToolTokenContext";
import { getErrorMessages } from "./helpers";
import { DetailsBreakDown } from "./DetailsBreakDown";

interface StyleProps {
  isMessageBold: boolean;
}

const useStyle = makeStyles<Theme, StyleProps>(
  ({ palette: p, spacing: s, typography: typo, breakpoints: b }) => ({
    wrapper: {
      width: "100%",
      height: "100%",
      minHeight: "500px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      padding: s(2),
      [b.down("sm")]: {
        minHeight: `calc(100vh - ${TOP_BAR_HEIGHT.mobile})`,
      },
    },
    eventIcon: {
      fontSize: "100px",
      marginTop: s(2),
      marginBottom: s(2),
    },
    okIcon: {
      color: p.success.main,
    },
    errorIcon: {
      width: "1em",
      height: "1em",
      boxSizing: "border-box",
    },
    title: {
      textAlign: "center",
    },
    sub1: {
      fontWeight: ({ isMessageBold }) => (isMessageBold ? 700 : 400),
      textAlign: "center",
      marginBottom: s(2),
    },
    sub2: {
      color: `rgba(0, 0, 0, 0.54)`,
      textAlign: "center",
    },
    info: {
      display: "flex",
      alignItems: "center",
      width: "fit-content",
      marginTop: s(2),
      "&:first-child": {
        marginTop: s(1),
      },
    },
    infoText: {
      ...typo.body1,
    },
    actions: {
      marginTop: s(3),
    },
    rebook: {},
    retry: {
      marginRight: s(2),
    },
    crmActions: {
      marginTop: s(1),
      textAlign: "center",
    },
    goToRecordButton: {
      outline: 0,
      border: 0,
      background: "none",
      textDecoration: "underline",
      color: "#2F80ED",
      padding: 0,
      margin: 0,
      cursor: "pointer",
    },
    link: {
      fontSize: typo.subtitle1.fontSize,
      color: p.secondary.main,
      textDecoration: "underline",
      "&:hover": {
        color: p.secondary.main,
      },
    },
  })
);

interface SchedulingResultProps {
  isSuccess: boolean;
  info: SchedulingInfo;
  mcm?: MCMInfo | null;
  slot: TimeSlot | null;
  rebook: () => any;
  retry: () => any;
  meeting?: MCMCall;
  nextMeetingZohoID?: string | null;
  host: "crm" | "client";
  errorMessage: string;
  handleClose: () => void;
  isFromEmail: boolean;
  contact?: ContactRes;
}

const SchedulingResult: React.FC<SchedulingResultProps> = ({
  isSuccess,
  info,
  mcm,
  slot: inputSlot,
  rebook,
  retry,
  meeting,
  nextMeetingZohoID,
  host,
  errorMessage,
  handleClose,
  isFromEmail,
  contact,
}) => {
  const tokens = useSchedulingTokens();
  const entityType = useSelector(zohoSelectors.getEntity);
  const [loading, setLoading] = useState(false);
  const [upcomingMCM, setUpcomingMCM] = useState<{
    call: MCMCall | null;
    schedulingInfo?: SchedulingInfo | undefined;
  } | null>(null);

  const slot = useMemo(() => {
    if (!inputSlot) return null;
    return updateSlotDatesWithTZ(
      inputSlot,
      info?.bmhTZ ?? "",
      info?.clientTZ || ""
    );
  }, [info?.clientTZ, info?.bmhTZ, inputSlot]);

  const isReschedule = useMemo(() => !!meeting, [meeting]);

  const {
    errorHeader,
    errorSubheader,
    canRetry,
    errorTitle,
    canClose,
    canRebook,
    withLink,
    showBreakdown,
    isMessageBold,
  } = useMemo(() => {
    return getErrorMessages(errorMessage, host, isReschedule, isFromEmail);
  }, [errorMessage, host, isReschedule, isFromEmail]);

  const onRebookClick = useCallback(() => {
    rebook();
  }, [rebook]);

  const onRetryClick = useCallback(() => {
    retry();
  }, [retry]);

  const onCRMCloseClick = useCallback(() => {
    if (nextMeetingZohoID && tokens.crmToken && entityType) {
      ZohoServices.goToZohoRecord({
        Entity: entityType,
        RecordID: nextMeetingZohoID,
      });
    } else {
      ZohoServices.closePopup();
    }
  }, [nextMeetingZohoID, entityType, tokens.crmToken]);

  const {
    upcomingMcm,
    upcomingSchedulingInfo,
    upcomingSlot,
    meetingZohoID,
  } = useMemo(() => {
    if (upcomingMCM?.schedulingInfo && upcomingMCM?.call) {
      return {
        upcomingMcm: upcomingMCM.schedulingInfo.mcm,
        upcomingSchedulingInfo: {
          ...upcomingMCM.schedulingInfo,
          purpose: upcomingMCM.call.purpose || undefined,
        },
        upcomingSlot: {
          start: convertTimeFromUTCToAnotherTimezone(
            upcomingMCM.call.start,
            upcomingMCM.call.clientTZ
          ),
          end: convertTimeFromUTCToAnotherTimezone(
            upcomingMCM.call.end,
            upcomingMCM.call.clientTZ
          ),
        },
        meetingZohoID: upcomingMCM.call.zohoID,
      };
    }

    return {
      upcomingMcm: null,
      upcomingSchedulingInfo: null,
      upcomingSlot: null,
      meetingZohoID: null,
    };
  }, [upcomingMCM]);

  const getNewMeetingDetails = useCallback(async () => {
    try {
      if (showBreakdown || withLink) {
        setLoading(true);
        setUpcomingMCM(
          await fetchUpcomingMCMReq(
            tokens,
            withLink ? contact?.zohoID : undefined
          )
        );
      }
    } catch {
      //
    } finally {
      setLoading(false);
    }
  }, [showBreakdown, withLink, tokens, contact?.zohoID]);

  useEffect(() => {
    getNewMeetingDetails();
  }, [getNewMeetingDetails]);

  const classes = useStyle({ isMessageBold });

  return (
    <Box className={classes.wrapper}>
      <Loader open={loading} />
      {isSuccess && (
        <>
          <Typography variant="h4" color="primary" className={classes.title}>
            Confirmation
          </Typography>
          <EventAvailable
            className={`${classes.eventIcon} ${classes.okIcon}`}
          />
          <Typography variant="body1" color="primary" className={classes.sub1}>
            {!!tokens.crmToken &&
              `The PCM call has been successfully ${
                meeting ? "rescheduled" : "scheduled"
              } for the client`}
            {!tokens.crmToken &&
              `You have successfully ${
                meeting ? "rescheduled" : "scheduled"
              } a call with our Personal Case Manager`}
          </Typography>
          <Typography variant="body2" className={classes.sub2}>
            {!!tokens.crmToken &&
              "A notification email has been sent to the client with the meeting information"}
            {!tokens.crmToken &&
              "We have sent you an email with the meeting information."}
          </Typography>
          <DetailsBreakDown mcm={mcm} info={info} slot={slot} host={host} />
          {!!tokens.crmToken && (
            <div className={classes.crmActions}>
              <Button color="primary" onClick={onCRMCloseClick}>
                Close
              </Button>
            </div>
          )}
        </>
      )}

      {!isSuccess && (
        <>
          <Typography variant="h4" color="primary" className={classes.title}>
            {errorTitle}
          </Typography>
          <img
            src={CalendarAlertOutline}
            alt="calendar alert"
            className={`${classes.eventIcon} ${classes.errorIcon}`}
          />

          <Typography variant="body1" color="primary" className={classes.sub1}>
            {errorHeader.split("\n").map((msg, index) => (
              <>
                {msg}
                {index !== errorHeader.split("\n").length - 1 && <br />}
              </>
            ))}
          </Typography>
          {!!errorSubheader && (
            <Typography variant="body2" className={classes.sub2}>
              {errorSubheader}
            </Typography>
          )}
          {withLink && meetingZohoID && (
            <a
              href={getCRMMeetingUrl(meetingZohoID)}
              target="_blank"
              rel="noreferrer"
              className={classes.link}
            >
              {meetingZohoID}
            </a>
          )}

          {showBreakdown && upcomingMcm && upcomingSchedulingInfo && (
            <DetailsBreakDown
              mcm={upcomingMcm}
              info={upcomingSchedulingInfo}
              slot={upcomingSlot}
              host={host}
            />
          )}

          <Box className={classes.actions}>
            {canRetry && (
              <Button
                className={classes.retry}
                color="primary"
                onClick={onRetryClick}
              >
                Retry
              </Button>
            )}
            {canRebook && (
              <Button
                className={classes.rebook}
                color="primary"
                onClick={onRebookClick}
              >
                {isReschedule ? "back to reschedule" : "Rebook"}
              </Button>
            )}

            {canClose && (
              <Button
                className={classes.rebook}
                color="primary"
                onClick={handleClose}
              >
                Close
              </Button>
            )}
          </Box>
        </>
      )}
    </Box>
  );
};

export default memo(SchedulingResult);
