import { initPortals } from "@deep-consulting-solutions/portals-web";
import { apiClient } from "apis";

initPortals({
  apiClient,
  staticPortals: {
    Customer: {
      display: "Customer Portal",
    },
  },
});
