import React, { ReactNode, useCallback, useMemo } from "react";
import { RouteProps, Route } from "react-router-dom";

import PrivateRoute from "components/PrivateRoute";

import {
  AppDispatch,
  pkAuthSelectors,
  pkAuthActions,
} from "@deep-consulting-solutions/auth-web";
import { createStyles, makeStyles, MenuItem } from "@material-ui/core";
import UserNameMenuBox, { LinkItem } from "components/UserNameMenuBox";
import { ROUTES } from "configs";
import { useDispatch, useSelector } from "react-redux";
import AuthLayout from "./AuthLayout";

interface Props extends Omit<RouteProps, "component"> {
  privateRoute?: boolean;
  component: NonNullable<RouteProps["component"]>;
  headerComponent?: ReactNode;
}

const useStyles = makeStyles((theme) =>
  createStyles({
    activeLink: {
      "& > li": {
        background: theme.palette.action.selected,
        "&:hover": {
          background: theme.palette.action.selected,
        },
      },
    },
  })
);

const AuthRoute: React.FC<Props> = ({
  headerComponent,
  component: Component,
  privateRoute,
  ...rest
}) => {
  const R = privateRoute ? PrivateRoute : Route;
  const dispatch = useDispatch<AppDispatch>();
  const contact = useSelector(pkAuthSelectors.authContact);

  const onLogoutClick = useCallback(() => {
    dispatch(pkAuthActions.logout());
  }, [dispatch]);

  const classes = useStyles();

  const header = useMemo(
    () =>
      headerComponent ||
      (privateRoute && headerComponent !== null ? (
        <UserNameMenuBox contact={contact}>
          <LinkItem
            activeClassName={classes.activeLink}
            to={ROUTES.profile.path}
          >
            <MenuItem>View Profile</MenuItem>
          </LinkItem>
          <MenuItem onClick={onLogoutClick}>Logout</MenuItem>
        </UserNameMenuBox>
      ) : (
        headerComponent
      )),
    [classes.activeLink, contact, headerComponent, onLogoutClick, privateRoute]
  );

  return (
    <R
      {...rest}
      render={(props: any) => (
        <AuthLayout header={header}>
          <Component {...props} />
        </AuthLayout>
      )}
    />
  );
};

export default AuthRoute;
