import React from "react";
import { Button, ButtonProps, makeStyles } from "@material-ui/core";

const useStyle = makeStyles(() => ({
  button: {
    paddingTop: 13,
    paddingBottom: 13,
    width: "100%",
  },
}));

const BigButton: React.FC<ButtonProps> = ({
  children,
  className,
  ...props
}) => {
  const classes = useStyle();
  const classNameFull = `${classes.button} ${className || ""}`;
  return (
    <Button className={classNameFull} {...props}>
      {children}
    </Button>
  );
};

export default BigButton;
