import React from "react";
import {
  AccountCircle,
  AccessTime as ClockIcon,
  Public as TZIcon,
  Today as CalendarIcon,
  Info,
} from "@material-ui/icons";
import {
  MCMInfo,
  SchedulingInfo,
} from "@deep-consulting-solutions/bmh-constants";
import { Box, makeStyles } from "@material-ui/core";

import {
  TimeSlot,
  formatDateInFull,
  formatSlotTime,
  findDaysDiff,
  calcDurationInMinutes,
  getClientFacingFromPurpose,
} from "../SchedulingTool.helpers";
import { ResultItem } from "./ResultItem";

const useStyle = makeStyles(({ palette: p, spacing: s }) => ({
  infos: {
    marginTop: s(3),
  },
  accountIcon: {
    color: p.primary.dark,
  },
  clockIcon: {
    color: p.primary.light,
  },
  infoIcon: {
    color: p.primary.main,
  },
  calendarIcon: {
    color: p.secondary.dark,
  },
  zoneIcon: {
    color: p.text.secondary,
  },
}));

interface DetailsBreakDownProps {
  info: SchedulingInfo;
  mcm?: MCMInfo | null;
  slot: Pick<TimeSlot, "start" | "end"> | null;
  host: "crm" | "client";
}

export const DetailsBreakDown = ({
  mcm,
  info,
  slot,
  host,
}: DetailsBreakDownProps) => {
  const classes = useStyle();
  return (
    <>
      <Box className={classes.infos}>
        <ResultItem
          title="PCM"
          Icon={AccountCircle}
          text={mcm?.name ?? ""}
          iconClassName={classes.accountIcon}
        />
        {!!info.purpose && (
          <ResultItem
            title="Purpose of Consultation"
            Icon={Info}
            iconClassName={classes.infoIcon}
            text={getClientFacingFromPurpose(info.purpose, host)}
          />
        )}
        <ResultItem
          title="Meeting duration"
          Icon={ClockIcon}
          iconClassName={classes.clockIcon}
          text={`${slot ? calcDurationInMinutes(slot.start, slot.end) : 0} min`}
        />
        <ResultItem
          title="Meeting time"
          Icon={CalendarIcon}
          iconClassName={classes.calendarIcon}
          text={`                ${slot ? formatDateInFull(slot.start) : ""}. ${
            slot ? formatSlotTime(slot.start, true) : ""
          } - ${slot ? formatSlotTime(slot.end, true) : ""} ${
            slot ? findDaysDiff(slot.start, slot.end) : ""
          }`}
        />
        <ResultItem
          title="Timezone"
          Icon={TZIcon}
          text={info?.clientTZ ?? ""}
          iconClassName={classes.zoneIcon}
        />
      </Box>
    </>
  );
};
