import { createAsyncThunk } from "@reduxjs/toolkit";
import { MCMCall } from "@deep-consulting-solutions/bmh-constants";

import type { AppState } from "redux/store";
import {
  AppDispatch,
  pkAuthActions,
  ReducerSliceEnum,
} from "@deep-consulting-solutions/auth-web";
import { SchedulingTokens } from "components/SchedulingTool/SchedulingToolTokenContext";

import { AxiosError } from "axios";
import {
  createMCMReq,
  rescheduleMCMReq,
  CreateMCMData,
  RescheduleMCMData,
} from "./requests";

export const ENTITY = "scheduling";

const updateAuthContactPhoneNumber = (
  dispatch: AppDispatch,
  getState: () => AppState,
  data: CreateMCMData,
  tokens: SchedulingTokens
) => {
  if (
    !tokens.crmToken &&
    !tokens.emailToken &&
    data.phone &&
    data.setAsMainPhone
  ) {
    const { user, contact, token } = getState()[ReducerSliceEnum.auth];
    if (user && contact) {
      dispatch(
        pkAuthActions.setAuthorised({
          token,
          user: {
            ...user,
            contact: {
              ...contact,
              phone: data.phone,
            },
          },
        })
      );
    }
  }
};

export const createMCM = createAsyncThunk<
  MCMCall,
  { data: CreateMCMData; tokens: SchedulingTokens },
  { state: AppState; dispatch: AppDispatch }
>(`${ENTITY}/createMCM`, async ({ data, tokens }, { getState, dispatch }) => {
  try {
    const zohoID = getState().zoho.ids[0] || "";
    const res = await createMCMReq(data, zohoID, tokens);

    updateAuthContactPhoneNumber(dispatch, getState, data, tokens);
    return res;
  } catch (e) {
    const err = e as AxiosError;
    throw new Error(err.response?.data.message);
  }
});

export const rescheduleMCM = createAsyncThunk<
  MCMCall,
  { data: RescheduleMCMData; meeting: MCMCall; tokens: SchedulingTokens },
  { state: AppState; dispatch: AppDispatch }
>(
  `${ENTITY}/rescheduleMCM`,
  async ({ data, meeting, tokens }, { getState, dispatch }) => {
    try {
      const zohoID = getState().zoho.ids[0];
      const res = await rescheduleMCMReq(meeting, data, tokens, zohoID);
      updateAuthContactPhoneNumber(dispatch, getState, data, tokens);
      return res;
    } catch (e) {
      const err = e as AxiosError;
      throw new Error(err.response?.data.message);
    }
  }
);
