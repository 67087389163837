import { TableCellProps } from "@material-ui/core";

export enum FetchResultsParamsFieldEnum {
  name = "name",
  bloodTakingOption = "bloodTakingOption",
  sampleCollectedOn = "sampleCollectedOn",
}

export interface FetchResultsParams {
  page: string;
  size: string;
  sort: "ASC" | "DESC";
  field: FetchResultsParamsFieldEnum;
}

interface TableMetaProps extends TableCellProps {
  label: string;
  noSort?: boolean;
}

export const TABLE_META: {
  [key in FetchResultsParamsFieldEnum | "action"]: TableMetaProps;
} = {
  [FetchResultsParamsFieldEnum.name]: {
    label: "Blood Tests",
  },
  [FetchResultsParamsFieldEnum.bloodTakingOption]: {
    label: "Blood Taking",
  },
  [FetchResultsParamsFieldEnum.sampleCollectedOn]: {
    label: "Test Date",
  },
  action: {
    label: "",
    noSort: true,
  },
};
