import { ConsultationPurposeEnum } from "@deep-consulting-solutions/bmh-constants";
import { format, differenceInDays } from "date-fns";

import { convertTimeFromUTCToAnotherTimezone } from "helpers";
import { formatDateInFull, formatSlotTime } from "../SchedulingTool.helpers";

export interface CallInfo {
  id: string;
  mcmName: string;
  clientName: string;
  callDuration: string;
  timeSlot: string;
  timezone: string;
  purpose?: ConsultationPurposeEnum | null;
}

export const displaySlot = (start: string, end: string, tz?: string | null) => {
  const startDate = tz
    ? convertTimeFromUTCToAnotherTimezone(start, tz)
    : new Date(start);
  const endDate = tz
    ? convertTimeFromUTCToAnotherTimezone(end, tz)
    : new Date(end);

  const isSameMeridian = format(startDate, "aa") === format(endDate, "aa");
  const isSameDate = !!differenceInDays(startDate, endDate);

  return `${formatDateInFull(startDate)}. ${formatSlotTime(
    startDate,
    !isSameMeridian
  )} - ${formatSlotTime(endDate, true)}${isSameDate ? " (+1)" : ""}`;
};
