import React from "react";
import { useResponsive } from "@deep-consulting-solutions/auth-web";
import {
  Box,
  Typography,
  Button,
  IconButton,
  Divider,
  createStyles,
  makeStyles,
} from "@material-ui/core";
import { Edit } from "@material-ui/icons";

const useStyles = makeStyles(({ palette: p }) =>
  createStyles({
    title: {
      color: p.primary.light,
    },
    subtitle: {
      color: p.grey[600],
    },
  })
);

interface FormHeaderProps {
  title: string;
  subtitle?: string;
  onEditClick: () => any;
  onCancelClick: () => any;
  editing: boolean;
  disabled: boolean;
  verticalButtonsOnMobile?: boolean;
}

export const FormHeader: React.FC<FormHeaderProps> = ({
  title,
  subtitle,
  editing,
  onEditClick,
  onCancelClick,
  disabled,
  verticalButtonsOnMobile,
}) => {
  const classes = useStyles();
  const { isXSDown } = useResponsive();
  const isVerticalButtonsOnMobile = isXSDown && verticalButtonsOnMobile;

  return (
    <>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <div>
          <Typography
            className={classes.title}
            variant={isXSDown ? "subtitle2" : "subtitle1"}
          >
            {title}
          </Typography>
          {subtitle && (
            <Typography variant="caption" className={classes.subtitle}>
              {subtitle}
            </Typography>
          )}
        </div>
        <Box
          display="flex"
          alignItems="center"
          flexDirection={
            isVerticalButtonsOnMobile ? "column-reverse" : undefined
          }
          justifyContent={isVerticalButtonsOnMobile ? "center" : undefined}
        >
          {editing ? (
            <>
              <Box
                mr={isVerticalButtonsOnMobile ? 0 : 2}
                mt={isVerticalButtonsOnMobile ? 1 : 0}
              >
                <Button variant="text" onClick={onCancelClick}>
                  Cancel
                </Button>
              </Box>
              <Button color="primary" type="submit" disabled={disabled}>
                Save
              </Button>
            </>
          ) : (
            <IconButton style={{ padding: "6px" }} onClick={onEditClick}>
              <Edit />
            </IconButton>
          )}
        </Box>
      </Box>
      <Box mt={0.5} mb={3}>
        <Divider />
      </Box>
    </>
  );
};
