import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

import { shouldAllowOffline } from "helpers";
import "services/pkAuth";
import "services/pkPortals";
import "services/pkInvoices";
import Root from "components/Root";
import { notifications } from "services";
import { ConstructionPage } from "Construction";
import App from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN || "",
  integrations: [
    new Integrations.BrowserTracing({
      tracingOrigins: [process.env.REACT_APP_BASE_URL!, /^\//],
    }),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
  environment: process.env.REACT_APP_SENTRY_ENV || "",
});

if (process.env.REACT_APP_CONSTRUCTION_STATE === "construction") {
  ReactDOM.render(<ConstructionPage />, document.getElementById("root"));
} else {
  ReactDOM.render(
    <Root>
      <App />
    </Root>,
    document.getElementById("root")
  );
}

if (shouldAllowOffline()) {
  serviceWorkerRegistration.register({
    onSuccess: () => {
      notifications.notifyInfo(
        "This web app can be installed on the home screen and also works partially offline."
      );
    },
    onUpdate: () => {
      notifications.notifyInfo(
        "A new update is available. It will be used when all tabs of the app are closed."
      );
    },
  });
} else {
  serviceWorkerRegistration.unregister();
}
