import React, { useCallback, useState, useEffect } from "react";
import { FormikCountrySelectInput } from "@deep-consulting-solutions/dcs-web-ui";
import { Grid, TextField as MuiTextField } from "@material-ui/core";
import {
  Autocomplete,
  AutocompleteRenderInputParams,
  AutocompleteChangeReason,
} from "@material-ui/lab";
import { Field, FormikProps } from "formik";
import { TextField } from "formik-material-ui";
import usePlacesAutocomplete, { getGeocode } from "use-places-autocomplete";

import { useScript } from "hooks";
import { UK_LAT, UK_LNG } from "configs";
import { VALIDATIONS } from "helpers";

import {
  getLongAddressObject,
  getShortAddressObject,
  statusMessage,
} from "./helpers";

export interface AddressFormValues {
  street: string;
  city: string;
  region: string;
  other: string;
  zip: string;
  country: string;
}

export const addressValidationSchema = {
  street: VALIDATIONS.street,
  city: VALIDATIONS.city,
  region: VALIDATIONS.region,
  other: VALIDATIONS.other,
  zip: VALIDATIONS.zip,
  country: VALIDATIONS.country,
};

interface Props<T extends AddressFormValues> {
  formikProps: FormikProps<T>;
  isActivation?: boolean;
  loadingAddress: boolean;
  setLoadingAddress: React.Dispatch<React.SetStateAction<boolean>>;
  setGoogleReady?: React.Dispatch<React.SetStateAction<any>>;
  editing?: boolean;
}

export const AddressForm = <T extends AddressFormValues>({
  isActivation,
  formikProps,
  setLoadingAddress,
  loadingAddress,
  setGoogleReady,
  editing,
}: Props<T>) => {
  const { values, touched, errors, isSubmitting } = formikProps;

  const googleStatus = useScript(
    `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_API_KEY}&libraries=places&callback=placesLoaded`
  );

  const [location, setLocation] = useState<google.maps.LatLng | undefined>(
    undefined
  );

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [error, setError] = useState("");

  const {
    ready,
    suggestions: { data, loading: placesLoading },
    setValue,
  } = usePlacesAutocomplete({
    requestOptions: {
      types: ["address"],
      location,
      radius: 38600000,
    },
    callbackName: "placesLoaded",
  });

  const handleSelect = useCallback(
    async (address: string) => {
      setLoadingAddress(true);
      setValue(address, false);
      try {
        const results = await getGeocode({ address });
        setLoadingAddress(false);
        return results[0];
      } catch (err) {
        setLoadingAddress(false);
        setError(statusMessage(err));
      }
      return undefined;
    },
    [setValue, setLoadingAddress]
  );

  const getOptionSelected = useCallback(
    (
      option: google.maps.places.AutocompletePrediction,
      value: google.maps.places.AutocompletePrediction
    ) => {
      const [stNumber, stName] = ((value as unknown) as string).split(" ");
      const hasNumber = option.description.includes(stNumber);
      const hasName = option.description.includes(stName);

      return hasNumber && hasName;
    },
    []
  );

  const onInputChange = useCallback(
    (_event: React.ChangeEvent<any>, value: string) => {
      setError("");
      setValue(value);
      formikProps.setFieldValue("street", value);
    },
    [setValue, formikProps]
  );

  const onAutocompleteChange = useCallback(
    async (
      event: React.ChangeEvent<any>,
      value: string | google.maps.places.AutocompletePrediction | null,
      reason: AutocompleteChangeReason
    ) => {
      if (reason === "select-option") {
        const result = await handleSelect(
          (value as google.maps.places.AutocompletePrediction).description
        );
        if (result) {
          const longAddress = getLongAddressObject(result);
          const shortAddress = getShortAddressObject(result);

          const street = `${longAddress.street_number || ""}${
            longAddress.route
              ? `${longAddress.street_number ? " " : ""}${longAddress.route}`
              : ""
          }`;

          formikProps.setFieldValue("street", street);
          setValue(street, false);

          formikProps.setFieldValue(
            "city",
            longAddress.locality ||
              longAddress.administrative_area_level_3 ||
              longAddress.administrative_area_level_4 ||
              longAddress.postal_town ||
              longAddress.neighborhood ||
              longAddress.sublocality ||
              ""
          );

          const regionValue =
            shortAddress.country === "GB"
              ? longAddress.administrative_area_level_2 ||
                longAddress.administrative_area_level_1
              : longAddress.administrative_area_level_1 ||
                longAddress.administrative_area_level_2;

          formikProps.setFieldValue("region", regionValue || "");

          formikProps.setFieldValue("zip", longAddress.postal_code || "");

          formikProps.setFieldValue("country", longAddress.country || "");
        }
      } else if (reason === "clear") {
        formikProps.setFieldValue("street", "");
      } else if (reason === "create-option") {
        //
      } else if (reason === "remove-option") {
        //
      } else if (reason === "blur") {
        //
      }
    },
    [handleSelect, setValue, formikProps]
  );

  const onInputBlur = useCallback(() => {
    formikProps.setFieldTouched("street", true);
  }, [formikProps]);

  useEffect(() => {
    if (ready) {
      setLocation(new google.maps.LatLng(UK_LAT, UK_LNG));
    }
  }, [ready]);

  useEffect(() => {
    if (googleStatus === "error") {
      setError("Failed to load Google Maps Places library.");
    } else {
      setError("");
    }
  }, [googleStatus]);

  useEffect(() => {
    if (setGoogleReady) setGoogleReady(ready);
  }, [ready, setGoogleReady]);

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={isActivation ? undefined : 4}>
          <Autocomplete<
            google.maps.places.AutocompletePrediction,
            false,
            false,
            true
          >
            freeSolo
            noOptionsText="No Results Found"
            value={values.street}
            getOptionSelected={getOptionSelected}
            onChange={onAutocompleteChange}
            inputValue={values.street}
            onInputChange={onInputChange}
            loading={placesLoading}
            disabled={!editing || loadingAddress || isSubmitting}
            options={data}
            getOptionLabel={(
              option: google.maps.places.AutocompletePrediction | string
            ) => (typeof option === "string" ? option : option.description)}
            renderInput={(params: AutocompleteRenderInputParams) => (
              <MuiTextField
                {...params}
                name="street"
                autoComplete="off"
                onBlur={onInputBlur}
                error={touched.street && !!errors.street}
                helperText={touched.street && errors.street}
                label="Shipping Street"
                required
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={isActivation ? undefined : 4}>
          <Field
            component={TextField}
            name="city"
            label="Shipping City"
            disabled={!editing || loadingAddress || isSubmitting}
            required
          />
        </Grid>
        <Grid item xs={12} sm={6} md={isActivation ? undefined : 4}>
          <Field
            component={TextField}
            name="region"
            label="Shipping Region"
            disabled={!editing || loadingAddress || isSubmitting}
            required
          />
        </Grid>
        <Grid item xs={12} sm={6} md={isActivation ? undefined : 4}>
          <Field
            component={TextField}
            name="other"
            label="Apartment / Flat / Door / Other"
            disabled={!editing || loadingAddress || isSubmitting}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={isActivation ? undefined : 4}>
          <Field
            component={TextField}
            name="zip"
            label="Shipping Postal Code"
            disabled={!editing || loadingAddress || isSubmitting}
            required
          />
        </Grid>
        <Grid item xs={12} sm={6} md={isActivation ? undefined : 4}>
          <Field
            component={FormikCountrySelectInput}
            name="country"
            label="Shipping Country"
            disabled={!editing || loadingAddress || isSubmitting}
            required
            useCountryCode={false}
          />
        </Grid>
      </Grid>
    </>
  );
};
