import * as Yup from "yup";
import {
  validatePhoneNumberWithCountryCode,
  simpleValidatePhoneNumber,
} from "@deep-consulting-solutions/dcs-web-ui";

import { getENText } from "./languages";

export const VALIDATIONS = {
  email: Yup.string()
    .email(getENText("validation.email.invalid"))
    .required(getENText("validation.email.required")),
  emailNotRequired: Yup.string().email(getENText("validation.email.invalid")),
  firstName: Yup.string().required(getENText("validation.firstName.required")),
  firstNameNotRequired: Yup.string(),
  lastName: Yup.string().required(getENText("validation.lastName.required")),
  lastNameNotRequired: Yup.string(),
  phone: Yup.string()
    .required(getENText("validation.phone.required"))
    .test(
      "check validity",
      getENText("validation.phone.invalid"),
      validatePhoneNumberWithCountryCode
    ),
  phoneNotRequired: Yup.string().test(
    "check validity",
    getENText("validation.phone.invalid"),
    simpleValidatePhoneNumber
  ),
  passwordLogin: Yup.string().required(
    getENText("validation.password.required")
  ),
  password: Yup.string()
    .min(8, getENText("validation.password.min"))
    .required(getENText("validation.password.required")),
  repassword: Yup.string()
    .required(getENText("validation.repassword.required"))
    .oneOf([Yup.ref("password")], getENText("validation.repassword.match")),
  street: Yup.string().required(getENText("validation.street.required")),
  city: Yup.string().required(getENText("validation.city.required")),
  region: Yup.string().required(getENText("validation.region.required")),
  other: Yup.string(),
  zip: Yup.string().required(getENText("validation.zip.required")),
  country: Yup.string().required(getENText("validation.country.required")),
  dob: Yup.date().required(getENText("validation.dob.required")).nullable(),
  gender: Yup.string().required(getENText("validation.gender.required")),
  reason: Yup.string().required(getENText("validation.reason.required")),
};
