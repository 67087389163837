import React, { useMemo } from "react";
import {
  BloodTakingOptionEnum,
  UserBloodTestResult,
} from "@deep-consulting-solutions/bmh-constants";
import {
  Box,
  Hidden,
  IconButton,
  makeStyles,
  Typography,
} from "@material-ui/core";
import {
  ChevronRight,
  Today,
  ErrorOutline as ErrorIcon,
} from "@material-ui/icons";
import { Link } from "react-router-dom";

import { processSampleCollectedDate } from "helpers";
import { ReactComponent as FingerIcon } from "images/finger.svg";
import { ReactComponent as SyringeIcon } from "images/syringe.svg";

import { getResultDetailsRoute } from "./helpers";

const useStyles = makeStyles(
  ({ spacing: s, palette: p, breakpoints: b, shadows, typography: typo }) => ({
    card: {
      display: "flex",
      textDecoration: "none",
      alignItems: "center",
      color: p.text.primary,
      backgroundColor: p.background.paper,
      "&:hover": {
        cursor: "pointer",
        backgroundColor: p.action.hover,
        textDecoration: "none",
      },
      [b.up("sm")]: {
        width: `calc(50% - ${s(1)}px)`,
        marginTop: s(2),
        boxShadow: shadows[2],
        padding: s(2),
        borderRadius: s(1),

        "&:nth-child(odd)": {
          marginRight: s(2),
        },
      },
      [b.only("xs")]: {
        width: "100%",
        padding: s(2, 0, 2, 2),
        borderBottom: `1px solid ${p.divider}`,
        "&:first-child": {
          borderTop: `1px solid ${p.divider}`,
        },
      },
    },
    takingOptionIcon: {
      fill: p.primary.light,
    },
    collectedDate: {
      display: "flex",
      alignItems: "flex-start",
      marginTop: s(1),
      color: p.error.light,
    },
    collectedDateErrorIcon: {
      marginRight: s(0.5),
      color: p.error.light,
      fontSize: typo.body1.fontSize,
    },
  })
);

interface ResultListItemProps {
  result: UserBloodTestResult;
}

export const ResultListItem: React.FC<ResultListItemProps> = ({ result }) => {
  const { date, warning } = useMemo(() => processSampleCollectedDate(result), [
    result,
  ]);

  const classes = useStyles();
  return (
    <Link
      className={classes.card}
      to={getResultDetailsRoute(result.id)}
      data-testid="blood-tests-page/item"
    >
      <Box
        color="primary.light"
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        flex="1"
        height="100%"
        mr={1}
      >
        <Typography component="h2" variant="h5" paragraph>
          {result.name}
        </Typography>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box display="flex" alignItems="center">
            <Box mr={1} display="inline-flex" alignItems="center">
              <Today />
            </Box>
            <Typography color="textPrimary">{date}</Typography>
          </Box>
          {result.bloodTakingOption && (
            <Box display="flex" alignItems="center">
              <Box
                className={classes.takingOptionIcon}
                mr={1}
                display="inline-flex"
                alignItems="center"
                justifyContent="center"
                width="20px"
                height="20px"
              >
                {result.bloodTakingOption ===
                BloodTakingOptionEnum.fingerprick ? (
                  <FingerIcon />
                ) : (
                  <SyringeIcon />
                )}
              </Box>
              <Typography color="textPrimary">
                {result.bloodTakingOption}
              </Typography>
            </Box>
          )}
        </Box>
        {warning && (
          <div className={classes.collectedDate}>
            <ErrorIcon className={classes.collectedDateErrorIcon} />
            <Typography variant="caption">{warning}</Typography>
          </div>
        )}
      </Box>
      <Hidden smUp>
        <Box>
          <IconButton color="primary" aria-label="details">
            <ChevronRight />
          </IconButton>
        </Box>
      </Hidden>
    </Link>
  );
};
