import { isValid, differenceInMinutes, format, isAfter } from "date-fns";
import ct from "countries-and-timezones";
import moment from "moment-timezone";
import { TZ } from "@deep-consulting-solutions/bmh-constants";

export const DATE_DISPLAY_FORMAT = "dd.MM.yyyy";

export const getTimezoneOffsetInMs = (tzName: string) => {
  const data = ct.getTimezone(tzName);
  if (!data) return 0;
  const isDST = moment.tz(new Date(), tzName).isDST();

  return (isDST ? data.dstOffset : data.utcOffset) * 60 * 1000;
};

export const getDifferenceInMinutes = (start: string, end: string) => {
  const s = new Date(start);
  const e = new Date(end);

  if (!isValid(s) || !isValid(e)) {
    return {
      minutes: 0,
      string: "0 minute",
    };
  }

  const diff = differenceInMinutes(e, s);
  const unit = diff > 1 ? "minutes" : "minute";
  return {
    minutes: diff,
    string: `${diff} ${unit}`,
  };
};

export const formatDateToDisplay = (d: string | Date) => {
  const date = typeof d === "string" ? new Date(d) : d;
  return format(date, "dd MMM yyyy");
};

export const formatTimeToDisplay = (d: string | Date, am?: boolean) => {
  const date = typeof d === "string" ? new Date(d) : d;
  return format(date, am ? "hh:mm a" : "HH:mm");
};

export const convertTimeFromUTCToAnotherTimezone = (
  utcTimeString: string,
  tz?: string | null
) => {
  const dateString = `${utcTimeString.replace(/Z$/, "")}Z`;
  const date = new Date(dateString);

  if (!tz) return date;

  const data = ct.getTimezone(tz);
  const offset = moment.tz(date, tz).isDST()
    ? data?.dstOffset ?? 0
    : data?.utcOffset ?? 0;
  const converted = new Date(
    date.getTime() + date.getTimezoneOffset() * 60 * 1000 + offset * 60 * 1000
  );
  return converted;
};

export const getAllTimezones = (() => {
  let data: {
    timezones: TZ[];
    mappedTimezones: {
      [tz: string]: TZ;
    };
    mappedCountries: { [countryLowerCaseString: string]: string[] };
  } | null = null;

  return () => {
    if (data) return data;

    const momentTZs: { [tz: string]: true } = {};
    moment.tz.names().forEach((name) => {
      momentTZs[name] = true;
    });

    const mappedTimezones: {
      [tz: string]: TZ;
    } = {};

    moment.tz.countries().forEach((countryCode) => {
      const countryData = ct.getCountry(countryCode);
      if (!countryData) return;

      const { name, timezones: countryTimezones } = countryData;
      countryTimezones.forEach((z) => {
        if (!momentTZs[z]) return;
        mappedTimezones[z] = {
          country: name,
          tz: z,
        };
      });
    });

    const timezones = Object.values(mappedTimezones).sort((a, b) =>
      a.tz < b.tz ? -1 : 1
    );

    const mappedCountries: { [countryLowerCaseString: string]: string[] } = {};
    timezones.forEach(({ country, tz }) => {
      const countryName = country.toLowerCase();
      if (!mappedCountries[countryName]) mappedCountries[countryName] = [];
      mappedCountries[countryName].push(tz);
    });

    data = {
      mappedCountries,
      mappedTimezones,
      timezones,
    };

    return data;
  };
})();

export const processSampleCollectedDate = (
  {
    sampleCollectedOn,
    sampleReceivedByLabOn,
  }: {
    sampleCollectedOn?: string | null;
    sampleReceivedByLabOn?: string | null;
  },
  {
    notFallback,
    isDetailsView,
  }: {
    notFallback?: boolean;
    isDetailsView?: boolean;
  } = {}
): {
  date: string;
  warning: string;
} => {
  const MISSING_MESSAGE = "Sample Collection Date was not provided";
  const ERROR_MESSAGE = isDetailsView
    ? "The recorded sample collected date is after sample received date, a data entry error likely occurred and the sample collected date is likely invalid."
    : "Recorded sample collected date is likely erroneous and thus the sample received date is shown.";

  const emptyResult = {
    date: "",
    warning: "",
  };

  if (!sampleCollectedOn && !sampleReceivedByLabOn) {
    return emptyResult;
  }

  if (!sampleReceivedByLabOn) {
    return {
      date: formatDateToDisplay(sampleCollectedOn!),
      warning: "",
    };
  }

  if (!sampleCollectedOn) {
    if (notFallback) {
      return {
        ...emptyResult,
        warning: MISSING_MESSAGE,
      };
    }

    return {
      date: formatDateToDisplay(sampleReceivedByLabOn),
      warning: MISSING_MESSAGE,
    };
  }

  if (isAfter(new Date(sampleCollectedOn), new Date(sampleReceivedByLabOn))) {
    return {
      date: formatDateToDisplay(
        notFallback ? sampleCollectedOn : sampleReceivedByLabOn
      ),
      warning: ERROR_MESSAGE,
    };
  }

  return {
    date: formatDateToDisplay(sampleCollectedOn),
    warning: "",
  };
};
