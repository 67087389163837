import React, { memo, useCallback } from "react";
import { MenuItem, makeStyles, Typography } from "@material-ui/core";
import { ExpandLess } from "@material-ui/icons";

import { TABLE_META, FetchResultsParamsFieldEnum } from "../types";

const getRotate = (isDesc?: boolean) => {
  if (isDesc === undefined || isDesc) {
    return "180deg";
  }
  return "0deg";
};

const useStyle = makeStyles(({ spacing: s, palette: p }) => ({
  wrapper: {
    display: "flex",
  },
  label: {
    marginRight: s(1),
  },
  icon: {
    marginLeft: "auto",
    color: p.primary.light,
    transition: "0.2s",
  },
}));

interface TableMobileSortItemProps {
  isDesc?: boolean;
  tableKey: FetchResultsParamsFieldEnum;
  onClick: (name: FetchResultsParamsFieldEnum) => any;
}

const TableMobileSortItem = React.forwardRef<any, TableMobileSortItemProps>(
  ({ tableKey, isDesc, onClick }, ref) => {
    const { label } = TABLE_META[tableKey];

    const onItemClick = useCallback(() => {
      onClick(tableKey);
    }, [onClick, tableKey]);

    const classes = useStyle();

    return (
      <MenuItem
        ref={ref}
        onClick={onItemClick}
        className={classes.wrapper}
        selected={isDesc !== undefined}
        data-testid="blood-tests-page/mobile/sort-item"
      >
        <Typography variant="body1" className={classes.label}>
          {label}
        </Typography>
        <ExpandLess
          className={classes.icon}
          style={{
            opacity: isDesc === undefined ? 0 : 100,
            transform: `rotate(${getRotate(isDesc)})`,
          }}
        />
      </MenuItem>
    );
  }
);

export default memo(TableMobileSortItem);
