import { Box, Button, Typography } from "@material-ui/core";
import React, { memo } from "react";

interface Props {
  message?: string | null;
  onButtonClick?: () => Promise<any>;
  buttonText?: string;
}

const ZohoErrorMessage: React.FC<Props> = ({
  message,
  onButtonClick,
  buttonText = "Retry",
}) => {
  if (!message) {
    return null;
  }

  return (
    <Box py={4} px={3}>
      <Box
        p={3}
        mx="auto"
        border="1px solid"
        borderColor="error.main"
        borderRadius="8px"
        display="flex"
        flexDirection="column"
        alignItems="center"
        maxWidth="650px"
      >
        <Typography
          variant="h5"
          component="p"
          color="error"
          align="center"
          role="alert"
          paragraph
        >
          {message}
        </Typography>
        {!!onButtonClick && (
          <Button
            variant="contained"
            size="large"
            color="primary"
            onClick={onButtonClick}
          >
            {buttonText}
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default memo(ZohoErrorMessage);
