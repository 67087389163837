import React, { memo, useCallback, useState, useEffect } from "react";
import { Paper, Box, Typography, Grid } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { Location } from "history";
import { OAuthTypeEnum } from "@deep-consulting-solutions/auth-constants";

import AppleButton from "components/AppleButton";
import FacebookButton from "components/FacebookButton";
import GoogleButton from "components/GoogleButton";
import BigButton from "components/BigButton";
import {
  generateOAuthEndpoint,
  pkAuthActions,
} from "@deep-consulting-solutions/auth-web";

import { AppDispatch } from "redux/store";
import { activationSelectors } from "redux/activation";
import { stringify } from "query-string";
import { DISABLE_FACEBOOK_LOGIN } from "configs";
import AuthFormDivider from "./AuthFormDivider";

export const generateAddSocialLoginUrl = (
  type: OAuthTypeEnum,
  contactID: string
) => {
  const params = {
    contactID,
  };

  return `${process.env.REACT_APP_BASE_URL}${generateOAuthEndpoint(
    type
  )}?${stringify(params)}`;
};

interface Props {
  title: string;
  form: React.ReactElement;
  footer?: React.ReactElement;
  isActivation?: boolean;
  disabled?: boolean;
  titleClassName?: string;
  socialButtonClassName?: string;
  location: Location;
}

const AuthFormSkeleton: React.FC<Props> = ({
  title,
  form,
  footer,
  isActivation,
  disabled,
  titleClassName,
  socialButtonClassName,
  location,
}) => {
  const currentPath = location.pathname + location.search;
  const contactID = useSelector(activationSelectors.getContactID);

  const [useEmail, setUseEmail] = useState(false);

  const dispatch = useDispatch<AppDispatch>();

  const onUseEmailClick = useCallback(() => {
    setUseEmail(true);
  }, []);

  const onSocialButtonClick = useCallback(
    (type: OAuthTypeEnum) => {
      if (!contactID) return;
      const link = generateAddSocialLoginUrl(type, contactID);

      if (link) {
        window.open(link, "_self");
        dispatch(
          pkAuthActions.loginBySocialButton({
            redirect: currentPath,
          })
        );
      }
    },
    [contactID, dispatch, currentPath]
  );

  const onAppleButtonClick = useCallback(() => {
    onSocialButtonClick(OAuthTypeEnum.apple);
  }, [onSocialButtonClick]);

  const onGoogleButtonClick = useCallback(() => {
    onSocialButtonClick(OAuthTypeEnum.google);
  }, [onSocialButtonClick]);

  const onFacebookButtonClick = useCallback(() => {
    onSocialButtonClick(OAuthTypeEnum.fb);
  }, [onSocialButtonClick]);

  useEffect(() => {
    return () => setUseEmail(false);
  }, []);

  const withText = isActivation ? "Continue" : "Log in";

  return (
    <Paper>
      <Box p={3}>
        <Box pb={3}>
          <Typography variant="h4" className={titleClassName}>
            {title}
          </Typography>
        </Box>
        {useEmail && (
          <>
            {form}
            <Box>
              <AuthFormDivider />

              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="subtitle1">
                    {isActivation
                      ? "Sign up with your social networks "
                      : "Log in with your social networks "}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Box display="flex">
                    {!DISABLE_FACEBOOK_LOGIN && (
                      <FacebookButton
                        disabled={disabled}
                        fullWidth
                        onClick={onFacebookButtonClick}
                        className={socialButtonClassName}
                      />
                    )}
                    <Box ml={1}>
                      <GoogleButton
                        disabled={disabled}
                        fullWidth
                        onClick={onGoogleButtonClick}
                        className={socialButtonClassName}
                      />
                    </Box>
                    <Box ml={1}>
                      <AppleButton
                        disabled={disabled}
                        fullWidth
                        onClick={onAppleButtonClick}
                        className={socialButtonClassName}
                      />
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </>
        )}

        {!useEmail && (
          <Box>
            <Grid container spacing={2}>
              {!DISABLE_FACEBOOK_LOGIN && (
                <Grid item xs={12}>
                  <FacebookButton
                    withText={withText}
                    disabled={disabled}
                    onClick={onFacebookButtonClick}
                    className={socialButtonClassName}
                  />
                </Grid>
              )}

              <Grid item xs={12}>
                <GoogleButton
                  withText={withText}
                  disabled={disabled}
                  onClick={onGoogleButtonClick}
                  className={socialButtonClassName}
                />
              </Grid>

              <Grid item xs={12}>
                <AppleButton
                  withText={withText}
                  disabled={disabled}
                  onClick={onAppleButtonClick}
                  className={socialButtonClassName}
                />
              </Grid>
            </Grid>

            <AuthFormDivider />

            <Grid container spacing={2}>
              <Grid item xs={12}>
                <BigButton
                  color="primary"
                  variant="contained"
                  onClick={onUseEmailClick}
                >
                  {isActivation ? "Activate with email" : "Log in with email"}
                </BigButton>
              </Grid>
            </Grid>
          </Box>
        )}

        {footer}
      </Box>
    </Paper>
  );
};

export default memo(AuthFormSkeleton);
