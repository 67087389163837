import { AxiosResponse } from "axios";
import {
  userEndpoints,
  PATCHContactBody,
  PATCHContactRes,
  ROUTE_CONTACT_TIMEZONE_BOOKING_PAGE,
  ROUTE_CONTACT_TIMEZONE_CRM,
  ContactRes,
  ROUTE_CONTACT_TIMEZONE_RESCHEDULE_PAGE,
  MCMCall,
} from "@deep-consulting-solutions/bmh-constants";

import { apiClient, crmClient } from "apis";
import { SchedulingTokens } from "components/SchedulingTool/SchedulingToolTokenContext";
import { getEmailClient } from "redux/scheduling/requests";

export const updateTimeZoneRequest = async (
  tz: string,
  {
    zohoID,
    tokens,
    meeting,
    contactZohoID,
  }: {
    zohoID: string;
    tokens?: SchedulingTokens;
    meeting?: MCMCall;
    contactZohoID?: string;
  }
) => {
  const body: PATCHContactBody = {
    timezone: tz,
  };
  let res: AxiosResponse<PATCHContactRes>;
  if (tokens && tokens.emailToken) {
    const { client } = getEmailClient(tokens.emailToken);
    res = await client.patch<PATCHContactRes>(
      meeting
        ? ROUTE_CONTACT_TIMEZONE_RESCHEDULE_PAGE
        : ROUTE_CONTACT_TIMEZONE_BOOKING_PAGE,
      body
    );
  } else if (tokens && tokens.crmToken) {
    res = await crmClient.patch<PATCHContactRes>(
      ROUTE_CONTACT_TIMEZONE_CRM.replace(
        ":zohoID",
        meeting ? contactZohoID || "" : zohoID
      ),
      body
    );
  } else {
    res = await apiClient.patch<PATCHContactRes>(
      userEndpoints.patchUserTimezone,
      body
    );
  }

  return res.data.data.contact;
};

export const fetchClientByClientZohoID = async (zohoID: string) => {
  const res = await crmClient.get<{ data: { contact: ContactRes } }>(
    `/contacts/crm/:zohoID`.replace(":zohoID", zohoID)
  );
  return res.data.data.contact;
};
