import React, { useCallback, useState } from "react";
import { Grid, Hidden, Box, Typography, makeStyles } from "@material-ui/core";
import { ErrorOutline } from "@material-ui/icons";
import { useResponsive } from "hooks";
import emailImage from "images/email.svg";
import { DialogSocialError } from "components/DialogSocialError";
import ActivationForm from "./ActivationForm";

const useStyles = makeStyles(({ palette: p }) => ({
  expiredWrapper: {
    textAlign: "center",
  },
  expiredText: {
    fontSize: 30,
    color: p.error.main,
  },
  expiredIcon: {
    fontSize: 300,
    color: p.error.main,
  },
}));

export const ActivationPage = () => {
  const { isSMDown } = useResponsive();
  const [newEmail, setNewEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const onChangeEmail = useCallback((email: string) => {
    setNewEmail(email);
  }, []);

  const textAlign = isSMDown ? "center" : "left";
  const classes = useStyles();

  const handleError = useCallback((message: string) => {
    setErrorMessage(message);
  }, []);

  if (errorMessage) {
    return (
      <div className={classes.expiredWrapper}>
        <Typography className={classes.expiredText}>{errorMessage}</Typography>
        <ErrorOutline className={classes.expiredIcon} />
      </div>
    );
  }

  return (
    <>
      <Grid container spacing={4}>
        {newEmail ? (
          <Grid item xs={12} md={6}>
            <Box mb={3}>
              <Typography
                color="primary"
                variant="h3"
                component="h1"
                align={textAlign}
              >
                Email confirmation
              </Typography>
            </Box>
            <Typography
              variant="subtitle1"
              component="p"
              align={textAlign}
              paragraph
            >
              You have set a different email from the invitation for activating
              your portal account.
            </Typography>
            <Typography
              variant="subtitle1"
              component="p"
              align={textAlign}
              paragraph
            >
              We have sent an email to the following email address:
              <b />
              <strong>{newEmail}</strong>
            </Typography>
            <Typography
              variant="subtitle1"
              component="p"
              align={textAlign}
              paragraph
            >
              Please follow the link in the email to verify this email account.
            </Typography>
          </Grid>
        ) : (
          <Hidden xsDown>
            <Grid item xs={12} md={6}>
              <Box mb={3}>
                <Typography color="primary" variant="h1" align={textAlign}>
                  Welcome to Bespoke UK and EU TRT & HRT Services
                </Typography>
              </Box>
              <Typography variant="h5" component="p" align={textAlign}>
                Balance My Hormones guides you through the journey of hormone
                rebalancing by facilitating your journey with doctors
                specialising in hormone deficiencies who are difficult to access
                through traditional NHS and private doctor practices.
              </Typography>
            </Grid>
          </Hidden>
        )}

        <Grid item xs={12} sm>
          <Box maxWidth="400px" mx="auto">
            {newEmail ? (
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                textAlign="center"
              >
                <img src={emailImage} alt="Check your email" />
                <Box mt={2}>
                  <Typography variant="subtitle2">Check your email</Typography>
                </Box>
              </Box>
            ) : (
              <ActivationForm
                onChangeEmail={onChangeEmail}
                handleError={handleError}
              />
            )}
          </Box>
        </Grid>
      </Grid>
      <DialogSocialError />
    </>
  );
};
