import React, { useCallback } from "react";
import {
  OAuthResponse,
  pkAuthSelectors,
} from "@deep-consulting-solutions/auth-web";
import { Container } from "@material-ui/core";
import { useHistory, useLocation } from "react-router-dom";

import { notifications } from "services/notifications";
import { ROUTES } from "configs";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "redux/store";
import { portalsActions } from "@deep-consulting-solutions/portals-web";
import { userActions, userSelectors } from "redux/user";

const OAuthResponsePage = () => {
  const dispatch = useDispatch<AppDispatch>();
  const redirectPathname = useSelector(userSelectors.getRedirectPathname);
  const history = useHistory();
  const location = useLocation();
  const socialRedirectPath = useSelector(pkAuthSelectors.authSocialRedirect);

  const onErrorMessage = useCallback((message: string) => {
    notifications.notifyError(message);
  }, []);

  const onSuccessMessage = useCallback((message: string) => {
    notifications.notifySuccess(message);
  }, []);

  const onErrorRedirect = useCallback(() => {
    history.push(socialRedirectPath || ROUTES.login.path, {
      hasSocialError: true,
    });
  }, [history, socialRedirectPath]);

  const onSuccessRedirect = useCallback(
    (prevPath: string) => {
      dispatch(portalsActions.resetPortalsState());
      if (prevPath.includes(ROUTES.activation.path)) {
        history.push(ROUTES.activationWelcome.path, { isActivating: true });
      } else {
        if (redirectPathname) {
          dispatch(userActions.setRedirectPathname(null));
          history.push(redirectPathname);
          return;
        }
        history.push(prevPath || ROUTES.login.path);
      }
    },
    [dispatch, history, redirectPathname]
  );

  return (
    <Container>
      <OAuthResponse
        onErrorMessage={onErrorMessage}
        onSuccessMessage={onSuccessMessage}
        onErrorRedirect={onErrorRedirect}
        onSuccessRedirect={onSuccessRedirect}
        location={location}
      />
    </Container>
  );
};

export default OAuthResponsePage;
