export default {
  en: {
    "default.notAllowed.widget": "This function is not available",
    "api.noti.info.tokenExpired":
      "Your session has expired. Please login again.",

    "validation.email.required": "Email is required",
    "validation.email.invalid": "Email is invalid",
    "validation.firstName.required": "First name is required",
    "validation.lastName.required": "Last name is required",
    "validation.phone.required": "Phone number is required",
    "validation.phone.invalid": "Phone number is invalid",
    "validation.role.required": "Role is required",
    "validation.password.min":
      "Passwords must be at least 8 characters in length",
    "validation.password.required": "Password is required",
    "validation.repassword.required": "Please confirm your password",
    "validation.repassword.match": "Passwords must match",
    "validation.street.required": "Street is required",
    "validation.city.required": "City is required",
    "validation.region.required": "Region is required",
    "validation.zip.required": "Postal code is required",
    "validation.country.required": "Country is required",
    "validation.dob.required": "Date of birth is required",
    "validation.dob.inPast": "Date of birth can only be in the past",
    "validation.gender.required": "Gender is required",
    "validation.reason.required": "Reason is required",

    "redux.activation.activateAccount.noti.noContact":
      "There is no appropriate contact loaded",
    "comp.ActivationPage.noti.returningUser":
      "Please login to activate your Account",
    "comp.ActivationPage.noti.tokenMissing": "Activation link is invalid!",
    "comp.ActivationPage.noti.activationSuccessful": "Activation successful!",
    "comp.ActivationPage.noti.verificationSent":
      "A verification email is sent to your new email address",

    "comp.SchedulingTool.SchedulingDetails.phone.required":
      "Phone number is required",
    "comp.SchedulingTool.SchedulingDetails.clientHasTwoRecentBloodTestResult.required":
      "Please select an option.",

    "validation.purposeOfConsultation.required":
      "Purpose of Consultation is required.",
    "validation.purposeOfConsultation.invalid":
      "Purpose of Consultation is invalid.",
  },
};
