import React, { useCallback } from "react";
import {
  DialogTitle,
  DialogProps,
  Box,
  Typography,
  makeStyles,
  IconButton,
  TypographyVariant,
  DialogTitleProps,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";

const useStyle = makeStyles(() => ({
  titleWrapper: {
    display: "flex",
    alignItems: "center",
  },
  closeBtn: {
    marginLeft: "auto",
  },
}));

interface DialogTitleWithCloseBtnProps extends DialogTitleProps {
  title: string;
  titleVariant?: TypographyVariant;
  onClose: DialogProps["onClose"];
}

export const DialogTitleWithCloseBtn: React.FC<DialogTitleWithCloseBtnProps> = ({
  title,
  titleVariant,
  onClose,
  ...props
}) => {
  const onCloseClick = useCallback(() => {
    if (onClose) onClose({}, "backdropClick");
  }, [onClose]);

  const classes = useStyle();

  return (
    <DialogTitle {...props}>
      <Box className={classes.titleWrapper}>
        <Typography variant={titleVariant || "h4"} color="primary">
          {title}
        </Typography>
        {onClose && (
          <IconButton className={classes.closeBtn} onClick={onCloseClick}>
            <Close />
          </IconButton>
        )}
      </Box>
    </DialogTitle>
  );
};
