import React, { useEffect, useCallback, useState, memo } from "react";
import { makeStyles, Box, Typography } from "@material-ui/core";
import { useLocation } from "react-router-dom";
import {
  MCMCall,
  SchedulingInfo,
  SchedulingEmailInaccessibleReasonEnum,
  ReschedulingEmailInaccessibleReasonEnum,
  ContactRes,
  ConsultationPurposeEnum,
} from "@deep-consulting-solutions/bmh-constants";

import {
  validateSchedulingToken,
  validatingReschedulingToken,
} from "redux/scheduling/requests";
import Loader from "components/Loader";
import bmhLogo from "images/bmh-logo.png";
import { useResponsive } from "hooks";

import { TOP_BAR_HEIGHT } from "./SchedulingTool.styles";
import {
  OtherReasonEnum,
  composeMCMCallFromCrmMeetingAndSchedulingInfo,
  getDurationFromPurpose,
} from "./SchedulingTool.helpers";
import { SchedulingTokenStatus } from "./SchedulingTokenStatus";
import { SchedulingTokenContext } from "./SchedulingToolTokenContext";
import { SchedulingToolMain } from "./SchedulingToolMain";

const useStyle = makeStyles(({ palette: p, spacing: s, breakpoints: b }) => ({
  wrapper: {
    height: "100vh",
    display: "flex",
    flexDirection: "column",
  },
  header: {
    display: "flex",
    alignItems: "center",
    background: p.common.white,
    borderBottom: `1px solid ${p.primary.main}`,
    height: TOP_BAR_HEIGHT.normal,
    paddingLeft: s(1),
    paddingRight: s(1),
    position: "fixed",
    zIndex: 1000,
    width: "100%",
    [b.down("sm")]: {
      height: TOP_BAR_HEIGHT.mobile,
      top: 0,
    },
  },
  headerLogo: {
    width: "56px",
    height: "56px",
    marginRight: s(2),
    [b.down("sm")]: {
      width: "42px",
      height: "42px",
    },
  },
  headerTitle: {
    [b.down("sm")]: {
      marginLeft: "auto",
    },
  },
  content: {
    flex: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: TOP_BAR_HEIGHT.normal,
    [b.down("sm")]: {
      marginTop: TOP_BAR_HEIGHT.mobile,
      display: "block",
    },
  },
  contentInner: {
    minWidth: "90vw",
    [b.down("sm")]: {
      minHeight: `calc(100vh - ${TOP_BAR_HEIGHT.mobile})`,
    },
  },
}));

interface SchedulingByEmailProps {
  isReschedule?: boolean;
}

export const SchedulingByEmail: React.FC<SchedulingByEmailProps> = ({
  isReschedule,
}) => {
  const { search } = useLocation();
  const { isSMDown } = useResponsive();

  const [reason, setReason] = useState<
    | SchedulingEmailInaccessibleReasonEnum
    | ReschedulingEmailInaccessibleReasonEnum
    | OtherReasonEnum
    | null
  >(null);
  const [emailToken, setEmailToken] = useState("");
  const [accessibility, setAccessibility] = useState<boolean | null>(null);
  const [meeting, setMeeting] = useState<MCMCall | null>(null);
  const [schedulingInfo, setSchedulingInfo] = useState<
    SchedulingInfo | undefined
  >();
  const [contact, setContact] = useState<ContactRes | null>(null);

  const fetchSchedule = useCallback(async (token: string) => {
    const res = await validateSchedulingToken(token);

    setSchedulingInfo(res.schedulingInfo);
    setReason(res.reason || null);
    setContact(res.client || null);
    setTimeout(() => {
      setAccessibility(res.accessibility);
    }, 0);
  }, []);

  const fetchReschedule = useCallback(async (token: string) => {
    const res = await validatingReschedulingToken(token);
    setMeeting(
      composeMCMCallFromCrmMeetingAndSchedulingInfo({
        meeting: res.crmMeeting,
        info: res.schedulingInfo,
      })
    );
    setContact(res && res.client ? res.client : null);

    setSchedulingInfo(res.schedulingInfo);
    setReason(res.reason || null);
    setTimeout(() => {
      setAccessibility(res.accessibility);
    }, 0);
  }, []);

  const fetchToken = useCallback(async () => {
    const params = new URLSearchParams(search);
    const token = params.get("token");

    if (!token) {
      setReason(OtherReasonEnum.noToken);
      setAccessibility(false);
      return;
    }

    try {
      if (isReschedule) {
        await fetchReschedule(token);
      } else {
        await fetchSchedule(token);
      }

      setEmailToken(token);
    } catch {
      setReason(OtherReasonEnum.others);
      setAccessibility(false);
    }
  }, [search, isReschedule, fetchReschedule, fetchSchedule]);

  useEffect(() => {
    fetchToken();
  }, [fetchToken]);

  const classes = useStyle();

  return (
    <Box className={classes.wrapper}>
      <Box className={classes.header}>
        <img src={bmhLogo} alt="BMH Logo" className={classes.headerLogo} />
        <Typography
          variant={isSMDown ? "subtitle2" : "h4"}
          color="primary"
          className={classes.headerTitle}
        >
          {isReschedule
            ? "Reschedule PCM Consultation"
            : "Book a PCM Consultation"}
        </Typography>
      </Box>
      <Box className={classes.content}>
        <Box className={classes.contentInner}>
          {accessibility === null && <Loader open />}
          {accessibility === false && !isReschedule && (
            <SchedulingTokenStatus reason={reason} isSchedule />
          )}
          {accessibility === false && isReschedule && (
            <SchedulingTokenStatus reason={reason} isReschedule />
          )}
          {!!accessibility && schedulingInfo && (
            <SchedulingTokenContext.Provider value={{ emailToken }}>
              <SchedulingToolMain
                meeting={meeting || undefined}
                schedulingInfo={
                  isReschedule
                    ? {
                        ...schedulingInfo,
                        ...(meeting?.purpose
                          ? {
                              purpose: meeting.purpose,
                              duration: getDurationFromPurpose(meeting.purpose),
                            }
                          : {}),
                      }
                    : {
                        ...schedulingInfo,
                        purpose:
                          schedulingInfo.purpose ===
                          ConsultationPurposeEnum.INITIAL_PCM_CONSULTATION
                            ? schedulingInfo.purpose
                            : undefined,
                      }
                }
                contact={contact || undefined}
                host="client"
                isFromEmail
              />
            </SchedulingTokenContext.Provider>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export const MemoSchedulingByEmail = memo(SchedulingByEmail);
