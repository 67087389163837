import React, { memo, useEffect, useState, useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { Container, Typography, makeStyles, Button } from "@material-ui/core";
import {
  MCMCall,
  SchedulingInfo,
  ContactRes,
} from "@deep-consulting-solutions/bmh-constants";

import { ZohoServices, ZohoMeetingRecord } from "services";
import { zohoSelectors } from "redux/zoho";
import {
  validateScheduleCRMToken,
  getMCMCallDetailsCRMReq,
} from "redux/scheduling/requests";
import { fetchClientByClientZohoID } from "redux/user/requests";
import Loader from "components/Loader";

import {
  getCRMMeetingUrl,
  getDurationFromPurpose,
  getInitialTimezoneForCRMScheduleMCM,
} from "./SchedulingTool.helpers";
import { SchedulingTokenContext } from "./SchedulingToolTokenContext";
import { SchedulingToolMain } from "./SchedulingToolMain";
import SchedulingTZModal from "./SchedulingTZModal";

const useStyle = makeStyles(({ spacing: s, palette: p, typography: typo }) => ({
  errorWrapper: {
    height: "90vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    textAlign: "center",
  },
  link: {
    fontSize: typo.subtitle1.fontSize,
    color: p.secondary.main,
    marginTop: s(2),
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
      color: p.secondary.main,
    },
  },
  crmActions: {
    marginTop: s(1),
    textAlign: "center",
  },
}));

interface SchedulingOnCRMProps {
  isReschedule?: boolean;
}

export const SchedulingOnCRM: React.FC<SchedulingOnCRMProps> = ({
  isReschedule,
}) => {
  const crmToken = useSelector(zohoSelectors.getToken);
  const ids = useSelector(zohoSelectors.getIds);
  const zohoRecords = useSelector(zohoSelectors.getRecords);
  const contactZohoID = useMemo(() => {
    if (!isReschedule) {
      return ids[0];
    }
    const record = zohoRecords[ids[0]] as ZohoMeetingRecord;
    return record?.Who_Id?.id || "";
  }, [ids, isReschedule, zohoRecords]);

  const [loading, setLoading] = useState(true);
  const [meetingZohoID, setMeetingZohoID] = useState("");
  const [meeting, setMeeting] = useState<MCMCall | null>(null);
  const [schedulingInfo, setSchedulingInfo] = useState<SchedulingInfo | null>(
    null
  );
  const [contact, setContact] = useState<ContactRes | null>(null);
  const [tempTimezone, setTempTimezone] = useState<string | undefined>(
    undefined
  );
  const [showTempTimezonePicker, setShowTempTimezonePicker] = useState(false);

  const fetchReschedule = useCallback(async () => {
    const { data } = await getMCMCallDetailsCRMReq(ids[0]);
    setMeeting(data.meeting);
    setSchedulingInfo(data.schedulingInfo);
    setContact(data.client);
  }, [ids]);

  const fetchSchedule = useCallback(async () => {
    const res = await validateScheduleCRMToken(ids[0]);
    if (res.crmMeeting) {
      setMeetingZohoID(res.crmMeeting.zohoID);
    } else if (res.schedulingInfo) {
      setSchedulingInfo(res.schedulingInfo);
    }
    const contactRes = await fetchClientByClientZohoID(ids[0]);
    setContact(contactRes);

    if (!res.crmMeeting && res.schedulingInfo && !res.schedulingInfo.clientTZ) {
      setTempTimezone(getInitialTimezoneForCRMScheduleMCM(res.bmh, contactRes));
      setShowTempTimezonePicker(true);
    }
  }, [ids]);

  const fetchStatus = useCallback(async () => {
    setLoading(true);
    try {
      if (isReschedule) {
        await fetchReschedule();
      } else {
        await fetchSchedule();
      }

      setLoading(false);
    } catch {
      setLoading(false);
    }
  }, [isReschedule, fetchReschedule, fetchSchedule]);

  const onTZUpdate = useCallback(
    (tz: string) => {
      if (!crmToken) return false;

      setTempTimezone(tz);
      setShowTempTimezonePicker(false);
      return true;
    },
    [crmToken]
  );

  const onCRMCloseClick = useCallback(() => {
    ZohoServices.closePopup();
  }, []);

  useEffect(() => {
    fetchStatus();
  }, [fetchStatus]);

  const classes = useStyle();

  const Content = useMemo(
    () => () => {
      if (!crmToken) return null;

      if (isReschedule) {
        if (!meeting || !schedulingInfo || !contact) {
          return (
            <Container className={classes.errorWrapper}>
              <Typography variant="subtitle1" color="error">
                Something went wrong
              </Typography>
              <div className={classes.crmActions}>
                <Button color="primary" onClick={onCRMCloseClick}>
                  Close
                </Button>
              </div>
            </Container>
          );
        }

        return (
          <SchedulingTokenContext.Provider value={{ crmToken }}>
            <SchedulingToolMain
              meeting={meeting}
              schedulingInfo={{
                ...schedulingInfo,
                ...(meeting.purpose
                  ? {
                      purpose: meeting.purpose,
                      duration: getDurationFromPurpose(meeting.purpose),
                    }
                  : {}),
              }}
              contact={contact}
              contactZohoID={contactZohoID}
              tempTimezone={tempTimezone}
              host="crm"
              handleClose={onCRMCloseClick}
            />
          </SchedulingTokenContext.Provider>
        );
      }

      if (meetingZohoID) {
        return (
          <Container className={classes.errorWrapper}>
            <Typography variant="subtitle1">
              The client has an upcoming PCM consultation already scheduled.
              <br />
              Access the meeting details following the link.
            </Typography>
            <a
              href={getCRMMeetingUrl(meetingZohoID)}
              target="_blank"
              rel="noreferrer"
              className={classes.link}
            >
              {meetingZohoID}
            </a>
            <div className={classes.crmActions}>
              <Button color="primary" onClick={onCRMCloseClick}>
                Close
              </Button>
            </div>
          </Container>
        );
      }

      if (
        schedulingInfo &&
        (schedulingInfo.clientTZ || tempTimezone) &&
        contact
      ) {
        return (
          <SchedulingTokenContext.Provider value={{ crmToken }}>
            <SchedulingToolMain
              schedulingInfo={schedulingInfo}
              contact={contact}
              tempTimezone={tempTimezone || ""}
              contactZohoID={contactZohoID}
              host="crm"
              handleClose={onCRMCloseClick}
            />
          </SchedulingTokenContext.Provider>
        );
      }

      return null;
    },
    [
      isReschedule,
      meeting,
      meetingZohoID,
      crmToken,
      classes,
      schedulingInfo,
      contact,
      tempTimezone,
      onCRMCloseClick,
      contactZohoID,
    ]
  );

  if (!crmToken) return null;

  return (
    <>
      <Loader open={loading} />
      <SchedulingTZModal
        open={showTempTimezonePicker}
        tz={tempTimezone}
        onUpdate={onTZUpdate}
        isCRMPreSchedule
      />
      {!loading && !showTempTimezonePicker && <Content />}
    </>
  );
};

export const MemoSchedulingOnCRM = memo(SchedulingOnCRM);
