import React, { memo, useState, useEffect, useCallback } from "react";
import { RouteProps, Route } from "react-router-dom";
import { useDispatch } from "react-redux";

import { AppDispatch } from "redux/store";
import { zohoActions, transformInitZohoPayload } from "redux/zoho";
import Loader from "components/Loader";
import ErrorMessage from "components/ZohoErrorMessage";
import { getENText } from "helpers";

import { ZohoRouteServiceEnum } from "./ZohoRoute.types";
import { InfoMessage } from "./InfoMessage";

interface ZohoRouteProps extends RouteProps {
  init?: boolean;
  entity?: boolean;
  services?: ZohoRouteServiceEnum | ZohoRouteServiceEnum[];
  onlyForTabs?: {
    tabs: string | string[];
    errorMessage?: string | ((currentTab: string) => string);
  };
}

export const ZohoRoute: React.FC<ZohoRouteProps> = ({
  init,
  entity,
  services,
  component,
  render,
  onlyForTabs,
  ...props
}) => {
  const dispatch = useDispatch<AppDispatch>();

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<any>();
  const [infoMessage, setInfoMessage] = useState<any>();

  const returnWithError = useCallback(
    (errorMessage = "Something went wrong") => {
      setError((current: any) => current || errorMessage);
      return false;
    },
    []
  );

  const returnWithInfoMessage = useCallback(
    (message = "Something went wrong") => {
      setInfoMessage((current: any) => current || message);
      return false;
    },
    []
  );

  const initZoho = useCallback(async () => {
    const res = await dispatch(zohoActions.initZoho());

    if (!zohoActions.initZoho.fulfilled.match(res)) {
      return returnWithError();
    }

    const entityError = "Cannot find zoho entity";

    if (onlyForTabs) {
      const { tabs, errorMessage } = onlyForTabs;
      if (!res.payload) {
        return returnWithError(entityError);
      }

      const tabArray = Array.isArray(tabs) ? tabs : [tabs];

      if (!tabArray.includes(res.payload.Entity)) {
        const message =
          typeof errorMessage === "function"
            ? errorMessage(res.payload.Entity)
            : errorMessage || getENText("default.notAllowed.widget");
        return returnWithInfoMessage(message);
      }
    }

    if (entity) {
      if (!res.payload) {
        return returnWithError(entityError);
      }
      const { ids } = transformInitZohoPayload(res.payload);
      if (!ids || !ids.length) {
        return returnWithError(entityError);
      }
      return ids;
    }
    return true;
  }, [entity, dispatch, returnWithError, onlyForTabs, returnWithInfoMessage]);

  const getToken = useCallback(async () => {
    const res = await dispatch(zohoActions.getZohoToken());
    if (!zohoActions.getZohoToken.fulfilled.match(res)) {
      return returnWithError("Cannot get Zoho token");
    }
    return true;
  }, [returnWithError, dispatch]);

  const fetchRecords = useCallback(async () => {
    const res = await dispatch(zohoActions.fetchRecords());
    if (!zohoActions.fetchRecords.fulfilled.match(res)) {
      return returnWithError("Cannot get Zoho Records");
    }
    return true;
  }, [returnWithError, dispatch]);

  const runServices = useCallback(async () => {
    setLoading(true);
    try {
      const tasks: Promise<boolean>[] = [];

      if (init) {
        const inited = await initZoho();

        if (!inited) {
          setLoading(false);
          return;
        }
        tasks.push(getToken());
      }

      let serviceArray: ZohoRouteServiceEnum[] = [];
      if (services) {
        serviceArray = Array.isArray(services) ? services : [services];
      }

      serviceArray.forEach((service) => {
        if (service === ZohoRouteServiceEnum.records) {
          tasks.push(fetchRecords());
        }
      });

      await Promise.all(tasks);
      setLoading(false);
    } catch {
      setLoading(false);
    }
  }, [init, services, initZoho, getToken, fetchRecords]);

  useEffect(() => {
    runServices();
  }, [runServices]);

  const getComponent = (): {
    component?: typeof component | (() => JSX.Element);
    render?: typeof render;
  } => {
    if (loading) {
      return {
        component: () => <Loader open />,
      };
    }

    if (infoMessage) {
      return {
        component: () => <InfoMessage message={infoMessage} />,
      };
    }

    if (error) {
      return {
        component: () => <ErrorMessage message={error} />,
      };
    }

    return {
      component,
      render,
    };
  };

  return <Route {...getComponent()} {...props} />;
};

export const MemoZohoRoute = memo(ZohoRoute);
