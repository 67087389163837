import React, { FC, useState, useEffect, useCallback, useMemo } from "react";
import { makeStyles, Theme, Container, Box } from "@material-ui/core";
import { useSelector } from "react-redux";

import { useWidgetResize } from "hooks";
import { getDifferenceInMinutes } from "helpers";
import { schedulingSelectors } from "redux/scheduling";
import Loader from "components/Loader";

import { displaySlot, CallInfo } from "./helpers";
import CRMCancelMcmCallForm from "./CRMCancelMcmCallForm";
import CRMCallResult from "./CRMCallResult";
import CancelMcmCallForm from "./CancelMcmCallForm";
import CallResult from "./CallResult";
import { SchedulingTokenStatus } from "../SchedulingTokenStatus";

export interface CancelMcmCallProps {
  isCRM?: boolean;
  isEmail?: boolean;
  cancelCallCallback?: () => void;
}

const useStyles = makeStyles<Theme, CancelMcmCallProps>(({ spacing: s }) => ({
  container: {
    height: "100%",
    padding: ({ isCRM, isEmail }) => (isCRM || isEmail ? s(3, 0) : s(2)),
  },
  content: {
    height: "100%",
    maxWidth: 690,
    margin: "0 auto",
    "&:first-child": {
      paddingTop: ({ isCRM }) => (isCRM ? s(3) : 0),
    },
  },
  status: {
    minHeight: "unset",
  },
}));

const CancelMcmCall: FC<CancelMcmCallProps> = ({
  isCRM,
  isEmail,
  cancelCallCallback,
}) => {
  const classes = useStyles({ isCRM });

  const { wrapperRef, resize } = useWidgetResize<HTMLDivElement>();

  const reason = useSelector(schedulingSelectors.getReason);
  const upcomingMcm = useSelector(schedulingSelectors.getUpcomingMCM);

  const callInfo: CallInfo | null = useMemo(
    () =>
      upcomingMcm
        ? {
            id: upcomingMcm.id,
            mcmName: upcomingMcm.mcm.name || "",
            clientName: `${upcomingMcm.client?.firstName} ${upcomingMcm.client?.lastName}`,
            callDuration: getDifferenceInMinutes(
              upcomingMcm.start,
              upcomingMcm.end
            ).string,
            timeSlot: displaySlot(
              upcomingMcm.start,
              upcomingMcm.end,
              upcomingMcm.clientTZ
            ),
            timezone: upcomingMcm.clientTZ,
            purpose: upcomingMcm.purpose,
          }
        : null,
    [upcomingMcm]
  );

  const [showResult, setShowResult] = useState(false);
  const [isCallCancelled, setIsCallCancelled] = useState(false);

  const host = useMemo(() => (isCRM ? "crm" : "client"), [isCRM]);

  const showResultView = useCallback(() => {
    setShowResult(true);
  }, []);

  const cancelCall = useCallback(() => {
    setIsCallCancelled(true);
    if (cancelCallCallback) cancelCallCallback();
  }, [cancelCallCallback]);

  useEffect(() => {
    if (isCRM && callInfo) {
      resize({ addHeight: 20 });
    }
  }, [isCRM, resize, callInfo, isCallCancelled, showResult]);

  return (
    <Container className={classes.container} innerRef={wrapperRef}>
      <Box className={classes.content}>
        {(() => {
          if (isCRM) {
            if (showResult) {
              return <CRMCallResult isCancelled={isCallCancelled} />;
            }

            if (callInfo) {
              return (
                <CRMCancelMcmCallForm
                  callInfo={callInfo}
                  cancelCall={cancelCall}
                  showResultView={showResultView}
                />
              );
            }

            return <Loader open />;
          }

          if (reason) {
            return (
              <SchedulingTokenStatus
                reason={reason}
                className={classes.status}
                isCancel
              />
            );
          }

          if (callInfo) {
            if (showResult) {
              return (
                <CallResult
                  host={host}
                  callInfo={callInfo}
                  isCancelled={isCallCancelled}
                />
              );
            }

            return (
              <CancelMcmCallForm
                callInfo={callInfo}
                cancelCall={cancelCall}
                showResultView={showResultView}
                isEmail={isEmail}
                host={host}
              />
            );
          }

          return <Loader open />;
        })()}
      </Box>
    </Container>
  );
};

export default CancelMcmCall;
