import React from "react";
import { RouteProps } from "react-router-dom";

import PrivateRoute from "components/PrivateRoute";
import CustomerLayout from "./CustomerLayout";

type Props = RouteProps & Required<Pick<RouteProps, "component">>;

export const CustomerRoute: React.FC<Props> = ({
  component: Component,
  ...rest
}) => (
  <PrivateRoute
    {...rest}
    render={(props) => (
      <CustomerLayout>
        <Component {...props} />
      </CustomerLayout>
    )}
  />
);
