import React, { memo, useCallback, useRef, useEffect, useState } from "react";
import { Button, Grid, Box, Typography } from "@material-ui/core";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { Formik, Field, Form, FormikConfig, FormikProps } from "formik";
import { TextField } from "formik-material-ui";
import { parse } from "query-string";

import { notifications } from "services";
import AuthFormSkeleton from "components/AuthFormSkeleton";
import { pkAuthSelectors } from "@deep-consulting-solutions/auth-web";
import { activationActions, activationSelectors } from "redux/activation";
import { AppDispatch } from "redux/store";
import { ROUTES } from "configs";
import Loader from "components/Loader";
import FormikLowerCasedField from "components/FormikLowerCasedField";
import BigButton from "components/BigButton";
import { getENText, VALIDATIONS } from "helpers";
import { userActions } from "redux/user";

const validationSchema = Yup.object({
  email: VALIDATIONS.email,
  password: VALIDATIONS.password,
  repassword: VALIDATIONS.repassword,
});

interface Values {
  email: string;
  password: string;
  repassword: string;
}

interface ActivationFormProps {
  onChangeEmail: (newEmail: string) => any;
  handleError: (message: string) => void;
}

const ActivationForm = ({
  onChangeEmail,
  handleError,
}: ActivationFormProps) => {
  const location = useLocation();

  const isAuthorised = useSelector(pkAuthSelectors.isAuthorised);
  const [loading, setLoading] = useState(false);
  const { token, redirect_path: redirectPathname } = parse(location.search);

  const formRef = useRef<FormikProps<Values>>(null);
  const history = useHistory();
  const dispatch = useDispatch<AppDispatch>();
  const contact = useSelector(activationSelectors.getContact);

  useEffect(() => {
    if (redirectPathname && typeof redirectPathname === "string") {
      dispatch(userActions.setRedirectPathname(redirectPathname));
    }
  }, [dispatch, redirectPathname]);

  const validateToken = useCallback(async () => {
    if (typeof token === "string" && token) {
      setLoading(true);
      const res = await dispatch(
        activationActions.validateActivationToken({ token })
      );
      setLoading(false);
      if (activationActions.validateActivationToken.fulfilled.match(res)) {
        if (res.payload.isUser) {
          notifications.notifyInfo(
            getENText("comp.ActivationPage.noti.returningUser")
          );
          history.push(ROUTES.login.path);
        } else if (res.payload.isExpired) {
          handleError("Invitation is Expired");
        } else if (res.payload.isNotPending) {
          handleError("Invitation is not Pending");
        } else if (res.payload.isInvalid) {
          handleError("Invitation is Invalid");
        }
      }
    } else {
      history.push(ROUTES.login.path);
    }
    return () => setLoading(false);
  }, [dispatch, history, token, handleError]);

  useEffect(() => {
    if (formRef && formRef.current) {
      formRef.current.validateForm();
    }
  }, []);

  useEffect(() => {
    if (!isAuthorised && !token) {
      notifications.notifyError(
        getENText("comp.ActivationPage.noti.tokenMissing")
      );
      history.push(ROUTES.login.path);
    }
  }, [history, isAuthorised, token]);

  useEffect(() => {
    validateToken();
  }, [validateToken]);

  const onSubmit: FormikConfig<Values>["onSubmit"] = useCallback(
    async ({ repassword, ...values }, helpers) => {
      helpers.setSubmitting(true);
      const res = await dispatch(activationActions.activateAccount(values));

      helpers.setSubmitting(false);

      if (!activationActions.activateAccount.fulfilled.match(res)) return;

      if (contact && values.email !== contact.email) {
        onChangeEmail(values.email);
      } else {
        history.push(ROUTES.activationWelcome.path, { isActivating: true });
      }
    },
    [dispatch, contact, onChangeEmail, history]
  );

  const onLoginClick = useCallback(() => {
    history.push(ROUTES.login.path);
  }, [history]);

  return (
    <Box position="relative">
      {loading && <Loader open={loading} />}

      <AuthFormSkeleton
        location={location}
        isActivation
        disabled={!contact}
        form={
          <Formik
            innerRef={formRef}
            enableReinitialize
            validationSchema={validationSchema}
            onSubmit={onSubmit}
            initialValues={{
              email: contact?.email ?? "",
              password: "",
              repassword: "",
            }}
          >
            {({ isValid, isSubmitting }) => (
              <Form>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Field
                      fullWidth
                      component={FormikLowerCasedField}
                      name="email"
                      label="Email"
                      autoComplete="username"
                      disabled={!contact}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      fullWidth
                      component={TextField}
                      type="password"
                      label="Password"
                      placeholder="Password"
                      autoComplete="new-password"
                      name="password"
                      disabled={!contact}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      fullWidth
                      component={TextField}
                      type="password"
                      label="Re-type Password"
                      placeholder="Re-type Password"
                      autoComplete="new-password"
                      name="repassword"
                      disabled={!contact}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <BigButton
                      disabled={!isValid || isSubmitting || !contact}
                      color="primary"
                      fullWidth
                      size="large"
                      type="submit"
                    >
                      activate
                    </BigButton>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        }
        title="Activate Account"
        footer={
          <Box
            mt={3}
            display="flex"
            flexDirection="column"
            alignItems="flex-end"
          >
            <Typography variant="body1" align="right">
              Already have an account?
            </Typography>
            <Button variant="text" color="primary" onClick={onLoginClick}>
              Login
            </Button>
          </Box>
        }
      />
    </Box>
  );
};

export default memo(ActivationForm);
