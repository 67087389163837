import React from "react";
import { ButtonProps } from "@material-ui/core";
import SocialButton from "components/SocialButton";
import FacebookIcon from "./facebook.svg";

interface Props extends ButtonProps {
  isStatic?: boolean;
  withText?: string;
}

const FacebookButton: React.FC<Props> = (props) => {
  return (
    <SocialButton
      icon={FacebookIcon}
      name="Facebook"
      color="#1877F2"
      {...props}
    />
  );
};

export default FacebookButton;
