import { Typography } from "@material-ui/core";
import React from "react";

interface Props {
  isMobile?: boolean;
}

export const FailureFlagInfo = ({ isMobile = false }: Props) => {
  return (
    <Typography variant="body2">
      Your results have some analysis failures.
      {isMobile && <br />}
      This is normally due to issues with handling the samples. Don&apos;t
      hesitate to call us on{" "}
      <a href="tel:+44 (0) 203 9003 209">+44 (0) 203 9003 209</a> if you need
      results for these markers and want to repeat the test - we will help you
      make arrangements
    </Typography>
  );
};
