export function getShortAddressObject(object: google.maps.GeocoderResult) {
  const address: { [key: string]: string } = {};
  const addressComponents = object.address_components;
  addressComponents.forEach((element) => {
    address[element.types[0]] = element.short_name;
  });
  return address;
}

export function getLongAddressObject(object: google.maps.GeocoderResult) {
  const address: { [key: string]: string } = {};
  const addressComponents = object.address_components;
  addressComponents.forEach((element) => {
    address[element.types[0]] = element.long_name;
    if (element.types.includes("sublocality")) {
      address.sublocality = element.long_name;
    }
  });
  return address;
}

export function statusMessage(status: string) {
  switch (status) {
    case "ZERO_RESULTS":
      return "No Results Found";
    case "UNKNOWN_ERROR":
      return "Netwrok or Server Error";
    case "ERROR":
      return "Network Error";
    default:
      return "Server Error";
  }
}
