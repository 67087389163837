import { pkAuthActions, User } from "@deep-consulting-solutions/auth-web";
import {
  ContactRes,
  ValidateActivationTokenRes,
  userEndpoints,
  ActivationRes,
} from "@deep-consulting-solutions/bmh-constants";
import { portalsActions } from "@deep-consulting-solutions/portals-web";
import { createAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiClient, setAuthorizationHeader } from "apis";
import { getENText } from "helpers";
import { AppState, AppDispatch } from "redux/store";
import { notifications } from "services";
import { TOKEN_EXPIRED_BE_ERROR } from "configs";

const ENTITY = "activation";

export const CONTACT_PORTAL_INVITATION_NOT_PENDING =
  "You already signed up using";

const CONTACT_NOT_ON_PORTAL = "Contact is not a user of this portal";

export interface ActivationState {
  isUser: boolean | null;
  contact: ContactRes | null;
  portal: string | null;
  contactID: string | null;
  isExpired?: boolean;
  isNotPending?: boolean;
  isInvalid?: boolean;
}

const initialState: ActivationState = {
  isUser: false,
  contact: null,
  portal: null,
  contactID: null,
};

export const resetActivationData = createAction(
  `${ENTITY}/reset-activation-data`
);

const validateActivationToken = createAsyncThunk<
  ActivationState,
  { token: string },
  { state: AppState; dispatch: AppDispatch }
>(`${ENTITY}/validateActivationToken`, async ({ token }, { dispatch }) => {
  dispatch(resetActivationData());
  await dispatch(pkAuthActions.logout());
  dispatch(portalsActions.resetPortalsState());

  try {
    const res = await apiClient.post<ValidateActivationTokenRes>(
      userEndpoints.postValidateActivationToken,
      undefined,
      {
        headers: {
          authorization: `Bearer ${token}`,
        },
      }
    );

    setAuthorizationHeader(token);

    return {
      contact: res.data.data.contact || null,
      portal: res.data.data.portal || null,
      contactID: res.data.data.contactID || null,
      isUser: res.data.data.isUser || null,
    };
  } catch (error: unknown) {
    const e = error as any;
    const message = e?.response?.data?.message || e?.message;

    if (message === TOKEN_EXPIRED_BE_ERROR) {
      return {
        contact: null,
        portal: null,
        contactID: null,
        isUser: null,
        isExpired: true,
      };
    }

    if (
      ((message || "") as string).indexOf(
        CONTACT_PORTAL_INVITATION_NOT_PENDING
      ) > -1
    ) {
      return {
        contact: null,
        portal: null,
        contactID: null,
        isUser: null,
        isNotPending: true,
      };
    }

    if (message === CONTACT_NOT_ON_PORTAL) {
      return {
        contact: null,
        portal: null,
        contactID: null,
        isUser: null,
        isInvalid: true,
      };
    }
    throw error;
  }
});

const activateAccount = createAsyncThunk<
  void,
  {
    email: string;
    password: string;
  },
  {
    state: AppState;
    dispatch: AppDispatch;
  }
>(`${ENTITY}/activateAccount`, async (credentials, { getState, dispatch }) => {
  const {
    activation: { contact, contactID, portal },
  } = getState();

  if (!contact) {
    const message = getENText(
      "redux.activation.activateAccount.noti.noContact"
    );
    notifications.notifyError(message);
    throw new Error(message);
  }

  const body: { [key: string]: any } = {
    ...credentials,
    contactID,
    portal,
  };

  const res = await apiClient.post<ActivationRes>(
    userEndpoints.getOrPost,
    body
  );

  const {
    data: {
      data: { token, user },
    },
  } = res;

  if (token) {
    setAuthorizationHeader(token);

    if (user) {
      dispatch(
        pkAuthActions.setAuthorised({
          user: user as User,
          token,
        })
      );
    }
  }
});

const slice = createSlice({
  name: ENTITY,
  initialState,
  reducers: {},
  extraReducers: (builders) =>
    builders
      .addCase(resetActivationData, () => {
        return { ...initialState };
      })
      .addCase(validateActivationToken.fulfilled, (state, action) => {
        return action.payload;
      }),
});

const getContact = (state: AppState) => state.activation.contact;
const getContactID = (state: AppState) => state.activation.contactID;

export const activationSelectors = {
  getContact,
  getContactID,
};

export const activationActions = {
  validateActivationToken,
  activateAccount,
  resetActivationData,
};

export default slice.reducer;
