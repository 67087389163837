import React, { Fragment, useMemo } from "react";
import {
  BloodTakingOptionEnum,
  BloodTestResult,
  BloodTestResultItem,
} from "@deep-consulting-solutions/bmh-constants";
import {
  Box,
  Button,
  createStyles,
  Divider,
  makeStyles,
  Tooltip,
  Typography,
} from "@material-ui/core";
import {
  ErrorOutline as ErrorIcon,
  CalendarTodayOutlined,
  InfoOutlined,
} from "@material-ui/icons";
import { BackButton } from "@deep-consulting-solutions/dcs-web-ui";
import { ReactComponent as FingerIcon } from "images/finger.svg";
import { ReactComponent as SyringeIcon } from "images/syringe.svg";
import { format } from "date-fns";
import { DEFAULT_DATE_FORMAT } from "configs";

import theme from "theme";
import { processSampleCollectedDate } from "helpers";
import { PdfDownloadButton } from "./PdfDownloadButton";
import { FailureFlagInfo } from "./FailureFlagInfo";

interface ListViewProps {
  resultDetails: BloodTestResult & {
    doctorConsultationNeeded?: boolean;
    offerMCMCall?: boolean;
  };
  resultsMap: [string, BloodTestResultItem[]][] | null;
  hasFailures: boolean;
  hasAbnormalFlag: boolean;
  handleDownloadFile: () => Promise<void>;
  toggleMCMScheduling: () => any;
  shouldShowPDF: boolean;
}

export const useStyles = makeStyles(
  ({ spacing: s, breakpoints: b, palette: p, typography: typo }) =>
    createStyles({
      wrapperBox: {
        [b.only("sm")]: {
          margin: "0 -24px",
        },
        [b.only("xs")]: {
          margin: "0 -16px",
        },
      },
      header: {
        paddingBottom: s(3),
        [b.only("xs")]: {
          paddingTop: s(2),
          paddingBottom: s(2),
        },
      },
      offerMCM: () => ({
        marginRight: s(2),
        color: p.primary.light,
        textAlign: "center",
        marginBottom: s(1),
      }),
      noWrap: {
        whiteSpace: "nowrap",
      },
      spacing: {
        [b.only("sm")]: {
          padding: s(2, 3),
        },
        [b.only("xs")]: {
          padding: s(2),
        },
      },
      takingOptionIcon: {
        fill: p.primary.main,
      },
      divider: {
        height: "2px",
        backgroundColor: p.secondary.main,
      },
      notes: {
        color: p.grey[700],
      },
      resultName: {
        marginBottom: s(1),
        width: "100%",
      },
      sampleCollectedError: {
        display: "flex",
        alignItems: "flex-start",
        color: p.error.light,
        marginBottom: s(1),
      },
      sampleCollectedErrorIcon: {
        fontSize: typo.body1.fontSize,
        marginRight: s(0.5),
      },
    })
);

export const ListView = ({
  resultDetails,
  resultsMap,
  hasFailures,
  hasAbnormalFlag,
  handleDownloadFile,
  toggleMCMScheduling,
  shouldShowPDF,
}: ListViewProps) => {
  const { date, warning } = useMemo(
    () =>
      processSampleCollectedDate(resultDetails, {
        isDetailsView: true,
        notFallback: true,
      }),
    [resultDetails]
  );
  const classes = useStyles();

  return (
    <Box
      className={classes.wrapperBox}
      data-testid="blood-test-details/list-view"
    >
      <Box className={classes.header} display="flex" alignItems="center">
        <BackButton color="primary" />
        <Typography noWrap variant="h3" component="h1" color="primary">
          Blood Test Result
        </Typography>
      </Box>
      <Box bgcolor="background.paper">
        {!hasFailures && resultDetails.doctorConsultationNeeded && (
          <Box className={classes.spacing} color="#015465">
            <Typography>
              Your results need to be discussed with a Doctor. Expect to be
              contacted by BMH to schedule a Doctor&apos;s consultation.
            </Typography>
          </Box>
        )}
        {!hasFailures && !!resultDetails.schedulingInfo && (
          <Box
            className={classes.spacing}
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography className={classes.offerMCM}>
              {resultDetails.isClientOnTherapy && (
                <>
                  Schedule a free consultation to discuss your results and any
                  concerns with your Personal Case Manager.
                </>
              )}
              {!resultDetails.isClientOnTherapy && (
                <>
                  As part of our service we are also offering you{" "}
                  <b>
                    a no-obligation free consultation with our Medical Case
                    Manager
                  </b>{" "}
                  who can help you go over the results and recommend possible
                  directions in your journey towards optimal hormone health.
                </>
              )}
            </Typography>
            <div>
              <Button
                className={classes.noWrap}
                color="primary"
                onClick={toggleMCMScheduling}
              >
                {resultDetails.isClientOnTherapy
                  ? "book a pcm consultation"
                  : "book My Consultation"}
              </Button>
            </div>
          </Box>
        )}
        {hasFailures && (
          <Box
            className={classes.spacing}
            color="error.dark"
            textAlign="center"
          >
            {!resultDetails.isClientOnTherapy ? (
              <FailureFlagInfo isMobile />
            ) : (
              <Typography>
                Blood test results have failures and report is being analysed by
                our experts. Expect to be contacted by BMH to schedule a retest
                if needed.
              </Typography>
            )}
          </Box>
        )}
        {hasAbnormalFlag && (
          <Box
            className={classes.spacing}
            color="error.dark"
            textAlign="center"
          >
            <Typography>
              Your results contain abnormal flags.
              <br />
              If you are a BMH Patient then your BMH Doctor will be reviewing
              your results and you can also discuss them with your Medical Case
              Manager.
              <br />
              If you are not a BMH patient and are concerned by your results
              please contact your GP.
            </Typography>
          </Box>
        )}
      </Box>

      <Box
        className={classes.spacing}
        bgcolor="rgba(159, 186, 223, 0.2)"
        display="flex"
        alignItems="center"
      >
        <Box flex={1}>
          <Typography
            variant="h6"
            color="primary"
            className={classes.resultName}
          >
            {resultDetails.name}
          </Typography>
          {resultDetails.testProfiles && resultDetails.testProfiles.length > 0 && (
            <Box display="flex" alignItems="center" mb={3}>
              {resultDetails.bloodTakingOption && (
                <Box
                  className={classes.takingOptionIcon}
                  display="inline-flex"
                  alignItems="center"
                  height="16px"
                  width="16px"
                  mr={1}
                >
                  {resultDetails.bloodTakingOption ===
                  BloodTakingOptionEnum.fingerprick ? (
                    <FingerIcon />
                  ) : (
                    <SyringeIcon />
                  )}
                </Box>
              )}
              <Typography style={{ fontWeight: 500 }} color="primary">
                {resultDetails.testProfiles
                  .map((profile) => profile.name)
                  .join(", ")}
              </Typography>
            </Box>
          )}

          <Box color="primary.light">
            <Typography variant="subtitle2" component="h2" paragraph>
              {resultDetails.labName}
            </Typography>

            <Box display="flex" alignItems="center" flexWrap="wrap">
              <Box display="flex" alignItems="center" mr={2} mb={1}>
                <Box
                  mr={1}
                  display="inline-flex"
                  alignItems="center"
                  fontSize="16px"
                >
                  <CalendarTodayOutlined fontSize="inherit" />
                </Box>
                <Typography color="textPrimary">
                  Collected Sample: {date}
                </Typography>
              </Box>
              {!!warning && (
                <Box className={classes.sampleCollectedError}>
                  <ErrorIcon className={classes.sampleCollectedErrorIcon} />
                  <Typography variant="caption">{warning}</Typography>
                </Box>
              )}
              {resultDetails.sampleReceivedByLabOn && (
                <Box display="flex" alignItems="center" mb={1} mr={2}>
                  <Box
                    mr={1}
                    display="inline-flex"
                    alignItems="center"
                    fontSize="16px"
                  >
                    <CalendarTodayOutlined fontSize="inherit" />
                  </Box>
                  <Box>
                    <Typography variant="body2" color="textPrimary">
                      Received Sample:{" "}
                      {format(
                        new Date(resultDetails.sampleReceivedByLabOn),
                        DEFAULT_DATE_FORMAT
                      )}
                    </Typography>
                  </Box>
                </Box>
              )}
              <Box display="flex" alignItems="center" mb={1} mr={2}>
                <Box
                  mr={1}
                  display="inline-flex"
                  alignItems="center"
                  fontSize="16px"
                >
                  <CalendarTodayOutlined fontSize="inherit" />
                </Box>
                <Typography variant="body2" color="textPrimary">
                  Result Report:{" "}
                  {format(
                    new Date(resultDetails.resultReportedOn),
                    DEFAULT_DATE_FORMAT
                  )}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
        {shouldShowPDF && (
          <Box>
            <PdfDownloadButton
              isMinimal
              handleDownloadFile={handleDownloadFile}
            />
          </Box>
        )}
      </Box>
      {resultsMap &&
        resultsMap.map(([identifier, results]) => (
          <Box bgcolor="background.paper" key={identifier}>
            <Box
              style={{ paddingBottom: identifier ? theme.spacing(1) : 0 }}
              className={classes.spacing}
              color="secondary.dark"
            >
              {identifier && (
                <Typography
                  variant="subtitle2"
                  component="h3"
                  data-testid="blood-test-details/list-view/identifier"
                >
                  {identifier}
                </Typography>
              )}
            </Box>
            <Divider className={classes.divider} />
            {results.map((result) => {
              let borderLeftColor: string | undefined;
              let title = "";
              if (result.abnormalFlag) {
                title = `Abnormal flag: ${result.abnormalFlag.flag}${
                  result.abnormalFlag.meaning
                    ? ` (${result.abnormalFlag.meaning})`
                    : ""
                }`;
                borderLeftColor = theme.palette.error.light;
              }
              if (result.isFailed) {
                title = `Failed code presents`;
                borderLeftColor = theme.palette.warning.light;
              }
              return (
                <Fragment key={result.id}>
                  <Box
                    style={{
                      paddingBottom: "0",
                      borderLeft: borderLeftColor
                        ? `8px solid ${borderLeftColor}`
                        : undefined,
                    }}
                    className={classes.spacing}
                    data-testid="blood-test-details/list-view/item"
                  >
                    <Box mb={2} display="flex" alignItems="center">
                      {title && (
                        <Tooltip title={title} arrow>
                          <Box
                            mr={1}
                            display="inline-flex"
                            alignItems="center"
                            color="text.secondary"
                          >
                            <InfoOutlined />
                          </Box>
                        </Tooltip>
                      )}
                      <Typography
                        variant="subtitle2"
                        component="h4"
                        data-testid="blood-test-details/list-view/item/name"
                      >
                        {result.name}
                      </Typography>
                    </Box>

                    <Box display="flex" alignItems="center" flexWrap="wrap">
                      <Box mb={2} mr={2} flex={1} minWidth="140px">
                        <Typography
                          color="textSecondary"
                          variant="body2"
                          gutterBottom
                        >
                          Result:
                        </Typography>
                        <Typography data-testid="blood-test-details/list-view/item/result">
                          {result.value} {result.unit}
                        </Typography>
                      </Box>
                      <Box mb={2} mr={2} flex={1} minWidth="140px">
                        <Typography
                          color="textSecondary"
                          variant="body2"
                          gutterBottom
                        >
                          Normal range:
                        </Typography>
                        <Typography data-testid="blood-test-details/list-view/item/range">
                          {result.normalRange}
                        </Typography>
                      </Box>
                      <Box
                        mb={result.abnormalFlag ? 2 : 0}
                        mr={result.abnormalFlag ? 2 : 0}
                        flex={1}
                        minWidth="140px"
                      >
                        {result.abnormalFlag && (
                          <>
                            <Typography
                              color="textSecondary"
                              variant="body2"
                              gutterBottom
                            >
                              Abnormal flags:
                            </Typography>
                            <Typography>
                              {result.abnormalFlag.meaning ||
                                result.abnormalFlag.flag ||
                                ""}
                            </Typography>
                          </>
                        )}
                      </Box>
                    </Box>
                    {!!result.notes && (
                      <Box
                        pb={1}
                        data-testid="blood-test-result-details/mobile/notes"
                      >
                        <Typography variant="caption" className={classes.notes}>
                          * {result.notes}
                        </Typography>
                      </Box>
                    )}
                  </Box>
                  <Divider />
                </Fragment>
              );
            })}
          </Box>
        ))}
      <Box />
    </Box>
  );
};
