export const capitalizeFirstLetter = (str: string) => {
  if (!str.length) return str;
  return str[0].toUpperCase() + str.slice(1);
};

export const hideEmailAddress = (email?: string): string => {
  if (!email) return "";
  const splits = email.split("@");
  const name = splits[0] || "";
  const domain = splits[1] || "";
  const firstChar = name[0] || "";
  const lastChar = name.length > 1 ? name[name.length - 1] : "";
  const asterisks = "*".repeat(8);
  return `${firstChar}${asterisks}${lastChar}@${domain}`;
};

export const removeSecondOccur = (str: string, char: string) => {
  return str.split(char).slice(0, 2).join(char);
};
