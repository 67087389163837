import { init } from "@deep-consulting-solutions/auth-web";
import {
  apiClient,
  silentClient,
  setAuthorizationHeader,
  removeAuthorizationHeader,
} from "apis";

init({
  appName: "Balance My Hormones", // name of the app (like BE, BMH...)
  apiClient, // main axios apiClient instance
  silentClient, // axios apiClient instance that wont throw error
  removeAuthorizationHeader, // remove token from apiClient
  setAuthorizationHeader, // add token to apiClient
  disabledActions: {
    facebook: true,
    facebookLoggedIn: true,
  },
});
