import React, { useState, useCallback } from "react";
import { useDispatch } from "react-redux";

import { AppDispatch } from "redux/store";
import { useBookPCMConsultation } from "hooks";
import { schedulingActions } from "redux/scheduling";
import { MemoSchedulingToolDialog } from "components/SchedulingTool";
import Loader from "components/Loader";
import { userActions } from "redux/user";

export const BookPCMConsultationDialog = () => {
  const {
    handleCloseBookPCMConsultationDialog,
    showBookPCMConsultationDialog,
  } = useBookPCMConsultation();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch<AppDispatch>();

  const onScheduleDone = useCallback(
    async (actions?: { shouldRefreshContact: boolean }) => {
      setLoading(true);
      if (actions?.shouldRefreshContact) {
        await dispatch(userActions.getUserProfile());
      }
      await dispatch(schedulingActions.fetchUpcomingMCM());
      handleCloseBookPCMConsultationDialog();
      setLoading(false);
    },
    [dispatch, handleCloseBookPCMConsultationDialog]
  );

  if (loading) {
    return <Loader open />;
  }
  return (
    <MemoSchedulingToolDialog
      onClose={handleCloseBookPCMConsultationDialog}
      onScheduleDone={onScheduleDone}
      open={showBookPCMConsultationDialog}
    />
  );
};
