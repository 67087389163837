import React from "react";
import { Grid, Typography, Divider } from "@material-ui/core";

const AuthFormDivider: React.FC = () => {
  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item xs={12} />
      <Grid item xs>
        <Divider />
      </Grid>
      <Grid item xs="auto">
        <Typography variant="subtitle2">OR</Typography>
      </Grid>
      <Grid item xs>
        <Divider />
      </Grid>
      <Grid item xs={12} />
    </Grid>
  );
};

export default AuthFormDivider;
