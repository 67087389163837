import { createContext } from "react";
import { BookPCMConsultationContextInterface } from "./types";

export const BookPCMConsultationContext = createContext<BookPCMConsultationContextInterface>(
  {
    handleOpenBookPCMConsultationDialog: () => {},
    handleCloseBookPCMConsultationDialog: () => {},
    showBookPCMConsultationDialog: false,
    canOpenBookPCMConsultationDialog: false,
  }
);
