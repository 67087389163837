import React, { ReactNode, useMemo } from "react";
import {
  Box,
  Drawer as MUIDrawer,
  Toolbar,
  List as MUIList,
  Hidden,
  withStyles,
  styled,
  createStyles,
  makeStyles,
  Container,
} from "@material-ui/core";

import { useUpdateStaticLastLogin } from "@deep-consulting-solutions/portals-web";
import { BookPCMConsultationProvider } from "context";
import CustomerHeader from "./CustomerHeader";
import ListItemLink from "./ListItemLink";
import BottomNav, { listItems } from "./BottomNav";

const DRAWER_WIDTH = 200;

const Drawer = withStyles(({ palette }) => ({
  root: {
    width: DRAWER_WIDTH,
    flexShrink: 0,
  },
  paper: {
    backgroundColor: palette.primary.main,
    color: "#7E9FCE",
    width: DRAWER_WIDTH,
    border: "none",
  },
}))(MUIDrawer);

const DrawerToolbar = styled(Toolbar)(({ theme }) => ({
  ...theme.mixins.toolbar,
  justifyContent: "center",
  backgroundColor: theme.palette.primary.main,
}));

const List = styled(MUIList)({
  padding: 0,
});

export const useStyles = makeStyles((theme) =>
  createStyles({
    wrapperBox: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
      position: "relative",
      overflow: "hidden",
      [theme.breakpoints.down("md")]: {
        paddingBottom: theme.spacing(9),
      },
      [theme.breakpoints.down("sm")]: {
        paddingTop: theme.spacing(3),
      },
      [theme.breakpoints.down("xs")]: {
        paddingTop: theme.spacing(0),
      },
    },
  })
);

const CustomerLayout: React.FC<{
  children: Exclude<ReactNode, null | undefined>;
}> = ({ children }) => {
  const classes = useStyles();

  useUpdateStaticLastLogin("Customer");

  const drawer = useMemo(() => {
    return (
      <>
        <DrawerToolbar />
        <Box overflow="auto" py={3}>
          <List>
            {listItems.map(({ path, ...props }) => {
              return <ListItemLink key={path} path={path} {...props} />;
            })}
          </List>
        </Box>
      </>
    );
  }, []);

  return (
    <BookPCMConsultationProvider>
      <Box display="flex" flex="1" minHeight="100vh">
        <CustomerHeader />
        <Hidden mdDown>
          <Drawer variant="permanent">{drawer}</Drawer>
        </Hidden>
        <Box flex={1} maxWidth="100%">
          <DrawerToolbar />
          <Box className={classes.wrapperBox}>
            <Container maxWidth="xl">{children}</Container>
          </Box>
        </Box>
      </Box>
      <Hidden lgUp>
        <BottomNav />
      </Hidden>
    </BookPCMConsultationProvider>
  );
};

export default CustomerLayout;
