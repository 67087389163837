import React, { useCallback, useEffect, useMemo, useState } from "react";
import { UserBloodTestResult } from "@deep-consulting-solutions/bmh-constants";
import { Link, useHistory, useLocation } from "react-router-dom";
import {
  Box,
  Button,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import Loader from "components/Loader";
import { useResponsive } from "hooks";
import { TablePaginationBox } from "components/TablePaginationBox";
import { DataWithWarning } from "components/DataWithWarning";
import { processSampleCollectedDate } from "helpers";
import TableCellHeader from "./TableCellHeader";
import { TableMobileSort } from "./TableMobileSort";
import { FetchResultsParamsFieldEnum, TABLE_META } from "./types";
import {
  generatePath,
  getNext,
  getResultDetailsRoute,
  parseParams,
} from "./helpers";
import { fetchResults } from "./requests";
import { ResultListItem } from "./ResultListItem";

const useStyles = makeStyles(
  ({ spacing: s, breakpoints: b, typography: typo, palette: p }) => ({
    wrapper: {
      [b.down("sm")]: {
        backgroundColor: p.background.default,
        border: "none",
      },
      [b.down("xs")]: {
        margin: "0 -16px",
      },
    },
    table: {
      marginBottom: s(2),
    },
    noData: {
      color: p.grey[500],
      fontSize: typo.body1.fontSize,
      fontWeight: typo.body1.fontWeight,
      textAlign: "center",
    },
    listView: {
      display: "flex",
      flexWrap: "wrap",
    },
    noInvoiceMobile: {
      marginTop: s(2),
      color: p.grey[500],
      textAlign: "center",
      width: "100%",
    },
    mobileHeaderWrapper: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      flexWrap: "wrap",

      [b.down("xs")]: {
        padding: s(3, 2),
      },
    },
    linkBtn: {
      textDecoration: "none",
    },
  })
);

export const BloodTestsPage = () => {
  const history = useHistory();
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [total, setTotal] = useState(0);
  const [results, setResults] = useState<UserBloodTestResult[]>([]);
  const { size, page, sortBy, sortASC } = useMemo(() => {
    return parseParams(location.search);
  }, [location.search]);

  const redirect = useCallback(
    (next?: {
      size?: number;
      page?: number;
      sortBy?: FetchResultsParamsFieldEnum;
      sortASC?: boolean;
    }) => {
      const { pathname, search: searchPath } = location;
      let nextPath = pathname;

      if (next) {
        const query = generatePath({
          size: getNext(size, next.size),
          page: getNext(page, next.page),
          sortBy: getNext(sortBy, next.sortBy),
          sortASC: getNext(sortASC, next.sortASC),
        });

        if (query) nextPath += `?${query}`;
      }

      if (nextPath !== pathname + searchPath) {
        history.push(nextPath);
      }
    },
    [history, location, page, size, sortASC, sortBy]
  );

  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      const data = await fetchResults({
        page,
        size,
        sortASC,
        sortBy,
      });
      setResults(data.results);
      setTotal(data.total);
    } catch {
      //
    }
    setLoading(false);
  }, [page, size, sortASC, sortBy]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const onPageChange = useCallback(
    (nextPage: number) => {
      redirect({
        page: nextPage,
      });
    },
    [redirect]
  );

  const onRowsPerPageChange = useCallback(
    (nextSize: number) => {
      redirect({
        page: 1,
        size: nextSize,
      });
    },
    [redirect]
  );

  const onSortClick = useCallback(
    (c: FetchResultsParamsFieldEnum) => {
      redirect({
        page: 1,
        sortBy: c,
        sortASC: c === sortBy ? !sortASC : false,
      });
    },
    [redirect, sortBy, sortASC]
  );

  const { isSMDown } = useResponsive();

  const classes = useStyles();
  return (
    <>
      <Loader open={loading} />
      {!isSMDown && (
        <Box mb={3}>
          <Typography color="primary" variant="h3" component="h1">
            Blood Tests
          </Typography>
        </Box>
      )}

      <Paper className={classes.wrapper} variant="outlined">
        {isSMDown && (
          <Box className={classes.mobileHeaderWrapper}>
            <Typography color="primary" variant="h3" component="h1">
              Blood Tests
            </Typography>

            <Box>
              <TableMobileSort
                sortASC={sortASC}
                sortBy={sortBy}
                onSortItemClick={onSortClick}
              />
            </Box>
          </Box>
        )}
        {!isSMDown && (
          <TableContainer className={classes.table}>
            <Table>
              <TableHead>
                <TableRow>
                  {Object.keys(TABLE_META).map((k) => {
                    const key = k as FetchResultsParamsFieldEnum;
                    return (
                      <TableCellHeader
                        key={key}
                        column={key}
                        isDesc={sortBy === key ? !sortASC : undefined}
                        onClick={onSortClick}
                      />
                    );
                  })}
                </TableRow>
              </TableHead>

              <TableBody>
                {!loading && !results.length && (
                  <TableRow>
                    <TableCell
                      colSpan={Object.keys(TABLE_META).length}
                      className={classes.noData}
                    >
                      No Blood Tests found
                    </TableCell>
                  </TableRow>
                )}

                {results.length > 0 &&
                  results.map((result) => {
                    const { date, warning } = processSampleCollectedDate(
                      result
                    );
                    return (
                      <TableRow key={result.id}>
                        <TableCell>{result.name}</TableCell>
                        <TableCell>{result.bloodTakingOption}</TableCell>
                        <TableCell>
                          <DataWithWarning warning={warning}>
                            {date}
                          </DataWithWarning>
                        </TableCell>
                        <TableCell>
                          <Button
                            component={Link}
                            color="primary"
                            variant="outlined"
                            size="small"
                            to={getResultDetailsRoute(result.id)}
                          >
                            View Results
                          </Button>
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        )}

        {isSMDown && (
          <Box className={classes.listView}>
            {results.length > 0 &&
              results.map((result) => {
                return <ResultListItem key={result.id} result={result} />;
              })}

            {!loading && !results.length && (
              <Typography variant="body1" className={classes.noInvoiceMobile}>
                No Blood Tests found
              </Typography>
            )}
          </Box>
        )}

        <TablePaginationBox
          total={total}
          page={page}
          rowsPerPage={size}
          changePage={onPageChange}
          changeRowsPerPage={onRowsPerPageChange}
        />
      </Paper>
    </>
  );
};
