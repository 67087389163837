import React, { memo, useCallback } from "react";
import { Typography, Avatar, makeStyles, Button } from "@material-ui/core";
import { MCMInfo } from "@deep-consulting-solutions/bmh-constants";

import { useResponsive } from "hooks";

import { COLORS } from "../SchedulingTool.styles";

const useStyle = makeStyles(({ spacing: s, palette: p, breakpoints: b }) => ({
  mcmBtn: {
    width: "600px",
    padding: s(3),
    display: "flex",
    alignItems: "center",
    border: `1px solid ${COLORS.violetLight}`,
    borderRadius: "10px",
    justifyContent: "flex-start",
    marginTop: s(2),

    "&:first-child": {
      marginTop: 0,
    },

    "&:hover": {
      background: COLORS.violetHover,
    },

    [b.down("sm")]: {
      padding: s(1),
      width: "100%",
    },
  },
  avatar: {
    height: "64px",
    width: "64px",
    marginRight: s(5),
    [b.down("xs")]: {
      height: "48px",
      width: "48px",
      marginRight: s(2),
    },
  },
  mcmName: {
    fontWeight: 400,
    color: p.primary.light,
  },
}));

interface MCMBtnProps {
  mcm: MCMInfo;
  onSelect: (mcm: MCMInfo) => any;
}

const MCMBtn: React.FC<MCMBtnProps> = ({ mcm, onSelect }) => {
  const { isSMDown } = useResponsive();

  const onClick = useCallback(() => {
    onSelect(mcm);
  }, [onSelect, mcm]);

  const classes = useStyle();

  return (
    <Button variant="outlined" className={classes.mcmBtn} onClick={onClick}>
      <Avatar
        src={mcm.avatar || ""}
        alt={mcm.name}
        className={classes.avatar}
      />
      <Typography
        variant={isSMDown ? "body1" : "h5"}
        className={classes.mcmName}
      >
        {mcm.name}
      </Typography>
    </Button>
  );
};

export default memo(MCMBtn);
