import { AxiosInstance, AxiosResponse } from "axios";
import {
  apiClient,
  createClient,
  crmClient,
  crmSilentClient,
  silentClient,
} from "apis";
import {
  ROUTE_QUALIFIED_MCMS_PORTAL,
  GETQualifiedMcmsRes,
  ROUTE_BOOKING_PAGE_ACCESSIBILITY_BY_SCHEDULING_TOKEN,
  GETBookingPageAccessibilityBySchedulingTokenRes,
  ROUTE_RESCHEDULE_PAGE_ACCESSIBILITY_BY_RESCHEDULING_TOKEN,
  GETReschedulingPageAccessibilityByReschedulingTokenRes,
  MCMCall,
  ROUTE_UPCOMING_MCM_CALL_PORTAL,
  GetUpcomingMCMCallRes,
  ROUTE_MEETING_PORTAL,
  POSTMeetingBody,
  POSTMeetingRes,
  SchedulingInfo,
  MCMInfo,
  CrmMeeting,
  ROUTE_CANCEL_PAGE_ACCESSIBILITY_BY_CANCEL_TOKEN,
  GETCancelPageAccessibilityByCancelTokenRes,
  GetMCMCallDetailsByMeetingZohoIdRes,
  POSTCancelMCMCallRes,
  ROUTE_QUALIFIED_MCMS_BOOKING_PAGE,
  ROUTE_MEETING_BOOKING_PAGE,
  ROUTE_BOOKING_PAGE_ACCESSIBILITY,
  GETBookingPageAccessibilityRes,
  ROUTE_QUALIFIED_MCMS_CRM,
  ROUTE_MEETING_CRM,
  ROUTE_QUALIFIED_MCMS_RESCHEDULING_PAGE,
  ContactRes,
} from "@deep-consulting-solutions/bmh-constants";
import { SchedulingTokens } from "components/SchedulingTool/SchedulingToolTokenContext";

export const getEmailClient = (() => {
  let clients:
    | {
        client: AxiosInstance;
        silentClient: AxiosInstance;
        setAuthorizationHeader: (token: string) => void;
      }
    | undefined;
  return (token: string) => {
    if (!clients) {
      clients = createClient({ disableActionsOnError: true });
    }

    clients.setAuthorizationHeader(token);
    return clients;
  };
})();

const createMCMCallFromMeetingAndMCMAndSchedulingInfo = (
  meeting: CrmMeeting,
  mcm: MCMInfo,
  info: SchedulingInfo
): MCMCall => {
  return {
    id: meeting.id,
    mcm,
    phone: meeting.clientPhone,
    start: meeting.startTime,
    end: meeting.endTime,
    bmhTZ: info.bmhTZ || "",
    clientTZ: info.clientTZ || "",
    zohoID: meeting.zohoID,
    purpose: info.purpose,
    comment: meeting.clientComments,
    clientHasTwoRecentBloodTest: meeting.clientHasTwoRecentBloodTest,
  };
};

export const fetchMCMCalendarRequest = async (
  tokens?: SchedulingTokens,
  meeting?: MCMCall,
  contactZohoID?: string
) => {
  let res: AxiosResponse<GETQualifiedMcmsRes>;
  if (tokens && tokens.emailToken) {
    const { client } = getEmailClient(tokens.emailToken);
    if (meeting) {
      res = await client.get<GETQualifiedMcmsRes>(
        ROUTE_QUALIFIED_MCMS_RESCHEDULING_PAGE
      );
    } else {
      res = await client.get<GETQualifiedMcmsRes>(
        ROUTE_QUALIFIED_MCMS_BOOKING_PAGE
      );
    }
  } else if (tokens && tokens.crmToken) {
    res = await crmClient.get<GETQualifiedMcmsRes>(
      `${ROUTE_QUALIFIED_MCMS_CRM}?contactZohoID=${contactZohoID || ""}`
    );
  } else {
    res = await apiClient.get<GETQualifiedMcmsRes>(ROUTE_QUALIFIED_MCMS_PORTAL);
  }
  return res.data.data;
};

export interface CreateMCMData {
  mcm: MCMInfo;
  info: SchedulingInfo;
  start: string;
  end: string;
  comment?: string;
  phone?: string;
  setAsMainPhone: boolean;
  timezone?: string;
  clientHasTwoRecentBloodTestResult?: boolean;
}

export interface RescheduleMCMData extends CreateMCMData {
  reason?: string;
}

export const createMCMReq = async (
  data: CreateMCMData,
  zohoId: string,
  tokens?: SchedulingTokens
): Promise<MCMCall> => {
  const { mcm, info, ...others } = data;
  const body: POSTMeetingBody = {
    ...others,
    mcmID: mcm.id,
    purpose: data.info.purpose,
  };
  let res: AxiosResponse<POSTMeetingRes>;

  if (tokens && tokens.emailToken) {
    const { silentClient: silentEmailClient } = getEmailClient(
      tokens.emailToken
    );
    res = await silentEmailClient.post<POSTMeetingRes>(
      ROUTE_MEETING_BOOKING_PAGE,
      body
    );
  } else if (tokens && tokens.crmToken) {
    res = await crmSilentClient.post<POSTMeetingRes>(
      ROUTE_MEETING_CRM.replace(":zohoID", zohoId),
      body
    );
  } else {
    res = await silentClient.post<POSTMeetingRes>(ROUTE_MEETING_PORTAL, body);
  }

  return createMCMCallFromMeetingAndMCMAndSchedulingInfo(
    res.data.data.crmMeeting,
    mcm,
    info
  );
};

interface RescheduleResponse {
  data: { crmMeeting: CrmMeeting };
}

export const rescheduleMCMReq = async (
  meeting: MCMCall,
  data: RescheduleMCMData,
  tokens: SchedulingTokens,
  zohoID?: string
): Promise<MCMCall> => {
  const { mcm, info, ...others } = data;
  const body = {
    ...others,
    mcmID: mcm.id,
    purpose: info.purpose,
  };

  let res: AxiosResponse<RescheduleResponse>;
  if (tokens && tokens.emailToken) {
    const { silentClient: silentEmailClient } = getEmailClient(
      tokens.emailToken
    );
    res = await silentEmailClient.put<RescheduleResponse>(
      "/meetings/rescheduling-page",
      body
    );
  } else if (tokens && tokens.crmToken) {
    res = await crmSilentClient.put<RescheduleResponse>(
      `/meetings/crm/:zohoID`.replace(":zohoID", zohoID || ""),
      body
    );
  } else {
    res = await silentClient.put<RescheduleResponse>(
      `/meetings/portal/${meeting.id}`,
      body
    );
  }
  return createMCMCallFromMeetingAndMCMAndSchedulingInfo(
    res.data.data.crmMeeting,
    mcm,
    info
  );
};

export const validateSchedulingToken = async (token: string) => {
  const { client } = getEmailClient(token);
  const res = await client.get<GETBookingPageAccessibilityBySchedulingTokenRes>(
    ROUTE_BOOKING_PAGE_ACCESSIBILITY_BY_SCHEDULING_TOKEN
  );

  return res.data.data;
};

export const validatingReschedulingToken = async (token: string) => {
  const { client } = getEmailClient(token);
  const res = await client.get<GETReschedulingPageAccessibilityByReschedulingTokenRes>(
    ROUTE_RESCHEDULE_PAGE_ACCESSIBILITY_BY_RESCHEDULING_TOKEN
  );
  return res.data.data;
};

export const fetchUpcomingMCMReq = async (
  tokens?: SchedulingTokens,
  contactZohoID?: string
) => {
  let res: AxiosResponse<GetUpcomingMCMCallRes>;

  if (tokens?.crmToken) {
    res = await crmSilentClient.get<GetUpcomingMCMCallRes>(
      `/meetings/mcm-call/crm/upcoming?contactZohoID=${contactZohoID}`
    );
  } else if (tokens?.emailToken) {
    const { silentClient: silentEmailClient } = getEmailClient(
      tokens.emailToken
    );

    res = await silentEmailClient.get<GetUpcomingMCMCallRes>(
      `/meetings/mcm-call/email/upcoming`
    );
  } else {
    res = await silentClient.get<GetUpcomingMCMCallRes>(
      ROUTE_UPCOMING_MCM_CALL_PORTAL
    );
  }

  return res.data.data;
};

export const fetchContactReq = async () => {
  const res = await silentClient.get<{
    data: { contact: ContactRes & { mcm?: MCMInfo | null } };
  }>("/contacts/portal");

  return res.data.data;
};

export const validateScheduleCRMToken = async (zohoId: string) => {
  const res = await crmClient.get<GETBookingPageAccessibilityRes>(
    ROUTE_BOOKING_PAGE_ACCESSIBILITY.replace(":zohoID", zohoId)
  );
  return res.data.data;
};

export const getCancelPageAccessibilityByCancelTokenReq = async (
  token: string
): Promise<GETCancelPageAccessibilityByCancelTokenRes> => {
  const { client } = getEmailClient(token);
  const res = await client.get<GETCancelPageAccessibilityByCancelTokenRes>(
    ROUTE_CANCEL_PAGE_ACCESSIBILITY_BY_CANCEL_TOKEN
  );
  return res.data;
};

export const cancelMCMCallByCancelTokenReq = async (
  reason: string,
  token: string
): Promise<POSTCancelMCMCallRes> => {
  const { client } = getEmailClient(token);
  const res = await client.post<POSTCancelMCMCallRes>(
    `/meetings/email/cancel`,
    { reason }
  );
  return res.data;
};

export const getMCMCallDetailsCRMReq = async (
  meetingZohoId: string
): Promise<GetMCMCallDetailsByMeetingZohoIdRes> => {
  const res = await crmClient.get<GetMCMCallDetailsByMeetingZohoIdRes>(
    `meetings/mcm-call/details/${meetingZohoId}`
  );
  return res.data;
};

export const cancelMCMCallCRMReq = async (
  mcmCallId: string,
  reason: string
): Promise<POSTCancelMCMCallRes> => {
  const res = await crmClient.post<POSTCancelMCMCallRes>(
    `/meetings/crm/${mcmCallId}/cancel`,
    { reason }
  );
  return res.data;
};

export const cancelMCMCallPortalReq = async (
  mcmCallId: string,
  reason: string
): Promise<POSTCancelMCMCallRes> => {
  const res = await apiClient.post<POSTCancelMCMCallRes>(
    `/meetings/portal/${mcmCallId}/cancel`,
    { reason }
  );
  return res.data;
};

export const requestSchedulingReq = async (
  meetingZohoID: string,
  data: { reason: string }
) => {
  await crmClient.post(
    `/meetings/request-reschedule/:zohoID`.replace(":zohoID", meetingZohoID),
    data
  );
};
