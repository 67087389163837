import React from "react";
import { CssBaseline } from "@material-ui/core";
import {
  BrowserRouter as Router,
  Switch,
  Redirect,
  Route,
} from "react-router-dom";
import { ZohoModuleEnum } from "@deep-consulting-solutions/bmh-constants";

// a comment

import NetworkErrorDialog from "components/NetworkErrorDialog";
import { NotificationProvider } from "services";
import { ROUTES } from "configs";
import LoginPage from "pages/LoginPage";
import OAuthResponsePage from "pages/OAuthResponsePage";
import ProfilePage from "pages/ProfilePage";
import ForgotPasswordPage from "pages/ForgotPasswordPage";
import RecoverPasswordPage from "pages/RecoverPasswordPage";
import UpdateEmailVerificationPage from "pages/UpdateEmailVerificationPage";
import AuthRoute from "components/AuthRoute";
import { ZohoRoute, ZohoRouteServiceEnum } from "components/ZohoRoute";
import { ActivationPage } from "pages/ActivationPage";
import { ActivationProfileInfoPage } from "pages/ActivationProfileInfoPage";
import { ActivationWelcomePage } from "pages/ActivationWelcomePage";
import { DashboardPage } from "pages/DashboardPage";
import { CustomerRoute } from "components/CustomerRoute";
import { InvoicesPage } from "pages/InvoicesPage";
import { BloodTestsPage } from "pages/BloodTestsPage";
import { BloodTestsDetailsPage } from "pages/BloodTestsDetailsPage";
import { SchedulingFromEmailPage } from "pages/SchedulingFromEmailPage";
import { SchedulingFromCRMPage } from "pages/SchedulingFromCRMPage";
import { CancelMcmCallFromEmailPage } from "pages/CancelMcmCallFromEmailPage";
import { CancelMcmCallFromCRMPage } from "pages/CancelMcmCallFromCRMPage";
import { ReschedulingFromEmailPage } from "pages/ReschedulingFromEmailPage";
import { ReschedulingFromCRMPage } from "pages/ReschedulingFromCRMPage";
import { RequestRescheduleFromCRMPage } from "pages/RequestRescheduleFromCRMPage";

export default () => {
  return (
    <Router>
      <CssBaseline />
      <NotificationProvider>
        <Switch>
          <AuthRoute path={ROUTES.login.path} exact component={LoginPage} />
          <AuthRoute
            path={ROUTES.oauthResponse.path}
            exact
            component={OAuthResponsePage}
          />
          <AuthRoute path={ROUTES.forgot.path} component={ForgotPasswordPage} />
          <AuthRoute
            path={ROUTES.recoverPassword.path}
            component={RecoverPasswordPage}
          />
          <AuthRoute
            path={ROUTES.updateEmailVerification.path}
            exact
            component={UpdateEmailVerificationPage}
          />
          <AuthRoute
            privateRoute
            path={ROUTES.profile.path}
            component={ProfilePage}
          />
          <AuthRoute
            exact
            path={ROUTES.activation.path}
            component={ActivationPage}
          />
          <AuthRoute
            privateRoute
            exact
            path={ROUTES.activationProfileInfo.path}
            component={ActivationProfileInfoPage}
            headerComponent={null}
          />
          <AuthRoute
            privateRoute
            exact
            path={ROUTES.activationWelcome.path}
            component={ActivationWelcomePage}
            headerComponent={null}
          />

          <CustomerRoute
            path={ROUTES.dashboard.path}
            component={DashboardPage}
          />
          <CustomerRoute
            exact
            path={ROUTES.bloodTests.path}
            component={BloodTestsPage}
          />
          <CustomerRoute
            path={ROUTES.bloodTestsDetails.path}
            component={BloodTestsDetailsPage}
          />
          <CustomerRoute path={ROUTES.invoices.path} component={InvoicesPage} />

          <Route
            path={ROUTES.schedulingFromEmail.path}
            component={SchedulingFromEmailPage}
          />

          <ZohoRoute
            path={ROUTES.schedulingFromCRM.path}
            component={SchedulingFromCRMPage}
            init
            entity
            exact
            onlyForTabs={{
              tabs: ZohoModuleEnum.CONTACTS,
              errorMessage:
                "The client has an upcoming PCM consultation already scheduled.",
            }}
          />

          <Route
            path={ROUTES.cancelScheduledMeetingFromEmail.path}
            component={CancelMcmCallFromEmailPage}
            exact
          />

          <ZohoRoute
            path={ROUTES.cancelScheduledMeetingFromCRM.path}
            component={CancelMcmCallFromCRMPage}
            init
            entity
            exact
            onlyForTabs={{
              tabs: ZohoModuleEnum.MEETINGS,
            }}
          />

          <Route
            path={ROUTES.reschedulingFromEmail.path}
            component={ReschedulingFromEmailPage}
          />

          <ZohoRoute
            path={ROUTES.reschedulingFromCRM.path}
            component={ReschedulingFromCRMPage}
            init
            entity
            services={ZohoRouteServiceEnum.records}
            onlyForTabs={{
              tabs: ZohoModuleEnum.MEETINGS,
            }}
          />

          <ZohoRoute
            path={ROUTES.requestReschedulingFromCRM.path}
            component={RequestRescheduleFromCRMPage}
            init
            entity
            onlyForTabs={{
              tabs: ZohoModuleEnum.MEETINGS,
            }}
          />

          <Redirect to={ROUTES.login.path} />
        </Switch>
      </NotificationProvider>
      <NetworkErrorDialog />
    </Router>
  );
};
