import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { Container, Typography, makeStyles, Button } from "@material-ui/core";
import { Formik, Form, Field, FormikConfig } from "formik";
import { TextField } from "formik-material-ui";
import { MCMCall, ContactRes } from "@deep-consulting-solutions/bmh-constants";

import { zohoSelectors } from "redux/zoho";
import {
  getMCMCallDetailsCRMReq,
  requestSchedulingReq,
} from "redux/scheduling/requests";
import Loader from "components/Loader";
import { useWidgetResize } from "hooks";
import { ZohoServices } from "services";

import { calcDurationInMinutes } from "../SchedulingTool.helpers";

interface FormValues {
  reason: string;
}

const useStyle = makeStyles(({ spacing: s }) => ({
  errorWrapper: {
    height: "90vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    textAlign: "center",
  },
  wrapper: {
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  meetingInfo: {
    textAlign: "center",
  },
  reasonGroup: {
    marginTop: s(3),
  },
  reasonLabel: {
    color: "rgba(0, 0, 0, 0.87)",
  },
  reasonField: {
    minWidth: "500px",
  },
  actions: {
    marginTop: s(2),
    textAlign: "center",
  },
  requestBtn: {
    marginRight: s(1),
  },
}));

export const RequestRescheduleOnCRM = () => {
  const { wrapperRef, resize } = useWidgetResize();

  const ids = useSelector(zohoSelectors.getIds);

  const [meeting, setMeeting] = useState<MCMCall | null>(null);
  const [client, setClient] = useState<ContactRes | null>(null);
  const [loading, setLoading] = useState(true);
  const [isRequested, setIsRequested] = useState(false);

  const duration = useMemo(() => {
    if (!meeting) return 0;
    return calcDurationInMinutes(meeting.start, meeting.end);
  }, [meeting]);

  const fetchMeeting = useCallback(async () => {
    setLoading(true);
    try {
      const res = await getMCMCallDetailsCRMReq(ids[0]);
      setMeeting(res.data.meeting);
      setClient(res.data.client);
      setLoading(false);
    } catch {
      setLoading(false);
    }
  }, [ids]);

  const onSubmit: FormikConfig<FormValues>["onSubmit"] = useCallback(
    async (values) => {
      setLoading(true);
      try {
        await requestSchedulingReq(ids[0], { reason: values.reason });
        setIsRequested(true);
        setLoading(false);
      } catch {
        setLoading(false);
      }
    },
    [ids]
  );

  const onCloseClick = useCallback(() => {
    ZohoServices.closePopup();
  }, []);

  useEffect(() => {
    fetchMeeting();
  }, [fetchMeeting]);

  useEffect(() => {
    resize();
  }, [meeting, resize, isRequested]);

  const classes = useStyle();

  return (
    <Formik<FormValues>
      initialValues={{
        reason: "",
      }}
      onSubmit={onSubmit}
    >
      {() => {
        return (
          <Form>
            <Loader open={loading} />
            {!loading && !meeting && (
              <Container className={classes.errorWrapper}>
                <Typography variant="subtitle1" color="error">
                  Something went wrong
                </Typography>
              </Container>
            )}
            {!!meeting && (
              <Container innerRef={wrapperRef} className={classes.wrapper}>
                {!isRequested && (
                  <>
                    <Typography
                      variant="subtitle1"
                      className={classes.meetingInfo}
                    >
                      You are going to request the client to reschedule for
                      <br />“<b>{duration} minutes</b> meeting between{" "}
                      <b>
                        {client?.firstName ?? ""} {client?.lastName ?? ""}
                      </b>{" "}
                      and <b>{meeting.mcm.name}</b>”
                    </Typography>
                    <div className={classes.reasonGroup}>
                      <Typography
                        variant="body2"
                        className={classes.reasonLabel}
                      >
                        Provide the client with a reason for the rescheduling
                        request
                      </Typography>
                      <Field
                        component={TextField}
                        name="reason"
                        placeholder="Reschedule reason"
                        multiline
                        rows={4}
                        className={classes.reasonField}
                      />
                    </div>
                    <div className={classes.actions}>
                      <Button
                        variant="contained"
                        color="primary"
                        className={classes.requestBtn}
                        type="submit"
                      >
                        request reschedule
                      </Button>
                      <Button
                        variant="text"
                        color="primary"
                        onClick={onCloseClick}
                      >
                        back
                      </Button>
                    </div>
                  </>
                )}
                {isRequested && (
                  <>
                    <Typography
                      variant="subtitle1"
                      className={classes.meetingInfo}
                    >
                      An email has been send to the client requesting him to
                      reschedule the meeting.
                    </Typography>
                    <div className={classes.actions}>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={onCloseClick}
                      >
                        got it
                      </Button>
                    </div>
                  </>
                )}
              </Container>
            )}
          </Form>
        );
      }}
    </Formik>
  );
};
