import clsx from "clsx";
import React, { FC } from "react";
import { makeStyles, Typography, Box } from "@material-ui/core";
import { EventBusyOutlined, EventAvailableOutlined } from "@material-ui/icons";

import { CallInfo } from "./helpers";
import CallDetails from "./CallDetails";

export interface CancelMcmCallResultDialogProps {
  isCancelled: boolean;
  callInfo: CallInfo;
  host: "crm" | "client";
}

const useStyles = makeStyles(({ spacing: s, palette: p }) => ({
  root: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  title: {
    fontSize: "1.5rem",
    letterSpacing: "0.15px",
  },
  icon: {
    fontSize: "4rem",
    marginTop: s(3),
    marginBottom: s(4),
    color: p.error.main,
  },
  success: {
    color: p.success.main,
  },
  description: {
    color: p.primary.light,
    textAlign: "center",
  },
  notes: {
    minHeight: s(2),
    textAlign: "center",
    marginTop: s(1),
  },
  callInfo: {
    margin: s(4, 0),
  },
}));

const CancelMcmCallResultDialog: FC<CancelMcmCallResultDialogProps> = ({
  isCancelled,
  callInfo,
  host,
}) => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Typography variant="h4" color="primary" className={classes.title}>
        {isCancelled ? "Cancelled" : "Confirmation"}
      </Typography>

      {isCancelled ? (
        <EventBusyOutlined
          fontSize="large"
          color="error"
          className={classes.icon}
        />
      ) : (
        <EventAvailableOutlined
          fontSize="large"
          className={clsx(classes.icon, classes.success)}
        />
      )}
      <Typography variant="body1" className={classes.description}>
        {isCancelled
          ? "Your call with our Personal Case Manager has been cancelled."
          : "Your call with our Personal Case Manager remains scheduled the same date and time."}
      </Typography>
      <Typography
        variant="body2"
        color="textSecondary"
        className={classes.notes}
      >
        {isCancelled
          ? "We have sent you an email with the cancellation information."
          : " "}
      </Typography>
      <CallDetails {...callInfo} className={classes.callInfo} host={host} />
    </Box>
  );
};

export default CancelMcmCallResultDialog;
