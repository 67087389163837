import React, { memo, useMemo } from "react";
import {
  Box,
  Typography,
  makeStyles,
  Grid,
  IconButton,
} from "@material-ui/core";
import { MCMInfo } from "@deep-consulting-solutions/bmh-constants";

import { ArrowBack } from "@material-ui/icons";
import MCMBtn from "./MCMBtn";

const useStyle = makeStyles(({ spacing: s, palette: p, breakpoints: b }) => ({
  wrapper: {
    marginLeft: s(5),
    [b.down("sm")]: {
      width: "100%",
      marginLeft: `0px`,
      paddingTop: s(2),
      borderTop: `1px solid ${p.grey[300]}`,
    },
  },
  mcms: {
    marginTop: s(2),
    display: "flex",
    flexDirection: "column",
  },
  backBtn: {
    marginTop: s(2),
  },
}));

interface SchedulingMCMSelectionProps {
  mcms: MCMInfo[];
  targetMCM?: MCMInfo;
  onSelect: (mcm: MCMInfo) => any;
  onBack: () => any;
}

const SchedulingMCMSelection: React.FC<SchedulingMCMSelectionProps> = ({
  mcms,
  targetMCM,
  onSelect,
  onBack,
}) => {
  const sorted = useMemo(
    () =>
      [...mcms].sort((a, b) => {
        if (targetMCM) {
          if (a.id === targetMCM.id) return -1;
          if (b.id === targetMCM.id) return 1;
        }
        return a.name < b.name ? -1 : 1;
      }),
    [mcms, targetMCM]
  );
  const classes = useStyle();
  return (
    <Box className={classes.wrapper}>
      <Grid container alignItems="center" justify="flex-start" spacing={2}>
        <Grid item>
          <IconButton onClick={onBack}>
            <ArrowBack />
          </IconButton>
        </Grid>
        <Grid item>
          <Typography variant="subtitle2">
            Select a Personal Case Manager
          </Typography>
        </Grid>
      </Grid>
      <Box className={classes.mcms}>
        {sorted.map((mcm) => (
          <MCMBtn key={mcm.id} mcm={mcm} onSelect={onSelect} />
        ))}
      </Box>
    </Box>
  );
};

export default memo(SchedulingMCMSelection);
