import clsx from "clsx";
import React, { FC } from "react";
import { makeStyles, Box, SvgIconTypeMap } from "@material-ui/core";
import { OverridableComponent } from "@material-ui/core/OverridableComponent";
import {
  AccountCircle,
  WatchLater,
  Today,
  Public,
  Info,
} from "@material-ui/icons";

import { ConsultationPurposeEnum } from "@deep-consulting-solutions/bmh-constants";
import { ResultItem } from "../SchedulingResult/ResultItem";
import { getClientFacingFromPurpose } from "../SchedulingTool.helpers";

export interface CallDetailsItem {
  id: string;
  Icon: OverridableComponent<SvgIconTypeMap<Record<string, unknown>, "svg">>;
  iconClassName?: string;
  text: string;
  tooltip: string;
}

export interface CallDetailsProps {
  purpose?: ConsultationPurposeEnum | null;
  mcmName: string;
  callDuration: string;
  timeSlot: string;
  timezone: string;
  className?: string;
  host: "crm" | "client";
}

const useStyles = makeStyles(({ spacing: s, palette: p }) => ({
  root: {},
  item: {
    display: "flex",
    alignItems: "center",
    margin: s(3.5, 0),
    "&:first-child": {
      marginTop: 0,
    },
    "&:last-child": {
      marginBottom: 0,
    },
  },
  text: {
    marginLeft: s(1.2),
    lineHeight: 1.25,
  },
  infoIcon: {
    fontSize: "24px",
  },
  userIcon: {
    color: p.primary.dark,
  },
  purposeIcon: {
    color: p.primary.main,
  },
  clockIcon: {
    color: p.primary.light,
  },
  calendarIcon: {
    color: p.secondary.dark,
  },
  timezoneIcon: {
    color: p.text.secondary,
  },
}));

const CallDetails: FC<CallDetailsProps> = ({
  mcmName,
  callDuration,
  timeSlot,
  timezone,
  className,
  purpose,
  host,
}) => {
  const classes = useStyles();

  const items: CallDetailsItem[] = [
    {
      id: "1",
      Icon: AccountCircle,
      iconClassName: classes.userIcon,
      text: mcmName,
      tooltip: "PCM Name",
    },
    ...(purpose
      ? [
          {
            id: "2",
            Icon: Info,
            iconClassName: classes.purposeIcon,
            text: getClientFacingFromPurpose(purpose, host),
            tooltip: "Purpose of Consultation",
          },
        ]
      : []),
    {
      id: "3",
      Icon: WatchLater,
      text: callDuration,
      iconClassName: classes.clockIcon,
      tooltip: "Meeting Duration",
    },
    {
      id: "4",
      Icon: Today,
      iconClassName: classes.calendarIcon,
      text: timeSlot,
      tooltip: "Meeting Time",
    },
    {
      id: "5",
      Icon: Public,
      iconClassName: classes.timezoneIcon,
      text: timezone,
      tooltip: "Timezone",
    },
  ];

  return (
    <Box className={clsx(classes.root, className)}>
      {items.map(({ id, Icon, iconClassName, text, tooltip }) => (
        <ResultItem
          key={id}
          title={tooltip}
          text={text}
          Icon={Icon}
          iconClassName={clsx(classes.infoIcon, iconClassName)}
        />
      ))}
    </Box>
  );
};

export default CallDetails;
