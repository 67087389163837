/* eslint-disable react/no-array-index-key */
import React, { memo, useMemo, useCallback, useRef, useEffect } from "react";
import { format, isPast, endOfDay } from "date-fns";
import {
  Box,
  Typography,
  makeStyles,
  IconButton,
  Button,
  Paper,
  Grid,
} from "@material-ui/core";
import { DatePicker } from "@material-ui/pickers";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import {
  McmWithAvailability,
  SchedulingInfo,
  MCMInfo,
} from "@deep-consulting-solutions/bmh-constants";

import { useResponsive } from "hooks";

import { ArrowBack } from "@material-ui/icons";
import {
  DailyAvailableSlot,
  calcSlotsFor1Day,
  calcSlotsFor1Month,
  getCalendarMetaData,
  calcSlotsForNextAvailableMonth,
} from "../SchedulingTool.helpers";
import { COLORS } from "../SchedulingTool.styles";
import TimeSlot from "./TimeSlot";

const formatDate = (d: Date) => {
  return format(d, "yyyy-MM-dd");
};

const formatDayOfWeek = (d: Date) => {
  return format(d, "EEEE, MMMM do");
};

const isTheSameDate = (compare: Date | null, to: Date | null) => {
  if (!compare || !to) return false;
  return formatDate(compare) === formatDate(to);
};

const splitDailySlots = (slots: DailyAvailableSlot[]) => {
  const am: DailyAvailableSlot[] = [];
  const pm: DailyAvailableSlot[] = [];
  slots.forEach((slot) => {
    if (slot.start.getHours() >= 12) {
      pm.push(slot);
    } else {
      am.push(slot);
    }
  });
  return { am, pm };
};

const isDateInThePast = (d?: Date | null) => {
  return d ? isPast(endOfDay(d)) : true;
};

const useStyle = makeStyles(
  ({ spacing: s, typography: typo, palette: p, breakpoints: b }) => ({
    wrapper: {
      height: "100%",
      display: "flex",
      flexDirection: "column",
      [b.down("sm")]: {
        alignItems: "center",
        borderTop: `1px solid ${p.grey[300]}`,
        paddingTop: s(2),
      },
    },
    inner: {
      marginTop: s(2),
      display: "flex",
      alignItems: "stretch",
      flex: 1,
      [b.down("sm")]: {
        flexDirection: "column",
        width: "100%",
      },
    },
    left: {
      height: "100%",
      [b.down("sm")]: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      },
    },
    calendarWrapper: {
      background: p.common.white,
      padding: s(0.5),

      "& > *": {
        background: "transparent",
      },
    },
    calendarMonthIcon: {
      background: p.common.white,
      "&:hover": {
        background: undefined,
      },
    },
    dateWrapper: {},
    dateBtn: {
      width: 36,
      height: 36,
      fontSize: typo.caption.fontSize,
      margin: "0 2px",
      color: "inherit",
      padding: s(0.5),
    },
    targetMCMDate: {
      background: COLORS.blueNormal,
      color: p.secondary.dark,

      "&:hover": {
        background: COLORS.blueNormal,
      },
    },
    otherMCMDate: {
      background: COLORS.blueLight,
      color: p.secondary.dark,

      "&:hover": {
        background: COLORS.blueLight,
      },
    },
    noDataDate: {
      color: p.grey[400],
    },
    pickedDate: {
      background: p.primary.main,
      color: p.common.white,

      "&:hover": {
        background: p.primary.main,
      },
    },
    outOfMonthDate: {
      opacity: 0,
    },
    havingMCMDate: {
      background: COLORS.teal,

      "&:hover": {
        background: COLORS.teal,
      },
    },
    right: {
      padding: s(2),
      flex: 1,
      display: "flex",
      flexDirection: "column",
      height: "100%",
      [b.down("sm")]: {
        paddingLeft: s(0.5),
        paddingRight: s(0.5),
      },
    },
    slots: {
      marginTop: s(2),
      width: "100%",
      display: "flex",
      [b.down("sm")]: {
        marginBottom: s(2),
      },
    },
    amSlots: {
      flex: 1,
      paddingRight: s(1),
    },
    pmSlots: {
      flex: 1,
      paddingLeft: s(1),
    },

    dayTitle: {
      fontWeight: 600,
      [b.down("sm")]: {
        textAlign: "center",
        paddingTop: s(2),
        borderTop: `1px solid ${p.grey[300]}`,
      },
    },
    legends: {
      marginTop: s(3),
      paddingTop: s(2),
      paddingBottom: s(2),
      paddingLeft: s(3),
      paddingRight: s(3),

      borderTop: `1px solid ${p.grey[400]}`,

      [b.down("sm")]: {
        display: "flex",
        marginTop: s(2),
        paddingTop: s(1),
        paddingBottom: s(1),
        paddingLeft: "2px",
        paddingRight: "2px",
      },
    },
    legend: {
      display: "flex",
      alignItems: "center",
      paddingTop: s(1),
      [b.down("sm")]: {
        "&:first-child": {
          marginRight: s(0.5),
        },
      },
    },
    statusLegend: {
      width: "20px",
      height: "20px",
      marginRight: s(1),
      borderRadius: "50%",
      [b.down("sm")]: {
        width: "10px",
        height: "10px",
        marginRight: s(0.5),
      },
    },
    statusLegendMCM: {
      background: COLORS.blueNormal,
    },
    statusLegendOther: {
      background: COLORS.blueLight,
      border: `1px solid ${COLORS.blueNormal}`,
    },
    legendTitle: {
      ...typo.caption,
      [b.down("xs")]: {
        fontSize: "10px",
      },
    },
    slotSelectionMessages: {
      marginTop: "auto",
    },
    toggleTargetBtn: {
      marginTop: s(1),
      [b.down("xs")]: {
        fontSize: "12px",
      },
    },
    targetMCMNoSlot: {
      [b.down("sm")]: {
        textAlign: "center",
      },
    },
    targetMCMNoSlotSub: {
      marginTop: s(5),
      paddingTop: s(3),
      borderTop: `1px solid ${p.grey[300]}`,
      color: "rgba(0, 0, 0, 0.54)",
      [b.down("sm")]: {
        textAlign: "center",
      },
    },
    toggleBtnWrapper: {
      [b.down("sm")]: {
        width: "100%",
        textAlign: "center",
      },
    },
    noTargetMCMnoSlotSub: {
      [b.down("sm")]: {
        textAlign: "center",
      },
    },
  })
);

interface SchedulingCalendarProps {
  calendar: McmWithAvailability[];
  info: SchedulingInfo;
  isTargetMCM: boolean;
  toggleIsTargetMCM: (options?: { toShow: boolean }) => any;
  onSlotSelect: (s: DailyAvailableSlot) => any;
  date: Date | null;
  setDate: React.Dispatch<React.SetStateAction<Date | null>>;
  month: number;
  setMonth: React.Dispatch<React.SetStateAction<number>>;
  year: number;
  setYear: React.Dispatch<React.SetStateAction<number>>;
  duration: number;
  bmhTZ: string;
  goToPurposeScreen: () => void;
  canGoToPurposeScreen: boolean;
}

const SchedulingCalendar: React.FC<SchedulingCalendarProps> = ({
  calendar,
  info,
  isTargetMCM,
  toggleIsTargetMCM,
  onSlotSelect,
  date,
  setDate,
  month,
  setMonth,
  year,
  setYear,
  duration,
  bmhTZ,
  canGoToPurposeScreen,
  goToPurposeScreen,
}) => {
  const { isXSDown } = useResponsive();

  const slotsRef = useRef<HTMLDivElement | null>(null);

  const meta = useMemo(() => getCalendarMetaData(calendar, bmhTZ), [
    calendar,
    bmhTZ,
  ]);

  const { monthlySlots } = useMemo(() => {
    return calcSlotsFor1Month({
      year,
      month,
      clientTZ: info.clientTZ || "",
      bmhTZ,
      durationInMinutes: duration,
      meta,
    });
  }, [duration, info.clientTZ, month, year, meta, bmhTZ]);

  const hasCalendar = !!calendar.length;
  useEffect(() => {
    if (hasCalendar) {
      const nextMonthData = calcSlotsForNextAvailableMonth({
        clientTZ: info.clientTZ || "",
        bmhTZ,
        durationInMinutes: duration,
        meta,
      });

      if (nextMonthData.data.slots.length) {
        setDate(nextMonthData.data.slots[0].start);
      }
      setMonth(nextMonthData.minDate.getMonth());
      setYear(nextMonthData.minDate.getFullYear());
    }
  }, [
    info.clientTZ,
    info.bmhTZ,
    duration,
    hasCalendar,
    setMonth,
    setYear,
    meta,
    setDate,
    bmhTZ,
  ]);

  const { mcmSlots, allSlots } = useMemo(() => {
    const slots = date
      ? calcSlotsFor1Day({
          date,
          clientTZ: info.clientTZ as string,
          bmhTZ,
          meta,
          durationInMinutes: duration,
        })
      : [];

    const mcm: DailyAvailableSlot[] = [];

    if (info.mcm) {
      slots.forEach(({ mcms: managers, ...others }) => {
        const targetMCMs: MCMInfo[] = [];
        managers.forEach((m) => {
          if (info.mcm && m.id === info.mcm.id) {
            targetMCMs.push(m);
          }
        });
        if (targetMCMs.length) {
          mcm.push({
            mcms: targetMCMs,
            ...others,
          });
        }
      });
    }

    return {
      mcmSlots: mcm,
      allSlots: slots,
    };
  }, [date, meta, info, duration, bmhTZ]);

  const { am, pm } = useMemo(
    () => splitDailySlots(isTargetMCM ? mcmSlots : allSlots),
    [mcmSlots, allSlots, isTargetMCM]
  );

  const changeMonth = useCallback(
    (d: MaterialUiPickersDate) => {
      if (d) {
        setMonth(d.getMonth());
        setYear(d.getFullYear());
      }
    },
    [setMonth, setYear]
  );

  const changeDate = useCallback(
    (d: MaterialUiPickersDate) => {
      if (d && !isDateInThePast(d)) {
        setDate(d);
        if (info.mcm) toggleIsTargetMCM({ toShow: true });
        setTimeout(() => {
          slotsRef.current?.scrollIntoView({
            behavior: "smooth",
          });
        }, 0);
      }
    },
    [setDate, toggleIsTargetMCM, info]
  );

  const toggleTarget = useCallback(() => {
    toggleIsTargetMCM();
  }, [toggleIsTargetMCM]);

  const classes = useStyle();

  return (
    <Box className={classes.wrapper}>
      <Grid
        container
        alignItems="center"
        justify={isXSDown ? "center" : "flex-start"}
        spacing={2}
      >
        {canGoToPurposeScreen ? (
          <Grid item>
            <IconButton onClick={goToPurposeScreen}>
              <ArrowBack />
            </IconButton>
          </Grid>
        ) : null}
        <Grid item>
          <Typography variant="subtitle2">Select a date and time</Typography>
        </Grid>
      </Grid>
      <Box className={classes.inner}>
        <Box className={classes.left}>
          <Paper elevation={1} className={classes.calendarWrapper}>
            <DatePicker
              disableToolbar
              variant="static"
              openTo="date"
              value={date}
              onChange={changeDate}
              onMonthChange={changeMonth}
              shouldDisableDate={isDateInThePast}
              renderDay={(d, selectedDate, dayInCurrentMonth) => {
                const dateInMonth = d?.getDate() ?? 0;
                const { mappedMCMs } = monthlySlots[dateInMonth - 1] || {
                  slots: [],
                  mappedMCMs: {},
                };

                const isInPast = isDateInThePast(d);
                let className = classes.dateBtn;

                if (!dayInCurrentMonth) {
                  className += ` ${classes.outOfMonthDate}`;
                } else if (isTheSameDate(date, d)) {
                  className += ` ${classes.pickedDate}`;
                } else if (!info.mcm) {
                  if (Object.keys(mappedMCMs).length) {
                    className += ` ${classes.havingMCMDate}`;
                  } else {
                    className += ` ${classes.noDataDate}`;
                  }
                } else if (mappedMCMs[info.mcm.id]) {
                  className += ` ${classes.targetMCMDate}`;
                } else if (Object.keys(mappedMCMs).length) {
                  className += ` ${classes.otherMCMDate}`;
                } else {
                  className += ` ${classes.noDataDate}`;
                }

                return (
                  <div className={classes.dateWrapper}>
                    <IconButton
                      className={className}
                      disabled={!dayInCurrentMonth || isInPast}
                    >
                      <span>{d?.getDate() ?? ""}</span>
                    </IconButton>
                  </div>
                );
              }}
            />
          </Paper>

          {!!info.mcm && (
            <Box className={classes.legends}>
              <Box className={classes.legend}>
                <div
                  className={`${classes.statusLegend} ${classes.statusLegendMCM}`}
                />
                <Typography className={classes.legendTitle}>
                  Your PCM is available
                </Typography>
              </Box>
              <Box className={classes.legend}>
                <div
                  className={`${classes.statusLegend} ${classes.statusLegendOther}`}
                />
                <Typography className={classes.legendTitle}>
                  Your PCM is not available, other PCMs are
                </Typography>
              </Box>
            </Box>
          )}
        </Box>

        <Box className={classes.right}>
          {!!date && (
            <>
              <Typography
                variant="body1"
                color="primary"
                className={classes.dayTitle}
              >
                {formatDayOfWeek(date)}
              </Typography>
              <div className={classes.slots} ref={slotsRef}>
                {allSlots && (
                  <>
                    {[am, pm].map((slots, index) => {
                      return (
                        <Box
                          key={index}
                          className={index ? classes.pmSlots : classes.amSlots}
                        >
                          {slots.map((slot) => (
                            <TimeSlot
                              key={`${slot.id}`}
                              isTargetMCM={isTargetMCM}
                              slot={slot}
                              onSlotSelect={onSlotSelect}
                            />
                          ))}
                        </Box>
                      );
                    })}
                  </>
                )}
              </div>

              {!!info.mcm && (
                <Box
                  className={
                    isTargetMCM && !mcmSlots.length
                      ? ""
                      : classes.slotSelectionMessages
                  }
                >
                  {isTargetMCM && (
                    <>
                      {!!mcmSlots.length && (
                        <Typography variant="caption">
                          Your assigned PCM has this time availability for this
                          day. We highly recommend you to schedule the call with
                          him, as they know your history in detail. However, if
                          it is really important for you to hold the call on
                          this date, you can consult other PCM time availability
                          for this call.
                        </Typography>
                      )}

                      {!mcmSlots.length && (
                        <>
                          <Typography
                            variant={isXSDown ? "body2" : "body1"}
                            className={classes.targetMCMNoSlot}
                          >
                            {allSlots.length
                              ? "Your Personal Case Manager is not"
                              : "No Personal Case Manager is"}{" "}
                            available for this day.
                          </Typography>

                          {!!allSlots.length && (
                            <Typography
                              variant={isXSDown ? "subtitle2" : "body2"}
                              className={classes.targetMCMNoSlotSub}
                            >
                              We highly recommend you to schedule the call with
                              your assigned PCM, as they know your history in
                              detail. However if it is really important for you
                              to hold the call on this date, you can select
                              another PCM for this call.
                            </Typography>
                          )}
                        </>
                      )}
                      {!!allSlots.length && (
                        <>
                          <br />
                          <Box className={classes.toggleBtnWrapper}>
                            <Button
                              variant="outlined"
                              color="primary"
                              className={classes.toggleTargetBtn}
                              onClick={toggleTarget}
                            >
                              CHECK ALL PCMS&apos; TIME AVAILABILITY
                            </Button>
                          </Box>
                        </>
                      )}
                    </>
                  )}

                  {!isTargetMCM && (
                    <Box className={classes.toggleBtnWrapper}>
                      <Typography variant={isXSDown ? "body2" : "body1"}>
                        You are viewing all PCMs&apos; availability
                      </Typography>
                      <Button
                        color="primary"
                        className={classes.toggleTargetBtn}
                        onClick={toggleTarget}
                      >
                        Return to your PCM calendar
                      </Button>
                    </Box>
                  )}
                </Box>
              )}

              {!info.mcm && (
                <>
                  {!allSlots.length && (
                    <>
                      <Typography
                        variant={isXSDown ? "body2" : "body1"}
                        className={classes.noTargetMCMnoSlotSub}
                      >
                        There is no Personal Case Manager available for this
                        day.
                        <br />
                        Please, select another day from the calendar.
                      </Typography>
                    </>
                  )}
                </>
              )}
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default memo(SchedulingCalendar);
