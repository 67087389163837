import React, { useCallback } from "react";
import {
  pkAuthActions,
  pkAuthSelectors,
  UpdateEmailVerification,
  User,
} from "@deep-consulting-solutions/auth-web";
import { useHistory, useLocation } from "react-router-dom";

import { ROUTES } from "configs";
import { notifications } from "services/notifications";
import { useDispatch, useSelector } from "react-redux";
import { activationSelectors } from "redux/activation";
import { AppDispatch } from "redux/store";
import { setAuthorizationHeader } from "apis";

const UpdateEmailVerificationPage = () => {
  const dispatch = useDispatch<AppDispatch>();
  const history = useHistory();
  const location = useLocation();
  const isActivating = useSelector(activationSelectors.getContactID);
  const contactID = useSelector(pkAuthSelectors.authContact)?.id;

  const onNoToken = useCallback(
    (message: string) => {
      notifications.notifyError(message);
      history.push(ROUTES.profile.path);
    },
    [history]
  );

  const onTokenVerified = useCallback((message: string) => {
    notifications.notifySuccess(message);
  }, []);

  const onComplete = useCallback(
    async (data?: { contactID: string; token: string; user: User }) => {
      if (data && contactID !== data.contactID) {
        if (isActivating) {
          setAuthorizationHeader(data.token);
          dispatch(
            pkAuthActions.setAuthorised({ token: data.token, user: data.user })
          );
          history.push(ROUTES.activationWelcome.path, { isActivating: true });
          return;
        }
        await dispatch(pkAuthActions.logout());
        history.push(ROUTES.login.path);
        return;
      }

      history.push(ROUTES.profile.path);
    },
    [contactID, dispatch, history, isActivating]
  );

  return (
    <UpdateEmailVerification
      location={location}
      onNoToken={onNoToken}
      onTokenVerified={onTokenVerified}
      onComplete={onComplete}
    />
  );
};

export default UpdateEmailVerificationPage;
