import React from "react";
import {
  Box,
  Typography,
  makeStyles,
  createStyles,
  Divider,
} from "@material-ui/core";

import { useResponsive } from "hooks";

const useStyles = makeStyles(({ palette: p }) =>
  createStyles({
    title: {
      color: p.primary.light,
    },
    subtitle: {
      color: p.grey[600],
    },
  })
);

interface Props {
  title: string;
  subtitle?: string;
}

export const FormHeader: React.FC<Props> = ({ title, subtitle }) => {
  const { isXSDown } = useResponsive();
  const classes = useStyles();
  return (
    <>
      <div>
        <Typography
          className={classes.title}
          variant={isXSDown ? "subtitle2" : "subtitle1"}
        >
          {title}
        </Typography>
        {subtitle && (
          <Typography variant="caption" className={classes.subtitle}>
            {subtitle}
          </Typography>
        )}
      </div>
      <Box mt={0.5} mb={3}>
        <Divider />
      </Box>
    </>
  );
};
