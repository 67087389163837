import React, { FC } from "react";
import { makeStyles, Box, Typography, Button } from "@material-ui/core";

import { ZohoServices } from "services";

export interface CRMCallResultProps {
  isCancelled: boolean;
}

const useStyles = makeStyles(({ spacing: s }) => ({
  root: {
    textAlign: "center",
  },
  text: {
    fontSize: "1.125rem",
    lineHeight: "28px",
  },
  action: {
    marginTop: s(4),
  },
}));

const CRMCallResult: FC<CRMCallResultProps> = ({ isCancelled }) => {
  const classes = useStyles();

  const handleGotItClick = () => {
    ZohoServices.closePopup(true);
  };

  return (
    <Box className={classes.root}>
      <Typography variant="body1" color="textPrimary" className={classes.text}>
        {(() => {
          if (!isCancelled) {
            return "Something went wrong";
          }

          return (
            <>
              Your PCM consultation has been cancelled. <br />
              An email notification has been sent to the client.
            </>
          );
        })()}
      </Typography>
      <Button
        variant="contained"
        color="primary"
        className={classes.action}
        onClick={handleGotItClick}
      >
        {isCancelled ? "Got it" : "Ok"}
      </Button>
    </Box>
  );
};

export default CRMCallResult;
