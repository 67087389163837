import {
  configureStore,
  combineReducers,
  ThunkDispatch,
  AnyAction,
  getDefaultMiddleware,
  DeepPartial,
} from "@reduxjs/toolkit";
import { createTransform, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import {
  pkAuthReducerObj,
  AppState as PkAuthState,
  pkAuthPersistTransform,
} from "@deep-consulting-solutions/auth-web";

import { getPersistKey } from "helpers";
import {
  pkPortalsPersistTransform,
  pkPortalsReducerObj,
  PortalsAppState,
} from "@deep-consulting-solutions/portals-web";
import activation, { ActivationState } from "./activation";
import user, { UserState } from "./user";
import scheduling, { SchedulingState } from "./scheduling";
import zoho, { ZohoState } from "./zoho";

export interface AppState extends PkAuthState, PortalsAppState {
  activation: ActivationState;
  user: UserState;
  scheduling: SchedulingState;
  zoho: ZohoState;
}

export type AppDispatch = ThunkDispatch<AppState, any, AnyAction>;

export const userTransform = createTransform(
  null,
  (state: UserState) => {
    const newState: UserState = {
      user: null,
      redirectPathname: state.redirectPathname,
    };
    return newState;
  },
  { whitelist: ["user"] }
);

const persistConfigs = {
  key: getPersistKey(),
  storage,
  blacklist: ["scheduling", "zoho"],
  transforms: [
    pkAuthPersistTransform,
    pkPortalsPersistTransform,
    userTransform,
  ],
};

export const createStore = (initialState?: DeepPartial<AppState>) => {
  const reducer = combineReducers({
    ...pkAuthReducerObj,
    ...pkPortalsReducerObj,
    activation,
    user,
    scheduling,
    zoho,
  });

  const persistedReducer = persistReducer(persistConfigs, reducer);

  return configureStore({
    reducer: persistedReducer,
    preloadedState: initialState || {},
    middleware: getDefaultMiddleware({
      serializableCheck: false,
    }),
  });
};

export default createStore();
