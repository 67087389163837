import React, { ReactNode } from "react";
import { Provider } from "react-redux";
import DateFnsUtils from "@date-io/date-fns";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import { ThemeProvider } from "@material-ui/core/styles";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { DeepPartial } from "@reduxjs/toolkit";

import store, { createStore, AppState } from "redux/store";
import theme from "theme";
import { NotificationProvider } from "services";
import { ErrorCatchPage } from "components/ErrorCatchPage";

export interface RootProps {
  initialState?: DeepPartial<AppState>;
  children: ReactNode;
}

const Root = (() => {
  let customizedStore: typeof store;

  return class RootWrapper extends React.PureComponent<RootProps> {
    static getStore() {
      return customizedStore;
    }

    render() {
      const { initialState, children } = this.props;

      customizedStore = initialState ? createStore(initialState) : store;

      const persistor = persistStore(customizedStore);
      persistor.flush();

      return (
        <Provider store={customizedStore}>
          <PersistGate loading={null} persistor={persistor}>
            <NotificationProvider>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <ThemeProvider theme={theme}>
                  <ErrorCatchPage>{children}</ErrorCatchPage>
                </ThemeProvider>
              </MuiPickersUtilsProvider>
            </NotificationProvider>
          </PersistGate>
        </Provider>
      );
    }
  };
})();

export default Root;
