import React, { useCallback, useState } from "react";
import {
  Invoice,
  StatusEnum,
  formatDateToDisplay,
  checkIfConflictWithLocalTime,
} from "@deep-consulting-solutions/invoices-web";
import {
  Box,
  Button,
  Chip,
  createStyles,
  Grid,
  makeStyles,
  Paper,
  Theme,
  Typography,
  IconButton,
  Hidden,
  Tooltip,
} from "@material-ui/core";
import {
  CalendarTodayOutlined,
  ChevronRight,
  Today,
  Warning as WarningIcon,
} from "@material-ui/icons";
import { ReactComponent as CoinsIcon } from "images/coins.svg";
import { MoneyText } from "@deep-consulting-solutions/dcs-web-ui";
import { stringifyItems } from "helpers";
import { useResponsive } from "hooks";
import Loader from "components/Loader";
import { getInvoicePublicURL } from "./requests";

interface StyleProps {
  status: StatusEnum;
}

const useCardStyles = makeStyles(
  ({ spacing: s, palette: p, typography: typo, breakpoints: b, shadows }) => ({
    card: {
      display: "flex",
      textDecoration: "none",
      alignItems: "center",
      color: p.text.primary,
      backgroundColor: p.background.paper,
      "&:hover": {
        cursor: "pointer",
        backgroundColor: p.action.hover,
        textDecoration: "none",
      },
      [b.up("sm")]: {
        width: `calc(50% - ${s(1)}px)`,
        marginTop: s(2),
        outline: 0,
        border: 0,
        boxShadow: shadows[2],
        padding: s(2),
        borderRadius: s(1),

        "&:nth-child(odd)": {
          marginRight: s(2),
        },
      },
      [b.only("xs")]: {
        width: "100%",
        padding: s(2, 0, 2, 2),
        border: 0,
        outline: 0,
        borderBottom: `1px solid ${p.divider}`,
        "&:first-child": {
          borderTop: `1px solid ${p.divider}`,
        },
      },
    },
    infoWrapper: {
      flex: 1,
      paddingRight: s(1),
    },
    dataRow: {
      marginTop: s(1.5),
      display: "flex",
      alignItems: "center",
    },
    items: {
      color: p.primary.light,
    },
    money: {
      marginLeft: s(1),
    },
    status: {
      marginLeft: "auto",
    },
    invoiceDate: {
      display: "flex",
      alignItems: "center",
    },
    invoiceDateIcon: {
      color: p.primary.light,
    },
    dueDate: {
      display: "flex",
      marginLeft: "auto",
      alignItems: "center",
    },
    dueDateIcon: ({ status }: StyleProps) => ({
      color: status === StatusEnum.overdue ? p.error.light : p.warning.light,
    }),
    date: {
      ...typo.body2,
      marginLeft: s(1),
      color: p.text.primary,
    },
    conflictWrapper: {
      display: "flex",
      alignItems: "center",
      marginTop: s(1),
    },
    warning: {
      color: p.warning.light,
      marginRight: s(1),
    },
    chip: {
      backgroundColor: (props: StyleProps) =>
        props.status === StatusEnum.overdue ? p.error.light : p.warning.light,
      color: p.common.white,
    },
  })
);

interface InvoiceCardProps {
  status: StatusEnum.overdue | StatusEnum.unpaid;
  invoice: Invoice;
  timeZone: string;
  handleOpenInvoice: (id: string) => void;
}

export const InvoiceCard: React.FC<InvoiceCardProps> = ({
  status,
  invoice: { name, date, items, total, unit, dueDate, paid, id },
  timeZone,
  handleOpenInvoice,
}) => {
  const classes = useCardStyles({ status });
  const conflict = checkIfConflictWithLocalTime({
    dueDate,
    paid,
    timeZone,
  });

  const handleOpenInvoiceClick = useCallback(() => {
    handleOpenInvoice(id);
  }, [id, handleOpenInvoice]);

  return (
    <button
      onClick={handleOpenInvoiceClick}
      type="button"
      className={classes.card}
      id={`invoice-button-${id}`}
      data-testid="invoice-card"
    >
      <Box className={classes.infoWrapper}>
        <Typography variant="h5" className={classes.items}>
          {stringifyItems(items)}
        </Typography>

        <Box className={classes.dataRow}>
          <Typography variant="body1" color="textSecondary">
            {name}
          </Typography>
          <Box className={classes.money}>
            <MoneyText
              amount={total}
              numberFormatProps={{
                prefix: unit,
              }}
            />
          </Box>
          <Box className={classes.status}>
            <Chip className={classes.chip} label={status} />
          </Box>
        </Box>

        <Box className={classes.dataRow}>
          <Box className={classes.invoiceDate} title="Invoice Date">
            <Today className={classes.invoiceDateIcon} />
            <Typography className={classes.date}>
              {formatDateToDisplay(date)}
            </Typography>
          </Box>

          <Box className={classes.dueDate} title="Due Date">
            <Today className={classes.dueDateIcon} />
            <Typography className={classes.date}>
              {formatDateToDisplay(dueDate)}
            </Typography>
          </Box>
        </Box>

        {!!conflict && (
          <Box className={classes.conflictWrapper}>
            <WarningIcon className={classes.warning} />
            <Typography variant="caption" color="textSecondary">
              {conflict}
            </Typography>
          </Box>
        )}
      </Box>

      <Hidden smUp>
        <Box>
          <IconButton color="primary">
            <ChevronRight />
          </IconButton>
        </Box>
      </Hidden>
    </button>
  );
};

export const usePaperStyles = makeStyles<Theme, { status: StatusEnum }>(
  ({ spacing: s, palette: p, shadows: sh }) => ({
    coinsIcon: {
      fill: p.primary.light,
    },
    paper: {
      width: "100%",
      "&:not(:last-child)": {
        marginBottom: s(2),
      },
    },
    chip: {
      backgroundColor: (props) =>
        props.status === StatusEnum.overdue ? p.error.light : p.warning.light,
      color: p.common.white,
    },
    warning: {
      color: p.warning.light,
      fontSize: "20px",
    },
    tooltipItemsWrapper: {
      backgroundColor: p.common.white,
      color: "rgba(0, 0, 0, 0.87)",
      boxShadow: sh[2],
    },
  })
);

interface InvoicePaperProps {
  invoice: Invoice;
  status: StatusEnum.overdue | StatusEnum.unpaid;
  timeZone: string;
  handleOpenInvoice: (id: string) => void;
}

export const InvoicePaper = ({
  invoice: { name, date, total, unit, dueDate, paid, id },
  status,
  timeZone,
  handleOpenInvoice,
}: InvoicePaperProps) => {
  const classes = usePaperStyles({ status });
  const conflict = checkIfConflictWithLocalTime({
    dueDate,
    paid,
    timeZone,
  });

  const handleOpenInvoiceClick = useCallback(() => {
    handleOpenInvoice(id);
  }, [id, handleOpenInvoice]);

  return (
    <Paper
      className={classes.paper}
      variant="outlined"
      data-testid="invoice-paper"
    >
      <Box p={3}>
        <Grid container spacing={6} alignItems="center">
          <Grid style={{ flex: 1 }} item>
            <Typography variant="subtitle1" component="h3">
              {name}
            </Typography>
          </Grid>

          <Grid item>
            <Chip className={classes.chip} label={status} />
          </Grid>
          <Grid item>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
            >
              <Box
                display="flex"
                alignItems="center"
                color="text.secondary"
                mb={1}
              >
                <CalendarTodayOutlined
                  style={{ marginRight: "4px", fontSize: "16px" }}
                />
                <Typography variant="body2">Invoice Date:</Typography>
              </Box>
              <Typography>{formatDateToDisplay(date)}</Typography>
            </Box>
          </Grid>
          <Grid item>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
            >
              <Box
                display="flex"
                alignItems="center"
                color="text.secondary"
                mb={1}
              >
                <Box
                  className={classes.coinsIcon}
                  display="inline-flex"
                  alignItems="center"
                  height="12px"
                  width="12px"
                  mr={0.5}
                >
                  <CoinsIcon />
                </Box>
                <Typography variant="body2">Total:</Typography>
              </Box>
              <Typography>
                {unit}
                {total}
              </Typography>
            </Box>
          </Grid>
          <Grid item>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
            >
              <Box
                display="flex"
                alignItems="center"
                color="text.secondary"
                mb={1}
              >
                <CalendarTodayOutlined
                  style={{ marginRight: "4px", fontSize: "16px" }}
                />
                <Typography variant="body2">Due Date:</Typography>
              </Box>
              <Tooltip
                classes={{
                  tooltip: classes.tooltipItemsWrapper,
                }}
                title={
                  !conflict ? (
                    ""
                  ) : (
                    <Typography variant="body2" align="center">
                      {conflict}
                    </Typography>
                  )
                }
              >
                <Box display="flex" alignItems="center" whiteSpace="nowrap">
                  <Typography>{formatDateToDisplay(dueDate)}</Typography>
                  {!!conflict && <WarningIcon className={classes.warning} />}
                </Box>
              </Tooltip>
            </Box>
          </Grid>
          <Grid item>
            <Button color="primary" onClick={handleOpenInvoiceClick}>
              Pay Invoice
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
};

const useStyles = makeStyles(({ palette: p, spacing: s, breakpoints: b }) =>
  createStyles({
    wrapper: {
      marginTop: s(2),
      [b.up("md")]: {
        marginTop: s(4),
      },
    },
    title: {
      color: p.primary.light,
      marginBottom: s(2),
      [b.down("xs")]: {
        padding: s(0, 2),
      },
      [b.only("sm")]: {
        marginBottom: 0,
      },
    },
  })
);

interface InvoicesProps {
  overdueInvoices: Invoice[] | null;
  unpaidInvoices: Invoice[] | null;
  timeZone: string;
}

export const Invoices = ({
  overdueInvoices,
  unpaidInvoices,
  timeZone,
}: InvoicesProps) => {
  const { isSMDown } = useResponsive();
  const classes = useStyles();
  const [loading, setLoading] = useState(false);

  const handleOpenInvoice = useCallback(async (id: string) => {
    const newTab = window.open("about:blank", "_blank");

    try {
      setLoading(true);
      const { invoiceURL } = await getInvoicePublicURL(id);
      if (newTab) {
        newTab.location.href = invoiceURL;
      }
    } catch {
      if (newTab) {
        newTab.close();
      }
    } finally {
      setLoading(false);
    }
  }, []);

  return (
    <Box className={classes.wrapper}>
      <Loader open={loading} />
      <Typography className={classes.title} variant="h6" component="h2">
        New Invoices
      </Typography>
      <Box display="flex" flexWrap="wrap">
        {overdueInvoices &&
          overdueInvoices.length > 0 &&
          overdueInvoices.map((invoice) =>
            isSMDown ? (
              <InvoiceCard
                key={invoice.id}
                invoice={invoice}
                timeZone={timeZone}
                status={StatusEnum.overdue}
                handleOpenInvoice={handleOpenInvoice}
              />
            ) : (
              <InvoicePaper
                key={invoice.id}
                invoice={invoice}
                status={StatusEnum.overdue}
                timeZone={timeZone}
                handleOpenInvoice={handleOpenInvoice}
              />
            )
          )}
        {unpaidInvoices &&
          unpaidInvoices.length > 0 &&
          unpaidInvoices.map((invoice) =>
            isSMDown ? (
              <InvoiceCard
                key={invoice.id}
                invoice={invoice}
                timeZone={timeZone}
                status={StatusEnum.unpaid}
                handleOpenInvoice={handleOpenInvoice}
              />
            ) : (
              <InvoicePaper
                key={invoice.id}
                invoice={invoice}
                status={StatusEnum.unpaid}
                timeZone={timeZone}
                handleOpenInvoice={handleOpenInvoice}
              />
            )
          )}
      </Box>
    </Box>
  );
};
