import { TZ } from "@deep-consulting-solutions/bmh-constants";

import { formatTimezone } from "../SchedulingTool.helpers";

export const processTZs = (tzs: TZ[]) => {
  const mappedTZs: { [tz: string]: string } = {};
  const mappedCountries: {
    [country: string]: {
      tz: string;
      label: string;
    }[];
  } = {};

  tzs.forEach(({ country, tz }) => {
    mappedTZs[tz] = country;
    if (!mappedCountries[country]) mappedCountries[country] = [];
    mappedCountries[country].push({
      tz,
      label: formatTimezone(tz),
    });
  });

  Object.keys(mappedCountries).forEach((c) => {
    mappedCountries[c].sort((a, b) => (a.label < b.label ? -1 : 1));
  });

  return {
    mappedTZs,
    mappedCountries,
    countries: Object.keys(mappedCountries).sort(),
  };
};
