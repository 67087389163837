import React, { memo, useCallback, useMemo } from "react";
import {
  Box,
  Typography,
  makeStyles,
  Button,
  Grid,
  IconButton,
  FormControlLabel,
  Radio,
} from "@material-ui/core";
import { useSelector } from "react-redux";
import { pkAuthSelectors, Contact } from "@deep-consulting-solutions/auth-web";
import { Form, Formik, Field, FormikConfig, FormikErrors } from "formik";
import { CheckboxWithLabel, RadioGroup, TextField } from "formik-material-ui";
import {
  MCMCall,
  ContactRes,
  SchedulingInfo,
  ConsultationPurposeEnum,
  crmFacingHave2RecentBloodTestResultsLabel,
  clientFacingHave2RecentBloodTestResultsLabel,
} from "@deep-consulting-solutions/bmh-constants";
import * as Yup from "yup";
import { validatePhoneNumberWithCountryCode } from "@deep-consulting-solutions/dcs-web-ui";

import { getENText } from "helpers";
import DCSPhoneInput from "components/DCSPhoneInput";

import { ArrowBack } from "@material-ui/icons";
import { useSchedulingTokens } from "../SchedulingToolTokenContext";
import { DetailsFormValues, yesOrNoRadio } from "../SchedulingTool.helpers";

const useStyle = makeStyles(
  ({ spacing: s, typography: typo, palette: p, breakpoints: b }) => ({
    wrapper: {
      [b.down("sm")]: {
        paddingTop: s(2),
        borderTop: `1px solid ${p.grey[300]}`,
      },
    },
    infoWrapper: {},
    info: {
      marginTop: s(2),
    },
    infoTitle: {
      ...typo.body2,
    },
    infoData: {
      ...typo.body2,
      color: p.text.secondary,
    },
    form: {
      marginTop: s(2),
    },
    phoneTitle: {
      fontSize: "13px",
    },
    phoneWrapper: {
      display: "flex",
      alignItems: "center",
      marginTop: s(1),
      [b.down("xs")]: {
        flexDirection: "column",
        alignItems: "flex-start",
      },
    },
    isMainPhone: {
      marginLeft: s(2),
    },
    comment: {
      marginTop: s(3),
    },
    actions: {
      marginTop: s(3),
      [b.down("sm")]: {
        marginBottom: s(3),
        display: "flex",
        flexDirection: "row-reverse",
      },
    },
    confirm: {
      marginRight: s(1),

      [b.down("sm")]: {
        marginRight: 0,
        marginLeft: s(1),
      },
    },
  })
);

interface SchedulingDetailsProps {
  onBack: (comment: string) => void;
  submit: (values: DetailsFormValues) => any;
  meeting?: MCMCall;
  contact?: ContactRes;
  comment: string;
  info: SchedulingInfo;
}

const SchedulingDetails: React.FC<SchedulingDetailsProps> = ({
  onBack,
  submit,
  meeting,
  contact,
  comment,
  info,
}) => {
  const tokens = useSchedulingTokens();
  const authContact = useSelector(pkAuthSelectors.authContact);

  const { name } = useMemo(() => {
    let c: Contact | ContactRes | undefined = contact;

    if (!tokens.crmToken && !tokens.emailToken) {
      c = c || authContact;
    }

    return {
      name: c ? `${c.firstName || ""} ${c.lastName || ""}` : "",
    };
  }, [contact, authContact, tokens]);

  const phoneOptions = useMemo(() => {
    const optionsMap: { [key: string]: true } = {};
    if (contact?.mobilePhone) optionsMap[contact.mobilePhone] = true;
    if (meeting?.phone) optionsMap[meeting.phone] = true;
    if (contact?.phone) optionsMap[contact.phone] = true;
    const options = Object.keys(optionsMap);
    return options.length ? options : undefined;
  }, [meeting, contact]);

  const validationSchema = useMemo(() => {
    return Yup.lazy(() => {
      return Yup.object({
        phone: Yup.string()
          .test(
            "check validity",
            getENText("comp.SchedulingTool.SchedulingDetails.phone.required"),
            (value: any) => {
              return validatePhoneNumberWithCountryCode(value);
            }
          )
          .required(),
        ...(info.purpose ===
          ConsultationPurposeEnum.INITIAL_PCM_CONSULTATION && {
          clientHasTwoRecentBloodTestResult: Yup.string().required(
            getENText(
              "comp.SchedulingTool.SchedulingDetails.clientHasTwoRecentBloodTestResult.required"
            )
          ),
        }),
      });
    });
  }, [info.purpose]);

  const getShouldShowSetAsMain = useCallback(
    (values: DetailsFormValues, errors: FormikErrors<DetailsFormValues>) => {
      return (
        contact?.mobilePhone &&
        values.phone &&
        contact.mobilePhone.replace("+", "").replace(/ /g, "") !==
          values.phone.replace("+", "").replace(/ /g, "") &&
        !errors.phone
      );
    },
    [contact?.mobilePhone]
  );

  const onSubmit: FormikConfig<DetailsFormValues>["onSubmit"] = useCallback(
    (values) => {
      submit(values);
    },
    [submit]
  );
  const initialValues = {
    phone: meeting?.phone || contact?.mobilePhone || contact?.phone || "",
    isMainPhone: false,
    comment,
    reschedulingReason: "",
    clientHasTwoRecentBloodTestResult: meeting?.clientHasTwoRecentBloodTest
      ? `${meeting?.clientHasTwoRecentBloodTest}`.toLowerCase()
      : "",
  };

  const classes = useStyle();
  return (
    <Formik<DetailsFormValues>
      initialValues={initialValues}
      initialTouched={{
        phone: true,
      }}
      enableReinitialize
      validateOnMount
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ values, isValid, errors }) => {
        return (
          <Box className={classes.wrapper}>
            <Grid
              container
              alignItems="center"
              justify="flex-start"
              spacing={2}
            >
              <Grid item>
                <IconButton onClick={() => onBack(values.comment)}>
                  <ArrowBack />
                </IconButton>
              </Grid>
              <Grid item>
                <Typography variant="subtitle2">Meeting details</Typography>
              </Grid>
            </Grid>
            <Box className={classes.infoWrapper}>
              <Box className={classes.info}>
                <Typography className={classes.infoTitle}>Name</Typography>
                <Typography className={classes.infoData}>{name}</Typography>
              </Box>
            </Box>
            <Form className={classes.form}>
              <Box>
                <Typography className={classes.phoneTitle}>
                  {tokens.crmToken &&
                    "Select or enter which number to call the client on for the consultation*"}
                  {!tokens.crmToken &&
                    "Select or enter which number to be called on for the consultation*"}
                </Typography>
                <Box className={classes.phoneWrapper}>
                  <Field
                    name="phone"
                    component={DCSPhoneInput}
                    placeholder="Phone"
                    options={phoneOptions}
                    noLabel
                    showCloseButton
                  />
                  {getShouldShowSetAsMain(values, errors) && (
                    <Field
                      type="checkbox"
                      name="isMainPhone"
                      component={CheckboxWithLabel}
                      Label={{
                        label: (
                          <Typography variant="caption">
                            Set as main phone
                          </Typography>
                        ),
                      }}
                      color="primary"
                      className={classes.isMainPhone}
                    />
                  )}
                </Box>
              </Box>
              {info.purpose ===
                ConsultationPurposeEnum.INITIAL_PCM_CONSULTATION && (
                <Box mt={3}>
                  <Typography variant="caption">
                    {tokens.crmToken
                      ? crmFacingHave2RecentBloodTestResultsLabel
                      : clientFacingHave2RecentBloodTestResultsLabel}
                    *
                  </Typography>
                  <Field
                    component={RadioGroup}
                    name="clientHasTwoRecentBloodTestResult"
                    row
                  >
                    {yesOrNoRadio.map((radio) => (
                      <FormControlLabel
                        value={radio.value}
                        control={<Radio color="primary" />}
                        label={radio.label}
                        key={radio.value}
                      />
                    ))}
                  </Field>
                </Box>
              )}
              <Box className={classes.comment}>
                <Typography variant="caption">
                  Please, share any comments you want us to consider for the
                  meeting.
                </Typography>
                <Field
                  component={TextField}
                  name="comment"
                  placeholder="Comments"
                  multiline
                  rows={5}
                />
              </Box>

              {meeting && (
                <Box className={classes.comment}>
                  <Typography variant="caption">
                    Can you tell us what is the reason of rescheduling of the
                    PCM consultation?
                  </Typography>
                  <Field
                    component={TextField}
                    name="reschedulingReason"
                    placeholder="Reason..."
                    multiline
                    rows={5}
                  />
                </Box>
              )}

              <Box className={classes.actions}>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.confirm}
                  type="submit"
                  disabled={!isValid}
                >
                  CONFIRM
                </Button>
              </Box>
            </Form>
          </Box>
        );
      }}
    </Formik>
  );
};

export default memo(SchedulingDetails);
