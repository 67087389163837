import { DEFAULT_ROWS_PER_PAGE_OPTIONS, ROUTES } from "configs";
import { toString } from "lodash";
import { parse, stringify } from "query-string";
import { FetchResultsParams, FetchResultsParamsFieldEnum } from "./types";

export const parseParams = (locationSearch: string) => {
  try {
    const p = parse(locationSearch) as {
      size?: string;
      page?: string;
      sortBy?: string;
      sort?: string;
    };

    return {
      size: Number.isNaN(Number(p.size))
        ? DEFAULT_ROWS_PER_PAGE_OPTIONS[0]
        : Number(p.size),
      page: Number.isNaN(Number(p.page)) ? 1 : Number(p.page),
      sortBy: (p.sortBy ||
        FetchResultsParamsFieldEnum.sampleCollectedOn) as FetchResultsParamsFieldEnum,
      sortASC: p.sort === "asc",
    };
  } catch (error) {
    return {
      size: DEFAULT_ROWS_PER_PAGE_OPTIONS[0],
      page: 1,
      sortBy: FetchResultsParamsFieldEnum.sampleCollectedOn,
      sortASC: false,
    };
  }
};

export const generatePath = ({
  size,
  page,
  sortBy,
  sortASC,
}: {
  size: number;
  page: number;
  sortBy: FetchResultsParamsFieldEnum;
  sortASC: boolean;
}) => {
  const params: { [key: string]: any } = {};

  if (page !== 1) params.page = page;

  if (size !== DEFAULT_ROWS_PER_PAGE_OPTIONS[0]) params.size = size;

  if (sortASC) params.sort = "asc";

  if (sortBy !== "sampleCollectedOn" || sortASC) params.sortBy = sortBy;

  return stringify(params);
};

export const getNext = <T extends unknown>(current: T, next?: T) => {
  return next === undefined ? current : next;
};

export const generateSearchParams = ({
  size,
  page,
  sortBy,
  sortASC,
}: {
  size: number;
  page: number;
  sortBy: FetchResultsParamsFieldEnum;
  sortASC: boolean;
}) => {
  const params: FetchResultsParams = {
    page: toString(page),
    size: toString(size),
    field: sortBy,
    sort: sortASC ? "ASC" : "DESC",
  };

  return params;
};

export const getResultDetailsRoute = (id: string) =>
  ROUTES.bloodTestsDetails.path.replace(":id", id);
