import { createAsyncThunk, createSlice, createAction } from "@reduxjs/toolkit";
import {
  MCMCall,
  GETCancelPageAccessibilityByCancelTokenRes,
  ReschedulingEmailInaccessibleReasonEnum,
  GetMCMCallDetailsByMeetingZohoIdRes,
  SchedulingInfo,
  GetUpcomingMCMCallRes,
  MCMInfo,
} from "@deep-consulting-solutions/bmh-constants";
import { pkAuthActions } from "@deep-consulting-solutions/auth-web";

import type { AppState } from "redux/store";
import { updateTimeZone } from "redux/user/common";
import { composeMCMCallFromCrmMeetingAndSchedulingInfo } from "components/SchedulingTool/SchedulingTool.helpers";

import {
  fetchUpcomingMCMReq,
  getCancelPageAccessibilityByCancelTokenReq,
  getMCMCallDetailsCRMReq,
  cancelMCMCallCRMReq,
  cancelMCMCallPortalReq,
  cancelMCMCallByCancelTokenReq,
  fetchContactReq,
} from "./requests";
import { ENTITY, createMCM, rescheduleMCM } from "./common";

export interface SchedulingState {
  upcomingMCM: MCMCall | null;
  schedulingInfo?: SchedulingInfo;
  reason?: ReschedulingEmailInaccessibleReasonEnum;
  accessibility?: boolean;
  assignedMCM?: MCMInfo | null;
  timezone?: string;
}

const initialState: SchedulingState = {
  upcomingMCM: null,
  schedulingInfo: undefined,
  reason: undefined,
  accessibility: undefined,
  assignedMCM: undefined,
  timezone: undefined,
};

const fetchUpcomingMCM = createAsyncThunk<GetUpcomingMCMCallRes["data"], void>(
  `${ENTITY}/fetchUpcomingMCM`,
  async () => {
    const data = await fetchUpcomingMCMReq();
    return data;
  }
);

const fetchAssignedMCMInfoAndTimezone = createAsyncThunk<
  Pick<SchedulingState, "assignedMCM" | "timezone">,
  void
>(`${ENTITY}/fetchAssignedMCMInfoAndTimezone`, async () => {
  const {
    contact: { mcm, timezone },
  } = await fetchContactReq();
  return { assignedMCM: mcm, timezone };
});

const getCancelPageAccessibilityByCancelToken = createAsyncThunk<
  GETCancelPageAccessibilityByCancelTokenRes,
  { token: string }
>(`${ENTITY}/getCancelPageAccessibilityByCancelToken`, async ({ token }) => {
  const callDetails = await getCancelPageAccessibilityByCancelTokenReq(token);
  return callDetails;
});

const cancelMcmCallByCancelToken = createAsyncThunk<
  void,
  { reason: string; token: string }
>(`${ENTITY}/cancelMcmCallByCancelToken`, async ({ reason, token }) => {
  await cancelMCMCallByCancelTokenReq(reason, token);
});

const getMCMCallDetailsCRM = createAsyncThunk<
  GetMCMCallDetailsByMeetingZohoIdRes,
  { meetingZohoId: string }
>(`${ENTITY}/getMCMCallDetailsCRM`, async ({ meetingZohoId }) => {
  const callDetails = await getMCMCallDetailsCRMReq(meetingZohoId);
  return callDetails;
});

const cancelMcmCallCRM = createAsyncThunk<
  void,
  { mcmCallId: string; reason: string }
>(`${ENTITY}/cancelMcmCallCRM`, async ({ mcmCallId, reason }) => {
  await cancelMCMCallCRMReq(mcmCallId, reason);
});

const cancelMcmCallPortal = createAsyncThunk<
  void,
  { mcmCallId: string; reason: string }
>(`${ENTITY}/cancelMcmCallPortal`, async ({ mcmCallId, reason }) => {
  await cancelMCMCallPortalReq(mcmCallId, reason);
});

const removeScheduledMcmCall = createAction(`${ENTITY}/removeScheduledMcmCall`);

const slice = createSlice({
  name: ENTITY,
  initialState,
  reducers: {},
  extraReducers: (builder) =>
    builder
      .addCase(fetchUpcomingMCM.fulfilled, (state, action) => {
        state.upcomingMCM = action.payload.call;
        state.schedulingInfo = action.payload.schedulingInfo;
      })
      .addCase(
        getCancelPageAccessibilityByCancelToken.fulfilled,
        (state, action) => {
          const { crmMeeting, schedulingInfo } = action.payload.data;
          state.upcomingMCM = composeMCMCallFromCrmMeetingAndSchedulingInfo({
            meeting: crmMeeting,
            info: schedulingInfo,
          });
          state.reason = action.payload.data.reason;
          state.accessibility = action.payload.data.accessibility;
        }
      )
      .addCase(getMCMCallDetailsCRM.fulfilled, (state, action) => {
        state.upcomingMCM = {
          ...action.payload.data.meeting,
          client: {
            ...(action.payload.data.client || {}),
            firstName: action.payload.data.client?.firstName ?? "",
          },
        };
      })
      .addCase(createMCM.fulfilled, (state, action) => {
        const { tokens } = action.meta.arg;
        if (!tokens.crmToken && !tokens.emailToken) {
          state.upcomingMCM = action.payload;
        }
      })
      .addCase(removeScheduledMcmCall, (state) => {
        state.upcomingMCM = null;
        state.reason = undefined;
        state.accessibility = undefined;
      })
      .addCase(rescheduleMCM.fulfilled, (state, action) => {
        state.upcomingMCM = action.payload;
        if (action.meta.arg.data.timezone && state.schedulingInfo) {
          state.schedulingInfo.clientTZ = action.meta.arg.data.timezone;
        }
      })
      .addCase(pkAuthActions.logout.fulfilled, () => {
        return initialState;
      })
      .addCase(updateTimeZone.fulfilled, (state, action) => {
        if (state.schedulingInfo) {
          state.schedulingInfo.clientTZ = action.meta.arg.tz;
        }
      })
      .addCase(fetchAssignedMCMInfoAndTimezone.fulfilled, (state, action) => {
        state.assignedMCM = action.payload.assignedMCM;
        state.timezone = action.payload.timezone;
      }),
});

const getUpcomingMCM = (state: AppState) => state.scheduling.upcomingMCM;
const getSchedulingInfo = (state: AppState) => state.scheduling.schedulingInfo;
const getReason = (state: AppState) => state.scheduling.reason;
const getAccessibility = (state: AppState) => state.scheduling.accessibility;
const getAssignedMcmInfo = (state: AppState) =>
  state.scheduling.assignedMCM || undefined;
const getTimezone = (state: AppState) => state.scheduling.timezone;

export const schedulingSelectors = {
  getUpcomingMCM,
  getReason,
  getAccessibility,
  getSchedulingInfo,
  getAssignedMcmInfo,
  getTimezone,
};

export const schedulingActions = {
  cancelMcmCallPortal,
  cancelMcmCallCRM,
  cancelMcmCallByCancelToken,
  removeScheduledMcmCall,
  fetchUpcomingMCM,
  getCancelPageAccessibilityByCancelToken,
  getMCMCallDetailsCRM,
  createMCM,
  rescheduleMCM,
  fetchAssignedMCMInfoAndTimezone,
};

export default slice.reducer;
