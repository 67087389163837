import clsx from "clsx";
import React, { FC, useCallback } from "react";
import {
  makeStyles,
  Typography,
  Grid,
  InputLabel,
  Button,
} from "@material-ui/core";
import { Formik, Field, Form, FormikConfig } from "formik";
import { TextField } from "formik-material-ui";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { parse } from "query-string";

import { useResponsive } from "hooks";
import { VALIDATIONS } from "helpers";
import { AppDispatch } from "redux/store";
import { schedulingActions } from "redux/scheduling";
import Loader from "components/Loader";

import { CallInfo } from "./helpers";
import CallDetails from "./CallDetails";

export interface CancelMcmCallFormProps {
  callInfo: CallInfo;
  cancelCall: () => void;
  showResultView: () => void;
  isEmail?: boolean;
  host: "crm" | "client";
}

interface FormValues {
  reason: string;
}

const useStyles = makeStyles(({ spacing: s }) => ({
  titleSM: {
    marginRight: s(4),
  },
  title: {
    fontSize: "1.5rem",
    letterSpacing: "0.15px",
  },
  callInfoSM: {
    margin: s(5, 0),
  },
  callInfo: {
    margin: s(2, 0),
  },
  form: {
    position: "relative",
  },
  label: {
    fontSize: "0.875rem",
  },
  labelText: {
    display: "inline",
  },
  action: {
    marginRight: s(1),
    marginBottom: s(1),
    "&:last-child": {
      marginRight: 0,
    },
  },
}));

const validationSchema = Yup.object({
  reason: VALIDATIONS.reason,
});

const CancelMcmCallForm: FC<CancelMcmCallFormProps> = ({
  callInfo,
  cancelCall,
  showResultView,
  isEmail,
  host,
}) => {
  const { isSMDown } = useResponsive();
  const classes = useStyles();
  const dispatch = useDispatch<AppDispatch>();
  const location = useLocation();

  const onSubmit: FormikConfig<FormValues>["onSubmit"] = useCallback(
    async (values, helpers) => {
      try {
        helpers.setSubmitting(true);

        if (isEmail) {
          const { token } = parse(location.search);

          const res = await dispatch(
            schedulingActions.cancelMcmCallByCancelToken({
              reason: values.reason,
              token: typeof token === "string" ? token : "",
            })
          );

          if (
            schedulingActions.cancelMcmCallByCancelToken.fulfilled.match(res)
          ) {
            cancelCall();
            showResultView();
          }
        } else {
          const res = await dispatch(
            schedulingActions.cancelMcmCallPortal({
              mcmCallId: callInfo.id,
              reason: values.reason,
            })
          );

          if (schedulingActions.cancelMcmCallPortal.fulfilled.match(res)) {
            cancelCall();
            showResultView();
          }
        }

        helpers.setSubmitting(false);
      } catch (err) {
        helpers.setSubmitting(false);
      }
    },
    [dispatch, isEmail, callInfo, cancelCall, showResultView, location.search]
  );

  const handleDontCancelClick = useCallback(() => {
    showResultView();
  }, [showResultView]);

  return (
    <>
      <Typography
        variant="h4"
        color="primary"
        className={clsx(classes.title, isSMDown && classes.titleSM)}
      >
        Are you sure you want to cancel your scheduled PCM consultation?
      </Typography>
      <CallDetails
        {...callInfo}
        className={isSMDown ? classes.callInfoSM : classes.callInfo}
        host={host}
      />
      <Formik
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        initialValues={{
          reason: "",
        }}
      >
        {({ isSubmitting }) => (
          <Grid container spacing={3} alignItems="center">
            <Grid item xs={12} sm={8}>
              <Form className={classes.form}>
                {isSubmitting && <Loader open={isSubmitting} absolute />}
                <Grid container spacing={3} alignItems="center">
                  <Grid item xs={12}>
                    <InputLabel htmlFor="reason" className={classes.label}>
                      <Typography
                        color="textPrimary"
                        variant="body1"
                        className={classes.labelText}
                      >
                        {
                          "Can you tell us what the reason is for cancelling the PCM consultation? "
                        }
                      </Typography>
                      <Typography
                        color="secondary"
                        variant="body1"
                        className={classes.labelText}
                      >
                        *
                      </Typography>
                    </InputLabel>
                    <Field
                      fullWidth
                      component={TextField}
                      id="reason"
                      name="reason"
                      placeholder="Reason"
                      disabled={isSubmitting}
                      variant="outlined"
                      multiline
                      rows={3}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      type="submit"
                      disabled={isSubmitting}
                      color="primary"
                      className={classes.action}
                    >
                      Cancel PCM Consultation
                    </Button>
                    <Button
                      variant="outlined"
                      color="primary"
                      disabled={isSubmitting}
                      className={classes.action}
                      onClick={handleDontCancelClick}
                    >
                      Don&apos;t Cancel
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            </Grid>
          </Grid>
        )}
      </Formik>
    </>
  );
};

export default CancelMcmCallForm;
