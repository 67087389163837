/* eslint-disable @typescript-eslint/no-loop-func */
import {
  format,
  startOfDay,
  endOfMonth,
  addDays,
  addMonths,
  startOfMonth,
} from "date-fns";
import moment from "moment-timezone";
import ct from "countries-and-timezones";
import {
  MeetingDurationEnum,
  McmWithAvailability,
  MCMInfo,
  SchedulingInfo,
  Interval,
  MCMCall,
  CrmMeeting,
  ContactRes,
  ConsultationPurposeEnum,
  clientFacingConsultationPurpose,
  crmFacingConsultationPurpose,
} from "@deep-consulting-solutions/bmh-constants";

import {
  SCHEDULING_INCREMENT_IN_MS,
  MAX_NUMBER_OF_AVATARS,
  MIN_SLOT_TIME_FROM_NOW,
  MAX_SLOT_TIME_FROM_NOW,
} from "configs";
import { CreateMCMData, RescheduleMCMData } from "redux/scheduling/requests";
import { getTimezoneOffsetInMs, getAllTimezones } from "helpers";

export enum SchedulingStepEnum {
  pickDate = "pick date",
  pickMCM = "pick PCM",
  details = "details",
  purposeOfConsultation = "purpose of consultation",
}

export interface DetailsFormValues {
  phone: string;
  isMainPhone: boolean;
  comment: string;
  reschedulingReason: string;
  clientHasTwoRecentBloodTestResult?: string | boolean;
}

export const yesOrNoRadio = [
  { label: "Yes", value: "yes" },
  { label: "No", value: "no" },
];

export enum OtherReasonEnum {
  noToken = "noToken",
  others = "others",
}

type NumberInterval = [number, number];
export interface MappedBlockeds {
  [mcmID: string]: NumberInterval[];
}
export interface MappedSpecials {
  [mcmID: string]: NumberInterval[];
}

interface CalendarMetaData {
  blocks: MappedBlockeds;
  specials: MappedSpecials;
  weekly: { [mcmID: string]: Interval[][] };
  mappedMCMs: { [mcmID: string]: MCMInfo };
}

export interface SingleAvailableSlot {
  id: string;
  start: Date;
  end: Date;
  mcm: MCMInfo;
}

export interface TimeSlot {
  id: string;
  start: Date;
  startClientOffset: number;
  startBMHTime: number;
  end: Date;
  endClientOffset: number;
  endBMHTime: number;
}

export interface DailyAvailableSlot extends TimeSlot {
  mcms: MCMInfo[];
}

export interface MappedMCMInfos {
  [id: string]: MCMInfo;
}

export const numberTo2DigitString = (num: number) => {
  return num < 10 ? `0${num}` : `${num}`;
};

export const formatSlotTime = (d: Date, withAM?: boolean) => {
  return format(d, `hh:mm${withAM ? " aa" : ""}`);
};

export const formatDateTimeToSend = (d: Date, offset: number) => {
  const utc = d.getTime() - offset;
  return format(new Date(utc), "yyyy-MM-dd'T'HH:mm:ss");
};

export const composeDataToSend = (
  {
    info,
    isTargetMCM,
    selectedOtherMCM,
    selectedSlot,
    details,
    originalTimezone,
  }: {
    info: SchedulingInfo;
    isTargetMCM: boolean;
    selectedSlot: Omit<DailyAvailableSlot, "mcms">;
    selectedOtherMCM: MCMInfo | null;
    details: DetailsFormValues;
    originalTimezone?: string | null;
  },
  meeting?: MCMCall,
  contact?: ContactRes
) => {
  const isTimezoneChanged = (originalTimezone || "") !== (info.clientTZ || "");

  const data: CreateMCMData = {
    mcm: isTargetMCM && info.mcm ? info.mcm : (selectedOtherMCM as MCMInfo),
    info,
    start: formatDateTimeToSend(
      selectedSlot.start,
      selectedSlot.startClientOffset
    ),
    end: formatDateTimeToSend(selectedSlot.end, selectedSlot.endClientOffset),
    comment: details.comment || undefined,
    phone: details.phone,
    setAsMainPhone:
      details.phone && contact?.mobilePhone ? details.isMainPhone : false,
    timezone: isTimezoneChanged ? info.clientTZ || "" : undefined,
    clientHasTwoRecentBloodTestResult: details.clientHasTwoRecentBloodTestResult
      ? `${details.clientHasTwoRecentBloodTestResult}`.toLowerCase() === "yes"
      : undefined,
  };

  if (!meeting) return data;

  const rescheduleData: RescheduleMCMData = {
    ...data,
    reason: details.reschedulingReason || undefined,
  };

  return rescheduleData;
};

export const formatDateInFull = (d: Date) => {
  return format(d, "EEEE, MMMM do, yyyy");
};

export const randomlyPickMCMs = (mcms: McmWithAvailability[]) => {
  const withAvatar = mcms.filter((mcm) => !!mcm.avatar);

  if (withAvatar.length <= MAX_NUMBER_OF_AVATARS) return withAvatar;

  const random = Array(withAvatar.length)
    .fill(null)
    .map((_, index) => {
      return {
        index,
        order: Math.random(),
      };
    });

  random.sort((a, b) => (a.order > b.order ? -1 : 1));

  return random
    .slice(0, MAX_NUMBER_OF_AVATARS)
    .map(({ index }) => withAvatar[index]);
};

export const transformEndOfDate = (hour: number, min: number) => {
  if (hour === 23 && min === 59) return [24, 0];
  return [hour, min];
};

export const sortAndJoinOverlappingIntervals = (
  intervals: NumberInterval[]
): NumberInterval[] => {
  const sorted = [...intervals].sort((a, b) => {
    if (a[0] === b[0]) return a[1] < b[1] ? -1 : 1;
    return a[0] < b[1] ? -1 : 1;
  });

  const joined: NumberInterval[] = [];
  let current: NumberInterval | null = null;
  sorted.forEach(([start, end]) => {
    if (!current) {
      current = [start, end];
    } else if (current[0] <= end && start <= current[1]) {
      current = [Math.min(current[0], start), Math.max(current[1], end)];
    } else {
      joined.push(current);
      current = [start, end];
    }
  });

  if (current) joined.push(current);
  return joined;
};

export const getCalendarMetaData = (
  calendar: McmWithAvailability[],
  bmhTZ: string
): CalendarMetaData => {
  const localDate = new Date();
  const localOffset = localDate.getTimezoneOffset() * -1 * 60 * 1000;
  const bmhOffset = getTimezoneOffsetInMs(bmhTZ);
  const offset = bmhOffset - localOffset;

  const blocks: MappedBlockeds = {};
  const specials: MappedSpecials = {};
  const weekly: CalendarMetaData["weekly"] = {};
  const mappedMCMs: CalendarMetaData["mappedMCMs"] = {};

  calendar.forEach(
    ({ meetings, availability: { days }, specialAvailabilities, ...mcm }) => {
      mappedMCMs[mcm.id] = mcm;
      blocks[mcm.id] = meetings
        .map(({ startTime, endTime }) => {
          // convert UTC time into BMH time in local timezone;
          const start = new Date(`${startTime}`);
          const end = new Date(`${endTime}`);
          return [start.getTime(), end.getTime()].map(
            (num) => num + offset
          ) as [number, number];
        })
        .sort((a, b) => (a[0] < b[0] ? -1 : 1));

      specials[mcm.id] = [];
      specialAvailabilities.forEach(({ date, intervals }) => {
        intervals.forEach(({ startHour, startMin, endHour, endMin }) => {
          const s = new Date(
            `${date}T${numberTo2DigitString(startHour)}:${numberTo2DigitString(
              startMin
            )}:00`
          );
          const [h, m] = transformEndOfDate(endHour, endMin);
          const e = new Date(
            `${date}T${numberTo2DigitString(h)}:${numberTo2DigitString(m)}:00`
          );
          specials[mcm.id].push([s.getTime(), e.getTime()]);
        });
      });
      specials[mcm.id] = sortAndJoinOverlappingIntervals(specials[mcm.id]);

      const mcmWeek: Interval[][] = Array(7)
        .fill(null)
        .map(() => []);

      days.forEach(({ dayOfWeek, intervals }) => {
        mcmWeek[dayOfWeek] = intervals;
      }, []);

      weekly[mcm.id] = mcmWeek;
    }
  );
  return {
    blocks,
    specials,
    weekly,
    mappedMCMs,
  };
};

const getOffsetForClientTZFn = (bmhTZ: string, clientTZ: string) => {
  const bmhData = ct.getTimezone(bmhTZ);
  const clientData = ct.getTimezone(clientTZ);

  return (timeInNumber: number) => {
    if (!clientData || !bmhData)
      return {
        bmhOffset: 0,
        clientOffset: 0,
        offset: 0,
      };
    const utc = timeInNumber - new Date().getTimezoneOffset() * 60 * 1000;
    const dateInBMH = moment.tz(utc, bmhTZ);
    const dateInClient = moment.tz(utc, clientTZ);
    const bmhOffset =
      (dateInBMH.isDST() ? bmhData.dstOffset : bmhData.utcOffset) * 60 * 1000;
    const clientOffset =
      (dateInClient.isDST() ? clientData.dstOffset : clientData.utcOffset) *
      60 *
      1000;

    return {
      bmhOffset,
      clientOffset,
      offset: bmhOffset - clientOffset,
    };
  };
};

const filterSlotsByBlockedMeetings = (
  slots: [number, number][],
  blocks: [number, number][]
) => {
  let currentBlockIndex = 0;
  let currentBlock = blocks[currentBlockIndex];

  const filtered: [number, number][] = [];
  for (let index = 0, length = slots.length; index < length; index += 1) {
    const slot = slots[index];

    while (currentBlock && currentBlock[1] <= slot[0]) {
      currentBlockIndex += 1;
      currentBlock = blocks[currentBlockIndex];
    }

    if (!currentBlock || slot[1] <= currentBlock[0]) {
      filtered.push(slot);
    }
  }

  return filtered;
};

const convertTimeSpan = (
  from: Date,
  to: Date,
  clientTZ: string,
  bmhTZ: string
) => {
  const clientTZOffset = getTimezoneOffsetInMs(clientTZ);
  const bmhTZOffset = getTimezoneOffsetInMs(bmhTZ);
  const offset = bmhTZOffset - clientTZOffset;

  const fromTime = from.getTime();
  const toTime = to.getTime();

  const now = new Date();
  const nowInClientTZ =
    now.getTime() + now.getTimezoneOffset() * 60 * 1000 + clientTZOffset;

  const minTime = nowInClientTZ + MIN_SLOT_TIME_FROM_NOW;
  const maxTime = nowInClientTZ + MAX_SLOT_TIME_FROM_NOW;

  const fromBMHHard = Math.max(fromTime, minTime) + offset;
  const fromBMH = fromBMHHard - 24 * 60 * 60 * 1000;
  const toBMHHard = Math.min(toTime, maxTime) + offset;
  const toBMH = toBMHHard + 24 * 60 * 60 * 1000;

  return {
    fromBMHHard,
    fromBMH,
    toBMHHard,
    toBMH,
    clientTZOffset,
    bmhTZOffset,
    offset,
  };
};

export const calcMCMSlots = ({
  from,
  to,
  clientTZ,
  bmhTZ,
  durationInMS,
  meta: { blocks, weekly, mappedMCMs, specials },
}: {
  from: Date; // Client time
  to: Date; // Client time
  clientTZ: string;
  bmhTZ: string;
  durationInMS: number;
  meta: CalendarMetaData;
}) => {
  // convert Client time (in local timezone) into BMH time (in local timezone)
  const { fromBMHHard, fromBMH, toBMHHard, toBMH } = convertTimeSpan(
    from,
    to,
    clientTZ,
    bmhTZ
  );
  const getOffset = getOffsetForClientTZFn(bmhTZ, clientTZ);

  if (toBMHHard <= fromBMHHard) return [];

  // get all PCM availabilities for the time span
  const availabilities: {
    [mcmID: string]: {
      mcm: MCMInfo;
      intervals: {
        from: number;
        to: number;
      }[];
    };
  } = {};

  Object.values(mappedMCMs).forEach((mcm) => {
    const week = weekly[mcm.id];

    const intervals = specials[mcm.id].filter(
      ([s, e]) => e >= fromBMH && s <= toBMH
    );
    let start = fromBMH;

    while (start < toBMH) {
      const startDate = new Date(start);
      const end = addDays(startOfDay(startDate), 1).getTime();

      const dayOfWeek = startDate.getDay();
      const year = startDate.getFullYear();
      const month = startDate.getMonth();
      const dateOfMonth = startDate.getDate();
      const weeklyIntervals = week[dayOfWeek];

      if (!availabilities[mcm.id]) {
        availabilities[mcm.id] = {
          mcm,
          intervals: [],
        };
      }

      weeklyIntervals.forEach(({ startHour, startMin, endHour, endMin }) => {
        const f = new Date(
          year,
          month,
          dateOfMonth,
          startHour,
          startMin
        ).getTime();
        const t = new Date(
          year,
          month,
          dateOfMonth,
          ...transformEndOfDate(endHour, endMin)
        ).getTime();
        if (f < start || t > end) return;
        intervals.push([f, t]);
      });

      // temp solution for timezone shift, to avoid infinite loop
      if (start >= end) break;

      start = end;
    }

    availabilities[mcm.id].intervals.push(
      ...sortAndJoinOverlappingIntervals(intervals).map(([s, e]) => ({
        from: s,
        to: e,
      }))
    );
  });

  // generate slots, and filter by blocked meetings, grouped by time key
  const mappedSlots: { [slotTimeKey: string]: MCMInfo[] } = {};

  Object.entries(availabilities).forEach(([mcmID, { intervals }]) => {
    const rawSlots: [number, number][] = [];
    intervals.forEach(({ from: intervalFrom, to: intervalTo }) => {
      const min = Math.max(intervalFrom, fromBMHHard);
      const max = Math.min(intervalTo, toBMHHard);

      let s = intervalFrom;
      while (s < min) {
        s += SCHEDULING_INCREMENT_IN_MS;
      }

      let e = s + durationInMS;
      while (s < max && e <= intervalTo) {
        rawSlots.push([s, e]);

        s += SCHEDULING_INCREMENT_IN_MS;
        e += SCHEDULING_INCREMENT_IN_MS;
      }
    });

    filterSlotsByBlockedMeetings(rawSlots, blocks[mcmID]).forEach(([s, e]) => {
      const key = `${s}-${e}`;
      if (!mappedSlots[key]) mappedSlots[key] = [];
      mappedSlots[key].push(mappedMCMs[mcmID]);
    });
  });

  // generate slot data + sort slots
  const slots: DailyAvailableSlot[] = [];

  Object.entries(mappedSlots).forEach(([key, mcmSlots]) => {
    const [s, e] = key.split("-").map((str) => Number(str)) as [number, number];
    const { offset: sOffset, clientOffset: sClientOffset } = getOffset(s);
    const { offset: eOffset, clientOffset: eClientOffset } = getOffset(e);

    // Display with difference in the BMH / Client timezones
    slots.push({
      id: key,
      start: new Date(s - sOffset),
      startClientOffset: sClientOffset,
      startBMHTime: s,
      end: new Date(e - eOffset),
      endClientOffset: eClientOffset,
      endBMHTime: e,
      mcms: mcmSlots,
    });
  });

  slots.sort((a, b) => {
    return a.id < b.id ? -1 : 1;
  });

  return slots;
};

export const calcSlotsFor1Day = ({
  date,
  clientTZ,
  bmhTZ,
  durationInMinutes,
  meta,
}: {
  date: Date;
  clientTZ: string;
  bmhTZ: string;
  durationInMinutes: number;
  meta: CalendarMetaData;
}) => {
  const from = startOfDay(date);
  const to = startOfDay(addDays(date, 1));
  return calcMCMSlots({
    from,
    to,
    clientTZ,
    bmhTZ,
    durationInMS: durationInMinutes * 60 * 1000,
    meta,
  });
};

export const calcSlotsFor1Month = ({
  month,
  year,
  clientTZ,
  bmhTZ,
  durationInMinutes,
  meta,
}: {
  month: number;
  year: number;
  clientTZ: string;
  bmhTZ: string;
  durationInMinutes: number;
  meta: CalendarMetaData;
}) => {
  const from = new Date(year, month, 1, 0, 0, 0, 0);
  const to = addMonths(new Date(year, month, 1, 0, 0, 0, 0), 1);

  const slots = calcMCMSlots({
    from,
    to,
    clientTZ,
    bmhTZ,
    durationInMS: durationInMinutes * 60 * 1000,
    meta,
  });

  const endOfMonthDay = endOfMonth(from).getDate();
  const monthlySlots: {
    slots: DailyAvailableSlot[];
    mappedMCMs: MappedMCMInfos;
  }[] = Array(endOfMonthDay)
    .fill(null)
    .map(() => ({
      slots: [],
      mappedMCMs: {},
    }));

  slots.forEach((slot) => {
    const dateInMonth = new Date(slot.start).getDate();
    const monthData = monthlySlots[dateInMonth - 1];
    monthData.slots.push(slot);
    slot.mcms.forEach((mcm) => {
      monthData.mappedMCMs[mcm.id] = mcm;
    });
  });

  return {
    slots,
    monthlySlots,
  };
};

export const calcSlotsForNextAvailableMonth = ({
  clientTZ,
  bmhTZ,
  durationInMinutes,
  meta,
}: {
  clientTZ: string;
  bmhTZ: string;
  durationInMinutes: number;
  meta: CalendarMetaData;
}) => {
  const thisMonth = startOfMonth(new Date());
  let thisMonthData: {
    slots: DailyAvailableSlot[];
    monthlySlots: {
      slots: DailyAvailableSlot[];
      mappedMCMs: MappedMCMInfos;
    }[];
  } | null = null;
  for (let monthsAdded = 0; monthsAdded < 12; monthsAdded += 1) {
    const nextMonth = addMonths(thisMonth, monthsAdded);
    const data = calcSlotsFor1Month({
      month: nextMonth.getMonth(),
      year: nextMonth.getFullYear(),
      clientTZ,
      bmhTZ,
      durationInMinutes,
      meta,
    });
    if (!thisMonthData) thisMonthData = data;
    if (data.slots.length) {
      return {
        data,
        minDate: nextMonth,
      };
    }
  }
  return {
    minDate: thisMonth,
    data: thisMonthData!,
  };
};

export const findDaysDiff = (start: Date, end: Date) => {
  const sDate = start.getDate();
  const eDate = end.getDate();
  const diff = eDate - sDate;
  if (diff > 0) return ` (+${diff})`;
  return "";
};

const formatNumberTo2Digits = (number: number) => {
  return `${number < 10 ? "0" : ""}${number}`;
};

export const formatTimezone = (tz: string) => {
  const offset = getTimezoneOffsetInMs(tz);
  const isNegative = offset < 0;

  const totalMinutes = Math.abs(offset / (60 * 1000));
  const minutes = totalMinutes % 60;
  const hours = (totalMinutes - minutes) / 60;
  return `${tz} (GMT${isNegative ? "-" : "+"}${formatNumberTo2Digits(
    hours
  )}:${formatNumberTo2Digits(minutes)})`;
};

export const getCRMMeetingUrl = (meetingID: string | number) => {
  return `${process.env.REACT_APP_CRM_BASE_URL}/${process.env.REACT_APP_MEETING_TAB}/${meetingID}?sub_module=Events`;
};

export const calcDurationInMinutes = (
  start: string | Date,
  end: string | Date
) => {
  const sTime = (typeof start === "string" ? new Date(start) : start).getTime();
  const eTime = (typeof end === "string" ? new Date(end) : end).getTime();
  const duration = eTime - sTime;
  return Math.floor(duration / (60 * 1000));
};

export const reverseDurationKeyMap = {
  15: MeetingDurationEnum.FIFTEEN_MIN,
  30: MeetingDurationEnum.THIRTY_MIN,
  60: MeetingDurationEnum.SIXTY_MIN,
};

export const calcDurationString = (start: string, end: string) => {
  const min = calcDurationInMinutes(start, end);

  return reverseDurationKeyMap[min as 15 | 30 | 60];
};
export const durationKeyMap = {
  [MeetingDurationEnum.FIFTEEN_MIN]: 15,
  [MeetingDurationEnum.THIRTY_MIN]: 30,
  [MeetingDurationEnum.SIXTY_MIN]: 60,
};

export const convertDuration = (
  duration: MeetingDurationEnum,
  toMs?: boolean
) => {
  const min = durationKeyMap[duration];
  return toMs ? min * 60 * 1000 : min;
};

export const composeMCMCallFromCrmMeetingAndSchedulingInfo = ({
  meeting,
  info,
}: {
  meeting?: CrmMeeting & { purpose?: ConsultationPurposeEnum };
  info?: SchedulingInfo;
}): MCMCall | null => {
  if (!meeting || !info || !info.clientTZ || !info.bmhTZ) return null;
  return {
    id: meeting.id,
    mcm: meeting.mcm,
    phone: meeting.clientPhone,
    start: meeting.startTime,
    end: meeting.endTime,
    bmhTZ: info.bmhTZ,
    clientTZ: info.clientTZ,
    purpose: info.purpose || meeting.purpose,
    comment: meeting.clientComments,
    clientHasTwoRecentBloodTest: meeting.clientHasTwoRecentBloodTest,
  };
};

export const getInitialTimezoneForCRMScheduleMCM = (
  bmh: {
    tz: string;
    country: string;
  },
  contactRes: ContactRes
) => {
  const { mappedCountries } = getAllTimezones();
  if (contactRes && contactRes.shippingAddress.country) {
    const tzs =
      mappedCountries[contactRes.shippingAddress.country.toLowerCase()];
    if (tzs && tzs.length) return tzs[0];
  }
  return bmh.tz;
};

export const getDateFromBMHTimeWithTZ = (
  bmhTime: number,
  bmhTZ: string,
  clientTZ: string
) => {
  const getOffset = getOffsetForClientTZFn(bmhTZ, clientTZ);
  const { offset, clientOffset } = getOffset(bmhTime);

  return {
    date: new Date(bmhTime - offset),
    clientOffset,
  };
};

export const updateSlotDatesWithTZ = <
  T extends Pick<DailyAvailableSlot, "endBMHTime" | "startBMHTime">
>(
  slot: T,
  bmhTZ: string,
  clientTZ: string
): T => {
  const { startBMHTime, endBMHTime } = slot;
  const {
    date: start,
    clientOffset: startClientOffset,
  } = getDateFromBMHTimeWithTZ(startBMHTime, bmhTZ, clientTZ);
  const { date: end, clientOffset: endClientOffset } = getDateFromBMHTimeWithTZ(
    endBMHTime,
    bmhTZ,
    clientTZ
  );

  return {
    ...slot,
    start,
    startClientOffset,
    end,
    endClientOffset,
  };
};

export const getDurationFromPurpose = (
  purpose: ConsultationPurposeEnum
): MeetingDurationEnum => {
  if (purpose === ConsultationPurposeEnum.OTHER_QUERIES) {
    return MeetingDurationEnum.FIFTEEN_MIN;
  }
  return MeetingDurationEnum.THIRTY_MIN;
};

export const getClientFacingFromPurpose = (
  purpose: ConsultationPurposeEnum,
  host: "client" | "crm"
): string => {
  return host === "client"
    ? clientFacingConsultationPurpose[purpose]
    : crmFacingConsultationPurpose[purpose];
};
