import React, {
  useEffect,
  useState,
  useCallback,
  useRef,
  useMemo,
} from "react";
import { Box, Paper, makeStyles } from "@material-ui/core";
import { useDispatch } from "react-redux";
import {
  SchedulingInfo,
  McmWithAvailability,
  MCMInfo,
  MCMCall,
  ContactRes,
} from "@deep-consulting-solutions/bmh-constants";
import { AppDispatch } from "redux/store";
import { fetchMCMCalendarRequest } from "redux/scheduling/requests";
import { schedulingActions } from "redux/scheduling";
import Loader from "components/Loader";
import { useResponsive } from "hooks";

import { TOP_BAR_HEIGHT } from "./SchedulingTool.styles";
import {
  DailyAvailableSlot,
  TimeSlot,
  SchedulingStepEnum,
  DetailsFormValues,
  composeDataToSend,
  convertDuration,
  updateSlotDatesWithTZ,
} from "./SchedulingTool.helpers";
import SchedulingInformation from "./SchedulingInformation";
import SchedulingTZModal from "./SchedulingTZModal";
import SchedulingCalendar from "./SchedulingCalendar";
import SchedulingMCMSelection from "./SchedulingMCMSelection";
import SchedulingDetails from "./SchedulingDetails";
import SchedulingResult from "./SchedulingResult";
import {
  useSchedulingTokens,
  SchedulingTokens,
} from "./SchedulingToolTokenContext";
import { PurposeOfConsultation } from "./PurposeOfConsultation";

interface StyleProps {
  isOnModal?: boolean;
  tokens: SchedulingTokens;
}

const useStyle = makeStyles(({ palette: p, spacing: s, breakpoints: b }) => ({
  contentWrapper: ({ isOnModal, tokens }: StyleProps) => ({
    background: p.common.white,
    position: "relative",
    display: "flex",
    [b.down("sm")]: {
      flexDirection: "column",
      minHeight: isOnModal
        ? 500
        : `calc(100vh - ${tokens.crmToken ? "0px" : TOP_BAR_HEIGHT.mobile})`,
    },
    [b.down("xs")]: {
      minHeight: `calc(100vh - ${TOP_BAR_HEIGHT.mobile})`,
    },
  }),
  infoPanel: {
    borderRight: `1px solid ${p.grey[400]}`,
    width: "30%",
    [b.down("sm")]: {
      width: "100%",
    },
  },
  bookingPanel: {
    flex: 1,
    minHeight: "500px",
    padding: s(3),
    [b.down("sm")]: {
      minHeight: "auto",
      width: "100%",
      paddingTop: s(1),
      paddingBottom: s(1),
      paddingLeft: s(2),
      paddingRight: s(2),
    },
  },
}));

interface SchedulingToolMainProps {
  meeting?: MCMCall; // for Reschedule
  schedulingInfo: SchedulingInfo;
  contact?: ContactRes; // For detecting user's phone number
  changeDialogSize?: (toSmallSize?: boolean) => any; // Change dialog size when rendering result info (where there is just a few info, no need big screen)
  onScheduleDone?: (actions?: {
    shouldRefreshContact: boolean;
  }) => Promise<void>; // callback for Schedule PCM Call on Portal (to close the dialog after done)
  tempTimezone?: string;
  contactZohoID?: string; // used for CRM widget, Client Zoho ID for Schedule PCM / Reschedule PCM
  host: "crm" | "client";
  isFromEmail?: boolean;
  handleClose?: () => void;
}

export const SchedulingToolMain: React.FC<SchedulingToolMainProps> = ({
  meeting,
  schedulingInfo,
  contact,
  changeDialogSize,
  onScheduleDone,
  tempTimezone,
  contactZohoID,
  host,
  isFromEmail = false,
  handleClose = () => {},
}) => {
  const [schedulingDetails] = useState<
    SchedulingToolMainProps["schedulingInfo"]
  >(schedulingInfo);
  const originalClientTZ = useMemo(() => {
    return tempTimezone || schedulingDetails?.clientTZ;
  }, [tempTimezone, schedulingDetails?.clientTZ]);
  const dispatch = useDispatch<AppDispatch>();
  const [comment, setComment] = useState(meeting?.comment || "");
  const [canGoToPurposeScreen, setCanGoToPurposeScreen] = useState<boolean>(
    false
  );
  const [errorMessage, setErrorMessage] = useState("");

  const { isXSDown } = useResponsive();
  const tokens = useSchedulingTokens();
  const isOnModal = !tokens.crmToken && !tokens.emailToken;

  const infoRef = useRef<HTMLDivElement | null>(null);

  const [loading, setLoading] = useState(false);
  const [info, setInfo] = useState<SchedulingInfo>({
    ...schedulingInfo,
    clientTZ: originalClientTZ,
    bmhTZ: schedulingInfo.bmhTZ,
  });

  const [calendar, setCalendar] = useState<McmWithAvailability[]>([]);
  const [showTZModal, setShowTZModal] = useState(false);
  const [isTargetMCM, setIsTargetMCM] = useState(!!schedulingDetails?.mcm);
  const [selectedSlot, setSelectedSlot] = useState<TimeSlot | null>(null);
  const [otherMCMs, setOtherMCMs] = useState<MCMInfo[]>([]);
  const [selectedOtherMCM, setSelectedOtherMCM] = useState<MCMInfo | null>(
    null
  );
  const [step, setStep] = useState<SchedulingStepEnum>(
    SchedulingStepEnum.pickDate
  );
  const [date, setDate] = useState<Date | null>(null);
  const [month, setMonth] = useState(new Date().getMonth());
  const [year, setYear] = useState(new Date().getFullYear());
  const [sendStatus, setSendStatus] = useState<null | 1 | -1>(null);
  const [cachedDetails, setCachedDetails] = useState<DetailsFormValues | null>(
    null
  );
  const [duration, setDuration] = useState<number | undefined>(undefined);
  const [nextMeetingZohoID, setNextMeetingZohoID] = useState("");

  const scrollToTop = useCallback(() => {
    if (!infoRef.current) return;

    if (tokens.crmToken) {
      infoRef.current.scrollIntoView();
    } else if (isXSDown) {
      if (isOnModal) {
        infoRef.current.scrollIntoView();
      } else {
        window.scrollTo({
          top: 0,
        });
      }
    }
  }, [isXSDown, isOnModal, tokens]);

  const resetScheduling = useCallback(() => {
    setInfo((currentInfo) => {
      setSelectedSlot((currentSelectedSlot) => {
        if (currentSelectedSlot) {
          const newSelectedSlot = updateSlotDatesWithTZ(
            currentSelectedSlot,
            currentInfo.bmhTZ || "",
            currentInfo.clientTZ || ""
          );
          if (
            newSelectedSlot.startClientOffset ===
              currentSelectedSlot.startClientOffset &&
            newSelectedSlot.endClientOffset ===
              currentSelectedSlot.endClientOffset
          ) {
            return currentSelectedSlot;
          }
          setIsTargetMCM(!!(currentInfo && currentInfo.mcm));
        }

        setStep(SchedulingStepEnum.pickDate);
        setOtherMCMs([]);
        setSelectedOtherMCM(null);
        scrollToTop();
        return null;
      });
      return currentInfo;
    });
    setSendStatus(null);
    setCachedDetails(null);
    setLoading(false);
    setShowTZModal(false);
  }, [scrollToTop]);

  const onTZClick = useCallback(() => {
    setShowTZModal(true);
  }, []);

  const onTZModalClose = useCallback(() => {
    setShowTZModal(false);
  }, []);

  const onTZUpdate = useCallback(
    (tz: string | null) => {
      const timezone = tz || Intl.DateTimeFormat().resolvedOptions().timeZone;
      setInfo((currentInfo) => {
        return {
          ...currentInfo,
          clientTZ: timezone,
        };
      });

      if (tz) {
        setTimeout(() => {
          setStep((currentStep) => {
            if (currentStep !== SchedulingStepEnum.purposeOfConsultation) {
              resetScheduling();
            }
            return currentStep;
          });
        }, 0);
      }
      return true;
    },
    [resetScheduling]
  );

  const fetchCalendar = useCallback(async () => {
    const calendarRes = await fetchMCMCalendarRequest(
      tokens,
      meeting,
      contactZohoID
    );
    setCalendar(calendarRes.mcmWithAvailability);
    setInfo((current) => ({
      ...current,
      ...(!current.bmhTZ ? { bmhTZ: calendarRes.bmhTZ } : {}),
    }));
  }, [meeting, tokens, contactZohoID]);

  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      await fetchCalendar();

      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }, [fetchCalendar]);

  useEffect(() => {
    if (!schedulingDetails?.clientTZ && !tempTimezone) {
      onTZUpdate(null);
    }
  }, [onTZUpdate, schedulingDetails, tempTimezone]);

  const toggleIsTargetMCM = useCallback((options?: { toShow: boolean }) => {
    setIsTargetMCM((current) => {
      if (options) return options.toShow;
      return !current;
    });
  }, []);

  const onSlotSelect = useCallback(
    ({ mcms: ms, ...slot }: DailyAvailableSlot) => {
      setSelectedSlot(slot);
      if (isTargetMCM) {
        setStep(SchedulingStepEnum.details);
      } else {
        setOtherMCMs(ms);
        setStep(SchedulingStepEnum.pickMCM);
      }
      scrollToTop();
    },
    [isTargetMCM, scrollToTop]
  );
  const onOtherMCMSelect = useCallback(
    (mcm: MCMInfo) => {
      setSelectedOtherMCM(mcm);
      setStep(SchedulingStepEnum.details);
      scrollToTop();
    },
    [scrollToTop]
  );

  const onMCMSelectBack = useCallback(() => {
    setSelectedSlot(null);
    setOtherMCMs([]);
    setStep(SchedulingStepEnum.pickDate);
    scrollToTop();
  }, [scrollToTop]);

  const onDetailsBack = useCallback(
    (updatedComment: string) => {
      setComment(updatedComment);
      if (isTargetMCM) {
        setSelectedSlot(null);
        setStep(SchedulingStepEnum.pickDate);
      } else {
        setSelectedOtherMCM(null);
        setStep(SchedulingStepEnum.pickMCM);
      }
      scrollToTop();
    },
    [isTargetMCM, scrollToTop]
  );

  const onSchedulingSubmit = useCallback(
    async (values?: DetailsFormValues) => {
      if (!info || !selectedSlot) return;

      const details = values || cachedDetails;
      if (!details) return;
      if (values) setCachedDetails(values);

      const data = composeDataToSend(
        {
          info: (info as unknown) as SchedulingInfo,
          isTargetMCM,
          selectedOtherMCM,
          selectedSlot,
          details,
          originalTimezone: schedulingDetails?.clientTZ,
        },
        meeting,
        contact
      );
      let isOK = false;
      try {
        setErrorMessage("");
        setLoading(true);
        if (meeting) {
          const res = await dispatch(
            schedulingActions.rescheduleMCM({
              data,
              meeting,
              tokens,
            })
          );
          const errorFromDispatch = ((res as any).error as Error)?.message;

          if (errorFromDispatch) {
            throw new Error(errorFromDispatch);
          }

          if (schedulingActions.rescheduleMCM.fulfilled.match(res)) {
            isOK = true;
            if (onScheduleDone) {
              onScheduleDone({ shouldRefreshContact: data.setAsMainPhone });
            }
            if (res.payload.zohoID && res.payload.zohoID !== meeting.zohoID) {
              setNextMeetingZohoID(res.payload.zohoID);
            }
          }
        } else {
          const res = await dispatch(
            schedulingActions.createMCM({
              data,
              tokens,
            })
          );

          const errorFromDispatch = ((res as any).error as Error)?.message;

          if (errorFromDispatch) {
            throw new Error(errorFromDispatch);
          }
          isOK = schedulingActions.createMCM.fulfilled.match(res);

          if (isOK && onScheduleDone) {
            onScheduleDone({ shouldRefreshContact: data.setAsMainPhone });
          }
        }
      } catch (e) {
        const err = e as Error;
        setErrorMessage(err.message);
      } finally {
        setLoading(false);
        setSendStatus(isOK ? 1 : -1);
      }
    },
    [
      info,
      isTargetMCM,
      selectedOtherMCM,
      selectedSlot,
      cachedDetails,
      tokens,
      meeting,
      dispatch,
      contact,
      onScheduleDone,
      schedulingDetails?.clientTZ,
    ]
  );

  const onDurationChange = useCallback(
    (nextDuration: number) => {
      setDuration((current) => {
        if (current === nextDuration) return current;
        setSelectedSlot(null);
        setOtherMCMs([]);
        setSelectedOtherMCM(null);
        setStep((s) => {
          if (s !== SchedulingStepEnum.pickDate) {
            scrollToTop();
          }
          return SchedulingStepEnum.pickDate;
        });
        return nextDuration;
      });
    },
    [scrollToTop]
  );

  const onSchedulingRebook = useCallback(async () => {
    setStep(SchedulingStepEnum.pickDate);
    setInfo((current) => {
      setIsTargetMCM(!!(current && current.mcm));

      return current;
    });
    setSelectedSlot(null);
    setOtherMCMs([]);
    setSelectedOtherMCM(null);
    setDate((d) => {
      if (d) {
        setMonth(d.getMonth());
        setYear(d.getFullYear());
      }
      return d;
    });
    setSendStatus(null);
    setCachedDetails(null);
    setShowTZModal(false);
    scrollToTop();
    setLoading(true);

    try {
      await fetchCalendar();
      setLoading(false);
    } catch {
      setLoading(false);
    }
  }, [fetchCalendar, scrollToTop]);

  const handleUpdateSchedulingDetails = useCallback(
    (
      details: Pick<SchedulingInfo, "duration" | "purpose">,
      tempComment: string
    ) => {
      setInfo((current) => ({ ...current, ...details }));
      setComment(tempComment);
      setStep(SchedulingStepEnum.pickDate);
    },
    []
  );

  const handleUpdateInfo = useCallback(
    (details: Pick<SchedulingInfo, "duration" | "purpose">) => {
      setInfo((current) => ({ ...current, ...details }));
    },
    []
  );

  const goToPurposeScreen = useCallback(() => {
    setStep(SchedulingStepEnum.purposeOfConsultation);
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const isShowingSendStatus = !!sendStatus;
  useEffect(() => {
    if (changeDialogSize) {
      changeDialogSize(isShowingSendStatus);
    }
  }, [isShowingSendStatus, changeDialogSize]);

  useEffect(() => {
    if (!schedulingDetails?.purpose || !schedulingDetails?.duration) {
      setStep(SchedulingStepEnum.purposeOfConsultation);
      setCanGoToPurposeScreen(true);
    }
  }, [schedulingDetails]);

  useEffect(() => {
    if (info.duration) {
      setDuration(convertDuration(info.duration));
    }
  }, [info.duration]);

  const classes = useStyle({ tokens, isOnModal });

  return (
    <Paper elevation={1} className={classes.contentWrapper}>
      <Loader absolute open={loading} />
      {!sendStatus && (
        <>
          <Box className={classes.infoPanel}>
            <SchedulingInformation
              info={info}
              onTZClick={onTZClick}
              isTargetMCM={isTargetMCM}
              selectedSlot={selectedSlot}
              selectedOtherMCM={selectedOtherMCM}
              calendar={calendar}
              duration={duration}
              onDurationChange={onDurationChange}
              freezeDuration={!tokens.crmToken}
              ref={infoRef}
              meeting={meeting}
              contact={contact}
              host={host}
            />
            <SchedulingTZModal
              open={showTZModal}
              onClose={onTZModalClose}
              onUpdate={onTZUpdate}
              tz={info?.clientTZ}
            />
          </Box>
          <div className={classes.bookingPanel}>
            {step === SchedulingStepEnum.pickDate && duration && info.bmhTZ && (
              <SchedulingCalendar
                info={info}
                calendar={calendar}
                isTargetMCM={isTargetMCM}
                toggleIsTargetMCM={toggleIsTargetMCM}
                onSlotSelect={onSlotSelect}
                date={date}
                setDate={setDate}
                month={month}
                setMonth={setMonth}
                year={year}
                setYear={setYear}
                duration={duration}
                bmhTZ={info.bmhTZ}
                canGoToPurposeScreen={canGoToPurposeScreen}
                goToPurposeScreen={goToPurposeScreen}
              />
            )}
            {step === SchedulingStepEnum.pickMCM && (
              <SchedulingMCMSelection
                mcms={otherMCMs}
                targetMCM={info?.mcm}
                onSelect={onOtherMCMSelect}
                onBack={onMCMSelectBack}
              />
            )}
            {step === SchedulingStepEnum.details && (
              <SchedulingDetails
                onBack={onDetailsBack}
                submit={onSchedulingSubmit}
                meeting={meeting}
                contact={contact}
                comment={comment}
                info={info}
              />
            )}

            {step === SchedulingStepEnum.purposeOfConsultation && (
              <PurposeOfConsultation
                handleUpdateSchedulingDetails={handleUpdateSchedulingDetails}
                info={info}
                comment={comment}
                host={host}
                handleUpdateInfo={handleUpdateInfo}
              />
            )}
          </div>
        </>
      )}

      {!!sendStatus && (
        <SchedulingResult
          isSuccess={sendStatus > 0}
          info={info}
          slot={selectedSlot}
          mcm={isTargetMCM ? info?.mcm ?? undefined : selectedOtherMCM}
          rebook={onSchedulingRebook}
          retry={onSchedulingSubmit}
          meeting={meeting}
          nextMeetingZohoID={nextMeetingZohoID}
          host={host}
          errorMessage={errorMessage}
          isFromEmail={isFromEmail}
          handleClose={handleClose}
          contact={contact}
        />
      )}
    </Paper>
  );
};
