import React, { useCallback, useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@material-ui/core";
import { useHistory, useLocation } from "react-router-dom";

export const DialogSocialError = () => {
  const history = useHistory();
  const location = useLocation<{ hasSocialError: boolean } | null>();

  const [open, setOpen] = useState(false);
  const toggleOpen = useCallback(() => {
    setOpen((prev) => !prev);
  }, []);

  useEffect(() => {
    if (location.state && location.state.hasSocialError) {
      toggleOpen();
      history.replace(location.pathname + location.search);
    }
  }, [history, location.pathname, location.search, location.state, toggleOpen]);

  return (
    <Dialog open={open} onClose={toggleOpen}>
      <DialogTitle>Social Account Already Connected</DialogTitle>
      <DialogContent>
        <DialogContentText>
          It seems the social account you are trying to login with is connected
          to another account. Please use a different social login or different
          social login method.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={toggleOpen}>
          Got It
        </Button>
      </DialogActions>
    </Dialog>
  );
};
