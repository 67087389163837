import React, { useCallback } from "react";
import {
  Box,
  createStyles,
  Divider,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { notifications } from "services/notifications";

import {
  ProfileEmailAndPassword,
  ProfileRemoveLogin,
  ProfileAddLogin,
} from "@deep-consulting-solutions/auth-web";

import { ROUTES } from "configs";
import { DialogSocialError } from "components/DialogSocialError";
import { UserProfileForm } from "components/UserProfileForm";
import { useResponsive } from "hooks";
import { AccountCircle } from "@material-ui/icons";
import { useSelector } from "react-redux";
import { userSelectors } from "redux/user";
import { BackButton } from "@deep-consulting-solutions/dcs-web-ui";

const useStyles = makeStyles(({ palette: p, breakpoints: b }) =>
  createStyles({
    title: {
      color: p.primary.light,
    },
    name: {
      [b.only("xs")]: {
        maxWidth: "120px",
      },
    },
    email: {
      color: p.primary.light,
      [b.only("xs")]: {
        maxWidth: "120px",
      },
    },
    wrapperClass: {
      "& > :first-child": {
        color: p.primary.light,
      },
    },
  })
);

const ProfilePage = () => {
  const classes = useStyles();
  const { isSMDown, isXSDown } = useResponsive();
  const contact = useSelector(userSelectors.getContact);

  const userName = `${contact?.firstName ? `${contact.firstName} ` : ""}${
    contact?.lastName ?? ""
  }`;

  const onSuccessMessage = useCallback((message: string) => {
    notifications.notifySuccess(message);
  }, []);

  return (
    <Box py={3}>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Box display="flex" alignItems="center" ml={-1.5}>
          <BackButton color="primary" />
          <Typography
            color="primary"
            variant={isXSDown ? "subtitle2" : "h4"}
            component="h1"
          >
            Profile
          </Typography>
        </Box>
        {contact && (
          <Box display="flex" alignItems="center">
            <Box
              fontSize={isSMDown ? "40px" : "60px"}
              display="inline-flex"
              alignItems="center"
              mr={1}
              color="primary.light"
            >
              <AccountCircle color="inherit" fontSize="inherit" />
            </Box>
            <Box>
              <Typography
                className={classes.name}
                color="primary"
                variant={isSMDown ? "subtitle2" : "h4"}
                component="h2"
              >
                {userName}
              </Typography>
            </Box>
          </Box>
        )}
      </Box>
      <UserProfileForm />
      <Box mt={5} color="primary.light">
        <Typography variant="h5" component="h1" gutterBottom>
          Security Settings
        </Typography>
        <Divider />
      </Box>
      <ProfileEmailAndPassword
        wrapperClass={classes.wrapperClass}
        onEmailUpdated={onSuccessMessage}
        onPasswordUpdated={onSuccessMessage}
      />
      <ProfileRemoveLogin
        wrapperClass={classes.wrapperClass}
        onRemoveSuccess={onSuccessMessage}
      />
      <ProfileAddLogin
        wrapperClass={classes.wrapperClass}
        currentPath={ROUTES.profile.path}
        onPasswordAdded={onSuccessMessage}
      />
      <DialogSocialError />
    </Box>
  );
};

export default ProfilePage;
