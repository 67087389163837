import * as Yup from "yup";
import { getENText } from "helpers";
import { ConsultationPurposeEnum } from "@deep-consulting-solutions/bmh-constants";

export const purposeValues = Object.values(
  ConsultationPurposeEnum
) as ConsultationPurposeEnum[];

export const validationSchema = Yup.object({
  comment: Yup.string(),
  purpose: Yup.string()
    .required(getENText("validation.purposeOfConsultation.required"))
    .test(
      "check validity",
      getENText("validation.purposeOfConsultation.invalid"),
      (purpose: string | undefined | null) => {
        return purposeValues.includes(purpose as ConsultationPurposeEnum);
      }
    ),
});

export const getInitialValues = (
  purpose?: ConsultationPurposeEnum,
  comment?: string
) => ({
  purpose: purpose || ("" as ConsultationPurposeEnum),
  comment: comment || "",
});
