import { ContactRes } from "@deep-consulting-solutions/bmh-constants";

export const missingPersonalInfo = (contact: ContactRes) => {
  const { mobilePhone, dob, gender } = contact;
  return !gender || !dob || !mobilePhone;
};

export const missingShippingAddress = (contact: ContactRes) => {
  const {
    shippingAddress: { street, city, region, zip, country },
  } = contact;
  return !street || !city || !region || !zip || !country;
};

export const missingContactInfo = (contact: ContactRes) => {
  return missingPersonalInfo(contact) || missingShippingAddress(contact);
};
