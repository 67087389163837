import languages from "languages";

export const getText = (key: keyof typeof languages) => {
  const data = languages[key];
  return (
    scope: keyof typeof data,
    args?: { [key: string]: string }
  ): string => {
    let text = data[scope];
    if (args) {
      Object.keys(args).forEach((k) => {
        text = text.replace(`{${k}}`, args[k]);
      });
    }
    return text;
  };
};

export const getENText = getText("en");
