import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import {
  LoginForm,
  pkAuthSelectors,
  useResponsive,
} from "@deep-consulting-solutions/auth-web";
import { Grid, Typography, Hidden, Box } from "@material-ui/core";
import { ROUTES } from "configs";
import emailImage from "images/email.svg";
import { portalsActions } from "@deep-consulting-solutions/portals-web";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "redux/store";
import { activationActions } from "redux/activation";
import { userActions, userSelectors } from "redux/user";

const LoginPage: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { isSMDown } = useResponsive();
  const location = useLocation();
  const history = useHistory();
  const [oldClientEmail, setOldClientEmail] = useState("");
  const isAuthorised = useSelector(pkAuthSelectors.isAuthorised);
  const redirectPathname = useSelector(userSelectors.getRedirectPathname);

  useEffect(() => {
    if (!isAuthorised) {
      dispatch(portalsActions.resetPortalsState());
      dispatch(activationActions.resetActivationData());
      dispatch(userActions.resetUserData());
    }
  }, [dispatch, isAuthorised]);

  const onAuthorised = useCallback(() => {
    if (redirectPathname) {
      dispatch(userActions.setRedirectPathname(null));
      history.push(redirectPathname);
      return;
    }
    history.push(ROUTES.dashboard.path);
  }, [dispatch, history, redirectPathname]);

  const onLoginOverride = useCallback(
    (values: { email: string; password: string }) => {
      setOldClientEmail(values.email);
    },
    []
  );

  const onForgotPasswordClick = useCallback(() => {
    history.push(ROUTES.forgot.path);
  }, [history]);

  const onFacebookLoggedInClick = useCallback(() => {
    history.push({
      pathname: ROUTES.forgot.path,
      search: "loggedIn=facebook",
    });
  }, [history]);

  const textAlign = isSMDown ? "center" : "left";

  return (
    <>
      {oldClientEmail && (
        <Box>
          <Box mb={3}>
            <Typography color="primary" variant="h1" align={textAlign}>
              We have launched a new Portal!
            </Typography>
          </Box>
          <Typography variant="h5" component="p" align={textAlign}>
            Please activate your account in the new the portal
          </Typography>
        </Box>
      )}
      <Grid container spacing={4}>
        {oldClientEmail ? (
          <Grid item xs={12} md={6}>
            <Box mb={3}>
              <Typography
                color="primary"
                variant="h4"
                component="h2"
                align={textAlign}
              >
                Email for Account Activation
              </Typography>
            </Box>
            <Typography variant="subtitle1" component="p" align={textAlign}>
              We have sent you an email to <strong>{oldClientEmail}</strong>{" "}
              email address to activate your account in the new BMH Client
              Portal.
            </Typography>
            <Typography variant="subtitle1" component="p" align={textAlign}>
              Please follow the link in the email to complete the activation in
              some easy steps.
            </Typography>
          </Grid>
        ) : (
          <Hidden xsDown>
            <Grid item xs={12} md={6}>
              <Box mb={3}>
                <Typography color="primary" variant="h1" align={textAlign}>
                  Welcome to Bespoke UK and EU TRT & HRT Services
                </Typography>
              </Box>
              <Typography variant="h5" component="p" align={textAlign}>
                Balance My Hormones guides you through the journey of hormone
                rebalancing by facilitating your journey with doctors
                specialising in hormone deficiencies who are difficult to access
                through traditional NHS and private doctor practices.
              </Typography>
            </Grid>
          </Hidden>
        )}

        <Grid item xs={12} sm>
          <Box maxWidth="400px" mx="auto">
            {oldClientEmail ? (
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                textAlign="center"
              >
                <img src={emailImage} alt="Check your email" />
                <Box mt={2}>
                  <Typography variant="subtitle2">Check your email</Typography>
                </Box>
              </Box>
            ) : (
              <LoginForm
                onAuthorised={onAuthorised}
                onLoginOverride={onLoginOverride}
                onForgotPasswordClick={onForgotPasswordClick}
                location={location}
                onFacebookLoggedIn={onFacebookLoggedInClick}
              />
            )}
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default LoginPage;
