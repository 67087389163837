export const downloadFile = (url: string, filename: string) => {
  try {
    const anchor = document.createElement("a");
    const fileNameWithExtension = filename;
    anchor.href = url;
    anchor.download = fileNameWithExtension;
    anchor.id = "download-file-created-element";
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
    return true;
  } catch {
    return false;
  }
};

export const downloadFileWithBlob = (
  url: string,
  fileName: string
): Promise<boolean> => {
  return new Promise((resolve) => {
    function onreadystatechange(this: XMLHttpRequest) {
      if (this.readyState === 4) {
        const fileOfBlob = new File([this.response], fileName);
        const fileUrl = URL.createObjectURL(fileOfBlob);
        downloadFile(fileUrl, fileName);
        resolve(true);
      }
    }
    const xhr = new XMLHttpRequest();
    xhr.onreadystatechange = onreadystatechange;
    xhr.open("GET", url);
    xhr.responseType = "blob";
    xhr.send();
  });
};

export const openPageUrl = (url: string, id: string) => {
  try {
    const anchor = document.createElement("a");
    anchor.href = url;
    anchor.target = "_blank";
    anchor.id = `open-page-url-${id}`;
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
    return true;
  } catch {
    return false;
  }
};
