import React, { memo, useCallback, useState } from "react";
import {
  MCMCall,
  SchedulingInfo,
} from "@deep-consulting-solutions/bmh-constants";

import { useDispatch, useSelector } from "react-redux";
import { userActions, userSelectors } from "redux/user";
import { AppDispatch } from "redux/store";
import Loader from "components/Loader";
import { DialogWrapper } from "./SchedulingTool.styles";
import { SchedulingToolMain } from "./SchedulingToolMain";
import { getDurationFromPurpose } from "./SchedulingTool.helpers";

interface ReschedulingDialogProps {
  meeting: MCMCall | null;
  schedulingInfo?: SchedulingInfo;
  onClose: () => any;
}

export const ReschedulingDialog: React.FC<ReschedulingDialogProps> = ({
  meeting,
  schedulingInfo,
  onClose,
}) => {
  const [smallSize, setSmallSize] = useState(false);
  const contact = useSelector(userSelectors.getContact);
  const dispatch = useDispatch<AppDispatch>();
  const [loading, setLoading] = useState(false);

  const changeDialogSize = useCallback((toSmallSize?: boolean) => {
    setSmallSize(!!toSmallSize);
  }, []);

  const onScheduleDone = useCallback(
    async (actions?: { shouldRefreshContact: boolean }) => {
      setLoading(true);
      if (actions?.shouldRefreshContact) {
        await dispatch(userActions.getUserProfile());
      }
      setLoading(false);
    },
    [dispatch]
  );

  return (
    <DialogWrapper
      onClose={onClose}
      open={!!meeting}
      title="Reschedule PCM Consultation"
      smallSize={smallSize}
    >
      <Loader open={loading} />
      {!!meeting && !!schedulingInfo && (
        <SchedulingToolMain
          meeting={meeting}
          schedulingInfo={{
            ...schedulingInfo,
            ...(meeting.purpose
              ? {
                  purpose: meeting.purpose,
                  duration: getDurationFromPurpose(meeting.purpose),
                }
              : {}),
          }}
          changeDialogSize={changeDialogSize}
          host="client"
          contact={contact || undefined}
          onScheduleDone={onScheduleDone}
        />
      )}
    </DialogWrapper>
  );
};

export const MemoReschedulingDialog = memo(ReschedulingDialog);
