import { ROUTES } from "configs";

const DISABLED_OFFLINE_ROUTES = {
  [ROUTES.schedulingFromEmail.path]: true,
  [ROUTES.schedulingFromCRM.path]: true,
  [ROUTES.reschedulingFromCRM.path]: true,
  [ROUTES.reschedulingFromEmail.path]: true,
  [ROUTES.cancelScheduledMeetingFromCRM.path]: true,
  [ROUTES.cancelScheduledMeetingFromEmail.path]: true,
};

export const isInIframe = () => {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
};

export const shouldAllowOffline = () => {
  return false;
  const { pathname } = window.location;
  return !isInIframe() && !DISABLED_OFFLINE_ROUTES[pathname];
};
