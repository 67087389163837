import React, { useCallback } from "react";
import { Box, Grid, Hidden, Typography } from "@material-ui/core";
import {
  RecoverPassword,
  useResponsive,
} from "@deep-consulting-solutions/auth-web";
import { useLocation, useHistory } from "react-router-dom";

import { notifications } from "services/notifications";
import { ROUTES } from "configs";

const RecoverPasswordPage = () => {
  const { isSMDown } = useResponsive();
  const history = useHistory();
  const location = useLocation();

  const onError = useCallback((message: string) => {
    notifications.notifyError(message);
  }, []);

  const onSuccess = useCallback(
    (message: string) => {
      notifications.notifySuccess(message);
      history.push(ROUTES.login.path);
    },
    [history]
  );

  const onToLoginClick = useCallback(() => {
    history.push(ROUTES.login.path);
  }, [history]);

  const textAlign = isSMDown ? "center" : "left";

  return (
    <Grid container spacing={4}>
      <Hidden xsDown>
        <Grid item xs={12} md={6}>
          <Box mb={3}>
            <Typography
              color="primary"
              variant="h2"
              component="h1"
              align={textAlign}
            >
              Almost there.
              <br />
              Choose a new password for your account
            </Typography>
          </Box>
          <Typography variant="h5" component="p" align={textAlign}>
            Select a new password with which to access your account. Something
            you can always remember.
          </Typography>
        </Grid>
      </Hidden>
      <Grid item xs={12} sm>
        <Box maxWidth="400px" mx="auto">
          <RecoverPassword
            onError={onError}
            onSuccess={onSuccess}
            onToLoginClick={onToLoginClick}
            location={location}
          />
        </Box>
      </Grid>
    </Grid>
  );
};

export default RecoverPasswordPage;
