import React, { memo, useEffect, useState, useCallback } from "react";
import { makeStyles, Box } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { AppDispatch } from "@deep-consulting-solutions/auth-web";

import { useWidgetResize } from "hooks";
import { zohoSelectors } from "redux/zoho";
import { schedulingActions } from "redux/scheduling";
import Loader from "components/Loader";
import CancelMcmCall from "./CancelMcmCall";
import CRMCallResult from "./CRMCallResult";

const useStyles = makeStyles(({ spacing: s }) => ({
  error: {
    paddingTop: s(4),
  },
}));

export const CRMCancelMcmCall: React.FC = () => {
  const classes = useStyles();

  const { wrapperRef, resize } = useWidgetResize<HTMLDivElement>();
  const dispatch = useDispatch<AppDispatch>();

  const ids = useSelector(zohoSelectors.getIds);

  const [loading, setLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  const fetchUpcomingMCMMeeting = useCallback(async () => {
    try {
      const res = await dispatch(
        schedulingActions.getMCMCallDetailsCRM({ meetingZohoId: ids[0] })
      );
      setLoading(false);
      if (!schedulingActions.getMCMCallDetailsCRM.fulfilled.match(res)) {
        setIsError(true);
      }
      resize({ addHeight: 20 });
    } catch {
      setLoading(false);
      setIsError(true);
    }
  }, [ids, dispatch, resize]);

  useEffect(() => {
    fetchUpcomingMCMMeeting();
  }, [fetchUpcomingMCMMeeting]);

  return (
    <div ref={wrapperRef}>
      <Loader open={loading} />
      {isError && (
        <Box className={classes.error}>
          <CRMCallResult isCancelled={false} />
        </Box>
      )}
      {!loading && !isError && <CancelMcmCall isCRM />}
    </div>
  );
};

export const MemoCRMCancelMcmCall = memo(CRMCancelMcmCall);
