import React, { memo, useCallback } from "react";
import {
  BottomNavigation,
  BottomNavigationAction,
  createStyles,
  makeStyles,
} from "@material-ui/core";
import { DashboardOutlined } from "@material-ui/icons";
import BloodIcon from "components/BloodIcon";
import InvoicesIcon from "components/InvoicesIcon";
import { ROUTES } from "configs";
import { removeSecondOccur } from "helpers";
import { useLocation, Link } from "react-router-dom";

export const useStyles = makeStyles((theme) =>
  createStyles({
    tabs: {
      width: "100%",
      backgroundColor: theme.palette.primary.main,
      position: "fixed",
      bottom: 0,
      left: 0,
    },
    root: {
      color: "#7E9FCE",
      "&:nth-of-type(2)": {
        border: `1px solid ${theme.palette.primary.light}`,
      },
    },
    selected: {
      backgroundColor: theme.palette.primary.dark,
      color: `${theme.palette.primary.contrastText} !important`,
    },
  })
);

export const listItems = [
  {
    Icon: DashboardOutlined,
    title: ROUTES.dashboard.title!,
    path: ROUTES.dashboard.path,
  },
  {
    Icon: BloodIcon,
    title: ROUTES.bloodTests.title!,
    path: ROUTES.bloodTests.path,
  },
  {
    Icon: InvoicesIcon,
    title: ROUTES.invoices.title!,
    path: ROUTES.invoices.path,
  },
];

const BottomNav = () => {
  const classes = useStyles();
  const location = useLocation();
  const [currentPath, setCurrentPath] = React.useState(
    removeSecondOccur(location.pathname, "/")
  );

  const handleChange = useCallback((event, newValue) => {
    setCurrentPath(newValue);
  }, []);
  return (
    <BottomNavigation
      color="primary"
      value={currentPath}
      onChange={handleChange}
      showLabels
      className={classes.tabs}
    >
      {listItems.map(({ Icon, path, title }) => (
        <BottomNavigationAction
          classes={{ root: classes.root, selected: classes.selected }}
          label={title}
          key={path}
          value={path}
          icon={<Icon color="inherit" />}
          to={path}
          component={Link}
        />
      ))}
    </BottomNavigation>
  );
};

export default memo(BottomNav);
