import { GETUserTestResults } from "@deep-consulting-solutions/bmh-constants";
import { apiClient } from "apis";
import { generateSearchParams } from "./helpers";
import { FetchResultsParamsFieldEnum } from "./types";

export const fetchResults = async (args: {
  size: number;
  page: number;
  sortBy: FetchResultsParamsFieldEnum;
  sortASC: boolean;
}) => {
  const params = generateSearchParams(args);

  const res = await apiClient.get<GETUserTestResults>("/blood-test-result", {
    params,
  });

  return res.data.data;
};
