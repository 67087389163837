import React, { memo, useCallback } from "react";
import {
  AppBar,
  Toolbar,
  Box,
  styled,
  makeStyles,
  createStyles,
  Container,
  MenuItem,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";

import UserNameMenuBox, { LinkItem } from "components/UserNameMenuBox";
import { ROUTES } from "configs";
import { AppDispatch } from "redux/store";
import { Link } from "react-router-dom";
import {
  pkAuthActions,
  pkAuthSelectors,
} from "@deep-consulting-solutions/auth-web";

import Logo from "images/bmh-logo.png";
import { useBookPCMConsultation } from "hooks";

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  background: theme.palette.common.white,
  border: `2px solid ${theme.palette.primary.main}`,
}));

const useStyles = makeStyles((theme) =>
  createStyles({
    header: {
      zIndex: theme.zIndex.drawer + 1,
    },
    activeLink: {
      "& > li": {
        background: theme.palette.action.selected,
        "&:hover": {
          background: theme.palette.action.selected,
        },
      },
    },
    image: {
      height: "56px",
      [theme.breakpoints.up("sm")]: {
        height: "64px",
      },
    },
  })
);

const CustomerHeader = () => {
  const {
    handleOpenBookPCMConsultationDialog,
    canOpenBookPCMConsultationDialog,
  } = useBookPCMConsultation();
  const classes = useStyles();
  const dispatch = useDispatch<AppDispatch>();
  const contact = useSelector(pkAuthSelectors.authContact);

  const onLogoutClick = useCallback(() => {
    dispatch(pkAuthActions.logout());
  }, [dispatch]);

  return (
    <StyledAppBar className={classes.header} position="fixed">
      <Container disableGutters maxWidth={false}>
        <Toolbar>
          <Box className={classes.image} mr={2} height="100%">
            <Link to={ROUTES.dashboard.path}>
              <img
                className={classes.image}
                src={Logo}
                alt="Balance My Hormones"
              />
            </Link>
          </Box>
          <Box ml="auto">
            <UserNameMenuBox contact={contact}>
              <MenuItem
                disabled={!canOpenBookPCMConsultationDialog}
                onClick={handleOpenBookPCMConsultationDialog}
              >
                Book PCM Consultation
              </MenuItem>
              <LinkItem
                activeClassName={classes.activeLink}
                to={ROUTES.profile.path}
              >
                <MenuItem>View Profile</MenuItem>
              </LinkItem>
              <MenuItem onClick={onLogoutClick}>Logout</MenuItem>
            </UserNameMenuBox>
          </Box>
        </Toolbar>
      </Container>
    </StyledAppBar>
  );
};

export default memo(CustomerHeader);
