import clsx from "clsx";
import React, { useCallback } from "react";
import { makeStyles, Box, Typography } from "@material-ui/core";
import {
  EventAvailable,
  EventBusy,
  CheckCircleOutline,
} from "@material-ui/icons";
import {
  SchedulingEmailInaccessibleReasonEnum,
  ReschedulingEmailInaccessibleReasonEnum,
} from "@deep-consulting-solutions/bmh-constants";

import { useResponsive } from "hooks";

import emailSentImage from "images/email_sent.svg";
import { OtherReasonEnum } from "../SchedulingTool.helpers";

const useStyle = makeStyles(({ palette: p, spacing: s }) => ({
  wrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    minHeight: "100vh",
    width: "100%",
    padding: s(1),
  },
  icon: {
    width: "90px",
    height: "90px",
  },
  iconError: {
    color: p.error.main,
  },
  iconSuccess: {
    color: p.success.main,
  },
  title: {
    marginTop: s(3),
    color: p.primary.main,
    textAlign: "center",
  },
  subtitle: {
    marginTop: s(2),
    color: p.primary.light,
    textAlign: "center",
  },
  delimiter: {
    marginTop: "60px",
    marginBottom: "60px",
    paddingTop: "1px",
    width: "300px",
    background: p.grey[500],
  },
  emailImage: {},
  emailSubtitle: {
    marginTop: s(2),
    color: p.primary.light,
    textAlign: "center",
  },
}));

type Reason =
  | OtherReasonEnum
  | SchedulingEmailInaccessibleReasonEnum
  | ReschedulingEmailInaccessibleReasonEnum;

type IsArg = Reason | [Reason, boolean | undefined];

interface SchedulingTokenStatusProps {
  reason: Reason | null;
  className?: string;
  isSchedule?: boolean;
  isReschedule?: boolean;
  isCancel?: boolean;
}

export const SchedulingTokenStatus: React.FC<SchedulingTokenStatusProps> = ({
  reason,
  className,
  isSchedule,
  isCancel,
  isReschedule,
}) => {
  const { isSMDown } = useResponsive();

  const is = useCallback(
    (...args: IsArg[]) => {
      return !!args.find((argItems) => {
        let status: Reason;
        let withUsage: boolean | undefined;

        if (Array.isArray(argItems)) {
          status = argItems[0];
          withUsage = argItems[1];
        } else {
          status = argItems;
        }

        if (withUsage === undefined) return reason === status;
        return !!withUsage && reason === status;
      });
    },
    [reason]
  );

  const classes = useStyle();

  return (
    <Box className={clsx(classes.wrapper, className)}>
      {is(
        OtherReasonEnum.noToken,
        OtherReasonEnum.others,
        [SchedulingEmailInaccessibleReasonEnum.cancelled, isSchedule],
        [ReschedulingEmailInaccessibleReasonEnum.cancelled, isReschedule],
        [ReschedulingEmailInaccessibleReasonEnum.cancelled, isCancel]
      ) && <EventBusy className={`${classes.icon} ${classes.iconError}`} />}
      {is(
        [SchedulingEmailInaccessibleReasonEnum.booked, isSchedule],
        [ReschedulingEmailInaccessibleReasonEnum.rescheduled, isReschedule]
      ) && (
        <EventAvailable className={`${classes.icon} ${classes.iconSuccess}`} />
      )}
      {is(
        [SchedulingEmailInaccessibleReasonEnum.completed, isSchedule],
        [ReschedulingEmailInaccessibleReasonEnum.completed, isReschedule],
        [ReschedulingEmailInaccessibleReasonEnum.completed, isCancel]
      ) && (
        <CheckCircleOutline
          className={`${classes.icon} ${classes.iconSuccess}`}
        />
      )}
      <Typography variant={isSMDown ? "h5" : "h3"} className={classes.title}>
        {is(OtherReasonEnum.noToken) && "Sorry! Token is missing!"}
        {is(OtherReasonEnum.others) && "Sorry! Something went wrong!"}
        {is([SchedulingEmailInaccessibleReasonEnum.booked, isSchedule]) &&
          "Your PCM consultation has already been booked!"}
        {is(
          [SchedulingEmailInaccessibleReasonEnum.cancelled, isSchedule],
          [ReschedulingEmailInaccessibleReasonEnum.cancelled, isReschedule],
          [ReschedulingEmailInaccessibleReasonEnum.cancelled, isCancel]
        ) && "Sorry! Your PCM consultation has already been cancelled!"}
        {is(
          [SchedulingEmailInaccessibleReasonEnum.completed, isSchedule],
          [ReschedulingEmailInaccessibleReasonEnum.completed, isReschedule],
          [ReschedulingEmailInaccessibleReasonEnum.completed, isCancel]
        ) && "Your PCM consultation has already been completed!"}
        {is([
          ReschedulingEmailInaccessibleReasonEnum.rescheduled,
          isReschedule,
        ]) && "Your PCM consultation has already been rescheduled!"}
      </Typography>

      {is(
        [SchedulingEmailInaccessibleReasonEnum.cancelled, isSchedule],
        [SchedulingEmailInaccessibleReasonEnum.completed, isSchedule],
        [ReschedulingEmailInaccessibleReasonEnum.cancelled, isReschedule],
        [ReschedulingEmailInaccessibleReasonEnum.completed, isReschedule],
        [ReschedulingEmailInaccessibleReasonEnum.completed, isCancel]
      ) && (
        <Typography
          variant={isSMDown ? "subtitle2" : "subtitle1"}
          className={classes.subtitle}
        >
          If you need to book a new PCM consultation please contact BMH
        </Typography>
      )}

      {is(
        [SchedulingEmailInaccessibleReasonEnum.booked, isSchedule],
        [ReschedulingEmailInaccessibleReasonEnum.rescheduled, isReschedule]
      ) && (
        <>
          <div className={classes.delimiter} />
          <img
            src={emailSentImage}
            alt="email sent"
            className={classes.emailImage}
          />
          <Typography variant="subtitle2" className={classes.emailSubtitle}>
            <b>Please check your inbox</b>
            <br />
            {reason === SchedulingEmailInaccessibleReasonEnum.booked && (
              <>
                You should have received a notification email with all
                information about the meeting
              </>
            )}
            {reason === ReschedulingEmailInaccessibleReasonEnum.rescheduled && (
              <>
                You should have received a notification email with all
                information about your rescheduled meeting
                <br />
                If you need to reschedule again, please use the link of this
                last email
              </>
            )}
          </Typography>
        </>
      )}
    </Box>
  );
};
