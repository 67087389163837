import { Grid, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import { AccessTime as ClockIcon } from "@material-ui/icons";
import { ConsultationPurposeEnum } from "@deep-consulting-solutions/bmh-constants";
import {
  getClientFacingFromPurpose,
  getDurationFromPurpose,
} from "../SchedulingTool.helpers";

const useStyle = makeStyles(({ palette: p }) => ({
  clockIcon: {
    color: p.primary.light,
    display: "block",
  },
}));

interface Props {
  purpose: ConsultationPurposeEnum;
  host: "client" | "crm";
}

export const PurposeOfConsultationLabel = ({ purpose, host }: Props) => {
  const classes = useStyle();
  return (
    <Grid container justify="flex-start" alignItems="center" spacing={1}>
      <Grid item>
        <Typography>{getClientFacingFromPurpose(purpose, host)}</Typography>
      </Grid>
      <Grid item>
        <ClockIcon color="primary" className={classes.clockIcon} />
      </Grid>
      <Grid item>
        <Typography>{getDurationFromPurpose(purpose)}</Typography>
      </Grid>
    </Grid>
  );
};
