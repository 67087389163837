import React, { useState, useCallback } from "react";
import {
  makeStyles,
  Typography,
  IconButton,
  Menu,
  MenuItem,
} from "@material-ui/core";
import {
  MoreVert as MenuIcon,
  Phone as PhoneIcon,
  Today as CalendarIcon,
  AccessTime as ClockIcon,
} from "@material-ui/icons";
import { MCMCall } from "@deep-consulting-solutions/bmh-constants";

import { getDifferenceInMinutes } from "helpers";

import { getClientFacingFromPurpose } from "components/SchedulingTool/SchedulingTool.helpers";
import { useCardStyles } from "../BloodTest";

const useStyle = makeStyles(({ palette: p, spacing: s }) => ({
  card: {
    flexDirection: "column",
  },
  titleWraper: {
    display: "flex",
    alignItems: "center",
    width: "100%",
  },
  title: {
    color: p.primary.light,
  },
  menuIcon: {
    marginLeft: "auto",
  },
  inner: {
    display: "flex",
    flexWrap: "wrap",
    width: "100%",
    paddingRight: s(2),
    alignItems: "flex-start",
  },
  item: {
    width: "50%",
    display: "flex",
    alignItems: "center",
    marginTop: s(2),
  },
  itemIcon: {
    color: p.secondary.main,
    marginRight: s(1),
  },
  tz: {
    marginTop: s(0.5),
  },
  time: {
    display: "flex",
    flexDirection: "column",
    width: "50%",
    marginTop: s(2),
  },
  timeItem: {
    display: "flex",
    alignItems: "center",
    width: "100%",
  },
  subtitle: {
    textAlign: "left",
  },
  subtitleContainer: {
    width: "100%",
    margin: s(2, 0),
  },
}));

interface UpcomingMCMCallMobileProps {
  mcmCall: MCMCall;
  handleOpenCancelMcmCall: () => void;
  openReschedulingDialog: () => any;
  startDate: string;
  startTime: string;
  tz?: string | null;
}

export const UpcomingMCMCallMobile: React.FC<UpcomingMCMCallMobileProps> = ({
  mcmCall,
  handleOpenCancelMcmCall,
  openReschedulingDialog,
  startDate,
  startTime,
  tz,
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const onMenuClick = useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(e.currentTarget);
  }, []);

  const onMenuClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const onRescheduleClick = useCallback(() => {
    openReschedulingDialog();
    setAnchorEl(null);
  }, [openReschedulingDialog]);

  const cardClasses = useCardStyles();
  const classes = useStyle();
  return (
    <div
      className={`${cardClasses.card} ${classes.card}`}
      data-testid="upcoming-mcm-mobile"
    >
      <div className={classes.titleWraper}>
        <Typography variant="h5" component="h2" className={classes.title}>
          {getDifferenceInMinutes(mcmCall.start, mcmCall.end).string} call with{" "}
          {mcmCall.mcm.name}
        </Typography>

        <IconButton
          className={classes.menuIcon}
          onClick={onMenuClick}
          data-testid="menu"
          size="small"
        >
          <MenuIcon />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          keepMounted
          open={!!anchorEl}
          onClose={onMenuClose}
        >
          <MenuItem onClick={onRescheduleClick}>RESCHEDULE</MenuItem>
          <MenuItem onClick={handleOpenCancelMcmCall}>CANCEL</MenuItem>
        </Menu>
      </div>
      {!!mcmCall.purpose && (
        <div className={classes.subtitleContainer}>
          <Typography
            variant="caption"
            component="p"
            className={classes.subtitle}
          >
            {getClientFacingFromPurpose(mcmCall.purpose, "client")}
          </Typography>
        </div>
      )}
      <div className={classes.inner}>
        <div className={classes.item}>
          <CalendarIcon className={classes.itemIcon} />
          <Typography>{startDate}</Typography>
        </div>

        <div className={classes.time}>
          <div className={classes.timeItem}>
            <ClockIcon className={classes.itemIcon} />
            <Typography>{startTime}</Typography>
          </div>
          {!!tz && (
            <Typography variant="caption" className={classes.tz}>
              ({tz})
            </Typography>
          )}
        </div>
        {!!mcmCall.phone && (
          <div className={classes.item}>
            <PhoneIcon className={classes.itemIcon} />
            <Typography>{mcmCall.phone}</Typography>
          </div>
        )}
      </div>
    </div>
  );
};
