import React, { ReactNode } from "react";
import { Box, Container } from "@material-ui/core";

import Header from "./Header";
import Footer from "./Footer";

interface Props {
  header?: ReactNode;
}

const AuthLayout: React.FC<Props> = ({ children, header: HeaderComponent }) => (
  <Box minHeight="100vh" display="flex" flexDirection="column">
    <Header>{HeaderComponent && HeaderComponent}</Header>
    <Box
      flexGrow={1}
      display="flex"
      flexDirection="column"
      justifyContent="center"
      py={1}
    >
      <Container
        style={{
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-around",
        }}
      >
        {children!}
      </Container>
    </Box>
    <Footer />
  </Box>
);

export default AuthLayout;
