import React, { memo, useState, useCallback } from "react";
import {
  Typography,
  Box,
  Popover,
  Button,
  styled,
  BoxProps,
} from "@material-ui/core";
import { ArrowDropUp, AccountCircle } from "@material-ui/icons";

import { TRANSITION } from "theme";
import { Contact } from "@deep-consulting-solutions/auth-web";
import { useResponsive } from "hooks";

const UserNameBtn = styled(Button)(({ theme }) => ({
  background: "none",
  color: theme.palette.primary.main,
  paddingRight: 0,
  border: "none",
  textTransform: "none",

  "&:hover": {
    background: "none",
  },
}));

const UserIcon = styled(AccountCircle)(({ theme }) => ({
  fontSize: theme.typography.h2.fontSize,
  marginLeft: theme.spacing(1),
}));

const ArrowIcon = styled(ArrowDropUp)({
  transition: TRANSITION,
});

const StyledTypography = styled(Typography)({
  maxWidth: "150px",
});

interface Props extends BoxProps {
  contact?: Contact | null;
}

const UserNameMenuBox: React.FunctionComponent<Props> = ({
  contact,
  children,
  ...boxProps
}) => {
  const { isXSDown } = useResponsive();
  const [userNameEl, setUserNameEl] = useState<HTMLButtonElement | null>(null);

  const onUserNameClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setUserNameEl(event.currentTarget);
    },
    []
  );

  const onUserNameClose = useCallback(() => {
    setUserNameEl(null);
  }, []);

  const userName = `${contact?.firstName ? `${contact.firstName} ` : ""}${
    contact?.lastName ?? ""
  }`;

  return (
    <Box position="relative" {...boxProps}>
      <UserNameBtn
        onClick={onUserNameClick}
        aria-label="user menu"
        aria-haspopup
        aria-expanded={!!userNameEl}
      >
        <StyledTypography
          variant={isXSDown ? "subtitle2" : "subtitle1"}
          noWrap
          title={userName}
        >
          <strong>{userName}</strong>
        </StyledTypography>
        <UserIcon />
        <ArrowIcon
          style={{ transform: `rotate(${userNameEl ? "0deg" : "180deg"})` }}
        />
      </UserNameBtn>

      <Popover
        open={!!userNameEl}
        anchorEl={userNameEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        onClose={onUserNameClose}
      >
        <Box onClick={onUserNameClose}>{children}</Box>
      </Popover>
    </Box>
  );
};

export default memo(UserNameMenuBox);
