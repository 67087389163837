import React, { FC, useCallback } from "react";
import {
  makeStyles,
  Box,
  Typography,
  Grid,
  InputLabel,
  Button,
} from "@material-ui/core";
import { Formik, Field, Form, FormikConfig } from "formik";
import { TextField } from "formik-material-ui";
import * as Yup from "yup";
import { useDispatch } from "react-redux";

import { VALIDATIONS } from "helpers";
import { ZohoServices } from "services";
import { AppDispatch } from "redux/store";
import { schedulingActions } from "redux/scheduling";
import Loader from "components/Loader";

import { CallInfo } from "./helpers";

export interface CRMCancelMcmCallFormProps {
  callInfo: CallInfo;
  cancelCall: () => void;
  showResultView: () => void;
}

interface FormValues {
  reason: string;
}

const useStyles = makeStyles(({ spacing: s }) => ({
  root: {
    textAlign: "center",
  },
  title: {
    fontSize: "1.125rem",
    lineHeight: "21px",
    color: "#000",
  },
  callInfoSM: {
    margin: s(5, 0),
  },
  callInfo: {
    margin: s(7.5, 0),
  },
  formContainer: {
    margin: s(4, 13, 0),
  },
  form: {
    position: "relative",
  },
  label: {
    fontSize: "0.875rem",
    textAlign: "left",
  },
  labelText: {
    display: "inline",
  },
  action: {
    marginRight: s(1),
    marginBottom: s(1),
    "&:last-child": {
      marginRight: 0,
    },
  },
}));

const validationSchema = Yup.object({
  reason: VALIDATIONS.reason,
});

const CRMCancelMcmCallForm: FC<CRMCancelMcmCallFormProps> = ({
  callInfo,
  cancelCall,
  showResultView,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch<AppDispatch>();

  const onSubmit: FormikConfig<FormValues>["onSubmit"] = useCallback(
    async (values, helpers) => {
      try {
        helpers.setSubmitting(true);

        const res = await dispatch(
          schedulingActions.cancelMcmCallCRM({
            mcmCallId: callInfo.id,
            reason: values.reason,
          })
        );

        if (schedulingActions.cancelMcmCallCRM.fulfilled.match(res)) {
          cancelCall();
        }

        showResultView();
        helpers.setSubmitting(false);
      } catch (err) {
        helpers.setSubmitting(false);
      }
    },
    [dispatch, callInfo, cancelCall, showResultView]
  );

  const handleDontCancelClick = useCallback(() => {
    ZohoServices.closePopup();
  }, []);

  return (
    <Box className={classes.root}>
      <Typography variant="body1" color="textPrimary" className={classes.title}>
        Are you sure you want to cancel <br />
        {`"${callInfo.callDuration.toLowerCase()} meeting between ${
          callInfo.mcmName
        } and ${callInfo.clientName}"?`}
      </Typography>
      <Formik
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        initialValues={{
          reason: "",
        }}
      >
        {({ isSubmitting }) => (
          <Box className={classes.formContainer}>
            <Form className={classes.form}>
              {isSubmitting && <Loader open={isSubmitting} absolute />}
              <Grid container spacing={3} alignItems="center">
                <Grid item xs={12}>
                  <InputLabel htmlFor="reason" className={classes.label}>
                    <Typography
                      color="textPrimary"
                      variant="body2"
                      className={classes.labelText}
                    >
                      {
                        "Can you tell us what the reason is for cancelling the PCM consultation? "
                      }
                    </Typography>
                    <Typography
                      color="secondary"
                      variant="body2"
                      className={classes.labelText}
                    >
                      *
                    </Typography>
                  </InputLabel>
                  <Field
                    fullWidth
                    component={TextField}
                    id="reason"
                    name="reason"
                    placeholder="Cancellation reason"
                    disabled={isSubmitting}
                    variant="outlined"
                    multiline
                    rows={3}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    type="submit"
                    disabled={isSubmitting}
                    color="primary"
                    className={classes.action}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="text"
                    color="primary"
                    disabled={isSubmitting}
                    className={classes.action}
                    onClick={handleDontCancelClick}
                  >
                    Don&apos;t Cancel
                  </Button>
                </Grid>
              </Grid>
            </Form>
          </Box>
        )}
      </Formik>
    </Box>
  );
};

export default CRMCancelMcmCallForm;
