import React from "react";
import { InvoicesTable } from "@deep-consulting-solutions/invoices-web";
import { useHistory, useLocation } from "react-router-dom";
import { Box, styled, Typography } from "@material-ui/core";
import { useResponsive } from "hooks";

const Wrapper = styled(Box)(({ theme }) => ({
  "& th > div > p, svg": {
    fontWeight: "bold",
    whiteSpace: "normal",
  },
  "& div > a": {
    backgroundColor: theme.palette.background.paper,
  },
  [theme.breakpoints.up("md")]: {
    backgroundColor: theme.palette.background.paper,
  },
  [theme.breakpoints.down("xs")]: {
    margin: "0 -16px",
  },
}));

export const InvoicesPage = () => {
  const history = useHistory();
  const location = useLocation();
  const { isMDUp } = useResponsive();
  return (
    <>
      {isMDUp && (
        <Box mb={3}>
          <Typography color="primary" variant="h3" component="h1">
            Invoices
          </Typography>
        </Box>
      )}
      <Wrapper>
        <InvoicesTable
          location={location}
          onRedirect={(nextPath) => {
            history.push(nextPath);
          }}
        />
      </Wrapper>
    </>
  );
};
