import {
  GETTRDetailsFromPortalRes,
  GETTRS3PresignedUrlRes,
  ROUTE_TR_S3_PRESIGNED_URL_PORTAL,
  ROUTE_TR_DETAILS_FROM_PORTAL,
} from "@deep-consulting-solutions/bmh-constants";
import { apiClient } from "apis";

export const fetchResultDetails = async (id: string) => {
  const res = await apiClient.get<GETTRDetailsFromPortalRes>(
    ROUTE_TR_DETAILS_FROM_PORTAL.replace(":id", id)
  );

  return res.data.data;
};

export const fetchResultDetailsPDFUrl = async ({ id }: { id: string }) => {
  const res = await apiClient.get<GETTRS3PresignedUrlRes>(
    ROUTE_TR_S3_PRESIGNED_URL_PORTAL.replace(":id", id)
  );

  return res.data.data.url;
};

export const fetchResultDetailsPDFData = async ({ id }: { id: string }) => {
  const res = await apiClient.get<GETTRS3PresignedUrlRes>(
    ROUTE_TR_S3_PRESIGNED_URL_PORTAL.replace(":id", id)
  );

  return res.data.data;
};
