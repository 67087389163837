export const APP_NAME = "bmh";
export const APP_VERSION = "0.1.0";
export const DEFAULT_ROWS_PER_PAGE_OPTIONS = [10, 20, 30];
export const DEFAULT_DATE_FORMAT = "dd MMM yyyy";
export const DATE_REQUEST_FORMAT = "yyyy-MM-dd";
export const ITEM_DESCRIPTION_TRUNCATE_LENGTH = 40;
export const UK_LAT = 55.3781;
export const UK_LNG = 3.436;
export const DEFAULT_COUNTRY = "GB";
export const DISABLE_FACEBOOK_LOGIN = true;
