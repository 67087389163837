import React from "react";
import { ButtonProps } from "@material-ui/core";
import SocialButton from "components/SocialButton";
import GoogleIcon from "./google.svg";

interface Props extends ButtonProps {
  isStatic?: boolean;
  withText?: string;
}

const GoogleButton: React.FC<Props> = (props) => {
  return (
    <SocialButton
      icon={GoogleIcon}
      name="Google"
      color="#757575"
      borderColor="#C4C4C4"
      {...props}
    />
  );
};

export default GoogleButton;
