import {
  userEndpoints,
  UserRes,
  PatchUpdateUserProfileBody,
  GenderEnum,
} from "@deep-consulting-solutions/bmh-constants";
import { createAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { apiClient } from "apis";
import { AppState, AppDispatch } from "redux/store";
import { createMCM, rescheduleMCM } from "redux/scheduling/common";

import { ENTITY, updateTimeZone } from "./common";

export interface UserState {
  user: UserRes | null;
  redirectPathname: string | null;
}

export const resetUserData = createAction(`${ENTITY}/reset-user-data`);

export const setRedirectPathname = createAction<string | null>(
  `${ENTITY}/set-redirect-pathname`
);

const getUserProfile = createAsyncThunk<
  UserRes,
  void,
  { state: AppState; dispatch: AppDispatch }
>(`${ENTITY}/getUserProfile`, async () => {
  const res = await apiClient.get<{ data: UserRes }>(
    userEndpoints.getOrPatchUserProfile
  );

  return res.data.data;
});

const updateUserProfile = createAsyncThunk<
  UserRes,
  PatchUpdateUserProfileBody,
  { state: AppState; dispatch: AppDispatch }
>(
  `${ENTITY}/updateUserProfile`,
  async (params: PatchUpdateUserProfileBody & { gender?: GenderEnum }) => {
    const res = await apiClient.patch<{ data: UserRes }>(
      userEndpoints.getOrPatchUserProfile,
      params
    );

    return res.data.data;
  }
);

const initialState: UserState = {
  user: null,
  redirectPathname: null,
};

const slice = createSlice({
  name: ENTITY,
  initialState,
  reducers: {},
  extraReducers: (builders) =>
    builders
      .addCase(getUserProfile.fulfilled, (state, action) => {
        state.user = action.payload;
      })
      .addCase(updateUserProfile.fulfilled, (state, action) => {
        state.user = action.payload;
      })
      .addCase(resetUserData, (state) => {
        return { ...initialState, redirectPathname: state.redirectPathname };
      })
      .addCase(setRedirectPathname, (state, action) => {
        state.redirectPathname = action.payload;
      })
      .addCase(updateTimeZone.fulfilled, (state, action) => {
        if (
          state.user &&
          (!action.meta.arg.tokens ||
            (!action.meta.arg.tokens.crmToken &&
              !action.meta.arg.tokens.emailToken))
        ) {
          state.user.contact.timezone = action.payload.timezone;
        }
      })
      .addCase(createMCM.fulfilled, (state, action) => {
        const isOnPortal =
          !action.meta.arg.tokens.crmToken &&
          !action.meta.arg.tokens.emailToken;
        if (
          isOnPortal &&
          action.meta.arg.data.phone &&
          action.meta.arg.data.setAsMainPhone
        ) {
          if (state.user) {
            state.user.contact.phone = action.meta.arg.data.phone;
          }
        }
        if (isOnPortal && state.user && action.meta.arg.data.timezone) {
          state.user.contact.timezone = action.meta.arg.data.timezone;
        }
      })
      .addCase(rescheduleMCM.fulfilled, (state, action) => {
        const isOnPortal =
          !action.meta.arg.tokens.crmToken &&
          !action.meta.arg.tokens.emailToken;
        if (
          isOnPortal &&
          action.meta.arg.data.phone &&
          action.meta.arg.data.setAsMainPhone
        ) {
          if (state.user) {
            state.user.contact.phone = action.meta.arg.data.phone;
          }
        }
        if (isOnPortal && state.user && action.meta.arg.data.timezone) {
          state.user.contact.timezone = action.meta.arg.data.timezone;
        }
      }),
});

const getUser = (state: AppState) => state.user.user;
const getContact = (state: AppState) => state.user.user?.contact ?? null;
const getRedirectPathname = (state: AppState) => state.user.redirectPathname;

export const userSelectors = {
  getUser,
  getContact,
  getRedirectPathname,
};

export const userActions = {
  getUserProfile,
  updateUserProfile,
  resetUserData,
  updateTimeZone,
  setRedirectPathname,
};

export default slice.reducer;
