import {
  BloodTakingOptionEnum,
  BloodTestResultWithPathway,
  BloodTestResultItem,
  SchedulingInfo,
  BloodTestResultInputMethodEnum,
  ConsultationPurposeEnum,
  BluePrintStagesEnum,
} from "@deep-consulting-solutions/bmh-constants";
import { BackButton } from "@deep-consulting-solutions/dcs-web-ui";
import {
  Box,
  Button,
  createStyles,
  Divider,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { CalendarTodayOutlined, InfoOutlined } from "@material-ui/icons";
import Loader from "components/Loader";
import { DEFAULT_DATE_FORMAT } from "configs";
import { format } from "date-fns";
import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useParams } from "react-router-dom";
import { ReactComponent as FingerIcon } from "images/finger.svg";
import { ReactComponent as SyringeIcon } from "images/syringe.svg";
import theme from "theme";
import { sortBy } from "lodash";
import { useResponsive } from "hooks";
import { MemoSchedulingToolDialog } from "components/SchedulingTool";
import { DataWithWarning } from "components/DataWithWarning";
import { processSampleCollectedDate } from "helpers";
import { downloadFileWithBlob } from "helpers/downloadFile";
import { getDurationFromPurpose } from "components/SchedulingTool/SchedulingTool.helpers";
import { useDispatch } from "react-redux";
import { AppDispatch } from "redux/store";
import { userActions } from "redux/user";
import { fetchResultDetails, fetchResultDetailsPDFData } from "./requests";
import { ListView } from "./ListView";
import { PdfDownloadButton } from "./PdfDownloadButton";
import { FailureFlagInfo } from "./FailureFlagInfo";

interface StyleProps {
  showAbnormalFlagTextOnNewLine: boolean;
}

const useStyles = makeStyles(({ spacing: s, palette: p, typography: typo }) =>
  createStyles({
    paper: {
      padding: s(2, 3),
    },
    title: {
      color: p.primary.light,
    },
    tableHeader: {
      color: p.primary.light,
      fontWeight: "bold",
    },
    noWrap: {
      whiteSpace: "nowrap",
    },
    divider: {
      height: "2px",
      backgroundColor: p.primary.main,
      margin: s(2, 0),
    },
    takingOptionIcon: {
      fill: p.primary.light,
    },
    spacingCell: {
      border: "none",
      paddingTop: s(3),
      paddingBottom: s(3),
    },
    firstCell: {
      paddingLeft: s(1),
    },
    note: {
      ...typo.body2,
      color: p.grey[500],
    },
    abnormalFlagText: ({ showAbnormalFlagTextOnNewLine }: StyleProps) => ({
      color: p.error.dark,
      textAlign: "right",
      width: showAbnormalFlagTextOnNewLine ? "100%" : undefined,
      paddingTop: s(showAbnormalFlagTextOnNewLine ? 1 : 0),
      flex: showAbnormalFlagTextOnNewLine ? undefined : 1,
    }),
    resultName: {
      marginBottom: s(1),
    },
    messageItalic: {
      fontSize: 11,
      fontStyle: "italic",
      textAlign: "right",
      marginBottom: s(1),
      color: "#34476a",
    },
  })
);

const TABLE_HEADERS = [
  "Test",
  "Result",
  "Normal Range",
  "Unit",
  "Abnormal Flags",
];

export const BloodTestsDetailsPage = () => {
  const { isMDUp, isSMDown } = useResponsive();
  const dispatch = useDispatch<AppDispatch>();
  const { id } = useParams<{ id: string }>();
  const [loading, setLoading] = useState<undefined | number>();
  const [
    resultDetails,
    setResultDetails,
  ] = useState<BloodTestResultWithPathway | null>(null);
  const [schedulingInfo, setSchedulingInfo] = useState<
    SchedulingInfo | undefined
  >(undefined);
  const {
    hasFailures,
    hasAbnormalFlag,
    resultsMap,
    isTherapy,
  }: {
    hasFailures: boolean;
    hasAbnormalFlag: boolean;
    isTherapy: boolean;
    resultsMap: [string, BloodTestResultItem[]][] | null;
  } = useMemo(() => {
    if (!resultDetails) {
      return {
        hasFailures: false,
        resultsMap: null,
        hasAbnormalFlag: true,
        isTherapy: false,
      };
    }

    let isFailed = false;
    let isAbnormal = false;

    const r: { [key: string]: BloodTestResultItem[] } = {};
    resultDetails.results.forEach((result) => {
      if (r[result.identifier]) {
        r[result.identifier].push(result);
      } else {
        r[result.identifier] = [result];
      }
      if (result.isFailed) {
        isFailed = true;
      }
      if (result.abnormalFlag) {
        isAbnormal = true;
      }
    });

    let rm: [string, BloodTestResultItem[]][] = Object.entries(r);

    rm = sortBy(rm, (map) => map[0]).map(([identifier, results]) => [
      identifier,
      sortBy(results, "name"),
    ]);

    return {
      hasFailures: isFailed,
      resultsMap: rm,
      hasAbnormalFlag: isAbnormal,
      isTherapy: !!resultDetails.isClientOnTherapy,
    };
  }, [resultDetails]);

  useEffect(() => {
    (async () => {
      setLoading((c) => (c || 0) + 1);
      try {
        const res = await fetchResultDetails(id);
        setResultDetails(res);
      } catch {
        //
      }
      setLoading((c) => (c || 0) - 1);
    })();
  }, [id]);

  const handleDownloadFile = useCallback(async () => {
    try {
      setLoading((c) => (c || 0) + 1);
      const { url, key } = await fetchResultDetailsPDFData({ id });
      await downloadFileWithBlob(url, key);
    } catch {
      //
    } finally {
      setLoading((c) => (c || 0) - 1);
    }
  }, [id]);

  const toggleMCMScheduling = useCallback(() => {
    setSchedulingInfo((current) => {
      if (current) return undefined;
      return resultDetails && resultDetails.schedulingInfo
        ? resultDetails.schedulingInfo
        : undefined;
    });
  }, [resultDetails]);

  const onScheduleDone = useCallback(
    async (actions?: { shouldRefreshContact: boolean }) => {
      setLoading((c) => (c || 0) + 1);
      if (actions?.shouldRefreshContact) {
        await dispatch(userActions.getUserProfile());
      }
      setSchedulingInfo(undefined);
      setResultDetails((r) => {
        if (!r) return r;
        return {
          ...r,
          schedulingInfo: undefined,
        };
      });
      setLoading((c) => (c || 0) - 1);
    },
    [dispatch]
  );

  const showAbnormalFlagTextOnNewLine = useMemo(
    () =>
      !!(
        hasFailures ||
        resultDetails?.doctorConsultationNeeded ||
        resultDetails?.schedulingInfo
      ),
    [
      resultDetails?.doctorConsultationNeeded,
      resultDetails?.schedulingInfo,
      hasFailures,
    ]
  );

  const { date, warning } = useMemo(() => {
    if (!resultDetails) return { date: "", warning: "" };
    return processSampleCollectedDate(resultDetails, {
      notFallback: true,
      isDetailsView: true,
    });
  }, [resultDetails]);

  const shouldShowPDF = useMemo(() => {
    if (!resultDetails) return false;
    return (
      resultDetails.inputMethod !== BloodTestResultInputMethodEnum.manual ||
      !!resultDetails.s3Key
    );
  }, [resultDetails]);

  const classes = useStyles({ showAbnormalFlagTextOnNewLine });

  const showAbnormalFlagText = useMemo(
    () => () => {
      if (hasAbnormalFlag) {
        return (
          <Box className={classes.abnormalFlagText}>
            <Typography variant="body2">
              Your results contain abnormal flags. If you are a BMH Patient then
              your BMH Doctor will be reviewing your results and you can also
              discuss them with your Personal Case Manager. If you are not a BMH
              patient and are concerned by your results please contact your GP.
            </Typography>
          </Box>
        );
      }
      return null;
    },
    [hasAbnormalFlag, classes.abnormalFlagText]
  );

  const topMessagesAndActions = useMemo(() => {
    if (
      resultDetails?.isClientOnTherapy &&
      [
        BluePrintStagesEnum.RESULTS_FAILED,
        BluePrintStagesEnum.PENDING_RETEST_CHARGES_APPROVAL,
        BluePrintStagesEnum.PENDING_RETEST_KIT_INVOICE_CREATION,
        BluePrintStagesEnum.PENDING_RETEST_INVOICE_CREATION,
        BluePrintStagesEnum.PENDING_ITEMS_CREATION,
      ].includes(resultDetails?.pathway?.stage || ("" as BluePrintStagesEnum))
    ) {
      return (
        <Box color="error.dark" textAlign="right">
          <Typography variant="body2">
            Your blood test results contain analysis failures.
          </Typography>
          <Typography variant="body2">
            They are being reviewed by our experts and we will contact you about
            the next steps shortly.
          </Typography>
        </Box>
      );
    }

    if (
      (resultDetails?.pathway?.stage === BluePrintStagesEnum.RESULTS_FAILED &&
        !resultDetails?.isClientOnTherapy) ||
      resultDetails?.pathway?.stage ===
        BluePrintStagesEnum.RETEST_ARRANGEMENTS_CANCELLED
    ) {
      return (
        <Box color="error.dark" textAlign="right">
          <Typography variant="body2">
            Your blood test results have failures.
          </Typography>
          <Typography variant="body2">
            If you need the failed markers to be retested, please order the
            blood tests again and make sure you follow the instructions of blood
            drawing and sending the sample to the lab.
          </Typography>
        </Box>
      );
    }

    if (
      resultDetails?.pathway?.stage ===
        BluePrintStagesEnum.RESULTS_RECEIVED_OK &&
      resultDetails?.offerMCMCall &&
      hasFailures &&
      !resultDetails?.pathway?.retestRequired
    ) {
      return (
        <div>
          <Box>
            <Typography className={classes.messageItalic}>
              Your results have analysis failures, They have been reviewed and
              despite those failures you are ready to continue to your
              consultation.
            </Typography>

            <Box
              color="primary.light"
              display="flex"
              alignItems="center"
              justifyContent="flex-end"
            >
              <Box mr={2}>
                <Typography variant="body2" align="right">
                  {resultDetails?.isClientOnTherapy && (
                    <>
                      Schedule a free consultation to discuss your results and
                      any concerns with your Personal Case Manager.
                    </>
                  )}
                  {!resultDetails?.isClientOnTherapy && (
                    <>
                      As part of our service we are also offering you{" "}
                      <b>
                        a no-obligation free consultation with our Personal Case
                        Manager
                      </b>{" "}
                      who can help you go over the results and recommend
                      possible directions in your journey towards optimal
                      hormone health.
                    </>
                  )}
                </Typography>
              </Box>
              <div>
                <Button
                  className={classes.noWrap}
                  color="primary"
                  onClick={toggleMCMScheduling}
                >
                  {resultDetails?.isClientOnTherapy
                    ? "book a pcm consultation"
                    : "book My Consultation"}
                </Button>
              </div>
            </Box>
          </Box>
          {showAbnormalFlagText()}
        </div>
      );
    }

    if (
      resultDetails?.pathway?.stage ===
        BluePrintStagesEnum.RESULTS_RECEIVED_OK &&
      resultDetails?.doctorConsultationNeeded &&
      hasFailures &&
      !resultDetails?.pathway?.retestRequired
    ) {
      return (
        <div>
          <Box>
            <Typography className={classes.messageItalic}>
              Your results have analysis failures, They have been reviewed and
              despite those failures you are ready to continue to your
              consultation.
            </Typography>
            <Box color="#015465" textAlign="right">
              <Typography variant="body2">
                Your results need to be discussed with a Doctor.
              </Typography>
              <Typography variant="body2">
                Expect to be contacted by BMH to schedule a Doctor&apos;s
                consultation.
              </Typography>
            </Box>
          </Box>
          {showAbnormalFlagText()}
        </div>
      );
    }

    if (
      resultDetails?.pathway?.stage ===
      BluePrintStagesEnum.RETEST_ARRANGEMENTS_MADE
    ) {
      return (
        <>
          <Box color="#015465" textAlign="right">
            <Typography variant="body2">
              Your results have analysis failures.
            </Typography>
            <Typography variant="body2">
              They have been reviewed by our experts and your test needs to be
              repeated before your next consultation.
            </Typography>
            <Typography variant="body2">
              We have emailed you about next steps.
            </Typography>
          </Box>
        </>
      );
    }

    if (!hasFailures) {
      if (resultDetails?.doctorConsultationNeeded) {
        return (
          <div>
            <Box color="#015465" textAlign="right">
              <Typography variant="body2">
                Your results need to be discussed with a Doctor.
              </Typography>
              <Typography variant="body2">
                Expect to be contacted by BMH to schedule a Doctor&apos;s
                consultation.
              </Typography>
            </Box>
            {showAbnormalFlagText()}
          </div>
        );
      }

      if (resultDetails?.schedulingInfo) {
        return (
          <div>
            <Box
              color="primary.light"
              display="flex"
              alignItems="center"
              justifyContent="flex-end"
            >
              <Box mr={2}>
                <Typography variant="body2" align="right">
                  {resultDetails?.isClientOnTherapy && (
                    <>
                      Schedule a free consultation to discuss your results and
                      any concerns with your Personal Case Manager.
                    </>
                  )}
                  {!resultDetails?.isClientOnTherapy && (
                    <>
                      As part of our service we are also offering you{" "}
                      <b>
                        a no-obligation free consultation with our Personal Case
                        Manager
                      </b>{" "}
                      who can help you go over the results and recommend
                      possible directions in your journey towards optimal
                      hormone health.
                    </>
                  )}
                </Typography>
              </Box>
              <div>
                <Button
                  className={classes.noWrap}
                  color="primary"
                  onClick={toggleMCMScheduling}
                >
                  {resultDetails?.isClientOnTherapy
                    ? "book a pcm consultation"
                    : "book My Consultation"}
                </Button>
              </div>
            </Box>
            {showAbnormalFlagText()}
          </div>
        );
      }
    }

    if (hasFailures) {
      return (
        <div>
          <Box color="error.dark" textAlign="right">
            {!isTherapy ? (
              <FailureFlagInfo />
            ) : (
              <>
                <Typography variant="body2">
                  Blood test results have failures and report is being analysed
                  by our experts.
                </Typography>
                <Typography variant="body2">
                  Expect to be contacted by BMH to schedule a retest if needed.
                </Typography>
              </>
            )}
          </Box>
          {showAbnormalFlagText()}
        </div>
      );
    }

    return null;
  }, [
    classes,
    hasFailures,
    resultDetails,
    toggleMCMScheduling,
    showAbnormalFlagText,
    isTherapy,
  ]);

  return (
    <>
      <Loader open={loading !== 0} />
      <MemoSchedulingToolDialog
        schedulingInfo={{
          ...schedulingInfo,
          purpose: ConsultationPurposeEnum.BLOOD_TEST_RESULT,
          duration: getDurationFromPurpose(
            ConsultationPurposeEnum.BLOOD_TEST_RESULT
          ),
        }}
        onClose={toggleMCMScheduling}
        onScheduleDone={onScheduleDone}
        open={!!schedulingInfo}
      />
      {resultDetails && isSMDown && (
        <ListView
          resultDetails={resultDetails}
          resultsMap={resultsMap}
          hasFailures={hasFailures}
          hasAbnormalFlag={hasAbnormalFlag}
          handleDownloadFile={handleDownloadFile}
          shouldShowPDF={shouldShowPDF}
          toggleMCMScheduling={toggleMCMScheduling}
        />
      )}
      {resultDetails && isMDUp && (
        <>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            pb={3}
          >
            <Box display="flex" alignItems="center" mr={2}>
              <BackButton color="primary" />
              <Typography noWrap variant="h3" component="h1" color="primary">
                Blood Test Result
              </Typography>
            </Box>
            {topMessagesAndActions}
          </Box>
          <Paper variant="outlined" className={classes.paper}>
            <Typography
              color="primary"
              variant="h6"
              className={classes.resultName}
            >
              {resultDetails.name}
            </Typography>
            <Box display="flex" color="primary.light">
              {resultDetails.testProfiles &&
                resultDetails.testProfiles.length > 0 && (
                  <Box flex={1} data-testid="blood-test-details/test-profiles">
                    <Typography variant="subtitle1" component="h2" paragraph>
                      Blood Test Profiles
                    </Typography>
                    <Box display="flex" alignItems="center" flexWrap="wrap">
                      {resultDetails.testProfiles.map((profile) => (
                        <Box
                          key={profile.id}
                          display="flex"
                          alignItems="center"
                          mb={1}
                          mr={2}
                        >
                          {resultDetails.bloodTakingOption && (
                            <Box
                              className={classes.takingOptionIcon}
                              display="inline-flex"
                              alignItems="center"
                              height="16px"
                              width="16px"
                              mr={1}
                            >
                              {resultDetails.bloodTakingOption ===
                              BloodTakingOptionEnum.fingerprick ? (
                                <FingerIcon />
                              ) : (
                                <SyringeIcon />
                              )}
                            </Box>
                          )}
                          <Typography variant="body2" color="textPrimary">
                            {profile.name}
                          </Typography>
                        </Box>
                      ))}
                    </Box>
                  </Box>
                )}
              <Box flex={1}>
                <Typography component="h2" paragraph>
                  {resultDetails.labName}
                </Typography>
                <Box display="flex" alignItems="center" mr={2} mb={1}>
                  <Box
                    mr={1}
                    display="inline-flex"
                    alignItems="center"
                    fontSize="16px"
                  >
                    <CalendarTodayOutlined fontSize="inherit" />
                  </Box>
                  <DataWithWarning warning={warning}>
                    <Typography color="textPrimary">
                      Collected Sample: {date}
                    </Typography>
                  </DataWithWarning>
                </Box>
                <Box display="flex" alignItems="center" flexWrap="wrap">
                  {resultDetails.sampleReceivedByLabOn && (
                    <Box display="flex" alignItems="center" mb={1} mr={2}>
                      <Box
                        mr={1}
                        display="inline-flex"
                        alignItems="center"
                        fontSize="16px"
                      >
                        <CalendarTodayOutlined fontSize="inherit" />
                      </Box>
                      <Box>
                        <Typography variant="body2" color="textPrimary">
                          Received Sample:{" "}
                          {format(
                            new Date(resultDetails.sampleReceivedByLabOn),
                            DEFAULT_DATE_FORMAT
                          )}
                        </Typography>
                      </Box>
                    </Box>
                  )}
                  <Box display="flex" alignItems="center" mb={1} mr={2}>
                    <Box
                      mr={1}
                      display="inline-flex"
                      alignItems="center"
                      fontSize="16px"
                    >
                      <CalendarTodayOutlined fontSize="inherit" />
                    </Box>
                    <Typography variant="body2" color="textPrimary">
                      Result Report:{" "}
                      {format(
                        new Date(resultDetails.resultReportedOn),
                        DEFAULT_DATE_FORMAT
                      )}
                    </Typography>
                  </Box>
                </Box>
              </Box>
              {shouldShowPDF && (
                <Box>
                  <PdfDownloadButton handleDownloadFile={handleDownloadFile} />
                </Box>
              )}
            </Box>
            <Divider className={classes.divider} />
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell />
                    {TABLE_HEADERS.map((header, i) => (
                      <TableCell
                        key={header}
                        className={`${i === 0 ? `${classes.firstCell} ` : ""}${
                          classes.tableHeader
                        }`}
                      >
                        {header}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {resultsMap &&
                    resultsMap.map(([identifier, results]) => (
                      <Fragment key={identifier}>
                        {!!identifier && (
                          <TableRow>
                            <TableCell />
                            <TableCell
                              className={classes.firstCell}
                              colSpan={5}
                              data-testid="blood-test-result-details/identifier"
                            >
                              <Typography variant="subtitle2" component="h3">
                                {identifier}
                              </Typography>
                            </TableCell>
                          </TableRow>
                        )}
                        {results.map((result) => {
                          let borderLeftColor: string | undefined;
                          let title = "";
                          if (
                            result.abnormalFlag &&
                            result.abnormalFlag.highlight
                          ) {
                            title = `Abnormal flag: ${
                              result.abnormalFlag.flag
                            }${
                              result.abnormalFlag.meaning
                                ? ` (${result.abnormalFlag.meaning})`
                                : ""
                            }`;
                            borderLeftColor = theme.palette.error.light;
                          } else if (result.isFailed) {
                            title = `Failed code presents`;
                            borderLeftColor = theme.palette.warning.light;
                          }

                          return (
                            <React.Fragment key={result.id}>
                              <TableRow data-testid="blood-test-result-details/data-row">
                                <Tooltip title={title} arrow>
                                  <TableCell
                                    style={{
                                      borderLeft: borderLeftColor
                                        ? `12px solid ${borderLeftColor}`
                                        : undefined,
                                      padding: "8px 0 8px 8px",
                                      width: "38px",
                                    }}
                                  >
                                    {title && (
                                      <Box
                                        display="flex"
                                        alignItems="center"
                                        color="text.secondary"
                                      >
                                        <InfoOutlined />
                                      </Box>
                                    )}
                                  </TableCell>
                                </Tooltip>
                                <TableCell className={classes.firstCell}>
                                  {result.name}
                                </TableCell>
                                <TableCell>{result.value}</TableCell>
                                <TableCell>{result.normalRange}</TableCell>
                                <TableCell>{result.unit}</TableCell>
                                <TableCell>
                                  {result.abnormalFlag?.meaning ||
                                    result.abnormalFlag?.flag ||
                                    ""}
                                </TableCell>
                              </TableRow>
                              {!!result.notes && (
                                <TableRow data-testid="blood-test-result-details/notes-row">
                                  <TableCell />
                                  <TableCell
                                    colSpan={5}
                                    className={classes.note}
                                  >
                                    {result.notes}
                                  </TableCell>
                                </TableRow>
                              )}
                            </React.Fragment>
                          );
                        })}
                        <TableRow>
                          <TableCell className={classes.spacingCell} />
                        </TableRow>
                      </Fragment>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </>
      )}
    </>
  );
};
