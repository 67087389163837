import React, { useCallback } from "react";
import {
  Typography,
  Box,
  Select,
  MenuItem,
  FormControl,
  BoxProps,
  makeStyles,
  Hidden,
} from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";

import { DEFAULT_ROWS_PER_PAGE_OPTIONS } from "configs";
import { useResponsive } from "hooks";

const useStyle = makeStyles(
  ({ spacing: s, breakpoints: b, typography: typ }) => ({
    wrapper: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      paddingTop: s(1),
      paddingBottom: s(2),

      [b.down("sm")]: {
        flexDirection: "column",
        justifyContent: "center",
      },
    },
    rowWrapper: {
      display: "flex",
      alignItems: "center",
      marginRight: s(2),

      [b.down("sm")]: {
        flexDirection: "column",
        justifyContent: "center",
      },
    },
    row: {
      display: "flex",
      alignItems: "center",
    },
    rowTitle: {
      marginRight: s(1),
    },
    rowSelect: {
      fontSize: typ.body1.fontSize,
      fontWeight: typ.body1.fontWeight,
    },
    range: {
      marginLeft: s(2),

      [b.down("sm")]: {
        marginLeft: 0,
        marginBottom: s(1),
      },
    },
    pagination: {
      [b.down("sm")]: {
        marginBottom: s(2),
        marginTop: s(2),
      },
    },
  })
);

export interface TablePaginationBoxProps extends BoxProps {
  total: number;
  page: number;
  rowsPerPage: number;
  rowsPerPageOptions?: number[];
  changePage: (nextPage: number) => any;
  changeRowsPerPage: (nextNumber: number) => any;
}

export const TablePaginationBox: React.FC<TablePaginationBoxProps> = ({
  total,
  page,
  rowsPerPage,
  rowsPerPageOptions,
  changePage,
  changeRowsPerPage,
  ...boxProps
}) => {
  const { isSMDown } = useResponsive();

  const from = rowsPerPage * (page - 1) + 1;
  const to = Math.min(rowsPerPage * page, total);
  const totalPages = Math.ceil(total / rowsPerPage);

  const onPageClick = useCallback(
    (_nouse: any, nextPage: number) => {
      if (nextPage !== page) {
        changePage(nextPage);
      }
    },
    [page, changePage]
  );

  const onRowsPerPageSelect = useCallback(
    (e: any) => {
      const value = e.target.value as number;

      if (value !== rowsPerPage) {
        changeRowsPerPage(value);
      }
    },
    [rowsPerPage, changeRowsPerPage]
  );

  const options = rowsPerPageOptions || DEFAULT_ROWS_PER_PAGE_OPTIONS;
  const classes = useStyle();

  if (!total) return null;

  return (
    <Box
      className={classes.wrapper}
      data-testid="table-pagination"
      {...boxProps}
    >
      <Box order={isSMDown ? 1 : 0} className={classes.rowWrapper}>
        <Box className={classes.row}>
          <Box className={classes.rowTitle}>
            <Typography color="textSecondary" variant="body1">
              Rows per page
            </Typography>
          </Box>
          <FormControl>
            <Select
              value={rowsPerPage}
              onChange={onRowsPerPageSelect}
              variant="standard"
              color="secondary"
              className={classes.rowSelect}
            >
              {options.map((rows) => (
                <MenuItem key={rows} value={rows}>
                  {rows}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <Hidden smDown>
          <Box className={classes.range}>
            <Typography>
              {from} - {to} of {total}
            </Typography>
          </Box>
        </Hidden>
      </Box>
      <Pagination
        count={totalPages}
        page={page}
        color="primary"
        onChange={onPageClick}
        size="large"
        className={classes.pagination}
      />
    </Box>
  );
};
