import React, { memo } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { CircularProgress, Backdrop } from "@material-ui/core";

interface StyleProps {
  absolute?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backdrop: ({ absolute }: StyleProps) => ({
      zIndex: theme.zIndex.drawer + 1,
      background: "rgba(255, 255, 255, 0.7)",
      position: absolute ? "absolute" : undefined,
    }),
  })
);

interface Props {
  open: boolean;
  absolute?: boolean;
}

const Loader: React.FC<Props> = ({ open, absolute }) => {
  const classes = useStyles({ absolute });

  return (
    <Backdrop className={classes.backdrop} open={open} data-testid="loader">
      <CircularProgress color="primary" />
    </Backdrop>
  );
};

export default memo(Loader);
