import React, { memo, useCallback } from "react";
import { Button, makeStyles } from "@material-ui/core";

import { COLORS } from "../SchedulingTool.styles";
import { DailyAvailableSlot, formatSlotTime } from "../SchedulingTool.helpers";

const useStyle = makeStyles(({ palette: p, spacing: s }) => ({
  slot: {
    width: "100%",
    background: p.common.white,
    marginBottom: s(1),
  },
  generalSlot: {
    border: `1px solid ${COLORS.teal2}`,
    color: COLORS.teal,
    "&:hover": {
      background: COLORS.violetHover,
    },
  },
  targetSlot: {
    border: `1px solid ${COLORS.blueMedium}`,
    color: p.secondary.dark,
    "&:hover": {
      background: COLORS.blueLight,
    },
  },
  otherSlot: {
    border: `1px solid ${COLORS.violetLight}`,
    color: COLORS.violetNormal,
    "&:hover": {
      background: COLORS.violetHover,
    },
  },
}));

interface TimeSlotProps {
  isTargetMCM: boolean;
  slot: DailyAvailableSlot;
  onSlotSelect: (s: DailyAvailableSlot) => any;
}

const TimeSlot: React.FC<TimeSlotProps> = ({
  isTargetMCM,
  onSlotSelect,
  slot,
}) => {
  const { start, end } = slot;

  const onClick = useCallback(() => {
    onSlotSelect(slot);
  }, [onSlotSelect, slot]);

  const classes = useStyle();

  let className = classes.slot;
  if (isTargetMCM) {
    className += ` ${classes.targetSlot}`;
  } else {
    className += ` ${classes.otherSlot}`;
  }

  return (
    <Button className={className} onClick={onClick}>
      {formatSlotTime(start)} - {formatSlotTime(end, true)}
    </Button>
  );
};

export default memo(TimeSlot);
