import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  createStyles,
  Divider,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { ROUTES } from "configs";
import { AccountCircle } from "@material-ui/icons";

import { pkAuthSelectors } from "@deep-consulting-solutions/auth-web";
import { Invoice } from "@deep-consulting-solutions/invoices-web";
import Loader from "components/Loader";
import { useResponsive } from "hooks";
import { schedulingActions } from "redux/scheduling";
import { AppDispatch } from "redux/store";

import { fetchBloodTest, fetchInvoices } from "./requests";
import { BloodTest } from "./BloodTest";
import { Invoices } from "./Invoices";
import { UpcomingMCMCall } from "./UpcomingMCMCall";

const useStyles = makeStyles(({ spacing: s, palette: p, breakpoints: b }) =>
  createStyles({
    wrapper: {
      [b.down("xs")]: {
        margin: "0 -16px",
      },
    },
    header: {
      [b.down("xs")]: {
        paddingTop: s(3),
      },
    },
    title: {
      color: p.primary.light,
    },
  })
);

export const DashboardPage = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { isSMDown } = useResponsive();
  const classes = useStyles();

  const [loading, setLoading] = useState(0);
  const [bloodTest, setBloodTest] = useState<{
    id: string;
    name: string;
    sampleCollectedOn: Date;
  } | null>(null);
  const [unpaidInvoices, setUnpaidInvoices] = useState<Invoice[] | null>(null);
  const [overdueInvoices, setOverdueInvoices] = useState<Invoice[] | null>(
    null
  );
  const [timeZone, setTimeZone] = useState("");

  useEffect(() => {
    (async () => {
      setLoading((count) => count + 1);
      try {
        const res = await fetchBloodTest();
        setBloodTest(res);
        setLoading((count) => count - 1);
      } catch {
        setLoading((count) => count - 1);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      setLoading((count) => count + 1);
      try {
        const res = await fetchInvoices();
        setOverdueInvoices(res.overdue);
        setUnpaidInvoices(res.unpaid);
        setTimeZone(res.timeZone);
        setLoading((count) => count - 1);
      } catch {
        setLoading((count) => count - 1);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      setLoading((count) => count + 1);
      await dispatch(schedulingActions.fetchUpcomingMCM());
      setLoading((count) => count - 1);
    })();
  }, [dispatch]);

  const contact = useSelector(pkAuthSelectors.authContact);
  const userName = `${contact?.firstName ? `${contact.firstName} ` : ""}${
    contact?.lastName ?? ""
  }`;

  return (
    <>
      <Loader open={loading > 0} />
      <Box display="flex" alignItems="flex-end" justifyContent="space-between">
        <Box display="flex" alignItems="center" className={classes.header}>
          <Box
            fontSize={isSMDown ? "40px" : "60px"}
            display="inline-flex"
            alignItems="center"
            mr={1}
            color="primary.light"
          >
            <AccountCircle color="inherit" fontSize="inherit" />
          </Box>
          <Box>
            <Typography
              variant={isSMDown ? "body2" : "subtitle1"}
              component="p"
            >
              Welcome,
            </Typography>
            <Typography
              color="primary"
              variant={isSMDown ? "subtitle2" : "h4"}
              component="h1"
            >
              {userName}
            </Typography>
          </Box>
        </Box>
        {!isSMDown && (
          <div>
            <Button
              color="primary"
              variant="text"
              component={Link}
              to={ROUTES.profile.path}
            >
              View Profile
            </Button>
          </div>
        )}
      </Box>
      <Box mt={1}>
        <Divider />
      </Box>
      <Box className={classes.wrapper}>
        <BloodTest bloodTest={bloodTest} loading={loading > 0} />

        <UpcomingMCMCall loading={loading > 0} />

        {((overdueInvoices && overdueInvoices.length > 0) ||
          (unpaidInvoices && unpaidInvoices.length > 0)) && (
          <Invoices
            timeZone={timeZone}
            overdueInvoices={overdueInvoices}
            unpaidInvoices={unpaidInvoices}
          />
        )}
      </Box>
    </>
  );
};
