import React from "react";
import {
  AppBar,
  Toolbar,
  styled,
  Box,
  createStyles,
  makeStyles,
  Container,
} from "@material-ui/core";
import { Link } from "react-router-dom";

import Logo from "images/bmh-logo.png";
import { pkAuthSelectors } from "@deep-consulting-solutions/auth-web";
import { useSelector } from "react-redux";
import { ROUTES } from "configs";

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  background: theme.palette.common.white,
  border: `2px solid ${theme.palette.primary.main}`,
}));

const useStyles = makeStyles((theme) =>
  createStyles({
    image: {
      height: "56px",
      [theme.breakpoints.up("sm")]: {
        height: "64px",
      },
    },
  })
);

const Header: React.FC = ({ children }) => {
  const isAuthorised = useSelector(pkAuthSelectors.isAuthorised);
  const classes = useStyles();
  return (
    <StyledAppBar position="static">
      <Container disableGutters maxWidth="xl">
        <Toolbar>
          <Box className={classes.image} mr={2} height="100%">
            <Link to={isAuthorised ? ROUTES.dashboard.path : ROUTES.login.path}>
              <img
                className={classes.image}
                src={Logo}
                alt="Balance My Hormones"
              />
            </Link>
          </Box>
          <Box ml="auto">{children}</Box>
        </Toolbar>
      </Container>
    </StyledAppBar>
  );
};

export default Header;
