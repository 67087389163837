import React from "react";
import { makeStyles, Tooltip } from "@material-ui/core";
import { ErrorOutline as ErrorIcon } from "@material-ui/icons";

const useStyles = makeStyles(({ spacing: s, palette: p }) => ({
  wrapper: {
    display: "flex",
    alignItems: "center",
  },
  errorIcon: {
    marginLeft: s(0.5),
    color: p.error.light,
  },
  tooltip: {
    textAlign: "center",
  },
}));

interface Props {
  warning?: string;
}

export const DataWithWarning: React.FC<Props> = ({ warning, children }) => {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      {children}
      {warning && (
        <Tooltip title={warning} classes={{ tooltip: classes.tooltip }}>
          <ErrorIcon className={classes.errorIcon} />
        </Tooltip>
      )}
    </div>
  );
};
