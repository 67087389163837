import React, { ReactNode } from "react";
import {
  ProviderContext,
  OptionsObject,
  SharedProps,
  SnackbarProvider,
} from "notistack";

import NotificationDisplay from "./NotificationDisplay";

export const notifications = (() => {
  let noti: ProviderContext | null = null;

  const display = ({
    message,
    variant,
    options,
  }: {
    message: ReactNode;
    variant?: SharedProps["variant"];
    options?: OptionsObject;
  }) => {
    noti?.enqueueSnackbar(message, {
      content: (key) => (
        <NotificationDisplay
          variant={variant}
          message={message}
          messageKey={key}
          notiRef={noti}
        />
      ),
      ...(options || {}),
    });
  };

  return {
    setRef: (ref: ProviderContext | null): void => {
      noti = ref;
    },
    getRef: (): ProviderContext | null => noti,
    notify: (message: ReactNode, options?: OptionsObject): void => {
      display({ message, options });
    },
    notifyError: (message: ReactNode, options: OptionsObject = {}): void => {
      display({ message, options, variant: "error" });
    },
    notifySuccess: (message: ReactNode, options: OptionsObject = {}): void => {
      display({ message, options, variant: "success" });
    },
    notifyInfo: (message: ReactNode, options: OptionsObject = {}): void => {
      display({ message, options, variant: "info" });
    },
    notifyWarning: (message: ReactNode, options: OptionsObject = {}): void => {
      display({ message, options, variant: "warning" });
    },
  };
})();

export const NotificationProvider: React.FC = ({ children }) => {
  return (
    <SnackbarProvider
      ref={notifications.setRef}
      maxSnack={2}
      autoHideDuration={4000}
      anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
    >
      {children}
    </SnackbarProvider>
  );
};
