import React, { useState, useCallback, useMemo } from "react";
import {
  Typography,
  makeStyles,
  Grid,
  Button,
  Tooltip,
  Divider,
} from "@material-ui/core";
import { useSelector } from "react-redux";
import { MCMCall } from "@deep-consulting-solutions/bmh-constants";

import { useBookPCMConsultation, useResponsive } from "hooks";
import { schedulingSelectors } from "redux/scheduling";
import {
  CancelMcmCallDialog,
  ReschedulingDialog,
} from "components/SchedulingTool";

import {
  formatDateToDisplay,
  formatTimeToDisplay,
  convertTimeFromUTCToAnotherTimezone,
} from "helpers";
import { Add } from "@material-ui/icons";
import { UpcomingMCMCallDesktop } from "./UpcomingMCMCall.Desktop";
import { UpcomingMCMCallMobile } from "./UpcomingMCMCall.Mobile";

const useStyle = makeStyles(({ palette: p, breakpoints: b, spacing: s }) => ({
  wrapper: {
    marginTop: s(2),
    [b.up("md")]: {
      marginTop: s(4),
    },
  },
  title: {
    color: p.primary.light,
  },
  noCall: {
    color: p.grey[400],
    [b.down("sm")]: {
      textAlign: "center",
    },
  },
  divider: {
    marginBottom: s(2),
  },
  titleGroup: {
    padding: s(2, 0),
    [b.down("xs")]: {
      padding: s(2),
    },
  },
}));

interface UpcomingMCMCallProps {
  loading: boolean;
}

export const UpcomingMCMCall: React.FC<UpcomingMCMCallProps> = ({
  loading,
}) => {
  const {
    handleOpenBookPCMConsultationDialog,
    canOpenBookPCMConsultationDialog,
  } = useBookPCMConsultation();
  const { isSMDown } = useResponsive();
  const classes = useStyle();

  const upcomingMCM = useSelector(schedulingSelectors.getUpcomingMCM);
  const schedulingInfo = useSelector(schedulingSelectors.getSchedulingInfo);

  const [isOpenCancelMcmCall, setIsOpenCancelMcmCall] = useState(false);
  const [reschedulingMCM, setReschedulingMCM] = useState<MCMCall | null>(null);

  const handleOpenCancelMcmCall = useCallback(() => {
    setIsOpenCancelMcmCall(true);
  }, []);

  const handleCloseCancelMcmCall = useCallback(() => {
    setIsOpenCancelMcmCall(false);
  }, []);

  const openReschedulingDialog = useCallback(() => {
    setReschedulingMCM(upcomingMCM);
  }, [upcomingMCM]);

  const closeReschedulingDialog = useCallback(() => {
    setReschedulingMCM(null);
  }, []);

  const [startDate, startTime] = useMemo(() => {
    if (!upcomingMCM || !schedulingInfo) return ["", ""];
    return [
      formatDateToDisplay(
        convertTimeFromUTCToAnotherTimezone(
          upcomingMCM.start,
          schedulingInfo.clientTZ || ""
        )
      ),
      formatTimeToDisplay(
        convertTimeFromUTCToAnotherTimezone(
          upcomingMCM.start,
          schedulingInfo.clientTZ || ""
        ),
        true
      ),
    ];
  }, [schedulingInfo, upcomingMCM]);

  const { title, buttonTitle } = useMemo(() => {
    return {
      title: `Upcoming ${
        isSMDown ? "PCM" : "Personal Case Manager"
      } Consultation`,
      buttonTitle: isSMDown ? "Book" : "Book PCM Consultation",
    };
  }, [isSMDown]);

  if (loading) return null;

  return (
    <div className={classes.wrapper}>
      <Grid
        container
        justify="space-between"
        alignItems="center"
        spacing={2}
        className={classes.titleGroup}
      >
        <Grid item>
          <Typography className={classes.title} variant="h6" component="h2">
            {title}
          </Typography>
        </Grid>
        <Grid item>
          <Tooltip
            title={
              <Typography variant="caption">
                PCM &apos;Personal Case Manager&apos; is one of our experts who
                can help you in your journey towards optimal hormone health.
              </Typography>
            }
            arrow
          >
            <Button
              disabled={!canOpenBookPCMConsultationDialog}
              onClick={handleOpenBookPCMConsultationDialog}
              color="primary"
              startIcon={isSMDown ? <Add /> : undefined}
            >
              {isSMDown ? (
                <span
                  style={{
                    lineHeight: 0,
                    padding: 0,
                    margin: 0,
                  }}
                >
                  {buttonTitle}
                </span>
              ) : (
                buttonTitle
              )}
            </Button>
          </Tooltip>
        </Grid>
      </Grid>
      {(!upcomingMCM || !schedulingInfo) && (
        <>
          <Divider className={classes.divider} />
          <Typography variant="body1" className={classes.noCall}>
            No Upcoming PCM Consultation
          </Typography>
        </>
      )}
      {!!upcomingMCM && !!schedulingInfo && (
        <>
          {!isSMDown && (
            <UpcomingMCMCallDesktop
              mcmCall={upcomingMCM}
              handleOpenCancelMcmCall={handleOpenCancelMcmCall}
              openReschedulingDialog={openReschedulingDialog}
              startDate={startDate}
              startTime={startTime}
              tz={schedulingInfo.clientTZ}
            />
          )}
          {isSMDown && (
            <UpcomingMCMCallMobile
              mcmCall={upcomingMCM}
              handleOpenCancelMcmCall={handleOpenCancelMcmCall}
              openReschedulingDialog={openReschedulingDialog}
              startDate={startDate}
              startTime={startTime}
              tz={schedulingInfo.clientTZ}
            />
          )}
        </>
      )}
      <CancelMcmCallDialog
        open={isOpenCancelMcmCall}
        onClose={handleCloseCancelMcmCall}
      />
      {!!upcomingMCM && (
        <ReschedulingDialog
          meeting={reschedulingMCM}
          schedulingInfo={schedulingInfo}
          onClose={closeReschedulingDialog}
        />
      )}
    </div>
  );
};
