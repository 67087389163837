import React, { useCallback, useEffect } from "react";
import { useResponsive } from "@deep-consulting-solutions/auth-web";
import {
  Box,
  Button,
  Grid,
  Hidden,
  Paper,
  styled,
  Typography,
} from "@material-ui/core";
import { useHistory, useLocation } from "react-router-dom";
import { ROUTES } from "configs";
import { useUpdateStaticLastLogin } from "@deep-consulting-solutions/portals-web";
import { useDispatch } from "react-redux";
import { AppDispatch } from "redux/store";
import { activationActions } from "redux/activation";

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4, 3),
  maxWidth: "450px",
  margin: "0 auto",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  [theme.breakpoints.up("md")]: {
    marginRight: 0,
  },
}));

export const ActivationWelcomePage = () => {
  const { isSMDown } = useResponsive();
  const textAlign = isSMDown ? "center" : "left";

  const dispatch = useDispatch<AppDispatch>();
  const location = useLocation<{ isActivating: boolean }>();
  const history = useHistory();
  const onContinueClick = useCallback(() => {
    history.push(ROUTES.activationProfileInfo.path, { isActivating: true });
  }, [history]);

  useEffect(() => {
    if (!location.state || !location.state.isActivating) {
      history.replace(ROUTES.profile.path);
    } else {
      dispatch(activationActions.resetActivationData());
    }
  }, [dispatch, history, location.state]);

  useUpdateStaticLastLogin("Customer");

  return (
    <Grid container spacing={4}>
      <Hidden xsDown>
        <Grid item xs={12} md={6}>
          <Box mb={3}>
            <Typography
              color="primary"
              variant="h2"
              component="h1"
              align={textAlign}
            >
              Welcome to Bespoke UK and EU TRT & HRT Services
            </Typography>
          </Box>
          <Typography variant="subtitle1" component="p" align={textAlign}>
            Balance My Hormones guides you through the journey of hormone
            rebalancing by facilitating your journey with doctors specialising
            in hormone deficiencies who are difficult to access through
            traditional NHS and private doctor practices.
          </Typography>
        </Grid>
      </Hidden>

      <Grid item xs={12} sm>
        <StyledPaper variant="outlined">
          <Box mb={3}>
            <Typography
              color="primary"
              variant="h3"
              component="h2"
              align="center"
            >
              Congratulations
            </Typography>
          </Box>
          <Box mb={3}>
            <Typography variant="subtitle1" component="p" align="center">
              Your account in now active. Login to complete your registration
              and manage your account.
            </Typography>
          </Box>
          <Button color="primary" onClick={onContinueClick}>
            continue
          </Button>
        </StyledPaper>
      </Grid>
    </Grid>
  );
};
