import React from "react";
import {
  Box,
  Button,
  createStyles,
  Grid,
  makeStyles,
  Paper,
  Typography,
  Hidden,
  IconButton,
} from "@material-ui/core";
import { CalendarTodayOutlined, Today, ChevronRight } from "@material-ui/icons";
import { DEFAULT_DATE_FORMAT } from "configs";
import { format } from "date-fns";
import { getResultDetailsRoute } from "pages/BloodTestsPage/helpers";
import { Link } from "react-router-dom";
import { useResponsive } from "hooks";

export const useCardStyles = makeStyles(
  ({ spacing: s, palette: p, breakpoints: b, shadows }) => ({
    card: {
      display: "flex",
      textDecoration: "none",
      alignItems: "center",
      color: p.text.primary,
      backgroundColor: p.background.paper,
      "&:hover": {
        cursor: "pointer",
        backgroundColor: p.action.hover,
        textDecoration: "none",
      },
      [b.up("sm")]: {
        width: `calc(50% - ${s(1)}px)`,
        marginTop: s(2),
        boxShadow: shadows[2],
        padding: s(2),
        borderRadius: s(1),

        "&:nth-child(odd)": {
          marginRight: s(2),
        },
      },
      [b.only("xs")]: {
        width: "100%",
        padding: s(2, 0, 2, 2),
        borderBottom: `1px solid ${p.divider}`,
        borderTop: `1px solid ${p.divider}`,
      },
    },
  })
);

interface ResultListItemProps {
  bloodTest: {
    id: string;
    name: string;
    sampleCollectedOn: Date;
  };
}

const BloodTestCard = ({ bloodTest }: ResultListItemProps) => {
  const classes = useCardStyles();
  return (
    <Link
      className={classes.card}
      to={getResultDetailsRoute(bloodTest.id)}
      data-testid="blood-test-card"
    >
      <Box
        color="primary.light"
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        flex="1"
        height="100%"
        mr={1}
      >
        <Typography component="h2" variant="h5" paragraph>
          {bloodTest.name}
        </Typography>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box display="flex" alignItems="center">
            <Box mr={1} display="inline-flex" alignItems="center">
              <Today />
            </Box>
            <Typography color="textPrimary">
              {format(
                new Date(bloodTest.sampleCollectedOn),
                DEFAULT_DATE_FORMAT
              )}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Hidden smUp>
        <Box>
          <IconButton color="primary" aria-label="details">
            <ChevronRight />
          </IconButton>
        </Box>
      </Hidden>
    </Link>
  );
};

const useStyles = makeStyles(({ palette: p, spacing: s, breakpoints: b }) =>
  createStyles({
    wrapper: {
      marginTop: s(2),
      [b.up("md")]: {
        marginTop: s(4),
      },
    },
    title: {
      color: p.primary.light,
      marginBottom: s(2),
      [b.down("xs")]: {
        padding: s(0, 2),
      },
      [b.only("sm")]: {
        marginBottom: 0,
      },
    },
  })
);

interface BloodTestProps {
  bloodTest: {
    id: string;
    name: string;
    sampleCollectedOn: Date;
  } | null;
  loading: boolean;
}

export const BloodTest = ({ bloodTest, loading }: BloodTestProps) => {
  const { isSMDown } = useResponsive();
  const classes = useStyles();
  return (
    <Box className={classes.wrapper}>
      <Typography className={classes.title} variant="h6" component="h2">
        Last Blood Test
      </Typography>

      {!loading && !bloodTest && (
        <Paper variant="outlined">
          <Box p={3}>
            <Typography color="textSecondary" align="center">
              You do not have any blood tests.
            </Typography>
          </Box>
        </Paper>
      )}

      {bloodTest && (
        <>
          {isSMDown ? (
            <BloodTestCard bloodTest={bloodTest} />
          ) : (
            <Paper variant="outlined">
              <Box p={3}>
                <Grid container spacing={6} alignItems="center">
                  <Grid style={{ flex: 1 }} item>
                    <Typography variant="subtitle1" component="h3">
                      {bloodTest.name}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Box
                      display="flex"
                      flexDirection="column"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Box
                        display="flex"
                        alignItems="center"
                        color="text.secondary"
                        mb={1}
                      >
                        <CalendarTodayOutlined
                          style={{ marginRight: "4px", fontSize: "16px" }}
                        />
                        <Typography variant="body2">Test date:</Typography>
                      </Box>
                      <Typography>
                        {format(
                          new Date(bloodTest.sampleCollectedOn),
                          DEFAULT_DATE_FORMAT
                        )}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item>
                    <Box>
                      <Button
                        color="primary"
                        component={Link}
                        to={getResultDetailsRoute(bloodTest.id)}
                      >
                        View Results
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Paper>
          )}
        </>
      )}
    </Box>
  );
};
