// FIXME: remove when all links set
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import {
  Typography,
  Container,
  Grid,
  Box,
  Divider,
  Link,
} from "@material-ui/core";

import { useResponsive } from "hooks";
import { BMH_LINKS } from "configs";
import moment from "moment-timezone";

const Footer: React.FC = () => {
  const { isXSDown } = useResponsive();

  return (
    <Container>
      <Divider />
      <Box py={4} px={1}>
        <Grid
          container
          alignItems="center"
          wrap={isXSDown ? "wrap" : "nowrap"}
          justify="space-between"
        >
          <Grid
            item
            xs={12}
            sm="auto"
            style={{ textAlign: isXSDown ? "center" : "left" }}
          >
            <Typography variant="caption" color="textSecondary">
              Copyright © {moment().year()} Balance My Hormones. All Rights
              Reserved.
            </Typography>
          </Grid>

          <Grid item xs={12} sm="auto">
            <Grid container justify={isXSDown ? "center" : undefined}>
              <Grid item xs="auto">
                <Link
                  href={BMH_LINKS.PRIVACY_POLICY}
                  color="textSecondary"
                  variant="caption"
                  target="_blank"
                >
                  Privacy Policy
                </Link>
              </Grid>
              <Grid item>
                <Divider orientation="vertical" />
              </Grid>
              <Grid item xs="auto">
                <Link
                  href={BMH_LINKS.DATA_PROTECTION_POLICY}
                  color="textSecondary"
                  variant="caption"
                  target="_blank"
                >
                  Data Protection & Privacy Policy
                </Link>
              </Grid>
              <Grid item>
                <Divider orientation="vertical" />
              </Grid>
              <Grid item xs="auto">
                <Link
                  href={BMH_LINKS.STATE_OF_PURPOSE}
                  color="textSecondary"
                  variant="caption"
                  target="_blank"
                >
                  Statement of Purpose
                </Link>
              </Grid>
              <Grid item>
                <Divider orientation="vertical" />
              </Grid>
              <Grid item xs="auto">
                <Link
                  href={BMH_LINKS.COOKIE_POLICY}
                  color="textSecondary"
                  variant="caption"
                  target="_blank"
                >
                  Cookie Policy
                </Link>
              </Grid>
              <Grid item>
                <Divider orientation="vertical" />
              </Grid>
              <Grid item xs="auto">
                <Link
                  href={BMH_LINKS.COMPLAINTS_AND_FEEDBACK}
                  color="textSecondary"
                  variant="caption"
                  target="_blank"
                >
                  Complaints and Feedback
                </Link>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default Footer;
