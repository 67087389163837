import React from "react";
import {
  makeStyles,
  Dialog,
  DialogProps,
  DialogContent,
} from "@material-ui/core";

import { useResponsive } from "hooks";
import { DialogTitleWithCloseBtn } from "components/DialogTitleWithCloseBtn";

export const COLORS = {
  blueNormal: "rgba(17, 180, 212, 0.4)",
  blueLight: "rgba(11, 190, 227, 0.1)",
  blueMedium: "rgba(11, 190, 227, 0.5)",
  violetNormal: "rgba(126, 159, 206, 1)",
  violetLight: "rgba(29, 57, 97, 0.5)",
  violetHover: "rgba(126, 159, 206, 0.2)",
  teal: "rgba(126, 159, 206, 1)",
  teal2: "rgba(29, 57, 97, 0.5)",
};

export const TOP_BAR_HEIGHT = {
  normal: "70px",
  mobile: "60px",
};

const useDialogWrapperStyle = makeStyles(
  ({ palette: p, spacing: s, breakpoints: b }) => ({
    modalWrapper: {
      background: p.background.default,
    },
    titleRoot: {
      [b.down("xs")]: {
        padding: s(0.5),
      },
    },
    contentRoot: {
      [b.down("xs")]: {
        padding: s(0.5),
      },
    },
  })
);

interface DialogWrapperProps extends DialogProps {
  onClose: () => any;
  title: string;
  smallSize?: boolean;
}

export const DialogWrapper: React.FC<DialogWrapperProps> = ({
  children,
  onClose,
  title,
  smallSize,
  ...props
}) => {
  const { isXSDown } = useResponsive();
  const classes = useDialogWrapperStyle();

  return (
    <Dialog
      maxWidth={smallSize ? "sm" : "lg"}
      fullWidth
      fullScreen={isXSDown}
      classes={{
        paper: classes.modalWrapper,
      }}
      {...props}
    >
      <DialogTitleWithCloseBtn
        title={title}
        onClose={onClose}
        classes={{
          root: classes.titleRoot,
        }}
      />
      <DialogContent
        classes={{
          root: classes.contentRoot,
        }}
      >
        {children}
      </DialogContent>
    </Dialog>
  );
};
