import React, { useEffect, useState, useCallback } from "react";
import {
  Dialog,
  DialogContent,
  Typography,
  Box,
  Button,
  styled,
} from "@material-ui/core";

import networkIcon from "./network.svg";

const ErrorTypo = styled(Typography)(({ theme: { typography, palette } }) => ({
  ...typography.subtitle1,
  textAlign: "center",
  color: palette.error.dark,
}));

const NetworkErrorDialog = () => {
  const [isOnline, setIsOnline] = useState(navigator.onLine);

  const onOnline = useCallback(() => {
    setIsOnline(true);
  }, []);

  const onOffline = useCallback(() => {
    setIsOnline(false);
  }, []);

  const onBackClick = useCallback(() => {
    setIsOnline(true);
  }, []);

  useEffect(() => {
    window.addEventListener("online", onOnline);
    window.addEventListener("offline", onOffline);

    return () => {
      window.removeEventListener("online", onOnline);
      window.removeEventListener("offline", onOffline);
    };
  }, [onOnline, onOffline]);

  return (
    <Box height={0} width={0}>
      <img style={{ display: "none" }} src={networkIcon} alt="no network" />
      <Dialog open={!isOnline}>
        <DialogContent>
          <ErrorTypo>
            Sorry! <br />
            It seems there’s a network error
          </ErrorTypo>
          <Box textAlign="center" mt={1}>
            <img src={networkIcon} alt="no network" />
          </Box>
          <Box textAlign="center" mt={1}>
            <Button variant="text" color="primary" onClick={onBackClick}>
              Back
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default NetworkErrorDialog;
