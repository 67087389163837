import React from "react";

export interface SchedulingTokens {
  emailToken?: string;
  crmToken?: string;
}

export const SchedulingTokenContext = React.createContext<SchedulingTokens>({});
export const useSchedulingTokens = () =>
  React.useContext(SchedulingTokenContext);
