import React, { useState, useCallback } from "react";
import { IconButton, Menu, makeStyles } from "@material-ui/core";
import { ImportExport } from "@material-ui/icons";

import TableMobileSortItem from "./TableMobileSortItem";
import { TABLE_META, FetchResultsParamsFieldEnum } from "../types";

const useStyle = makeStyles(({ spacing: s }) => ({
  sortIcon: {
    marginRight: s(1),
  },
}));

interface TableMobileSortProps {
  sortASC: boolean;
  sortBy: FetchResultsParamsFieldEnum;
  onSortItemClick: (name: FetchResultsParamsFieldEnum) => any;
}

export const TableMobileSort: React.FC<TableMobileSortProps> = ({
  sortASC,
  sortBy,
  onSortItemClick,
}) => {
  const [mobileSort, setMobileSort] = useState<null | HTMLElement>(null);

  const onMobileSortClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      setMobileSort(e.currentTarget);
    },
    []
  );

  const onMobileSortClose = useCallback(() => {
    setMobileSort(null);
  }, []);

  const clickSort = useCallback(
    (next: FetchResultsParamsFieldEnum) => {
      setMobileSort(null);
      onSortItemClick(next);
    },
    [onSortItemClick]
  );

  const classes = useStyle();

  return (
    <>
      <IconButton
        aria-haspopup="true"
        aria-label="toggle-sort"
        onClick={onMobileSortClick}
        color="default"
        size="small"
        className={classes.sortIcon}
        data-testid="blood-tests-page/mobile/sort-icon"
      >
        <ImportExport />
      </IconButton>

      <Menu
        anchorEl={mobileSort}
        open={!!mobileSort}
        onClose={onMobileSortClose}
      >
        {Object.keys(TABLE_META).map((key) => {
          const k = key as FetchResultsParamsFieldEnum;
          const { noSort } = TABLE_META[k];
          if (noSort) return null;

          return (
            <TableMobileSortItem
              key={k}
              tableKey={k}
              isDesc={sortBy === k ? !sortASC : undefined}
              onClick={clickSort}
            />
          );
        })}
      </Menu>
    </>
  );
};
