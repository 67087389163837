import React, { FC, useState, useCallback } from "react";
import {
  makeStyles,
  Dialog,
  DialogContent,
  IconButton,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { useDispatch } from "react-redux";

import { AppDispatch } from "redux/store";
import { useResponsive } from "hooks";
import { schedulingActions } from "redux/scheduling";

import CancelMcmCall from "./CancelMcmCall";

export interface CancelMcmCallDialogProps {
  open: boolean;
  onClose: () => void;
}

const useStyles = makeStyles(({ spacing: s }) => ({
  paperSM: {
    width: "calc(100% - 32px)",
    maxHeight: "calc(100% - 32px)",
    margin: s(2),
    padding: s(1, 0),
    height: "100%",
  },
  paper: {
    padding: s(2, 0),
    position: "relative",
  },
  close: {
    position: "absolute",
    top: 0,
    right: 0,
    transform: "translate(-25%, 25%)",
    zIndex: 1000,
  },
  dialogContent: {
    padding: s(0, 2),
  },
  content: {
    height: "100%",
    "&:first-child": {
      paddingTop: 0,
    },
  },
}));

const CancelMcmCallDialog: FC<CancelMcmCallDialogProps> = ({
  open,
  onClose,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const classes = useStyles();
  const { isSMDown } = useResponsive();

  const [isCallCancelled, setIsCallCancelled] = useState(false);

  const handleClose = useCallback(() => {
    if (isCallCancelled) {
      dispatch(schedulingActions.removeScheduledMcmCall());
    }
    setIsCallCancelled(false);
    onClose();
  }, [onClose, dispatch, isCallCancelled]);

  const cancelCall = useCallback(() => {
    setIsCallCancelled(true);
  }, []);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="lg"
      classes={{ paper: isSMDown ? classes.paperSM : classes.paper }}
    >
      <IconButton className={classes.close} onClick={handleClose}>
        <Close />
      </IconButton>

      <DialogContent className={classes.dialogContent}>
        <CancelMcmCall cancelCallCallback={cancelCall} />
      </DialogContent>
    </Dialog>
  );
};

export default CancelMcmCallDialog;
