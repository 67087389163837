import React, { useCallback, useState, useMemo } from "react";
import {
  ForgotPassword,
  useResponsive,
} from "@deep-consulting-solutions/auth-web";
import { Box, Grid, Hidden, makeStyles, Typography } from "@material-ui/core";
import { useHistory, useLocation } from "react-router-dom";

import { ROUTES } from "configs";

const useStyle = makeStyles(() => ({
  title: {
    color: "#1D3961",
    fontSize: "34px",
  },
  subTitle: {
    color: "#1D3961",
    fontSize: "17px",
  },
}));

// A custom hook that builds on useLocation to parse
// the query string for you.
const useQuery = () => {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
};

export default () => {
  const { isSMDown } = useResponsive();
  const [showText, setShowText] = useState(true);
  const history = useHistory();
  const query = useQuery();

  const onToLoginClick = useCallback(() => {
    history.push(ROUTES.login.path);
  }, [history]);

  const onForgotPasswordSuccess = useCallback(() => {
    setShowText(false);
  }, []);

  const textAlign = isSMDown ? "center" : "left";

  const classes = useStyle();

  return (
    <Grid container spacing={4}>
      <Hidden xsDown>
        <Grid item xs={12} md={6}>
          <Box mb={3}>
            <Typography
              color="primary"
              variant="h2"
              component="h1"
              align={textAlign}
            >
              {query.get("loggedIn") === "facebook"
                ? "Please set up your email login"
                : "Oops, don’t worry we’ll help get your account working again"}
            </Typography>
          </Box>
          {showText && (
            <Typography variant="h5" component="p" align={textAlign}>
              {query.get("loggedIn") === "facebook"
                ? "Due to Facebook's restrictive policies, we are removing Facebook login from the list of options. If you previously used Facebook to sign in, please set up your login by email and password. Please enter your email and a password reset link will be emailed to you."
                : "Do you remember the registered email address? Start by entering that email address and then click the Get Password Reset Link button."}
            </Typography>
          )}
        </Grid>
      </Hidden>
      <Grid item xs={12} sm>
        <Box maxWidth="400px" mx="auto">
          <ForgotPassword
            onToLoginClick={onToLoginClick}
            onForgotPasswordSuccess={onForgotPasswordSuccess}
            titleClass={classes.title}
            subTitleClass={classes.subTitle}
          />
        </Box>
      </Grid>
    </Grid>
  );
};
