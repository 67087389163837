import React, { ReactNode, useCallback, useMemo } from "react";
import { SharedProps, ProviderContext } from "notistack";
import { makeStyles, IconButton, Box } from "@material-ui/core";
import {
  CheckCircleOutline,
  ReportProblemOutlined,
  ErrorOutlineOutlined,
  InfoOutlined,
  Close,
} from "@material-ui/icons";
import { CONTACT_PORTAL_INVITATION_NOT_PENDING } from "redux/activation";
import { ROUTES } from "configs";

const getVariantData = (variant: SharedProps["variant"]) => {
  if (variant === "success") {
    return {
      color: "#4FB056",
      textColor: "#224925",
      Icon: CheckCircleOutline,
    };
  }
  if (variant === "warning") {
    return {
      color: "#FDA336",
      textColor: "#6F4918",
      Icon: ReportProblemOutlined,
    };
  }
  if (variant === "error") {
    return {
      color: "#F3554C",
      textColor: "#6B2926",
      Icon: ErrorOutlineOutlined,
    };
  }
  return {
    color: "#429FF1",
    textColor: "#1B4467",
    Icon: InfoOutlined,
  };
};

interface StyleProps {
  color: string;
  textColor: string;
}

const useStyle = makeStyles(({ spacing: s, breakpoints: b }) => ({
  wrapper: ({ color, textColor }: StyleProps) => ({
    background: "white",
    padding: s(1),
    borderLeftWidth: 10,
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderRightWidth: 1,
    borderColor: color,
    borderStyle: "solid",
    borderRadius: 5,
    display: "flex",
    alignItems: "center",
    color: textColor,

    [b.down("sm")]: {
      marginLeft: s(1),
      maxWidth: "300px",
    },
  }),
  messageWrapper: {
    flex: 1,
    wordWrap: "break-word",
    overflow: "hidden",
  },
  icon: ({ color }: StyleProps) => ({
    marginRight: s(1),
    color,
  }),
  closeIconWrapper: {
    marginLeft: "auto",
  },
  closeIcon: {
    marginLeft: s(1),
    color: "#7A7A7A",
  },
}));

interface Props {
  variant: SharedProps["variant"];
  message: ReactNode;
  messageKey: number | string;
  notiRef: ProviderContext | null;
}

const NotificationDisplay = React.forwardRef<any, Props>(
  ({ variant, message, notiRef, messageKey }, ref) => {
    const { color, textColor, Icon } = getVariantData(variant);
    const classes = useStyle({ color, textColor });

    const onCloseClick = useCallback(() => {
      notiRef?.closeSnackbar(messageKey);
    }, [messageKey, notiRef]);

    const inputMessage = useMemo(() => {
      if (
        typeof message === "string" &&
        message.indexOf(CONTACT_PORTAL_INVITATION_NOT_PENDING) > -1
      ) {
        const [lead, end] = message.split(" here ");
        return (
          <>
            {lead} <a href={ROUTES.login.path}>here</a> {end}
          </>
        );
      }
      return <>{message}</>;
    }, [message]);

    return (
      <div ref={ref} className={classes.wrapper}>
        <Icon className={classes.icon} />
        <Box className={classes.messageWrapper}>{inputMessage}</Box>
        <Box className={classes.closeIconWrapper}>
          <IconButton
            className={classes.closeIcon}
            onClick={onCloseClick}
            aria-label="close notification"
          >
            <Close />
          </IconButton>
        </Box>
      </div>
    );
  }
);

export default NotificationDisplay;
