import React, { memo, useCallback } from "react";
import {
  ListItem as MUIListItem,
  ListItemText,
  ListItemIcon as MUIListItemIcon,
  ListItemProps,
  SvgIcon,
} from "@material-ui/core";
import { styled, withStyles } from "@material-ui/core/styles";
import { useRouteMatch, useHistory } from "react-router-dom";

const ListItemIcon = styled(MUIListItemIcon)({
  color: "inherit",
});

const ListItem = withStyles(({ palette }) => ({
  selected: {
    borderRight: "4px solid",
    color: palette.primary.contrastText,
    backgroundColor: `${palette.primary.dark} !important`,
  },
}))(MUIListItem);

const ListItemLink: React.FC<
  ListItemProps & {
    path: string;
    title: string;
    Icon: typeof SvgIcon;
  }
> = ({ title, path, Icon }) => {
  const match = useRouteMatch();
  const history = useHistory();
  const handleClick = useCallback(() => {
    history.push(path);
  }, [history, path]);
  return (
    <ListItem
      button
      selected={match.url.startsWith(path)}
      onClick={handleClick}
    >
      <ListItemIcon>
        <Icon />
      </ListItemIcon>
      <ListItemText>{title}</ListItemText>
    </ListItem>
  );
};

export default memo(ListItemLink);
