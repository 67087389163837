import {
  PATH_OAUTH_RESPONSE,
  PATH_RECOVER_PASSWORD,
  PATH_UPDATE_EMAIL_VERIFICATION,
} from "@deep-consulting-solutions/auth-web";

export const ROUTES: {
  [pathKey: string]: {
    path: string;
    title?: string;
  };
} = {
  login: {
    path: "/login",
  },
  forgot: {
    path: "/forgot-password",
  },
  profile: {
    path: "/profile",
    title: "My Profile",
  },
  oauthResponse: {
    path: PATH_OAUTH_RESPONSE,
  },
  recoverPassword: {
    path: PATH_RECOVER_PASSWORD,
  },
  updateEmailVerification: {
    path: PATH_UPDATE_EMAIL_VERIFICATION,
  },
  dashboard: {
    path: "/dashboard",
    title: "Dashboard",
  },
  bloodTests: {
    path: "/blood-tests",
    title: "Blood Tests",
  },
  bloodTestsDetails: {
    path: "/blood-tests/:id",
  },
  invoices: {
    path: "/invoices",
    title: "Invoices",
  },
  activation: {
    path: "/activation",
  },
  activationProfileInfo: {
    path: "/activation/profile-info",
  },
  activationWelcome: {
    path: "/activation/welcome",
  },
  schedulingFromEmail: {
    path: "/schedule",
  },
  schedulingFromCRM: {
    path: "/crm/schedule",
  },
  reschedulingFromEmail: {
    path: "/reschedule",
  },
  reschedulingFromCRM: {
    path: "/crm/reschedule",
  },
  cancelScheduledMeetingFromEmail: {
    path: "/scheduling/cancel-meeting",
  },
  cancelScheduledMeetingFromCRM: {
    path: "/crm/scheduling/cancel-meeting",
  },
  requestReschedulingFromCRM: {
    path: "/crm/request-reschedule",
  },
};
