import React, { useCallback, useEffect, useRef, useState } from "react";
import * as Yup from "yup";
import { Box, LinearProgress, styled } from "@material-ui/core";
import { Form, Formik, FormikProps } from "formik";
import { missingPersonalInfo, missingShippingAddress } from "helpers";
import { userActions, userSelectors } from "redux/user";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "redux/store";
import Loader from "components/Loader";
import { isEqual } from "lodash";
import { DATE_REQUEST_FORMAT } from "configs";
import { format } from "date-fns";
import { GenderEnum } from "@deep-consulting-solutions/bmh-constants";
import { FormHeader } from "./FormHeader";
import {
  AddressForm,
  AddressFormValues,
  addressValidationSchema,
} from "./AddressForm";
import {
  PersonalForm,
  PersonalFormValues,
  personalValidationSchema,
} from "./PersonalForm";

export const WrapperBox = styled(Box)(({ theme }) => ({
  position: "relative",
  backgroundColor: theme.palette.background.paper,
  padding: theme.spacing(2, 3, 3, 3),
  borderRadius: theme.spacing(0.5),
  border: `1px solid ${theme.palette.divider}`,
  [theme.breakpoints.down("xs")]: {
    padding: theme.spacing(2),
  },
  "&:not(:first-child)": {
    marginTop: theme.spacing(5),
  },
}));

const PersonalvalidationSchema = Yup.object(personalValidationSchema);

const AddressValidationSchema = Yup.object(addressValidationSchema);

interface UserProfileFormProps {
  isActivation?: boolean;
}

export const UserProfileForm: React.FC<UserProfileFormProps> = ({
  isActivation = false,
}) => {
  const personalFormRef = useRef<FormikProps<PersonalFormValues>>(null);
  const addressFormRef = useRef<FormikProps<AddressFormValues>>(null);

  const dispatch = useDispatch<AppDispatch>();
  const [loading, setLoading] = useState(false);
  const contact = useSelector(userSelectors.getContact);

  const [loadingAddress, setLoadingAddress] = useState(false);

  const [editingPersonal, setEditingPersonal] = useState(false);
  const toggleEditingPersonal = useCallback(() => {
    setEditingPersonal((prev) => !prev);
  }, []);
  const [googleReady, setGoogleReady] = useState(false);

  useEffect(() => {
    if (contact && missingPersonalInfo(contact)) {
      setEditingPersonal(true);
    }
  }, [contact]);

  const [editingAddress, setEditingAddress] = useState(false);
  const toggleEditingAddress = useCallback(() => {
    setEditingAddress((prev) => !prev);
  }, []);

  useEffect(() => {
    if (contact && missingShippingAddress(contact)) {
      setEditingAddress(true);
    }
  }, [contact]);

  const onPersonalSubmit = useCallback(
    async (values: PersonalFormValues) => {
      setLoading(true);
      const res = await dispatch(
        userActions.updateUserProfile({
          ...values,
          gender: values.gender as GenderEnum,
          dob: values.dob ? format(values.dob, DATE_REQUEST_FORMAT) : undefined,
        })
      );
      if (userActions.updateUserProfile.fulfilled.match(res)) {
        toggleEditingPersonal();
      }
      setLoading(false);
    },
    [dispatch, toggleEditingPersonal]
  );

  const onAddressSubmit = useCallback(
    async ({
      street,
      city,
      region,
      other,
      zip,
      country,
    }: AddressFormValues) => {
      setLoading(true);
      const res = await dispatch(
        userActions.updateUserProfile({
          shippingAddress: {
            street,
            city,
            region,
            street2: other,
            zip,
            country,
          },
        })
      );
      if (userActions.updateUserProfile.fulfilled.match(res)) {
        toggleEditingAddress();
      }
      setLoading(false);
    },
    [dispatch, toggleEditingAddress]
  );

  const onCancelPersonalClick = useCallback(() => {
    personalFormRef.current?.resetForm();
    toggleEditingPersonal();
  }, [toggleEditingPersonal]);

  const onCancelAddressClick = useCallback(() => {
    addressFormRef.current?.resetForm();
    toggleEditingAddress();
  }, [toggleEditingAddress]);

  if (!contact) {
    return <Loader open />;
  }

  const personalInitialValues: PersonalFormValues = {
    mobilePhone: contact.mobilePhone || "",
    phone: contact.phone || "",
    gender: contact.gender || "",
    dob: contact.dob ? new Date(contact.dob.replace(/-/g, "/")) : null,
  };

  const addressInitialValues: AddressFormValues = {
    street: contact.shippingAddress.street || "",
    city: contact.shippingAddress.city || "",
    region: contact.shippingAddress.region || "",
    other: contact.shippingAddress.street2 || "",
    zip: contact.shippingAddress.zip || "",
    country: contact.shippingAddress.country || "",
  };

  return (
    <>
      <WrapperBox>
        <Formik<PersonalFormValues>
          innerRef={personalFormRef}
          validationSchema={PersonalvalidationSchema}
          onSubmit={onPersonalSubmit}
          initialValues={personalInitialValues}
          enableReinitialize
        >
          {(props) => {
            const { isValid, isSubmitting, values } = props;
            return (
              <Form noValidate autoComplete="off">
                <FormHeader
                  title="Personal Information"
                  editing={editingPersonal}
                  onEditClick={toggleEditingPersonal}
                  onCancelClick={onCancelPersonalClick}
                  disabled={
                    !isValid ||
                    isSubmitting ||
                    isEqual(values, personalInitialValues)
                  }
                />
                <PersonalForm
                  formikProps={props}
                  isActivation={isActivation}
                  contact={contact}
                  editing={editingPersonal}
                />
              </Form>
            );
          }}
        </Formik>
      </WrapperBox>
      <WrapperBox>
        {loadingAddress && (
          <LinearProgress
            style={{ position: "absolute", top: "0", left: "0", width: "100%" }}
          />
        )}
        <Formik<AddressFormValues>
          innerRef={addressFormRef}
          validationSchema={AddressValidationSchema}
          onSubmit={onAddressSubmit}
          initialValues={addressInitialValues}
          enableReinitialize
        >
          {(props) => {
            const { isValid, isSubmitting, values } = props;
            return (
              <Form noValidate autoComplete="off">
                <FormHeader
                  title="Your Shipping Address"
                  subtitle="Used for sending medications and blood testing kits if applicable"
                  verticalButtonsOnMobile
                  editing={editingAddress}
                  onEditClick={toggleEditingAddress}
                  onCancelClick={onCancelAddressClick}
                  disabled={
                    !isValid ||
                    loadingAddress ||
                    isSubmitting ||
                    isEqual(values, addressInitialValues)
                  }
                />
                <AddressForm
                  formikProps={props}
                  isActivation={isActivation}
                  loadingAddress={loadingAddress}
                  setLoadingAddress={setLoadingAddress}
                  setGoogleReady={setGoogleReady}
                  editing={editingAddress}
                />
              </Form>
            );
          }}
        </Formik>
      </WrapperBox>
      <Loader open={loading || !googleReady} />
    </>
  );
};
