/* eslint-disable react/no-array-index-key */
import React, { memo, useMemo } from "react";
import { Box, Typography, makeStyles, Button } from "@material-ui/core";
import {
  AccessTime as ClockIcon,
  Public as TZIcon,
  Today as CalendarIcon,
  Update as CurrentSlotIcon,
  Warning,
  AccountCircleOutlined as AccountIcon,
  EditOutlined as EditIcon,
  Info,
} from "@material-ui/icons";
import {
  SchedulingInfo,
  McmWithAvailability,
  MCMInfo,
  MCMCall,
  ContactRes,
} from "@deep-consulting-solutions/bmh-constants";

import { convertTimeFromUTCToAnotherTimezone } from "helpers";
import { useResponsive } from "hooks";

import clsx from "clsx";
import { useSchedulingTokens } from "../SchedulingToolTokenContext";
import {
  DailyAvailableSlot,
  formatSlotTime,
  formatDateInFull,
  randomlyPickMCMs,
  findDaysDiff,
  formatTimezone,
  updateSlotDatesWithTZ,
  getClientFacingFromPurpose,
} from "../SchedulingTool.helpers";
import { MCMAvatar } from "./MCMAvatar";

const displaySlot = (start: Date, end: Date, breakline?: boolean) => {
  const full = formatDateInFull(start);
  const slot = `${formatSlotTime(start, true)} - ${formatSlotTime(
    end,
    true
  )}${findDaysDiff(start, end)}`;
  if (breakline) return [full, slot];
  return [`${full} ${slot}`];
};

const useStyle = makeStyles(
  ({ spacing: s, breakpoints: b, palette: p, typography: typo }) => ({
    wrapper: {
      padding: s(3),
      [b.down("sm")]: {
        paddingTop: s(2),
        paddingBottom: s(1),
        paddingLeft: s(2),
        paddingRight: s(2),
      },
    },
    manager: {
      display: "flex",
      alignItems: "center",
    },
    infoItem: {
      marginTop: s(3),
      display: "flex",
      [b.down("sm")]: {
        marginTop: s(1),
        alignItems: "center",
      },
    },
    infoDuration: {
      alignItems: "center",
    },
    infoIcon: {
      marginRight: s(1),
    },
    durationSelection: {
      [b.down("sm")]: {
        width: "auto",
      },
    },
    tzBtn: {
      padding: 0,
      textTransform: "none",
      color: p.info.dark,
    },
    noEmailWrapper: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      marginBottom: s(1),
    },
    noEmailText: {
      color: p.warning.light,
    },
    noEmailIcon: {
      color: p.warning.main,
      marginRight: s(1),
    },
    currentMCM: {
      display: "flex",
      alignItems: "center",
    },
    currentMCMIcon: {
      color: p.warning.main,
      marginRight: s(0.5),
    },
    tzEditIcon: {
      marginLeft: s(0.5),
      fontSize: typo.subtitle1.fontSize,
      color: p.info.dark,
    },
    durationIcon: {
      color: p.primary.light,
    },
    calendarIcon: {
      color: p.secondary.dark,
    },
    reschedulingDateTitle: {
      color: p.secondary.dark,
    },
    tzIcon: {
      color: p.text.secondary,
    },
  })
);

interface SchedulingInfoProps {
  info: SchedulingInfo;
  onTZClick: () => any;
  isTargetMCM: boolean;
  selectedSlot: Omit<DailyAvailableSlot, "mcms"> | null;
  selectedOtherMCM: MCMInfo | null;
  calendar: McmWithAvailability[];
  duration?: number;
  onDurationChange: (next: number) => any;
  freezeDuration?: boolean;
  meeting?: MCMCall;
  contact?: ContactRes;
  host: "crm" | "client";
}

const SchedulingInformation = React.forwardRef<
  HTMLDivElement,
  SchedulingInfoProps
>(
  (
    {
      info,
      onTZClick,
      isTargetMCM,
      selectedSlot: inputSelectedSlot,
      selectedOtherMCM,
      calendar,
      meeting,
      contact,
      host,
    },
    ref
  ) => {
    const tokens = useSchedulingTokens();
    const { isXSDown, isSMDown, isSMOnly } = useResponsive();

    const selectedSlot = useMemo(() => {
      if (!inputSelectedSlot || !info) return null;
      return updateSlotDatesWithTZ(
        inputSelectedSlot,
        info.bmhTZ || "",
        info.clientTZ || ""
      );
    }, [info, inputSelectedSlot]);
    const randomlyPicked = useMemo(() => randomlyPickMCMs(calendar), [
      calendar,
    ]);

    const { startMeetingDate, endMeetingDate, currentMCMName } = useMemo(() => {
      return {
        startMeetingDate:
          meeting && info
            ? convertTimeFromUTCToAnotherTimezone(meeting.start, info.clientTZ)
            : null,
        endMeetingDate:
          meeting && info
            ? convertTimeFromUTCToAnotherTimezone(meeting.end, info.clientTZ)
            : null,
        currentMCMName: meeting ? meeting.mcm.name : "",
      };
    }, [meeting, info]);

    const tz = info.clientTZ;
    const classes = useStyle();

    return (
      <div className={classes.wrapper} ref={ref}>
        {!!(tokens.crmToken && contact && !contact.email) && (
          <div className={classes.noEmailWrapper}>
            <Warning className={classes.noEmailIcon} />
            <Typography className={classes.noEmailText} variant="body2">
              {contact.firstName} {contact.lastName} does not have email setup
            </Typography>
          </div>
        )}
        <Box className={classes.manager}>
          {!!info.mcm && (
            <>
              {isTargetMCM && <MCMAvatar mcm={info.mcm} />}
              {!isTargetMCM && !selectedOtherMCM && (
                <Typography variant={isXSDown ? "subtitle2" : "h4"}>
                  {startMeetingDate ? "Reschedule" : "Book"} with another PCM
                </Typography>
              )}
              {!isTargetMCM && !!selectedOtherMCM && (
                <MCMAvatar mcm={selectedOtherMCM} />
              )}
            </>
          )}
          {!info.mcm && (
            <>
              {!selectedOtherMCM &&
                randomlyPicked.map((mcm, index) => (
                  <MCMAvatar key={mcm.id} mcm={mcm} onlyImage index={index} />
                ))}
              {!!selectedOtherMCM && <MCMAvatar mcm={selectedOtherMCM} />}
            </>
          )}
        </Box>
        <Box>
          {!!info.purpose && (
            <Box className={classes.infoItem}>
              <Info color="primary" className={classes.infoIcon} />
              <Typography variant={isXSDown ? "body2" : "body1"}>
                {getClientFacingFromPurpose(info.purpose, host)}
              </Typography>
            </Box>
          )}
          {!!info.duration && !!info.purpose && (
            <Box className={`${classes.infoItem} ${classes.infoDuration}`}>
              <ClockIcon
                color="primary"
                className={clsx(classes.infoIcon, classes.durationIcon)}
              />
              <Typography
                variant={isXSDown ? "body2" : "body1"}
                color="textPrimary"
              >
                {info.duration}
              </Typography>
            </Box>
          )}

          {startMeetingDate && endMeetingDate && (
            <>
              <Box className={classes.infoItem}>
                <CurrentSlotIcon
                  color="secondary"
                  className={classes.infoIcon}
                />
                <Box>
                  {!isSMDown && (
                    <Typography variant="body1" color="secondary">
                      Current scheduled date:
                    </Typography>
                  )}
                  {displaySlot(startMeetingDate, endMeetingDate, !isSMOnly).map(
                    (text, index) => (
                      <Typography
                        key={index}
                        variant={isXSDown ? "body2" : "body1"}
                        color="textPrimary"
                      >
                        {text}
                      </Typography>
                    )
                  )}
                </Box>
              </Box>
              <Box className={classes.infoItem}>
                <AccountIcon color="secondary" className={classes.infoIcon} />
                <Box>
                  {!isSMDown && (
                    <Typography variant="body1" color="secondary">
                      PCM conducting the meeting:
                    </Typography>
                  )}
                  <Typography
                    variant={isXSDown ? "body2" : "body1"}
                    color="textPrimary"
                  >
                    {currentMCMName}
                  </Typography>
                </Box>
              </Box>
            </>
          )}
          {!!selectedSlot && (
            <Box className={classes.infoItem}>
              <CalendarIcon
                className={clsx(classes.infoIcon, classes.calendarIcon)}
              />
              <Box>
                {!isSMDown && startMeetingDate && (
                  <Typography
                    variant="body1"
                    className={classes.reschedulingDateTitle}
                  >
                    Rescheduling date:
                  </Typography>
                )}
                {displaySlot(
                  selectedSlot.start,
                  selectedSlot.end,
                  !isSMOnly
                ).map((text, index) => (
                  <Typography
                    key={index}
                    variant={isXSDown ? "body2" : "body1"}
                    color="textPrimary"
                  >
                    {text}
                  </Typography>
                ))}
              </Box>
            </Box>
          )}
          <Box className={classes.infoItem}>
            <TZIcon className={clsx(classes.infoIcon, classes.tzIcon)} />
            <Box>
              {!isSMDown && (
                <Typography variant="body1" color="textSecondary">
                  Your time zone:
                </Typography>
              )}
              <Button
                variant="text"
                color="secondary"
                className={classes.tzBtn}
                onClick={onTZClick}
              >
                {tz ? formatTimezone(tz) : null}
                <EditIcon className={classes.tzEditIcon} />
              </Button>
            </Box>
          </Box>
        </Box>
      </div>
    );
  }
);

export default memo(SchedulingInformation);
