import React, { memo, useEffect, useCallback, useState, useMemo } from "react";
import {
  Dialog,
  DialogProps,
  DialogContent,
  DialogActions,
  makeStyles,
  TextField,
  MenuItem,
  Button,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { TZ } from "@deep-consulting-solutions/bmh-constants";

import { DialogTitleWithCloseBtn } from "components/DialogTitleWithCloseBtn";
import { useResponsive } from "hooks";
import { getAllTimezones } from "helpers";

import { processTZs } from "./helpers";

const useStyle = makeStyles(({ spacing: s }) => ({
  titleWrapper: {
    display: "flex",
    alignItems: "center",
  },
  actions: {
    paddingRight: s(3),
    paddingBottom: s(3),
    paddingTop: s(2),
  },
  select: {
    marginTop: s(2),
  },
}));

interface SchedulingTZModalProps extends Omit<DialogProps, "onClose"> {
  tz?: string | null;
  onClose?: () => any;
  onUpdate: (tz: string) => any;
  isCRMPreSchedule?: boolean;
}

const SchedulingTZModal: React.FC<SchedulingTZModalProps> = ({
  open,
  tz,
  onClose,
  onUpdate,
  isCRMPreSchedule,
  ...props
}) => {
  const { isXSDown } = useResponsive();
  const tzs = useMemo(() => getAllTimezones().timezones, []);

  const { mappedCountries, countries } = useMemo(() => processTZs(tzs), [tzs]);
  const [selectedTZ, setSelectedTZ] = useState(tz || "");
  const [selectedCountry, setSelectedCountry] = useState("");
  const [isTZDisable, setIsTZDisable] = useState(false);

  const filteredTZs = useMemo(() => mappedCountries[selectedCountry] || [], [
    mappedCountries,
    selectedCountry,
  ]);

  const selectCountryFromTZ = useCallback(
    (inputTZs: TZ[], currentTZ: string) => {
      const foundTZ = inputTZs.find((t) => t.tz === currentTZ);
      if (foundTZ) {
        setSelectedCountry(foundTZ.country);
        setSelectedTZ(foundTZ.tz);
      }
    },
    []
  );

  const onCountrySelect = useCallback((_, newValue: string | null) => {
    setSelectedCountry((current) => {
      if (current !== newValue) {
        setSelectedTZ("");
      }
      return newValue || "";
    });
  }, []);

  const onTZSelect = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedTZ(e.target.value);
  }, []);

  const onCancelClick = useCallback(() => {
    if (onClose) onClose();
  }, [onClose]);

  const onSubmit = useCallback(() => {
    if (!selectedTZ) return;
    onUpdate(selectedTZ);
    if (onClose) onClose();
  }, [onClose, selectedTZ, onUpdate]);

  useEffect(() => {
    if (tzs.length && tz) {
      selectCountryFromTZ(tzs, tz);
    }
  }, [tzs, tz, selectCountryFromTZ]);

  useEffect(() => {
    if (tz && open) {
      selectCountryFromTZ(tzs, tz);
    }
  }, [open, tzs, tz, selectCountryFromTZ]);

  useEffect(() => {
    if (filteredTZs.length === 1) {
      setSelectedTZ(filteredTZs[0].tz);
      setIsTZDisable(true);
    } else {
      setIsTZDisable(false);
    }
  }, [filteredTZs]);

  const classes = useStyle();

  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      open={open}
      onClose={onCancelClick}
      {...props}
    >
      <DialogTitleWithCloseBtn
        title={isCRMPreSchedule ? "Set Client Time Zone" : "Your Time Zone"}
        titleVariant={isXSDown ? "body1" : "h6"}
        onClose={onClose ? onCancelClick : undefined}
      />
      <DialogContent>
        <Autocomplete
          id="country-select"
          options={countries}
          value={selectedCountry}
          onChange={onCountrySelect}
          getOptionLabel={(option: string) => option}
          renderOption={(option: string) => option}
          renderInput={(params) => (
            <TextField
              {...params}
              inputProps={{
                ...params.inputProps,
                autoComplete: "new-password", // disable autocomplete and autofill
              }}
              label="Country"
            />
          )}
        />
        <TextField
          value={selectedTZ}
          select
          onChange={onTZSelect}
          className={classes.select}
          disabled={!selectedCountry || isTZDisable}
          label="Timezone"
        >
          {filteredTZs.map((z) => (
            <MenuItem key={z.tz} value={z.tz}>
              {z.label}
            </MenuItem>
          ))}
        </TextField>
      </DialogContent>
      <DialogActions className={classes.actions}>
        {onClose && (
          <Button variant="text" color="primary" onClick={onCancelClick}>
            CANCEL
          </Button>
        )}
        <Button color="primary" onClick={onSubmit} disabled={!selectedTZ}>
          SELECT
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default memo(SchedulingTZModal);
