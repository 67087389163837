import React from "react";
import {
  FormikDatePicker,
  FormikPhoneInput,
} from "@deep-consulting-solutions/dcs-web-ui";
import { Field, FormikProps } from "formik";
import {
  ContactRes,
  GenderEnum,
} from "@deep-consulting-solutions/bmh-constants";
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  Radio,
  Typography,
} from "@material-ui/core";
import { RadioGroup } from "formik-material-ui";
import { endOfYesterday, format } from "date-fns";

import { getENText, hideEmailAddress, VALIDATIONS } from "helpers";

export interface PersonalFormValues {
  mobilePhone: string;
  phone: string;
  gender: GenderEnum | "";
  dob: Date | null;
}

export const personalValidationSchema = {
  mobilePhone: VALIDATIONS.phone,
  phone: VALIDATIONS.phoneNotRequired,
  dob: VALIDATIONS.dob,
  gender: VALIDATIONS.gender,
};

interface Props<T extends PersonalFormValues> {
  formikProps: FormikProps<T>;
  isActivation?: boolean;
  contact?: ContactRes | null;
  editing?: boolean;
}

export const PersonalForm = <T extends PersonalFormValues>({
  formikProps,
  isActivation,
  contact,
  editing,
}: Props<T>) => {
  const { touched, errors, isSubmitting } = formikProps;
  const genderError = touched.gender && errors.gender;

  return (
    <>
      {!!contact && (
        <>
          <Grid container spacing={3} alignItems="flex-start">
            <Grid
              style={{ paddingLeft: "20px" }}
              item
              xs={12}
              sm={6}
              md={isActivation ? undefined : 4}
            >
              {isActivation && (
                <>
                  <Typography paragraph>
                    <Typography component="span" color="textSecondary">
                      Name:{" "}
                    </Typography>
                    {contact.firstName || ""}
                    {contact.lastName || ""}
                  </Typography>
                  <Typography paragraph>
                    <Typography component="span" color="textSecondary">
                      Email:{" "}
                    </Typography>
                    {hideEmailAddress(contact.email || "")}
                  </Typography>
                </>
              )}
              {contact.gender && (
                <Typography paragraph>
                  <Typography component="span" color="textSecondary">
                    Gender:{" "}
                  </Typography>
                  {contact.gender === GenderEnum.male ? "Male" : "Female"}
                </Typography>
              )}
              {contact.dob && (
                <Typography>
                  <Typography component="span" color="textSecondary">
                    Date of Birth:{" "}
                  </Typography>
                  {format(
                    new Date(contact.dob.replace(/-/g, "/")),
                    "dd.MM.yyyy"
                  )}
                </Typography>
              )}
            </Grid>
            {!contact.gender && (
              <Grid item xs={12} sm={6} md={isActivation ? undefined : 4}>
                <FormControl component="fieldset" error={!!genderError}>
                  <FormLabel component="legend" required>
                    Gender
                  </FormLabel>
                  <Field component={RadioGroup} row name="gender">
                    <FormControlLabel
                      value={GenderEnum.male}
                      control={
                        <Radio
                          color="primary"
                          disabled={!editing || isSubmitting}
                        />
                      }
                      label="Male"
                      disabled={!editing || isSubmitting}
                    />
                    <FormControlLabel
                      value={GenderEnum.female}
                      control={
                        <Radio
                          color="primary"
                          disabled={!editing || isSubmitting}
                        />
                      }
                      label="Female"
                      disabled={!editing || isSubmitting}
                    />
                    <FormControlLabel
                      value=""
                      control={
                        <Radio
                          color="primary"
                          disabled={!editing || isSubmitting}
                        />
                      }
                      label="None"
                      disabled
                    />
                  </Field>
                  <FormHelperText>{genderError}</FormHelperText>
                </FormControl>
              </Grid>
            )}
            {!contact.dob && (
              <Grid item xs={12} sm={6} md={isActivation ? undefined : 4}>
                <Field
                  component={FormikDatePicker}
                  disablePast={false}
                  disableToolbar={false}
                  disableFuture
                  maxDate={endOfYesterday()}
                  maxDateMessage={getENText("validation.dob.inPast")}
                  label="Date of Birth"
                  name="dob"
                  required
                  disabled={!editing || isSubmitting}
                />
              </Grid>
            )}
            {isActivation && contact.gender && contact.dob && (
              <Grid item xs={12} sm={6} md={isActivation ? undefined : 4}>
                <Field
                  component={FormikPhoneInput}
                  name="mobilePhone"
                  label="Mobile Phone"
                  defaultCountry="GB"
                  required
                  disabled={!editing || isSubmitting}
                />
                <div style={{ marginTop: "20px" }}>
                  <Field
                    component={FormikPhoneInput}
                    name="phone"
                    label="Other Phone"
                    defaultCountry="GB"
                    disabled={!editing || isSubmitting}
                  />
                </div>
              </Grid>
            )}
            {!(isActivation && contact.gender && contact.dob) && (
              <>
                <Grid item xs={12} sm={6} md={isActivation ? undefined : 4}>
                  <Field
                    component={FormikPhoneInput}
                    name="mobilePhone"
                    label="Mobile Phone"
                    defaultCountry="GB"
                    required
                    disabled={!editing || isSubmitting}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={isActivation ? undefined : 4}>
                  <Field
                    component={FormikPhoneInput}
                    name="phone"
                    label="Other Phone"
                    defaultCountry="GB"
                    disabled={!editing || isSubmitting}
                  />
                </Grid>
              </>
            )}
          </Grid>
        </>
      )}
    </>
  );
};
