import React, { memo, useCallback, useMemo, useState } from "react";
import { DialogProps } from "@material-ui/core";
import { SchedulingInfo } from "@deep-consulting-solutions/bmh-constants";
import { getAllTimezones } from "helpers";
import { userSelectors } from "redux/user";

import { useSelector } from "react-redux";
import { schedulingSelectors } from "redux/scheduling";
import { useHistory } from "react-router-dom";
import { ROUTES } from "configs";
import { SchedulingToolMain } from "./SchedulingToolMain";
import { DialogWrapper } from "./SchedulingTool.styles";

interface SchedulingToolDialogProps extends DialogProps {
  onClose: () => any;
  schedulingInfo?: SchedulingInfo;
  onScheduleDone: (actions?: {
    shouldRefreshContact: boolean;
  }) => Promise<void>;
}

export const SchedulingToolDialog: React.FC<SchedulingToolDialogProps> = ({
  onClose,
  schedulingInfo,
  onScheduleDone,
  onPointerEnter,
  open,
  ...props
}) => {
  const [smallSize, setSmallSize] = useState(false);
  const contact = useSelector(userSelectors.getContact);
  const assignedMcmInfo = useSelector(schedulingSelectors.getAssignedMcmInfo);
  const timezone = useSelector(schedulingSelectors.getTimezone);
  const history = useHistory();

  const changeDialogSize = useCallback((toSmallSize?: boolean) => {
    setSmallSize(!!toSmallSize);
  }, []);

  const tempTimezone = useMemo(() => {
    if (timezone) {
      return undefined;
    }
    const { mappedCountries } = getAllTimezones();
    if (contact?.shippingAddress?.country) {
      const tzs =
        mappedCountries[contact.shippingAddress.country.toLowerCase()];

      if (tzs && tzs.length) return tzs[0];
    }
    return undefined;
  }, [contact?.shippingAddress?.country, timezone]);

  const schedulingDetails = useMemo(() => {
    if (!schedulingInfo) {
      return { mcm: assignedMcmInfo, clientTZ: timezone };
    }
    return {
      ...schedulingInfo,
      clientTZ: schedulingInfo.clientTZ || timezone,
      mcm: schedulingInfo.mcm || assignedMcmInfo,
    };
  }, [assignedMcmInfo, schedulingInfo, timezone]);

  const handleClose = useCallback(() => {
    onScheduleDone();
    history.push(ROUTES.dashboard.path);
  }, [history, onScheduleDone]);

  return (
    <DialogWrapper
      open={open}
      onClose={onClose}
      title="Book a PCM Consultation"
      smallSize={smallSize}
      {...props}
    >
      <SchedulingToolMain
        schedulingInfo={schedulingDetails}
        changeDialogSize={changeDialogSize}
        onScheduleDone={onScheduleDone}
        tempTimezone={tempTimezone}
        host="client"
        contact={contact || undefined}
        handleClose={handleClose}
      />
    </DialogWrapper>
  );
};

export const MemoSchedulingToolDialog = memo(SchedulingToolDialog);
