import React, { useEffect, memo, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Redirect,
  Route,
  RouteProps,
  useHistory,
  useLocation,
  useRouteMatch,
} from "react-router-dom";
import {
  pkAuthSelectors,
  useIsChecked,
} from "@deep-consulting-solutions/auth-web";

import { ROUTES } from "configs";
import Loader from "components/Loader";
import { AppDispatch } from "redux/store";
import { userActions, userSelectors } from "redux/user";
import { missingContactInfo } from "helpers";
import { portalsSelectors } from "@deep-consulting-solutions/portals-web";

const PrivateRoute: React.FC<RouteProps> = (props) => {
  const dispatch = useDispatch<AppDispatch>();
  const loggedInPortal = useSelector(
    portalsSelectors.isLoggedInStatic("Customer")
  );
  const history = useHistory();
  const matchActivationProfile = useRouteMatch(
    ROUTES.activationProfileInfo.path
  );
  const matchActivationWelcome = useRouteMatch(ROUTES.activationWelcome.path);

  const isAuthorised = useSelector(pkAuthSelectors.isAuthorised);
  const isChecked = useIsChecked();
  const contact = useSelector(userSelectors.getContact);

  const shouldRedirect = useMemo(() => {
    if (contact && !matchActivationWelcome && !matchActivationProfile) {
      return missingContactInfo(contact);
    }
    return false;
  }, [contact, matchActivationProfile, matchActivationWelcome]);

  useEffect(() => {
    if (!isAuthorised) {
      history.push(ROUTES.login.path);
    } else if (!contact) {
      (async () => {
        await dispatch(userActions.getUserProfile());
      })();
    }
  }, [contact, dispatch, history, isAuthorised]);

  const location = useLocation();

  useEffect(() => {
    if ((!isAuthorised || shouldRedirect) && !loggedInPortal) {
      dispatch(
        userActions.setRedirectPathname(location.pathname + location.search)
      );
    }
  }, [
    dispatch,
    isAuthorised,
    location.pathname,
    location.search,
    loggedInPortal,
    shouldRedirect,
  ]);

  if (shouldRedirect) {
    return (
      <Redirect
        to={{
          pathname: ROUTES.activationProfileInfo.path,
          state: {
            isActivating: true,
          },
        }}
      />
    );
  }

  if (isChecked && isAuthorised && contact) {
    return <Route {...props} />;
  }

  return <Loader open />;
};

export default memo(PrivateRoute);
