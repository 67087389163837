import {
  GETInvoicePublicUrl,
  Invoice,
  ROUTE_INVOICE_PUBLIC_URL,
} from "@deep-consulting-solutions/invoices-web";
import { apiClient } from "apis";

export const fetchBloodTest = async () => {
  const res = await apiClient.get<{
    data: { result: { id: string; name: string; sampleCollectedOn: Date } };
  }>(`/blood-test-result/latest`);

  return res.data.data.result;
};

export const fetchInvoices = async () => {
  const res = await apiClient.get<{
    data: { unpaid: Invoice[]; overdue: Invoice[]; timeZone: string };
  }>(`/package/invoices/invoices/unpaid/overdue`);

  return res.data.data;
};

export const getInvoicePublicURL = async (invoiceId: string) => {
  const res = await apiClient.get<GETInvoicePublicUrl>(
    ROUTE_INVOICE_PUBLIC_URL.replace(":id", invoiceId)
  );
  return res.data.data;
};
