import { InvoiceLineItem } from "@deep-consulting-solutions/invoices-web";
import { ITEM_DESCRIPTION_TRUNCATE_LENGTH } from "configs";
import _truncate from "lodash/truncate";

export const stringifyItems = ([first, ...others]: InvoiceLineItem[]) => {
  const firstString = _truncate(first.name, {
    length: ITEM_DESCRIPTION_TRUNCATE_LENGTH,
  });
  if (!others.length) return firstString;

  return `${firstString} + ${others.length} other item${
    others.length > 1 ? "s" : ""
  }`;
};
