import {
  Box,
  Button,
  FormControlLabel,
  makeStyles,
  Radio,
  Typography,
} from "@material-ui/core";
import React, { useCallback } from "react";
import { Field, Form, useFormikContext } from "formik";
import { RadioGroup, TextField } from "formik-material-ui";
import {
  ConsultationPurposeEnum,
  SchedulingInfo,
} from "@deep-consulting-solutions/bmh-constants";
import { PurposeOfConsultationLabel } from "./PurposeOfConsultationLabel";
import { purposeValues } from "./helpers";
import { getDurationFromPurpose } from "../SchedulingTool.helpers";
import { FormValues } from "./types";

const useStyle = makeStyles(({ spacing: s, palette: p, breakpoints: b }) => ({
  wrapper: {
    [b.down("sm")]: {
      paddingTop: s(2),
      borderTop: `1px solid ${p.grey[300]}`,
    },
  },
  comment: {
    marginTop: s(3),
  },
  actions: {
    marginTop: s(3),
    [b.down("sm")]: {
      marginBottom: s(3),
      display: "flex",
      flexDirection: "row-reverse",
    },
  },
  confirm: {
    marginRight: s(1),

    [b.down("sm")]: {
      marginRight: 0,
      marginLeft: s(1),
    },
  },
  form: {
    marginTop: s(2),
  },
  purpose: {
    marginTop: s(1),
  },
}));

interface Props {
  host: "crm" | "client";
  handleUpdateInfo: (
    details: Pick<SchedulingInfo, "duration" | "purpose">
  ) => void;
}

export const PurposeOfConsultationForm = ({
  host,
  handleUpdateInfo,
}: Props) => {
  const {
    values: { purpose },
    isValid,
    setFieldValue,
  } = useFormikContext<FormValues>();
  const classes = useStyle();

  const onPurposeChange = useCallback(
    (checked: boolean, purposeValue: ConsultationPurposeEnum) => {
      if (checked) {
        setFieldValue("purpose", purposeValue);
        const duration = getDurationFromPurpose(purposeValue);
        handleUpdateInfo({ purpose: purposeValue, duration });
      }
    },
    [handleUpdateInfo, setFieldValue]
  );

  return (
    <Box className={classes.wrapper}>
      <Typography variant="subtitle2">Purpose of Consultation *</Typography>
      <Form className={classes.form}>
        <Box className={classes.purpose}>
          <Field
            component={RadioGroup}
            column
            name="purpose"
            label="Purpose of Consultation"
            required
            value={purpose}
          >
            {purposeValues
              .filter(
                (i) =>
                  ![
                    ConsultationPurposeEnum.THREE_WEEKS_CHECK_IN,
                    ConsultationPurposeEnum.SIX_WEEKS_CHECK_IN,
                    ConsultationPurposeEnum.TREATMENT_CHANGES,
                  ].includes(i)
              )
              .map((purposeValue) => (
                <Box key={purposeValue}>
                  <FormControlLabel
                    value={purposeValue}
                    control={<Radio color="primary" />}
                    onChange={(_, checked) => {
                      onPurposeChange(checked, purposeValue);
                    }}
                    label={
                      <PurposeOfConsultationLabel
                        purpose={purposeValue}
                        host={host}
                      />
                    }
                  />
                  {purposeValue ===
                    ConsultationPurposeEnum.INITIAL_PCM_CONSULTATION && (
                    <Box mb={1}>
                      <Typography variant="caption">
                        <i>
                          For your initial PCM consultation, it is essential to
                          book this 30 minutes consultation.
                          <br />
                          Please do not select any of the below options if you
                          haven&apos;t had a consultation with one of our
                          PCM&apos;s yet, otherwise we will require you to
                          rebook for this consultation.
                        </i>
                      </Typography>
                    </Box>
                  )}
                  {purposeValue ===
                    ConsultationPurposeEnum.BLOOD_TEST_RESULT && (
                    <Box mb={1}>
                      <Typography variant="caption">
                        <i>
                          For initial, 6 week and ad hoc results only, do not
                          book for 6 month and 12 month blood test results
                        </i>
                      </Typography>
                    </Box>
                  )}
                </Box>
              ))}
          </Field>
        </Box>
        <Box className={classes.comment}>
          <Typography variant="caption">
            Please, share any comments you want us to consider for the meeting.
          </Typography>
          <Field
            component={TextField}
            name="comment"
            placeholder="Comments"
            multiline
            rows={5}
          />
        </Box>

        <Box className={classes.actions}>
          <Button
            variant="contained"
            color="primary"
            className={classes.confirm}
            type="submit"
            disabled={!isValid}
          >
            NEXT
          </Button>
        </Box>
      </Form>
    </Box>
  );
};
