import {
  clientFacingMeetingReschedulingErrorMessage,
  clientFacingMeetingSchedulingErrorMessage,
  crmFacingMeetingReschedulingErrorMessage,
  crmFacingMeetingSchedulingErrorMessage,
  MeetingSchedulingErrorMessageEnum,
} from "@deep-consulting-solutions/bmh-constants";

export const getErrorMessages = (
  errorMessage: string | MeetingSchedulingErrorMessageEnum,
  host: "client" | "crm",
  isReschedule: boolean,
  isFromEmail: boolean
) => {
  let errorSubheader =
    "Please, retry or select another day or time for the PCM Consultation.";
  const message = errorMessage as MeetingSchedulingErrorMessageEnum;

  const errorTitleObject =
    host === "client"
      ? {
          reschedule: "Sorry! Your rescheduling could not be completed!",
          schedule: "Sorry! Your booking could not be completed!",
        }
      : {
          reschedule: "Sorry! Rescheduling could not be completed!",
          schedule: "Sorry! Scheduling could not be completed!",
        };

  const errorTitle = errorTitleObject[isReschedule ? "reschedule" : "schedule"];

  if (!Object.values(MeetingSchedulingErrorMessageEnum).includes(message)) {
    return {
      errorTitle,
      errorHeader: errorMessage,
      errorSubheader,
      canRetry: true,
      canRebook: true,
      canClose: false,
      withLink: false,
      showBreakdown: false,
      isMessageBold: false,
    };
  }

  errorSubheader =
    "Please, select another day or time for the PCM Consultation.";

  if (
    errorMessage === MeetingSchedulingErrorMessageEnum.PCM_ALREADY_SCHEDULED
  ) {
    errorSubheader = "";
  }

  const hostObject =
    host === "client"
      ? {
          reschedule: clientFacingMeetingReschedulingErrorMessage,
          schedule: clientFacingMeetingSchedulingErrorMessage,
        }
      : {
          reschedule: crmFacingMeetingReschedulingErrorMessage,
          schedule: crmFacingMeetingSchedulingErrorMessage,
        };

  const errorHeaderObject = isReschedule
    ? hostObject.reschedule
    : hostObject.schedule;

  const errorHeader = errorHeaderObject[message];
  const canClose =
    errorMessage === MeetingSchedulingErrorMessageEnum.PCM_ALREADY_SCHEDULED &&
    !isReschedule &&
    !isFromEmail;

  const canRebook =
    isReschedule ||
    errorMessage !== MeetingSchedulingErrorMessageEnum.PCM_ALREADY_SCHEDULED;

  const withLink =
    errorMessage === MeetingSchedulingErrorMessageEnum.PCM_ALREADY_SCHEDULED &&
    !isReschedule &&
    host === "crm";

  const showBreakdown =
    errorMessage === MeetingSchedulingErrorMessageEnum.PCM_ALREADY_SCHEDULED &&
    !isReschedule &&
    host === "client";

  const isMessageBold =
    errorMessage === MeetingSchedulingErrorMessageEnum.PCM_ALREADY_SCHEDULED &&
    !isReschedule;

  return {
    errorTitle,
    errorHeader,
    errorSubheader,
    canRetry: false,
    canRebook,
    canClose,
    withLink,
    showBreakdown,
    isMessageBold,
  };
};
