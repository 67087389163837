import { BookPCMConsultationDialog } from "components/BookPCMConsultationDialog";
import Loader from "components/Loader";
import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { schedulingActions, schedulingSelectors } from "redux/scheduling";
import { AppDispatch } from "redux/store";
import { BookPCMConsultationContext } from "./context";

export const BookPCMConsultationProvider: FC = ({ children }) => {
  const upcomingMCM = useSelector(schedulingSelectors.getUpcomingMCM);
  const schedulingInfo = useSelector(schedulingSelectors.getSchedulingInfo);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch<AppDispatch>();

  const [
    showBookPCMConsultationDialog,
    setShowBookPCMConsultationDialog,
  ] = useState(false);

  const canOpenBookPCMConsultationDialog = useMemo(() => {
    const hasUpcomingConsultation = !!upcomingMCM && !!schedulingInfo;
    return !hasUpcomingConsultation;
  }, [schedulingInfo, upcomingMCM]);

  const handleOpenBookPCMConsultationDialog = useCallback(() => {
    setShowBookPCMConsultationDialog(true);
  }, []);

  const handleCloseBookPCMConsultationDialog = useCallback(() => {
    setShowBookPCMConsultationDialog(false);
  }, []);

  const getAssignedPCM = useCallback(async () => {
    setLoading(true);
    await dispatch(schedulingActions.fetchAssignedMCMInfoAndTimezone());
    setLoading(false);
  }, [dispatch]);

  useEffect(() => {
    getAssignedPCM();
  }, [getAssignedPCM]);

  return (
    <BookPCMConsultationContext.Provider
      value={{
        handleOpenBookPCMConsultationDialog,
        showBookPCMConsultationDialog,
        handleCloseBookPCMConsultationDialog,
        canOpenBookPCMConsultationDialog,
      }}
    >
      {children}
      <BookPCMConsultationDialog />
      <Loader open={loading} />
    </BookPCMConsultationContext.Provider>
  );
};
