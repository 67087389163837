import { truncate } from "lodash";
import React from "react";
import {
  Avatar,
  Box,
  Typography,
  makeStyles,
  Tooltip,
} from "@material-ui/core";
import { MCMInfo } from "@deep-consulting-solutions/bmh-constants";

import { useResponsive } from "hooks";

const truncateMCMName = ({ name }: MCMInfo) => {
  return {
    name,
    truncated: truncate(name, {
      length: 15,
    }),
  };
};

interface StyleProps {
  index: number;
}

const useStyle = makeStyles(({ spacing: s, breakpoints: b, palette: p }) => ({
  avatar: ({ index }: StyleProps) => ({
    height: "64px",
    width: "64px",
    marginRight: s(index ? 0 : 1),
    position: "relative",
    left: `-${index * 32}px`,
    [b.down("sm")]: {
      height: "40px",
      width: "40px",
      left: `-${index * 20}px`,
    },
  }),
  subtitle: {
    color: p.secondary.dark,
  },
}));

interface MCMAvatarProps {
  mcm: MCMInfo;
  onlyImage?: boolean;
  index?: number;
}

export const MCMAvatar: React.FC<MCMAvatarProps> = ({
  mcm,
  onlyImage,
  index,
}) => {
  const { isXSDown, isSMDown } = useResponsive();
  const classes = useStyle({ index: index || 0 });
  const { name, truncated } = truncateMCMName(mcm);
  const mcmName = isSMDown ? name : truncated;
  return (
    <>
      <Avatar alt={name} src={mcm.avatar || ""} className={classes.avatar} />
      {!onlyImage && (
        <Box>
          <Tooltip title={mcmName === name ? "" : name}>
            <Typography
              variant={isXSDown ? "subtitle2" : "h4"}
              color="textPrimary"
            >
              {truncateMCMName(mcm)[isSMDown ? "name" : "truncated"]}
            </Typography>
          </Tooltip>
          <Typography
            variant={isXSDown ? "caption" : "body2"}
            className={classes.subtitle}
          >
            Your Personal Case Manager
          </Typography>
        </Box>
      )}
    </>
  );
};
