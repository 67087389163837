import React from "react";
import {
  ButtonProps,
  Button as MUIButton,
  makeStyles,
} from "@material-ui/core";

const DEFAULT_BORDER_COLOR = "#E0DCDC";

interface StyleProps {
  isStatic?: boolean;
  color: string;
  borderColor: string;
}

const useStyle = makeStyles(({ spacing: s, typography: typo }) => ({
  button: ({ isStatic }: StyleProps) => ({
    background: "white",
    padding: `${s(1)}px 0`,
    border: `1px solid ${DEFAULT_BORDER_COLOR}`,
    width: "56px",
    height: "56px",
    boxShadow: `0 0 ${s(0.3)}px ${DEFAULT_BORDER_COLOR}`,
    "&:hover": {
      background: isStatic ? "white" : "#F6F8FB",
    },
  }),
  fullWidthBtn: ({ color, borderColor }: StyleProps) => ({
    background: "white",
    fontSize: typo.h5.fontSize,
    border: `1px solid ${borderColor}`,
    width: "100%",
    textTransform: "none",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    padding: s(1),
    color,
    fontWeight: 500,
    textAlign: "left",
    "&:hover": {
      background: "#F6F8FB",
    },
  }),
  fullWidthIcon: {
    paddingLeft: s(1),
    paddingRight: s(2),
    width: "57.03px",
    height: "24px",
  },
  image: {
    height: "24px",
  },
  imageFacebook: {
    height: "29px",
  },
}));
interface Props extends Omit<ButtonProps, "color"> {
  isStatic?: boolean;
  withText?: string;
  icon: string;
  name: string;
  color: string;
  borderColor?: string;
}

const SocialButton: React.FC<Props> = ({
  isStatic,
  withText,
  icon,
  name,
  color,
  borderColor,
  className,
  ...props
}) => {
  const classes = useStyle({
    isStatic,
    color,
    borderColor: borderColor || color,
  });

  const classNameBase = withText ? classes.fullWidthBtn : classes.button;
  const classNameFull = `${classNameBase} ${className || ""}`;

  return (
    <MUIButton className={classNameFull} {...props}>
      {!!withText && (
        <>
          <img src={icon} alt={name} className={classes.fullWidthIcon} />
          <span>
            {withText} with {name}
          </span>
        </>
      )}

      {!withText && (
        <img
          src={icon}
          alt={name}
          className={
            name === "Facebook" ? classes.imageFacebook : classes.image
          }
        />
      )}
    </MUIButton>
  );
};

export default SocialButton;
