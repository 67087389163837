import React from "react";
import { ButtonProps } from "@material-ui/core";
import SocialButton from "components/SocialButton";
import AppleIcon from "./apple.svg";

interface Props extends ButtonProps {
  isStatic?: boolean;
  withText?: string;
}

const AppleButton: React.FC<Props> = (props) => {
  return (
    <SocialButton icon={AppleIcon} name="Apple" color="#000000" {...props} />
  );
};

export default AppleButton;
